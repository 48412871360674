import React, { useEffect, useState } from "react";

import {
  Button,
  Modal,
  Input,
  Select,
  Checkbox,
  Typography,
  Card,
  Calendar as AntdCalendar,
  List,
  Divider,
  Form,
  Row,
  Col,
} from "antd";
import { CloseOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

import { Dayjs } from "dayjs";

import dayjs from "dayjs";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../../shared";
import AddCalendar from "./AddCalendar";
import PopUpCalender from "./CalendarPopup";
// import CalenderForm from './calenderForm';
const { Option } = Select;
const { Title, Text } = Typography;
const Calendar = () => {
  const [selectedDate, setSelectedDate] = useState();
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [selectedMails, setSelectedMails] = useState([]);
  const [Calenders, setCalenders] = useState([]);
  const [importedMail, setImportedMails] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [createForm, setCreateForm] = useState(false);
  const [data, setData] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);

  const handleCreateNewCalender = () => {
    // const data = {
    //     ownerEmail: 'theoceann@theoceann.ai',
    //     subject: 'Prep for customer meeting',
    //     body: 'Does this time work for you?',
    //     start: '2024-10-22T13:00:00',
    //     end: '2024-10-22T14:00:00',
    //     location: 'Cordova conference room',
    //     attendees: [
    //       { email: 'AdeleV@contoso.com', name: 'Adele Vance', type: 'required' },
    //       { email: 'arun@theoceann.ai', name: 'Arun Vance', type: 'optional' },
    //     ],
    //   };
    setCreateForm(true);
    setData(selectedDate);
  };
  const [eventDetails, setEventDetails] = useState(null);
  const [eventDetailsModal, setEventDetailsModal] = useState(false);
  const [todays, setTodaysCalender] = useState([]);

  const getTimeDifferenceCalender = (startData, endData) => {
    // Parse the start and end date-times using moment
    const start = moment(startData);
    const end = moment(endData);

    // Check if the start and end are on the same day
    if (start.isSame(end, "day")) {
      // Calculate the difference in hours and minutes if same day
      const duration = moment.duration(end.diff(start));
      const hours = Math.floor(duration.asHours()); // Full hours
      const minutes = duration.minutes(); // Remaining minutes

      if (hours > 0 && minutes > 0) {
        return `${hours}h ${minutes}min`;
      } else if (hours > 0) {
        return `${hours}h`;
      } else {
        return `${minutes}min`;
      }
    } else {
      // If not the same day, calculate day difference
      const dayDifference = end.diff(start, "days");
      return `${dayDifference} day${dayDifference !== 1 ? "s" : ""}`;
    }
  };
  const callMails = async () => {
    try {
      // const userData: any = localStorage.getItem('userData')
      let url = `${process.env.REACT_APP_MT_URL}accounts/outlook-mails`;
      const response = await getAPICall(url);
      // console.log("resposneee", response);
      if (response.status == true) {
        let mails = response?.data?.map((item) => item.email);
        setImportedMails(mails);
        setSelectedMails(mails);
        getCalender(mails);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterEventsByToday = (data, filterDate = null) => {
    const today = new Date();

    today.setHours(0, 0, 0, 0); // Set time to 00:00:00 for comparison

    return data.filter((event) => {
      const eventDate = new Date(event.start.dateTime);
      eventDate.setHours(0, 0, 0, 0);
      if (filterDate) {
        // console.log(filterDate == eventDate.getDate());
        return eventDate.getDate() === filterDate;
      } else {
        return eventDate.getDate() === today.getDate();
      }
    });
  };
  const getCalender = async (email, month = null, year = null) => {
    try {
      let url = `${process.env.REACT_APP_MT_URL}mail/get-calendar-events`;
      if (month) {
        url = `${process.env.REACT_APP_MT_URL}mail/get-calendar-events?month=${month}&year=${year}`;
      }
      const payload = {
        emails: email,
      };
      const response = await postAPICall(url, payload, "post");
      // console.log("responseee", response);
      if (response?.status == true) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to 00:00:00 for comparison
        const filter = filterEventsByToday(response?.data);

        setTodaysCalender(filter);
        setCalenders(response?.data);
      } else {
        console.log("some error occured");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callMails();
  }, [createForm]);

  // Handle checkbox selection
  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedProviders([...selectedProviders, value]);
    } else {
      setSelectedProviders(selectedProviders.filter((item) => item !== value));
    }
  };

  const handleImportCalender = async (e) => {
    e.preventDefault();
    if (selectedProviders.length > 0) {
      let url = `${process.env.REACT_APP_MT_URL}mail/import-outlook-calendar`;
      const payload = {
        emails: selectedProviders,
      };
      const response = await postAPICall(url, payload, "post");
      // console.log(response);
      if (response.status == true) {
        openNotificationWithIcon("success", "Events imported");
      }

      setOpenEmailModal(false);
    }
  };

  const handleTableCalender = (value) => {
    if (value === "all") {
      setSelectedMails(importedMail);
      getCalender(importedMail);
    } else {
      setSelectedMails(value);
      getCalender(value);
    }
  };

  const handleSelectChange = (value) => {
    // console.log("value677", value);
    if (value.includes("Select All")) {
      if (importedMail.length + 1 === value.length) {
        setSelectedMails([]);
      } else {
        handleTableCalender(importedMail);
        setSelectedMails([...importedMail]);
      }
    } else {
      handleTableCalender(value);
      setSelectedMails(value);
    }
  };

  // Handle the date change
  const onSelect = (date) => {
    // console.log("dateeeee555", date);
    if (
      selectedDate?.month() == date?.month() &&
      selectedDate?.year() == date?.year()
    ) {
      let selectedData = filterEventsByToday(Calenders, date?.date());
      if (selectedData?.length > 0) {
        setTodaysCalender(selectedData);
      } else {
        if (
          selectedDate?.month() == date?.month() &&
          selectedDate?.year() == date?.year() &&
          date.date() == selectedDate?.date()
        ) {
          handleCreateNewCalender();
        }

        setTodaysCalender([]);
      }
    } else {
      getCalender(selectedMails, date?.month() + 1, date?.year());
    }
    setSelectedDate(date);

    // getCalender(selectedMails, date?.month() + 1, date?.year());
    // getCalender()
    // openNotification(date); // Show notification when date is selected
  };

  // Optional: Add some data for special dates (with badges)
  const getListData = (value) => {
    let listData = [];

    Calenders?.forEach((event) => {
      const eventDate = new Date(event?.start?.dateTime);

      // Check if day, month, and year match
      if (
        value.date() === eventDate.getDate() &&
        value.month() === eventDate.getMonth() && // Month is 0-indexed, same as Date object
        value.year() === eventDate.getFullYear()
      ) {
        listData.push({ type: "warning", content: event });
      }
    });

    return listData.length > 0 ? listData : [];
  };

  // Render the cells in the calendar with event data
  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.length > 0 && (
          <>
            <li
              key={0}
              onClick={() => {
                setEventDetailsModal(true);
                setEventDetails(listData[0]?.content);
              }}
              style={{ color: "green" }}
            >
              <div className="calender-cell">
                {listData[0]?.content?.subject}
              </div>
            </li>

            {listData.length > 1 && (
              <li key="remaining">
                <div className="calender-cell-button">
                  +{listData.length - 1}
                </div>
              </li>
            )}
          </>
        )}
      </ul>
    );
  };
  const AddCalendarEvent = () => {
    setCreateForm(false);
    getCalender(selectedMails, selectedDate?.month() + 1, selectedDate?.year());
  };

  const handleSelectAllChange = (checked) => {
    if (checked) {
      setSelectedMails(importedMail); // Select all mails
    } else {
      setSelectedMails([]); // Deselect all
    }
  };

  const isAllSelected =
    importedMail.length > 0 && selectedMails.length === importedMail.length;

  const onClose = () => {
    setOpenAddModal(false);
  };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <Button
          type="primary"
          icon={<CalendarOutlined />}
          onClick={() => setOpenAddModal(true)}
        >
          Create calendar event
        </Button>

        <Button
          type="primary"
          icon={<CalendarOutlined />}
          onClick={() => setOpenEmailModal(true)}
        >
          Import Calendar
        </Button>

        <div style={{ marginLeft: "auto" }}>
          <Select
            style={{ width: 320 }}
            placeholder="Select Mail"
            value={selectedMails}
            dropdownRender={() => (
              <div>
                {/* Select All Option */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "4px 8px",
                    borderBottom: "1px solid #f0f0f0",
                  }}
                >
                  <Checkbox
                    checked={isAllSelected}
                    indeterminate={
                      selectedMails.length > 0 &&
                      selectedMails.length < importedMail.length
                    }
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const newSelectedMails = checked ? [...importedMail] : [];
                      setSelectedMails(newSelectedMails);
                      handleSelectChange(newSelectedMails); // Explicitly call handleSelectChange
                    }}
                  >
                    Select All
                  </Checkbox>
                </div>

                {/* Individual Options */}
                {importedMail.map((mail) => (
                  <div
                    key={mail}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "4px 8px",
                    }}
                  >
                    <Checkbox
                      checked={selectedMails.includes(mail)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        const newSelectedMails = isChecked
                          ? [...selectedMails, mail]
                          : selectedMails.filter((item) => item !== mail);
                        setSelectedMails(newSelectedMails);
                        handleSelectChange(newSelectedMails); // Explicitly call handleSelectChange
                      }}
                    >
                      {mail}
                    </Checkbox>
                  </div>
                ))}
              </div>
            )}
          ></Select>
        </div>
      </div>

      <div
        className="main-calender-container"
        style={{ display: "flex", marginTop: 16 }}
      >
        <div className="left-calender" style={{ flex: 1 }}>
          <Card
            style={{
              borderRadius: "8px",
              boxShadow: 3,
              height: "88vh",
              overflow: "scroll",
              width: "100%",
            }}
          >
            <AntdCalendar
              fullscreen={false}
              value={selectedDate}
              onSelect={onSelect}
              className="small-calendar"
              mode="month"
            />
          </Card>
        </div>

        <div className="middle-calender" style={{ flex: 3, marginRight: 16 }}>
          <Card>
            <AntdCalendar
              fullscreen
              dateCellRender={dateCellRender}
              value={selectedDate}
              onSelect={onSelect}
              className="bigCalender"
              mode="month"
            />
          </Card>
        </div>

        <div className="right-side-notification" style={{ flex: 1.5 }}>
          <Card
            style={{
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              height: "88vh",
              overflowY: "scroll",
              width: "100%",
            }}
          >
            {todays?.length > 0 ? (
              todays?.map((event, index) => {
                const eventDate = new Date(event?.start?.dateTime);
                const eventEndDate = new Date(event?.end?.dateTime);
                const isPastEvent = new Date() > eventEndDate;
                return (
                  <div key={index}>
                    {index === 0 && (
                      <Divider>
                        <Title level={5} style={{ fontWeight: "bold" }}>
                          {moment(eventDate).format("ddd, DD MMM") || "N/A"}
                        </Title>
                      </Divider>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                        padding: "12px",
                        boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.5)",
                        cursor: "pointer",
                        marginBottom: "12px",
                        backgroundColor: isPastEvent
                          ? "rgb(252 239 240)"
                          : "#d2e8fc",
                        position: "relative",
                      }}
                      onClick={() => {
                        setEventDetailsModal(true);
                        setEventDetails(event);
                      }}
                    >
                      {/* Vertical bar */}
                      <div
                        style={{
                          backgroundColor: "#1a73e8",
                          width: "4px",
                          height: "100%",
                          marginRight: "12px",
                        }}
                      ></div>

                      {/* Event details */}
                      <Row style={{ flex: 1 }} gutter={[10, 10]}>
                        <Col>
                          <Text strong>
                            {moment(event?.start?.dateTime).format("HH:mm")}
                          </Text>
                          <Text
                            type="secondary"
                            style={{
                              display: "block",
                              textAlign: "center",
                              marginTop: "8px",
                              color: "#1677FF",
                            }}
                          >
                            {getTimeDifferenceCalender(
                              event?.start?.dateTime,
                              event?.end?.dateTime
                            )}
                          </Text>
                        </Col>
                        <Col flex="auto">
                          <Text strong>{event?.subject}</Text>
                          <Text
                            type="secondary"
                            style={{
                              display: "block",
                              marginTop: "8px",
                              color: "#1677FF",
                            }}
                          >
                            {event?.location?.displayName || ""}
                          </Text>
                        </Col>
                      </Row>
                      <div
                        style={{
                          position: "absolute",
                          top: "8px", 
                          right: "12px", 
                          color: isPastEvent ? "red" : "green",
                          fontWeight: "bold",
                        }}
                      >
                        {isPastEvent ? "Completed" : "Upcoming"}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                No event for the day
              </div>
            )}
          </Card>
        </div>
      </div>

      <Modal
        visible={openEmailModal}
        title="Select Emails to import calendar"
        onCancel={() => {
          setOpenEmailModal(false);
          setSelectedProviders([]);
        }}
        footer={null}
      >
        <Form>
          {importedMail.map((mail) => (
            <Form.Item key={mail}>
              <Checkbox
                checked={selectedProviders.includes(mail)}
                onChange={handleCheckboxChange}
                value={mail}
              >
                {mail}
              </Checkbox>
            </Form.Item>
          ))}
        </Form>
        <Button
          type="primary"
          onClick={handleImportCalender}
          style={{ marginTop: 16 }}
        >
          Submit
        </Button>
      </Modal>

      <Modal
        visible={eventDetailsModal}
        title="Event Details"
        onCancel={() => setEventDetailsModal(false)}
        footer={null}
      >
        <div>
          <PopUpCalender data={eventDetails} />
        </div>
      </Modal>

      {openAddModal ? (
        <AddCalendar
          open={openAddModal}
          onClose={onClose}
          getCalender={getCalender}
        />
      ) : null}
    </div>
  );
};

export default Calendar;
