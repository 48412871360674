import { Modal } from "antd";
import React from "react";
import SpotPrice from "../../port-to-port/SpotPrice";
import TCOV from "../../chartering/routes/tcov";

const VoyageEstimateModal = ({
  showVoyageEstimateModal,
  setShowVoyageEstimateModal,
}) => {


  return (
    <Modal
      title="Voyage Estimate"
      open={showVoyageEstimateModal}
      closable={true}
      onCancel={() => setShowVoyageEstimateModal(false)}
      footer={null}
      style={{
        top: 20,
      }}
      width={"90%"}
      bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <TCOV stayOnMOdal={true} />
    </Modal>
  );
};

export default VoyageEstimateModal;