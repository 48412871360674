class ReduxState {
  constructor() {
    this.formName = null;
    this.reduxStore = {};
  }

  setForm = (formName) => {
    this.formName = formName;
    this.reduxStore[this.formName] = {};
  };

  getForm = () => this.formName;

  addGroupProperty = (groupName, value, index) => {
    // console.log({groupName, value, index,formName:this.getForm()})
    let oi = {};
    if (!this.reduxStore.hasOwnProperty(this.formName)) {
      this.reduxStore[this.formName] = {};
    }

    if (index >= 0) {
      if (!this.reduxStore[this.formName].hasOwnProperty(groupName)) {
        this.reduxStore[this.formName][groupName] = [];
      }

      let oldItems = this.reduxStore[this.formName][groupName];

      if (oldItems && oldItems[index]) {
        oi = Object.assign(oi, oldItems[index], value);
      } else if (
        this.formName === "new_laytime_calculation_form" ||
        this.formName === "freight-commission-invoice" ||
        this.formName === "tco_commission_entry" ||
        this.formName === "time_charter_in_form" ||
        this.formName === "tco_form" ||
        this.formName === "tde_form" ||
        this.formName == "other_expense_invoice_form" ||
        this.formName === "deviation_invoice_form" ||
        this.formName === "bunker_requirement_form" ||
        this.formName == "coa_vci_form" ||
        this.formName === "vcargo_in_contract_form" ||
        this.formName === "port_bunker_and_activity" ||
        this.formName === "INITIAL_FRIEGHT_INVOICE" ||
        this.formName === "head_fixture_form"
      ) {
        /*Fix: When adding a new row to the index_table, the entire array is included 
      as an entry within the newly added element.*/
        if (!oldItems[index] && value[groupName]) {
          oi = { ...value[groupName][value[groupName]?.length - 1] };
        } else {
          oi = { ...oldItems[index], ...value };
        }
      }
      //  below line is added because item is not selected in  vessel offhire ticket 1648
      else if (
        oldItems &&
        !oldItems[index] &&
        this.formName !== "time_charter_in_form"
      ) {
        oi = { ...value };
      }
      // ticket 1648 in the tci and tco when we select vessel offhire it is selcting in one time, but not going to save.
      oldItems[index] = oi;

      if (groupName === "offhiredelays" && this.formName == "tco_form") {
        // this.reduxStore[this.formName][groupName] = value[groupName];
      }
      // when select port in coa and vc purchase then the nested loadoption is coming . so fix the issue .
      else if (
        // this.formName == "coa_vci_form" ||
        // this.formName === "vcargo_in_contract_form" ||
        this.formName === "vessel_schedule" ||
        // this.formName === "port_bunker_and_activity" ||
        this.formName === "cargo_contract_form" ||
        //this.formName == "VOYAGE_RELET" ||
        this.formName == "tab_coa_linked_trade_cargos" ||
        this.formName == "coa_vci_linked_trade_cargos" ||
        this.formName == "coa_form" ||
        // this.formName === "tde_form" ||
        // this.formName == "other_expense_invoice_form" ||
        // this.formName === "deviation_invoice_form" ||
        this.formName === "bunker_invoice" ||
        // this.formName === "bunker_requirement_form" ||
        this.formName == "tc_commission_entry" ||
        this.formName == "delay_form" ||
        this.formName == "time_charter_in_form" ||
        this.formName === "bunker_purchased_order_form"
        //this.formName == "other_cost_detail_form" || this.formName=='freight-commission-invoice'
      ) {
        if (!oldItems[index]) {
          this.reduxStore[this.formName][groupName] = value[groupName];
        } else {
          if (value[groupName]) {
            this.reduxStore[this.formName][groupName] = value[groupName];
          } else {
            this.reduxStore[this.formName][groupName] = oldItems;
          }
        }
      } else {
        this.reduxStore[this.formName][groupName] = oldItems;
      }
    } else if (!index && value.hasOwnProperty(groupName)) {
      if (!this.reduxStore[this.formName].hasOwnProperty(groupName)) {
        this.reduxStore[this.formName][groupName] = [];
      }
      this.reduxStore[this.formName][groupName] = value[groupName];
    } else if (!index) {
      if (!this.reduxStore[this.formName].hasOwnProperty(groupName)) {
        this.reduxStore[this.formName][groupName] = {};
      }
      oi = Object.assign({}, this.reduxStore[this.formName][groupName], value);
      this.reduxStore[this.formName][groupName] = oi;
    }
  };

  createReducer = (store = {}, action) => {
    // console.log("redux action",action)
    if (
      action &&
      action.hasOwnProperty("formName") &&
      action["formName"] !== "" &&
      action["formName"] &&
      this.formName !== action["formName"]
    ) {
      this.formName = action["formName"];
    }
    if (action && action.type === "add") {
      if (action.hasOwnProperty("groupName") && action.groupName) {
        this.addGroupProperty(action.groupName, action.value, action.index);
      } else if (action.hasOwnProperty("groupName") && !action.groupName) {
        let oldItems = Object.assign(this.reduxStore[this.formName], {});
        this.reduxStore[this.formName] = Object.assign(oldItems, action.value);
      }
    } else if (action && action.type === "delete") {
      if (
        action.hasOwnProperty("groupName") &&
        action.groupName &&
        this.reduxStore[this.formName][action.groupName] &&
        this.reduxStore[this.formName][action.groupName][action.index]
      ) {
        let elements = [];
        this.reduxStore[this.formName][action.groupName].map((e, idx) => {
          if (idx !== action.index) elements.push(e);
        });

        this.reduxStore[this.formName][action.groupName] = elements;
      }
    } else if (action && action.type === "reset") {
      this.reduxStore[this.formName] = {};
    } else if (action && action.type === "edit_info") {
      this.reduxStore[this.formName] = action.editFormData;
    }

    return this.reduxStore;
  };
}

export default new ReduxState();
