import { Popover, Select } from 'antd'
import React from 'react'
import PopToolTip from './Tooltip';

const ToolTipPopover = ({ row, children }) => {
    if (row.group_name === 'Port Itinerary' || row.group_name === 'Cargos' || row.group_name === 'Bunker Details' || row.group_name === "Port Date Details" || row.group_name === "CII Dynamics" || row.group_name === "EU ETS" || row.group_name === "Delivery Term" ||row.group_name === 'Port Itinerary' || row.group_name === 'Cargos' || row.group_name === 'Bunker Details' || row.group_name === "Port Date Details" || row.group_name === "CII Dynamics" || row.group_name === "EU ETS" || row.group_name === "Hire Term" || row.group_name === "Commission Term" || row.group_name === "Other Term" || row.group_name === "Bunkers" || row.group_name === "TC Bunker Adjustment" || row.group_name === "OffHire Delay" || row.group_name === "Vessel OffHire Delay" || row.group_name === "Voyage History" || row.group_name === "Port Consp. Table (Per Day)" || row.group_name === "Sea Spd Consp. Table (Per Day)" || row.group_name === "OffHire Delays"|| row.group_name === ".." || row.group_name === "..." ) {
        return children
    }    

    return (
        <Popover
            placement="right"
            destroyTooltipOnHide={true}
            content={<PopToolTip text={row.f_tool_tip} title={row.name} />}
            overlayClassName="custom-tooltip"
        >
            {children}
        </Popover>
    )
}

export default ToolTipPopover