import React, { useEffect, useState } from "react";


export const VoyageReletCalcArr = [
    {
      title: "Revenue",
      formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
    },
  
    {
      title: "Freight (Cargo )",
      formula:
        "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in sell Cargo",
    },
  
    {
      title: "VC Cargo Commision",
      formula:
        "Sum of ((((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum)*B.Comm %) in sell Cargo",
    },
  
    {
      title: "Other Revenue",
      formula: "Sum of (Extra Rev) in sell Cargo",
    },
  
    {
      title: "Gross Revenue",
      formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
    },
  
    {
      title: "Net Revenue",
      formula: "Freight (Cargo ) + Other Revenue + (Demurrage - Despatch)",
    },
  
    // expenses:Expenses
  
    {
      title: "Expenses",
      formula:
        "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in Purchase Cargo",
    },
  
    {
      title: "VC Frieght",
      formula:
        "Sum of ((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum in Purchase Cargo",
    },
  
    {
      title: "VC Frieght Commission",
      formula:
        "Sum of ((((CP Qty*opt% +CP Qty)*frt Rate or Worldscale) or Lumpsum)*B.Comm %) in Purchase Cargo",
    },
  
    {
      title: "Other Expenses",
      formula: "Other Cost  + Ballast Bonus",
    },
  
    {
      title: "Gross Expenses",
      formula: "VC Frieght + (Other Cost  + Ballast Bonus)",
    },
  
    {
      title: "Net Expenses",
      formula: " Gross Expenses - VC Frieght Commission;",
    },
  
    // yoyage result
  
    {
      title: "Voyage Result",
      formula: "Net Revenue- Net Expenses",
    },
    {
      title: "Profit (Loss)",
      formula: "Net Revenue- Net Expenses",
    },
  
    {
      title: "Daily Profit (Loss)",
      formula: "(Net Revenue- Net Expenses)/Total Voyage Days",
    },
  ];



  const VoyageReletPnl=({ desc, tooltipData, title, formData })=>{
    const [caluculation, setCalculation] = useState("");

    useEffect(() => {
    
        if (title === "Revenue") {
          setCalculation(
            `${tooltipData?.revenues_frate} + ${tooltipData?.extra_rev} + (${tooltipData?.demmurage_amt_rev} - ${tooltipData?.desmurage_amt_rev}) - ${tooltipData?.freightCommission_rev} = ${tooltipData?.rev_net_rev}`
          );
          // Code for "Revenue"
        } else if (title === "Freight (Cargo )") {
          const freightCalculations = formData?.cargos
            ?.filter((cargo) => cargo.sp_type === 186)
            ?.map((cargo) => {
              const cpQty = parseFloat(cargo.cp_qty) || 0;
              const optPercentage = parseFloat(cargo.opt_percentage) || 0;
              const fratRate = parseFloat(cargo.frat_rate) || 0;
              const worldScale = parseFloat(cargo.world_scale) || 0;
              const lumsum = parseFloat(cargo.lumsum) || 0;
    
              let calculationDetails;
    
              if (cargo.frt_type=="38") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * fratRate;
                calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${fratRate} = ${freightValue.toFixed(
                  2
                )} -- Flat Rate`;
              } else if (cargo.frt_type=="259") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * worldScale;
                calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${worldScale} = ${freightValue.toFixed(
                  2
                )}  -- World Scale`;
              } else {
                calculationDetails = `${lumsum.toFixed(2)}  -- Lumpsum`;
              }
    
              return `${calculationDetails}`;
            });
    
          freightCalculations.push(
            `Total Freight = ${tooltipData?.revenues_frate}`
          );
          setCalculation(freightCalculations.join("\n"));
    
          // Code for "Freight (Cargo )"
        } else if (title === "VC Cargo Commision") {
          const freightCalculations = formData?.cargos
            ?.filter((cargo) => cargo.sp_type === 186)
            ?.map((cargo) => {
              const cpQty = parseFloat(cargo.cp_qty) || 0;
              const optPercentage = parseFloat(cargo.opt_percentage) || 0;
              const fratRate = parseFloat(cargo.frat_rate) || 0;
              const worldScale = parseFloat(cargo.world_scale) || 0;
              const lumsum = parseFloat(cargo.lumsum) || 0;
              const bComm = parseFloat(cargo.b_commission) || 0;
    
              let calculationDetails;
    
              if (cargo.frt_type=="38") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * fratRate;
                calculationDetails = `${freightValue} * ${bComm}% = ${
                  freightValue * bComm * 0.01
                } -- Flat Rate`;
              } else if (cargo.frt_type=="259") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * worldScale;
                calculationDetails = `${freightValue} * ${bComm}% = ${
                  freightValue * bComm * 0.01
                } -- World Scale`;
              } else {
                calculationDetails = `${lumsum.toFixed(2)} * ${bComm}% = ${
                  lumsum.toFixed(2) * bComm * 0.01
                }  -- Lumpsum`;
              }
    
              return `${calculationDetails}`;
            });
    
          freightCalculations.push(
            `Total Freight commission = ${tooltipData?.freightCommission_rev}`
          );
          setCalculation(freightCalculations.join("\n"));
        } else if (title === "Other Revenue") {
          const freightCalculations = formData?.cargos
            ?.filter((cargo) => cargo.sp_type === 186)
            ?.map((cargo) => {
              const extraRev = parseFloat(cargo.extra_rev) || 0;
    
              return `${extraRev}`;
            });
    
          setCalculation(
            `${freightCalculations.join(" + ") || 0} = ${tooltipData?.extra_rev}`
          );
        } else if (title === "Gross Revenue") {
          setCalculation(
            `${tooltipData?.revenues_frate} + ${tooltipData?.extra_rev} + (${tooltipData?.demmurage_amt_rev} - ${tooltipData?.desmurage_amt_rev}) = ${tooltipData?.rev_gross_rev}`
          );
        } else if (title === "Net Revenue") {
          setCalculation(
            `${tooltipData?.revenues_frate} + ${tooltipData?.extra_rev} + (${tooltipData?.demmurage_amt_rev} - ${tooltipData?.desmurage_amt_rev}) - ${tooltipData?.freightCommission_rev} = ${tooltipData?.rev_net_rev}`
          );
        } else if (title === "Expenses" || title === "VC Frieght") {
          const freightCalculations = formData?.cargos
            ?.filter((cargo) => cargo.sp_type === 187)
            ?.map((cargo) => {
              const cpQty = parseFloat(cargo.cp_qty) || 0;
              const optPercentage = parseFloat(cargo.opt_percentage) || 0;
              const fratRate = parseFloat(cargo.frat_rate) || 0;
              const worldScale = parseFloat(cargo.world_scale) || 0;
              const lumsum = parseFloat(cargo.lumsum) || 0;
    
              let calculationDetails;
    
              if (cargo.frt_type=="38") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * fratRate;
                calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${fratRate} = ${freightValue.toFixed(
                  2
                )} -- Flat Rate`;
              } else if (cargo.frt_type=="259") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * worldScale;
                calculationDetails = `(${cpQty} * ${optPercentage}% + ${cpQty}) * ${worldScale} = ${freightValue.toFixed(
                  2
                )}  -- World Scale`;
              } else {
                calculationDetails = `${lumsum.toFixed(2)}  -- Lumpsum`;
              }
    
              return `${calculationDetails}`;
            });
    
          freightCalculations.push(`Expenses = ${tooltipData?.expenses_frate}`);
          setCalculation(freightCalculations.join("\n"));
        } else if (title === "VC Frieght Commission") {
          const freightCalculations = formData?.cargos
            ?.filter((cargo) => cargo.sp_type === 187)
            ?.map((cargo) => {
              const cpQty = parseFloat(cargo.cp_qty) || 0;
              const optPercentage = parseFloat(cargo.opt_percentage) || 0;
              const fratRate = parseFloat(cargo.frat_rate) || 0;
              const worldScale = parseFloat(cargo.world_scale) || 0;
              const lumsum = parseFloat(cargo.lumsum) || 0;
              const bComm = parseFloat(cargo.b_commission) || 0;
    
              let calculationDetails;
    
               if (cargo.frt_type=="38") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * fratRate;
                calculationDetails = `${freightValue} * ${bComm}% = ${
                  freightValue * bComm * 0.01
                } -- Flat Rate`;
              } else if (cargo.frt_type=="259") {
                const optValue = cpQty * (optPercentage / 100);
                const totalQty = cpQty + optValue;
                const freightValue = totalQty * worldScale;
                calculationDetails = `${freightValue} * ${bComm}% = ${
                  freightValue * bComm * 0.01
                } -- World Scale`;
              } else {
                calculationDetails = `${lumsum.toFixed(2)} * ${bComm}% = ${
                  lumsum.toFixed(2) * bComm * 0.01
                }  -- Lumpsum`;
              }
    
              return `${calculationDetails}`;
            });
    
          freightCalculations.push(
            `Total Freight commission = ${tooltipData?.freightCommission_exp}`
          );
          setCalculation(freightCalculations.join("\n"));
        } else if (title === "Other Expenses") {
          setCalculation(
            `${formData?.other_cost} + ${tooltipData?.bb} = ${
                formData?.other_cost + tooltipData?.bb
            }`
          );
        } else if (title === "Gross Expenses") {
          setCalculation(
            `${tooltipData?.expenses_frate} + (${formData?.other_cost} + ${tooltipData?.bb}) = ${tooltipData?.exp_gross_expense}`
          );
        } else if (title === "Net Expenses") {
          setCalculation(
            `${tooltipData?.exp_gross_expense} - ${tooltipData?.freightCommission_exp} = ${tooltipData?.exp_net_expense}`
          );
        } else if (title === "Voyage Result") {
          setCalculation(
            `${tooltipData?.rev_net_rev} - ${tooltipData?.exp_net_expense} = ${tooltipData?.itemValue}`
          );
        } else if (title === "Profit (Loss)") {
          setCalculation(
            `${tooltipData?.rev_net_rev} - ${tooltipData?.exp_net_expense} = ${tooltipData?.itemValue}`
          );
        } else if (title === "Daily Profit (Loss)") {
          setCalculation(
            `(${tooltipData?.rev_net_rev} - ${tooltipData?.exp_net_expense})/ ${
              tooltipData?.totalVoyageDays
            } = ${(tooltipData?.itemValue / tooltipData?.totalVoyageDays).toFixed(2)}`
          );
        } else {
          // Code for unknown titles
        }
      }, [title]);

    return (
        <div style={{ top: 100 }}>
          {/* <p>{desc}</p> */}
          <p>Actual Data</p>
          <pre>{caluculation}</pre>
        </div>
      );

  }

  export default VoyageReletPnl