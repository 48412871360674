import React, { useState, useEffect } from "react";
import AiLoader from "../components/PL/tcov/AiLoader";
import { postAPICall } from "..";
import "./AiPortCostAnalysis.scss";

const AiPortCostAnalysis = ({ vesData }) => {
  const [loading, setLoading] = useState(true);
  const [vesselData, setVesselData] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedPortData, setSelectedPortData] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const aiPayLoad = {
        dwt: vesData?.dwt || "Unknown",
        cargo_name: vesData?.cargos || "Unknown",
        port: vesData?.portitinerary || [],
      };

      const url = `${process.env.REACT_APP_URL}v1/recommendation_bp/cost_estimate`;
      const response = await postAPICall(url, aiPayLoad, "POST");
      const data = response?.cost_breakdown || [];
      setVesselData(data);
      setMenuItems(data.map((port) => Object.keys(port)[0])); // Extract port names
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [vesData]);

  const handlePortSelection = (portName) => {
    const selectedData = vesselData.find((portData) => portData[portName]);
    setSelectedPortData(selectedData ? selectedData[portName] : null);
    setSelectedMenu(portName);
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            height: "500px",
            justifyContent: "center",
          }}
        >
          <AiLoader text="Loading..." />
        </div>
      ) : (
        <div>
          <div className="menu-bar">
            {menuItems.map((port, index) => (
              <div
                key={index}
                className={`menu-item ${
                  selectedMenu === port ? "ai-active" : ""
                }`}
                onClick={() => handlePortSelection(port)}
              >
                {port}
              </div>
            ))}
          </div>

          {selectedPortData ? (
            <div className="port-card-container">
              <h2 className="port-name">{selectedMenu}</h2>
              <div className="port-card">
                {/* Equipment Usage */}
                {selectedPortData.equipment_usage && (
                  <div className="section">
                    <h3>Equipment Cost</h3>
                    {Object.entries(selectedPortData.equipment_usage).map(
                      ([key, value]) => (
                        <p key={key}>
                          <strong>{key}:</strong> ${value}
                        </p>
                      )
                    )}
                  </div>
                )}

                {/* Labor Costs */}
                {selectedPortData.labor_costs && (
                  <div className="section">
                    <h3>Labor Costs</h3>
                    {Object.entries(selectedPortData.labor_costs).map(
                      ([key, value]) => (
                        <p key={key}>
                          <strong>{key}:</strong> ${value}
                        </p>
                      )
                    )}
                  </div>
                )}

                {/* Port Charges */}
                {selectedPortData.port_charges && (
                  <div className="section">
                    <h3>Port Charges</h3>
                    {Object.entries(selectedPortData.port_charges).map(
                      ([key, value]) => (
                        <p key={key}>
                          <strong>{key}:</strong> ${value}
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
              {/* Total Estimated Cost */}
              {selectedPortData.total_estimated_cost && (
                <div className="total-cost">
                  <p>
                    <strong>Total Estimated Cost:</strong> $
                    {selectedPortData.total_estimated_cost}
                  </p>
                  {/* <h3></h3>
                  <span> ${selectedPortData.total_estimated_cost}</span> */}
                </div>
              )}
              {/* Display Data as JSON */}
              {/* <div className="json-display">
                <h3>Raw JSON Data</h3>
                <code>
                  <pre>{JSON.stringify(selectedPortData, null, 2)}</pre>
                </code>
              </div> */}
            </div>
          ) : (
            <div className="no-data-message">
              <p>Select a port to view details.</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AiPortCostAnalysis;
