import React, { useRef, useEffect, useState } from "react";
import ReactToPrint from "react-to-print";
import {
  Row,
  Col,
  Layout,
  Drawer,
  Tree,
  Modal,
  notification,
  Alert,
  Spin,
} from "antd";
import _, { replace } from "lodash";
import NormalFormIndex from "../../../../shared/NormalForm/normal_from_index";
import URL_WITH_VERSION, {
  getAPICall,
  URL_WITHOUT_VERSION,
  postAPICall,
  awaitPostAPICall,
  openNotificationWithIcon,
  apiDeleteCall,
  useStateCallback,
} from "../../../../shared";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../../../shared/attachments";
import RightBarUI from "../../../../components/RightBarUI";
import MakePayment from "../../../../components/MakePayment/TcoMakepayment";
import ModalAlertBox from "../../../../shared/ModalAlertBox";
import CommissionSummary from "../../../../shared/components/CommissionSummary/TcoCommisionSummary";
import TCoHireStatement from "../../../../shared/components/HireStatement/TcoHireStatement";
import * as moment from "moment";
import Properties from "../tcto/right-panel/Properties";
import TciPlSummary from "../../../pl-summary-list/TctoPlSummary";
import EstimateSummary from "../tcto/right-panel/EstimateSummary";
import CommissionPayment from "../../../../shared/components/CommissionPayment/TcoCommisionpayment";
import TcoReports from "../../../form-reports/TcoReports";
import { Button } from "antd/lib/radio";
import AttachmentFile from "../../../../shared/components/Attachment";
import StatementofAccount from "./statementofAccount";
import StatementofAccountRecap from "./StatementofAccountRecap";
import {
  DeleteOutlined,
  MenuFoldOutlined,
  PlusOutlined,
  SaveOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import Email from "../../../../components/Email";

import ParsedInfo from "../../../voyage-list/components/ParsedInfo";
import { Navigate, useLocation, useNavigate, useParams } from "react-router";
import VoyageManagerInfoTciTco from "../../../../components/vessel-form/VoyageManagerInfoTci-Tco";
import RippleComponent from "../../../NotFoundPage";
import CustomLoader from "../../../../components/Loading/CustomLoader";
import MailSpiltView from "../../../inbox/Inbox";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedNav } from "../../../../actions/settingsActions";
import MailLayoutDrawer from "../../../inbox/MailLayoutDrawer";
import redux_state from "../../../../services/redux_state";

const { Content } = Layout;
//const TreeNode = Tree.TreeNode;

const openNotification = (keyName, description = undefined) => {
  if (description === undefined) {
    notification.info({
      message: `Can't Open ${keyName}`,
      description: `Please save TCO Form first and fix then click on ${keyName}`,
      placement: "topRight",
    });
  } else {
    notification.info({
      message: `Can't Open ${keyName}`,
      description: `${description}`,
      placement: "topRight",
    });
  }
};

const newFormData = {
  "..": [
    {
      notice_type: "25 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "15 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "10 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "7 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "5 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "4 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "3 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "1 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
  ],
  "...": [
    {
      notice_type: "25 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "15 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "10 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "7 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "5 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "4 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "3 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
    {
      notice_type: "1 DAYS",
      email_body: "",
      email_content: "",
      notice_sent: 0,
      project_delivary: "",
    },
  ],
  id: 0,
  deliveryterm: [
    {
      d_t_name: "Delivery",
      port_name: "Select Port",
      min_days: 0.0,
      max_days: 0.0,
      editable: true,
      index: 0,
      ID: -9e6,
    },
    {
      d_t_name: "Redelivery",
      port_name: "Select Port",
      min_days: 0.0,
      max_days: 0.0,
      editable: true,
      index: 1,
      ID: -9e6 + 1,
    },
  ],
  hireterm: [
    {
      period: 1,
      hire_rate: 0,
      rate_type: 1,
      editable: true,
      index: 0,
      ID: -9e6,
    },
  ],
  otherterm: [
    {
      short_code: "TCOBB",
      description: 1,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6,
      rate_type: 1,
      commission: true,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOGRA",
      description: 2,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 1,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOVIC",
      description: 3,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 2,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOREA",
      description: 4,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 3,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOLAST",
      description: 5,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 4,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOILOHC",
      description: 6,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 5,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOIHC",
      description: 7,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 6,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOOTHEXP",
      description: 8,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 7,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
    {
      short_code: "TCOOTHREV",
      description: 9,
      amount: 0,
      editable: true,
      index: 0,
      id: -9e6 + 7,
      rate_type: 1,
      disablefield: ["description", "rate_type"],
    },
  ],
  commissionterm: [
    {
      commission: 10,
      rate_figure: 0,
      editable: true,
      index: 0,
      ID: -9e6,
      payment_method: 3,
      rate_type: 1,
    },
  ],
  bareboats: [
    {
      beneficiary: "Select Value",
      code: 0,
      editable: true,
      index: 0,
      ID: -9e6,
    },
  ],
  bunkers: [
    {
      short_code: "TCOBAD",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 3,
    },
    {
      short_code: "TCOBED",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 2,
    },
    {
      short_code: "TCOBDD",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 4,
    },
    // {
    //   short_code: 'TCOBSD',
    //   f_IFO: 0,
    //   f_IFO_p: 0,
    //   f_VLSFO: 0,
    //   f_VLSFO_p: 0,
    //   f_LSMGO: 0,
    //   f_LSMGO_p: 0,
    //   f_MGO: 0,
    //   f_MGO_p: 0,
    //   f_ULSFO: 0,
    //   f_ULSFO_p: 0,
    //   description: 5,
    // },
    {
      short_code: "TCOBOR",
      f_IFO: 0,
      f_IFO_p: 0,
      f_VLSFO: 0,
      f_VLSFO_p: 0,
      f_LSMGO: 0,
      f_LSMGO_p: 0,
      f_MGO: 0,
      f_MGO_p: 0,
      f_ULSFO: 0,
      f_ULSFO_p: 0,
      description: 6,
    },
    // {
    //   short_code: 'TCOBPO',
    //   f_IFO: 0,
    //   f_IFO_p: 0,
    //   f_VLSFO: 0,
    //   f_VLSFO_p: 0,
    //   f_LSMGO: 0,
    //   f_LSMGO_p: 0,
    //   f_MGO: 0,
    //   f_MGO_p: 0,
    //   f_ULSFO: 0,
    //   f_ULSFO_p: 0,
    //   description: 7,
    // },
    // {
    //   short_code: 'TCOBFA',
    //   f_IFO: 0,
    //   f_IFO_p: 0,
    //   f_VLSFO: 0,
    //   f_VLSFO_p: 0,
    //   f_LSMGO: 0,
    //   f_LSMGO_p: 0,
    //   f_MGO: 0,
    //   f_MGO_p: 0,
    //   f_ULSFO: 0,
    //   f_ULSFO_p: 0,
    //   description: 8,
    // },
  ],
  tcbunkeradjustment: [
    {
      bod: "Delivery Value IFO",
      bod_value: "0.00",
      bor: "Redelivery Value IFO",
      bor_value: "0.00",
      net_bunker: "Net IFO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value LSMGO",
      bod_value: "0.00",
      bor: "Redelivery Value LSMGO",
      bor_value: "0.00",
      net_bunker: "Net LSMGO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value ULSFO",
      bod_value: "0.00",
      bor: "Redelivery Value ULSFO",
      bor_value: "0.00",
      net_bunker: "Net ULSFO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value VLSFO",
      bod_value: "0.00",
      bor: "Redelivery Value VLSFO",
      bor_value: "0.00",
      net_bunker: "Net VLSFO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      bod: "Delivery Value MGO",
      bod_value: "0.00",
      bor: "Redelivery Value MGO",
      bor_value: "0.00",
      net_bunker: "Net MGO Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
    {
      // bod : "Delivery Value MGO",
      // "bod_value": "0.00",
      // "bor": "Redelivery Value MGO",
      // "bor_value": "0.00",
      net_bunker: "Net Bunker Value",
      per_allowed: "100.00",
      all_bunker_amount: "0.00",
      net_bunker_value: "0.00",
      voyage_number: "",
    },
  ],
  "portconsp.tableperday": [
    {
      con_type: "Select Fuel type",
      con_g: "select Grade",
      con_unit: 1,
      editable: true,
      index: 0,
      ID: -9e6,
    },
  ],
};

let myData = null;

const TCO = (props) => {
  const statementRef = useRef();
  const _formData = _.cloneDeep(newFormData);
  const [state, setState] = useStateCallback({
    frmName: "tco_form",
    marineData: null,
    responseData: { frm: [], tabs: [], active_tab: {} },
    formData: Object.assign(_formData, props.formData || {}),
    formReportdata: Object.assign(_formData, props.reportFormData || {}),
    visibleSummary: false,
    visibleMakePayment: false,
    paymentData: {},
    commissionEntry: {},
    visiblehirePayment: false,
    hmPay: {},
    isOpenSoar: false,
    isOpenSoa: false,
    tcoID: _formData.id,
    popupFroms: props.popupFroms || {},
    visibleDrawer: false,
    title: undefined,
    loadComponent: undefined,
    width: 1200,
    isShowSearchTci: false,
    isShowSearchTco: false,
    isShowTcoReports: false,
    VoyageManagerInfoModal: false,
    vmInfoData: null,
    isDoSchedule: false,
    rightMenuButtons: [],
    frmVisible: true,
    showSideListBar:
      props.showSideListBar === false ? props.showSideListBar : false,
    // isSetsLeftBtn: [
    //   {
    //     id: "7",
    //     key: "menu-fold",
    //     type: <MenuFoldOutlined />,
    //     withText: "List",
    //     event: "menu-fold",
    //     showToolTip: true,
    //   },
    //   {
    //     id: "5",
    //     key: "plus",
    //     type: <PlusOutlined />,
    //     withText: "Add New",
    //     showToolTip: true,
    //     event: (key, data) => _onCreateFormData(),
    //   },
    //   {
    //     id: "2",
    //     key: "save",
    //     type: <SaveOutlined />,
    //     withText: "Save",
    //     showToolTip: true,
    //     event: (key, data) => saveFormData(data),
    //   },
    //   {
    //     id: "6",
    //     key: "delete",
    //     type: <DeleteOutlined />,
    //     withText: "Delete",
    //     showToolTip: true,
    //     event: (key, data) => _onDeleteFormData(data),
    //   },
    //   {
    //     id: "20",
    //     key: "refresh",
    //     type: <SyncOutlined />,
    //     withText: "Refresh",
    //     showToolTip: true,
    //     event: () => {
    //       reFreshForm();
    //     },
    //   },
    // ],
  });
  const [isVoyageManagerInfo, setIsVoyageManagerInfo] = useState(false);
  const [sharebtn, setSharebtn] = useState();
  const [shareLink, setShareLink] = useState();
  const [closeCount, setCloseCount] = useState(1);

  const idRef = useRef(null);
  const responseDataRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const storeData = useSelector((store) => store.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === "/Add-TC-Out") {
      _onCreateFormData();
    }
  }, [location.pathname]);
  const reFreshForm = async () => {
    if (idRef.current !== null) {
      _onLeftSideListClick(idRef.current);
    } else {
      _onCreateFormData();
    }
  };

  const fetchData = async () => {
    try {
      const { formData } = state;
      const response = await getAPICall(
        `${URL_WITHOUT_VERSION}get/${state.frmName}`
      );
      const data = await response.data;
      responseDataRef.current = data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props?.formData?.id) {
      idRef.current = props.formData.tco_id;
    }
    const _id = window?.state?.data?.["tc_code"];
    const localdata = JSON.parse(localStorage.getItem("tco_id"));
    let paramid = params.id;
    let id = paramid || localdata?.tc_code || _id;

    if (
      id &&
      paramid &&
      !(
        location.pathname.includes("/edit-tc-est-fullestimate") ||
        location.pathname.includes("/edit-voyage-estimate") ||
        location.pathname.includes("/voyage-manager") ||
        location.pathname.includes("/edit-cargo-contract") ||
        location.pathname.includes("/edit-voyage-cargo") ||
        location.pathname.includes(`/TC-IN/${id}`) ||
        location.pathname.includes("/voy-relet-full-estimate-edit") ||
        location.pathname.includes("/edit-coa-contract") ||
        location.pathname.includes("/edit-coa-vci")
      )
    ) {
      _onLeftSideListClick(id);
    }
  }, [props?.formData?.id, window?.state?.data]);

  const _onCreateFormData = () => {
    const _formData = _.cloneDeep(newFormData);
    //const rightMenuButtons = _.cloneDeep(newRightMenuButtons);
    idRef.current = null;
    localStorage.removeItem("fixedvessel");
    setState(
      (prevState) => ({
        ...prevState,
        frmVisible: false,
        formData: _formData,
        id: 0,
        is_schedule: 0,
        is_fixed: 0,
        // rightMenuButtons: rightMenuButtons,
        // showSideListBar: true,
      }),
      () => {
        setState((prevState) => ({
          ...prevState,
          frmVisible: true,
        }));
        if (
          !(
            location.pathname.includes("/edit-tc-est-fullestimate") ||
            location.pathname.includes("/edit-voyage-estimate") ||
            location.pathname.includes("/voyage-manager")
          )
        ) {
          navigate("/Add-TC-Out", { replace: true });
        }
      }
    );
  };

  useEffect(() => {
    if (isVoyageManagerInfo === true) {
      getTableData();
    }
  }, [isVoyageManagerInfo]);

  const getTableData = async () => {
    try {
      const response = await getAPICall(
        // voyage-manager/info/tci/1494?p=1&l=2
        `${URL_WITH_VERSION}/voyage-manager/info/tco/${state.formData.id}`
      );
      const respData = await response["data"];
      if (respData.length > 0) {
        setState((prevState) => ({
          ...prevState,
          vmInfoData: respData,
          VoyageManagerInfoModal: true,
        }));
      } else {
        openNotificationWithIcon("info", "No VM Data available for this TCO");
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handlePrint = () => {
    document.getElementById("print-button").click();
  };

  const onCloseDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      visibleDrawer: false,
      title: undefined,
      loadComponent: undefined,
    }));
  };

  const _onDeleteFormData = (postData) => {
    if (postData && postData.id <= 0) {
      openNotificationWithIcon(
        "error",
        "TCO Id is empty. Kindly check it again!"
      );
    }
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => _onDelete(postData),
    });
  };

  const _onDelete = (postData) => {
    let _url = `${URL_WITH_VERSION}/tco/delete`;
    apiDeleteCall(_url, { id: postData.id }, (response) => {
      if (response && response.data) {
        openNotificationWithIcon("success", response.message);
        window.emitNotification({
          n_type: "TCO Deleted",
          msg: window.notificationMessageCorrector(
            `TCO is deleted, for vessel(${postData.vessel_code}), by ${window.userName}`
          ),
        });
        _onCreateFormData();
        // if (
        //   props.modalCloseEvent &&
        //   typeof props.modalCloseEvent === "function"
        // ) {
        //   props.modalCloseEvent();
        // } else {
        //   _onCreateFormData();
        // }
      } else {
        openNotificationWithIcon("error", response.message);
      }
    });
  };

  const onClickRightMenu = async (key, options) => {
    const { formData } = state;
    if (!storeData.collapsedNav.collapsedNav) {
      dispatch(toggleCollapsedNav(true));
    }
    onCloseDrawer();
    let loadComponent = undefined;
    let marinedata = {};
    switch (key) {
      case "summary":
        loadComponent = (
          <EstimateSummary
            tciID={formData && formData.id}
            type={"TCO"}
            datatci={formData}
          />
        );
        break;
      case "properties":
        loadComponent = <Properties />;
        break;
      case "pl-summary":
        loadComponent = <TciPlSummary />;
        break;
      case "sideMap": {
        const { formData, marineData } = state;
        if (marineData["SHIPNAME"]) {
          marinedata = { ...marineData };
        } else {
          marinedata = await getMarineVessel(formData.vessel_name);
        }

        if (!marinedata?.SHIPNAME) {
          openNotificationWithIcon("info", "Vessel data not found");
          return;
        }
        loadComponent = <ParsedInfo marineData={marinedata} />;
        break;
      }
      // case "summary":
      //   loadComponent = <EstimateSummary tciID={formData["id"]} type={"TCO"} datatci={formData} />;
      //   break;
      case "attachment":
        const { id } = formData;
        if (id) {
          const attachments = await getAttachments(id, "EST");
          const callback = (fileArr) =>
            uploadAttachment(fileArr, id, "EST", "tco");
          loadComponent = (
            <AttachmentFile
              uploadType="Estimates"
              attachments={attachments}
              onCloseUploadFileArray={callback}
              deleteAttachment={(file) =>
                deleteAttachment(file.url, file.name, "EST", "tco")
              }
              tableId={0}
            />
          );
        } else {
          openNotificationWithIcon(
            "info",
            "Attachment will open only after save"
          );
        }
        break;
      case "vm":
        if (state.formData.id) {
          setIsVoyageManagerInfo(!isVoyageManagerInfo);
        } else {
          openNotificationWithIcon("info", "Please select vessel from list");
        }
        break;

      case "mail": {
        // loadComponent = <MailSpiltView />;
        const formData = redux_state.reduxStore["tco_form"];
        if (
          !formData?.vesselInfo?.vessel_name?.trim()?.lenght > 0 &&
          !formData?.vessel_name
        ) {
          openNotificationWithIcon("info", "Please select a vessel");
          return;
        }
        loadComponent = (
          <MailLayoutDrawer
            voyageEstimate={true}
            shipName={
              state?.marineData?.SHIPNAME ||
              formData?.vesselInfo?.vessel_name ||
              formData?.vessel_name ||
              ""
            }
            companyName={
              formData?.companyInfo?.name ||
              formData?.company_fixed_with_name ||
              ""
            }
          />
        );
        break;
      }

      default:
        break;
    }

    setState((prevState) => ({
      ...prevState,
      visibleDrawer: true,
      title: options.title,
      loadComponent: loadComponent,
      marineData: marinedata,
      width: options.width && options.width > 0 ? options.width : 1200,
    }));
  };

  const saveFormData = (postData, innerCB) => {
    let tcbunkeradjustment = [];

    if (
      postData &&
      postData.hasOwnProperty("tcbunkeradjustment") &&
      postData["tcbunkeradjustment"]
    ) {
      let deliveryData = null;
      let reDeliveryData = null;
      postData?.bunkers?.forEach((val, indx) => {
        let {
          f_IFO,
          f_IFO_p,
          f_LSMGO,
          f_LSMGO_p,
          f_MGO,
          f_MGO_p,
          f_ULSFO,
          f_ULSFO_p,
          f_VLSFO,
          f_VLSFO_p,
        } = val;

        let ifo_cal = f_IFO && f_IFO_p ? +f_IFO * +f_IFO_p : 0;
        let lsmgo_cal = f_LSMGO && f_LSMGO_p ? +f_LSMGO * +f_LSMGO_p : 0;
        let mgo_cal = f_MGO && f_MGO_p ? +f_MGO * +f_MGO_p : 0;
        let ulsfo_cal = f_ULSFO && f_ULSFO_p ? +f_ULSFO * +f_ULSFO_p : 0;
        let vlsfo_cal = f_VLSFO && f_VLSFO_p ? +f_VLSFO * +f_VLSFO_p : 0;

        if (indx === 0) {
          deliveryData = [ifo_cal, lsmgo_cal, ulsfo_cal, vlsfo_cal, mgo_cal];
        } else if (indx === postData.bunkers.length - 1) {
          reDeliveryData = [ifo_cal, lsmgo_cal, ulsfo_cal, vlsfo_cal, mgo_cal];
        }
      });

      let sum = 0;
      postData?.tcbunkeradjustment?.forEach((val, indx) => {
        let bod_value = deliveryData[indx];
        let bor_value = reDeliveryData[indx];
        let net_bunker_value = bor_value - bod_value;
        // let voyage_number = 0;
        if (postData.tcbunkeradjustment.length - 1 !== indx) {
          let all_bunker_amount =
            (Number(val.per_allowed) * net_bunker_value) / 100;
          tcbunkeradjustment.push({
            ...val,
            bod_value,
            bor_value,
            net_bunker_value,
            all_bunker_amount,
          });
          sum += net_bunker_value;
        } else {
          let all_bunker_amount = (Number(val.per_allowed) * sum) / 100;
          tcbunkeradjustment.push({
            val,
            net_bunker_value: sum,
            all_bunker_amount,
            id: val.id,
          });
        }
      });
    }
    const { frmName, popupFroms } = state;
    const _postData = _.cloneDeep(postData);
    try {
      setState((prevState) => ({ ...prevState, frmVisible: false }));
      let _url = "save";
      let _method = "post";
      let params = "";
      if (postData.hasOwnProperty("id")) {
        delete postData["company_fixed_with_name"];
        if (Array.isArray(postData.hireterm)) {
          postData.hireterm.forEach((ele) => {
            if (ele.hire_rate === "") {
              ele.hire_rate = 0;
            }
          });
        }
        _url = "update";
        _method = "put";
      }

      // if (postData["hireterm"] && postData["hireterm"].length > 0) {
      //   for (const ele of postData["hireterm"]) {
      //     if (!ele["from_date"] || !ele["to_date"] || !ele["duration"]) {
      //       openNotificationWithIcon(
      //         "error",
      //         "Please fill Hire Rate, From Date, and To Date in Hire Term."
      //       );
      //       setState((prevState) => ({ ...prevState, frmVisible: true }));
      //       return;
      //     }
      //   }
      // }
      if (state.formData.tco_status == 111 && postData["tco_status"] == 125) {
        let msg = `You cannot change Draft after fix status.`;
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />,
          5
        );
        setState((prevState) => ({ ...prevState, frmVisible: true }));
        return false;
      }
      if (state.formData.tco_status == 14 && postData["tco_status"] == 125) {
        let msg = `You cannot change Draft after schedule status.`;
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />,
          5
        );
        setState((prevState) => ({ ...prevState, frmVisible: true }));
        return false;
      }

      let tco_code = postData["tc_code"];

      ["owner", "billing_beneficiary"].forEach((e) => delete postData[e]);

      Object.keys(postData).forEach(
        (key) => postData[key] === null && delete postData[key]
      );

      if (popupFroms && JSON.stringify("{}") !== JSON.stringify(popupFroms)) {
        let keys = Object.keys(popupFroms);
        keys.map((e) => (params += "&" + e + "=" + popupFroms[e]));
      }
      delete postData["tco_code"];
      delete postData["commission"];
      postData = {
        ...postData,
        // laycan_from: moment(postData.laycan_from).format("YYYY-MM-DD"),
        // laycan_to: moment(postData.laycan_to).format("YYYY-MM-DD"),
      };

      if (postData.hasOwnProperty("vessel_name") && postData["vessel_name"]) {
        delete postData["vessel_name"];
      }

      if (postData.hasOwnProperty("companyInfo") && postData["companyInfo"]) {
        delete postData["companyInfo"];
      }

      postAPICall(
        `${URL_WITH_VERSION}/tco/${_url}?frm=${frmName}${params}`,
        postData,
        _method,
        (data) => {
          if (data.data) {
            openNotificationWithIcon("success", data.message);
            if (_url === "save") {
              window.emitNotification({
                n_type: "TCO Added",
                msg: window.notificationMessageCorrector(
                  `TCO is added, for vessel(${postData.vessel_code}), by ${window.userName}`
                ),
              });
            } else {
              window.emitNotification({
                n_type: "TCO Updated",
                msg: window.notificationMessageCorrector(
                  `TCI is updated, for vessel(${postData.vessel_code}), by ${window.userName}`
                ),
              });
            }
            if (_url == "update") {
              _onLeftSideListClick(tco_code);
            }
            if (_url == "save") {
              // window.location.href = "#/TC-OUT-list";
              _onLeftSideListClick(data.row.tc_code);
            }

            if (
              props.modalCloseEvent &&
              typeof props.modalCloseEvent === "function"
            ) {
              props.modalCloseEvent(data["row"]);
            }
            if (innerCB && typeof innerCB === "function") {
              innerCB();
            }
          } else {
            let dataMessage = data.message;
            let msg = "<div className='row'>";

            if (typeof dataMessage !== "string") {
              Object.keys(dataMessage).map(
                (i) =>
                  (msg +=
                    "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
              );
            } else {
              msg += dataMessage;
            }

            msg += "</div>";
            openNotificationWithIcon(
              "info",
              <div dangerouslySetInnerHTML={{ __html: msg }} />
            );
            setState((prevState) => ({
              ...prevState,
              frmVisible: true,
              formData: _postData,
            }));
          }
        }
      );
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        frmVisible: true,
        formData: _postData,
      }));
      openNotificationWithIcon("error", "Something went wrong.");
    }
  };

  const openTcoReports = async (showTcoReports, tcoID) => {
    // for report Api
    try {
      const responseReport = await getAPICall(
        `${URL_WITH_VERSION}/tco/report?e=${tcoID}`
      );
      const respDataReport = await responseReport["data"];
      if (respDataReport) {
        setState((prevState) => ({
          ...prevState,
          reportFormData: respDataReport,
          isShowTcoReports: showTcoReports,
        }));
      } else {
        openNotificationWithIcon("error", "Unable to show report", 5);
      }
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong.", 5);
    }
  };

  // TcoReports = showTcoReports => setState({ ...state, isShowTcoReports: showTcoReports });
  const onCancelMakePayment = () =>
    setState((prevState) => ({ ...prevState, visibleMakePayment: false }));

  const onCancelhirestatement = () =>
    setState((prevState) => ({ ...prevState, modalStatus: false }));

  const onCancelModal = () => {
    setState((prevState) => ({
      ...prevState,
      hmPay: {},
      modalStatus: false,
      modalBody: null,
      modalHeader: null,
      modalFooter: [null],
      commissionEntry: {},
    }));
  };

  const scheduleTCO = (boolVal) =>
    setState((prevState) => ({ ...prevState, isDoSchedule: boolVal }));

  const onSubMenuOptionClick = async (inCall, data) => {
    //const { responseData } = state;
    // const { responseData, formData, hmPay } = state;

    setState((prevState) => ({
      ...prevState,
      visibleMakePayment: false,
    }));
    let formData = {};
    if (data) {
      //formData={...data}
      formData = _.cloneDeep(data);
    } else {
      formData = _.cloneDeep(state.formData);
    }

    if (!responseDataRef.current) {
      await fetchData();
    }

    let responseData = _.cloneDeep(responseDataRef.current);

    let payObj = {};
    let hirePaymentData = { id: 0, "-": [] };
    let makePaymentData = { id: 0, "-": [], actualID: formData["id"] };
    if (
      responseData &&
      responseData.hasOwnProperty("frm") &&
      responseData.frm.length > 0 &&
      formData
    ) {
      let response = await getAPICall(
        `${URL_WITH_VERSION}/make_payment/tco-list?l=0`,
        {
          id: {
            where:
              "`vessel_id`= " +
              formData.vessel_id +
              " AND `tc_id`='" +
              formData.tc_code +
              "'",
          },
        }
      );
      let respData = await response?.["data"];

      if (respData && respData?.length > 0) {
        let paymentForm = moment(respData[respData.length - 1]["period_form"]);
        makePaymentData["period_form"] = paymentForm.format("YYYY-MM-DDTHH:mm");
      } else if (
        formData &&
        formData.hasOwnProperty("deliveryterm") &&
        formData["deliveryterm"].length > 0 &&
        formData["deliveryterm"][0].hasOwnProperty("est_gmt") &&
        formData["deliveryterm"][0]["est_gmt"] !== ""
      ) {
        makePaymentData["period_form"] = moment(
          formData["deliveryterm"][0]["actual_gmt"]
        ).format("YYYY-MM-DDTHH:mm");
      }

      responseData.frm.forEach((e) => {
        if (e.f_name === "vessel_id") {
          hirePaymentData[e.f_name] = formData[e.f_name] * 1;
          makePaymentData[e.f_name] = formData[e.f_name] * 1;
        } else if (e.f_name === "config_date") {
          hirePaymentData["cp_date"] = formData[e.f_name];
          makePaymentData["tc_date"] = formData[e.f_name];
        } else if (e.f_name === "tc_code") {
          hirePaymentData["tc_code"] = formData[e.f_name];
          makePaymentData["tc_id"] = formData[e.f_name];
        } else if (e.f_name === "currency_exhange_rate") {
          makePaymentData["exchange_rates"] = formData[e.f_name];
        } else if (e.f_name === "payment_term") {
          makePaymentData["terms"] = formData[e.f_name];
        } else if (e.f_name === "trade_area") {
          makePaymentData["currency"] = 205; // USD
        } else if (e.f_name === "charterer") {
          makePaymentData["charterer_from"] = formData["charterer"];
          makePaymentData["my_company"] = formData["company_fixed_with"];
          makePaymentData["lob"] = formData["company_lob"];
        }
      });

      if (formData && formData.hasOwnProperty("cp_date_fixed_by")) {
        hirePaymentData["chartered_from"] = formData["charterer"];
      }

      if (
        formData &&
        formData.hasOwnProperty("deliveryterm") &&
        formData["deliveryterm"].length > 0
      ) {
        formData["deliveryterm"].forEach((e) => {
          if (
            e["d_t_name"] === "Delivery" &&
            e["actual_gmt"] &&
            e["actual_gmt"] !== ""
          ) {
            hirePaymentData["delivery_date"] = e["actual_gmt"];
            makePaymentData["delivery"] = e["actual_gmt"];
          } else if (
            e["d_t_name"] === "Redelivery" &&
            e["actual_gmt"] &&
            e["actual_gmt"] !== ""
          ) {
            hirePaymentData["redelivery_date"] = e["actual_gmt"];
            makePaymentData["re_delivery"] = e["actual_gmt"];
          }
        });
      }

      if (
        formData &&
        formData.hasOwnProperty("hireterm") &&
        formData["hireterm"].length > 0
      ) {
        if (!makePaymentData.hasOwnProperty("amount")) {
          makePaymentData["amount"] = 0;
          makePaymentData["actual_amount"] = 0;
          makePaymentData["daily_rates"] = 0;
          makePaymentData["invoice_total"] = 0;
        }
        // formData["hireterm"].forEach((e) => {
        //   makePaymentData["amount"] =
        //     makePaymentData["amount"] + e["hire_rate"].replaceAll(",", "") * 1;
        //   makePaymentData["actual_amount"] = makePaymentData["amount"];
        //   makePaymentData["daily_rates"] = makePaymentData["amount"];
        // });

        formData["hireterm"].forEach((e) => {
          // Convert hire_rate to a string before using replaceAll
          const hireRateAsString = String(e["hire_rate"]);

          makePaymentData["amount"] =
            makePaymentData["amount"] +
            hireRateAsString.replaceAll(",", "") * 1;

          makePaymentData["actual_amount"] = makePaymentData["amount"];
          makePaymentData["daily_rates"] = makePaymentData["amount"];
          makePaymentData["invoice_total"] = makePaymentData["amount"];
        });
      }
    }

    if (
      formData &&
      formData.hasOwnProperty("bunkers") &&
      formData["bunkers"].length > 0
    ) {
      let bunker = [
        { dr_name: "Delivery Cost" },
        { dr_name: "Adj on delivery" },
        { dr_name: "Redelivery Cost" },
        { dr_name: "Adj on redelivery" },
      ];
      // formData["bunkers"].map((e) => {
      //   if (e.description === 3 || e.description === 6) {
      //     let index = e.description === 3 ? 0 : 2;
      //     let cDesc = JSON.parse(e.c_fields_values);
      //     let item = { ifo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0, mgo: 0 };
      //     let iv = {
      //       IFO: "ifo",
      //       VLSFO: "vlsfo",
      //       ULSFO: "ulsfo",
      //       MGO: "mgo",
      //       LSMGO: "lsmgo",
      //     };
      //     cDesc.map(
      //       (_e) => (item[iv[_e.name]] = _e.consumption + " X " + _e.price)
      //     );
      //     bunker[index] = Object.assign(item, bunker[index]);
      //   }
      // });
      formData["bunkers"].map((e) => {
        if (e.description === 3 || e.description === 6) {
          let index = e.description === 3 ? 0 : 2;
          let cDesc;

          // Check if e.c_fields_values is a string before parsing as JSON
          if (typeof e.c_fields_values === "string") {
            try {
              cDesc = JSON.parse(e.c_fields_values);
            } catch (error) {
              //   console.error("Error parsing JSON:", error);
              return; // Skip this iteration if JSON parsing fails
            }
          } else {
            //   console.error("Invalid JSON string:", e.c_fields_values);
            return; // Skip this iteration if not a valid JSON string
          }

          let item = { ifo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0, mgo: 0 };
          let iv = {
            IFO: "ifo",
            VLSFO: "vlsfo",
            ULSFO: "ulsfo",
            MGO: "mgo",
            LSMGO: "lsmgo",
          };

          cDesc.map(
            (_e) => (item[iv[_e.name]] = _e.consumption + " X " + _e.price)
          );
          bunker[index] = Object.assign(item, bunker[index]);
        }
      });

      makePaymentData["bunkerdeliveryredeliveryterm"] = bunker;
    }

    payObj = { hpay: hirePaymentData, mpay: makePaymentData };
    setState((prevState) => ({ ...prevState, hmPay: payObj }));
    return inCall(payObj);
  };

  const onHirePayment = (key, data) => {
    //const { formData } = state;

    let formData = {};
    // if(data) {
    //   //formData={...data}
    //   formData = _.cloneDeep(data);
    // }else {
    //   formData = _.cloneDeep(state.formData);
    // }
    formData = _.cloneDeep(data);
    let deliveryterm = formData["deliveryterm"];
    let modalBody = null,
      modalHeader = null,
      modalFooter = [null];
    let tcoID = {
      vessel_id: formData.vessel_id,
      tc_id: formData.tc_code,
      tco_id: formData.id,
      chartrer_id: formData.charterer,
      delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
      redelivery_date: deliveryterm[1] ? deliveryterm[1].actual_gmt : undefined,
    };
    onSubMenuOptionClick((hmPay) => {
      modalBody = () => (
        <TCoHireStatement
          tciID={tcoID}
          paymentFormData={hmPay.mpay}
          paymentData={hmPay.hpay}
          handlePrint={handlePrint}
          ref={statementRef}
        />
      );
      modalHeader = "TC Out Hire Summary";
      setState((prevState) => ({
        ...prevState,
        modalBody,
        modalHeader,
        modalFooter,
        modalStatus: true,
      }));
    }, formData);
  };

  const onMakePayment = async (key, data) => {
    onSubMenuOptionClick((hmPay) => {
      setState((prevState) => ({
        ...prevState,
        paymentData: hmPay.mpay,
        visibleMakePayment: true,
      }));
    }, data);
  };

  useEffect(() => {
    let newWindow = {};
    if (state.visibleMakePayment) {
      newWindow = window.open("#/issue-bill", "_blank");
      if (newWindow) {
        newWindow.state = {
          formData: state.paymentData,
          remarksID: state.formData.tc_code,
        };
        localStorage.setItem("issue-bill", JSON.stringify(newWindow.state));
      }
    }
  }, [state.visibleMakePayment]);

  const onClickCommission = async (key, data) => {
    //const { responseData, formData } = state;

    let formData = {};
    if (data) {
      //formData={...data}
      formData = _.cloneDeep(data);
    } else {
      formData = _.cloneDeep(state.formData);
    }

    if (!responseDataRef.current) {
      await fetchData();
    }
    let responseData = _.cloneDeep(responseDataRef.current);

    const summarytabledata = await getAPICall(
      `${URL_WITH_VERSION}/make_payment/tco-list?l=0`,
      { id: { where: "`tco_id`='" + formData.id + "'", order_by: "id DESC" } }
    );

    if (summarytabledata.data.length == 0) {
      openNotificationWithIcon(
        "info",
        "Please generate an issue bill first.",
        3
      );
      return;
    }
    let makeCommissionData = { id: 0 },
      makeCommissionEntry = { id: 0 };

    let modalBody = null,
      Commission = undefined,
      modalHeader = null,
      modalFooter = [null];

    if (
      responseData &&
      responseData.hasOwnProperty("frm") &&
      responseData.frm.length > 0
    ) {
      responseData.frm.forEach((e) => {
        if (
          e.f_name === "vessel_id" &&
          e.f_dyc_extras &&
          e.f_dyc_extras.options &&
          e.f_dyc_extras.options.length > 0
        ) {
          makeCommissionData["vessel_name"] = e.f_dyc_extras.options.filter(
            (o) => o.id * 1 === formData["vessel_id"] * 1
          )?.[0]?.["name"];
          makeCommissionEntry["vessel_id"] = e.f_dyc_extras.options.filter(
            (o) => o.id * 1 === formData["vessel_id"] * 1
          )?.[0]?.["id"];
        } else if (e.f_name === "tc_code") {
          makeCommissionData["tc_code"] = formData["tc_code"];
          makeCommissionEntry["tc_code"] = makeCommissionData["tc_code"];
        } else if (e.f_name === "cp_date_fixed_by") {
          makeCommissionData["chartered_form"] = formData["cp_date_fixed_by"];
        } else if (e.f_name === "currency_exhange_rate") {
          makeCommissionData["currency"] = formData["currency_exhange_rate"];
        }
      });

      let response = await getAPICall(
        `${URL_WITH_VERSION}/accounts/tco-borker/commission?t=tco&e=${formData["id"]}`
      );
      let respData = await response["data"];

      makeCommissionEntry["tco_id"] = formData["id"];
      makeCommissionEntry["payment_terms"] = formData["payment_type"];
      setCloseCount(closeCount + 1);

      //makeCommissionEntry['payment_terms2'] = respData['ptd']['description']
      if (key === "tc_commission") {
        Commission = () => (
          <CommissionPayment
            commissionData={makeCommissionEntry}
            formData={formData}
            onCancelModal={onCancelModal}
            frmOptions={[
              { key: "broker", data: respData && respData["brokers"] },
              { key: "remmitance_bank", data: respData && respData["banks"] },
            ]}
            type="tco"
            key={makeCommissionEntry?.tco_id}
            closeCount={closeCount}
          />
        );
        modalBody = Commission;
        modalHeader = "TCO Commission Payment";
      } else if (key === "claim_commission") {
        makeCommissionData["tco_id"] = formData["id"];
        Commission = () => (
          <CommissionSummary
            frmCode="tco_commission_summary"
            commissionData={makeCommissionEntry}
            formData={makeCommissionData}
            frmOptions={[
              { key: "broker", data: respData && respData["brokers"] },
            ]}
            type="tco"
            key={makeCommissionEntry?.tco_id}
          />
        );
        modalBody = Commission;
        modalHeader = "TCO Commission Summary";
      }
    }

    setState((prevState) => ({
      ...prevState,
      commissionEntry: makeCommissionEntry,
      modalBody,
      modalStatus: true,
      modalHeader,
      modalFooter,
    }));
  };

  const performFix = async () => {
    try {
      const { formData } = state;

      let tcoId = formData["id"];
      let tcCode = formData["tc_code"];
      let _url = `${URL_WITH_VERSION}/tco/fix`;
      let data = { tco: tcoId }; // Fixed variable name from idRef.current to tcoId

      let response = await awaitPostAPICall(_url, data);
      let respData = response.data; // Access response data directly

      if (respData !== false) {
        openNotificationWithIcon("success", response.message);
        window.emitNotification({
          n_type: "TCO Fixed",
          msg: window.notificationMessageCorrector(
            `TCI is Fixed, for vessel(${formData.vessel_code}), by ${window.userName}`
          ),
        });

        // Uncomment the following block if modalCloseEvent is required
        // if (props.modalCloseEvent && typeof props.modalCloseEvent === "function") {
        //   props.modalCloseEvent();
        // }

        _onLeftSideListClick(tcCode); // Fixed function parameter from idRef.current to tcoId
      } else {
        let dataMessage = response.message;
        let msg = "<div className='row'>";

        if (typeof dataMessage !== "string") {
          Object.keys(dataMessage).map(
            (i) =>
              (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
          );
        } else {
          msg += dataMessage;
        }

        msg += "</div>";
        openNotificationWithIcon(
          "error",
          <div dangerouslySetInnerHTML={{ __html: msg }} />
        );
      }
    } catch (error) {
      //console.error("Error in performFix:", error);
    }
  };

  const linkTCIWithTCOV = () =>
    props.history.push(`/tci-voy-est/${state.formData["tc_code"]}`);

  const linkTCIWithTCTO = () =>
    openNotificationWithIcon(
      "error",
      <div
        dangerouslySetInnerHTML={{
          __html:
            "Go to TCTO page and create TCTO estimate and Link this TCO for subject vessel and schedule the voyage",
        }}
      />
    );

  const linkTCIWithVoyageRelet = () => props.history.push(`/voy-relet`);

  const scheduleTCIWithoutTrade = async () => {
    const { formData } = state;
    const response = await getAPICall(
      `${URL_WITH_VERSION}/tco/schedule/${state.formData["id"]}`
    );
    const respData = await response;

    if (respData["data"] === true) {
      openNotificationWithIcon("success", respData["message"]);
      window.emitNotification({
        n_type: "TCO Scheduled",
        msg: window.notificationMessageCorrector(
          `TCI is Scheduled for, vessel(${formData.vessel_code}), by ${window.userName}`
        ),
      });
      if (formData && formData.id && formData.tc_code) {
        _onLeftSideListClick(formData.tc_code);
      }
      // if (
      //   props.modalCloseEvent &&
      //   typeof props.modalCloseEvent === "function"
      // ) {
      //   props.modalCloseEvent();
      // }
    } else if (respData["data"] === false) {
      let dataMessage = respData["message"];
      let msg = "<div className='row'>";

      if (typeof dataMessage !== "string") {
        Object.keys(dataMessage).map(
          (i) =>
            (msg += "<div className='col-sm-12'>" + dataMessage[i] + "</div>")
        );
      } else {
        msg += dataMessage;
      }

      msg += "</div>";
      openNotificationWithIcon(
        "error",
        <div dangerouslySetInnerHTML={{ __html: msg }} />
      );
    }
  };

  const getMarineVessel = async (vesselname) => {
    try {
      let url = `${process.env.REACT_APP_MT_URL}marine/get-vessels-name/${vesselname}`;
      let marineData = await getAPICall(url);
      let marinVessel = marineData.filter(
        (item) => item.SHIPNAME.toLowerCase() === vesselname.toLowerCase()
      );

      if (marinVessel.length) {
        marinVessel = marinVessel[0];
      }
      return marinVessel;
    } catch (err) {
      console.log("err", err);
    }
  };

  const _onLeftSideListClick = async (tcoId) => {
    try {
      idRef.current = tcoId;
      const { formData } = [];
      let marinVessel = [];
      setState((prevState) => ({ ...prevState, frmVisible: false }));
      const response = await getAPICall(
        `${URL_WITH_VERSION}/tco/edit?ae=${tcoId}`
      );

      //formData["id"] = tcoId;

      const respData = await response["data"];

      if (respData) {
        if (respData["is_fixed"] == 1 || respData["is_schedule"] == 1) {
          localStorage.setItem("fixedvessel", JSON.stringify(true));
        }
        if (
          respData &&
          respData.hasOwnProperty("..") &&
          respData[".."].length < 8
        )
          respData[".."] = [
            {
              notice_type: "25 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "15 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "10 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "7 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "5 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "4 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "3 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "1 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
          ];
        if (
          respData &&
          respData.hasOwnProperty("...") &&
          respData["..."].length < 8
        )
          respData["..."] = [
            {
              notice_type: "25 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "15 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "10 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "7 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "5 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "4 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "3 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
            {
              notice_type: "1 DAYS",
              email_body: "",
              email_content: "",
              notice_sent: 0,
              project_delivary: "",
            },
          ];

        let newformData = _.cloneDeep(newFormData);
        if (respData["otherterm"] && respData["otherterm"].length > 0) {
          respData["otherterm"].forEach((respterm) => {
            newformData["otherterm"].forEach((frmterm, index) => {
              if (respterm["short_code"] === frmterm["short_code"]) {
                newformData["otherterm"][index] = respterm;
              }
            });
          });
          respData["otherterm"] = newformData["otherterm"];
        }
      }

      let _formData = Object.assign({}, formData, respData);

      setState(
        (prevState) => ({
          ...prevState,
          frmVisible: false,
          formData: respData,
          //rightMenuButtons: rightMenuButtons,
          showSideListBar: false,
          marineData: marinVessel,
        }),
        () => {
          setState((prevState) => ({ ...prevState, frmVisible: true }));
          localStorage.setItem(
            "tco_id",
            JSON.stringify({
              id: _formData["id"],
              tc_code: _formData["tc_code"],
              vessel_code: _formData["vessel_code"],
              company_lob: _formData["company_lob"],
            })
          );

          if (!(typeof props.modalCloseEvent === "function")) {
            navigate("/TC-OUT/" + _formData["tc_code"]);
          }
        }
      );
    } catch (err) {
      console.log("err", err);
      setState((prevState) => ({
        ...prevState,
        frmVisible: false,

        showSideListBar: false,
      }));
    }
  };

  const onClickExtraIcon = async (action, data) => {
    let delete_id = data && data.id;
    let groupKey = action["gKey"];
    let frm_code = "";
    if (groupKey == "Hire Term") {
      groupKey = "hireterm";
      frm_code = "tab_tco_pricing_form";
    }
    if (groupKey == "Commission Term") {
      groupKey = "commissionterm";
      frm_code = "tab_tco_pricing_form";
    }
    if (groupKey == "Bareboat") {
      groupKey = "bareboat";
      frm_code = "tab_tco_bareboat_form";
    }

    //vessel hire delete
    if (groupKey == "Vessel OffHire Delay") {
      groupKey = "vesseloffhiredelay";
      frm_code = "tab_tco_vessel_off_hire_form";
    }
    //END vessel hire delete

    if (groupKey == "..") {
      frm_code = "tab_tco_alerts_form";
    }
    if (groupKey == "...") {
      frm_code = "tab_tco_alerts_form";
    }

    if (groupKey == "Port Consp. Table (Per Day)") {
      groupKey = "Port Consp. Table (Per Day)";
      frm_code = "tab_tco_performance_form";
    }
    if (groupKey == "Sea Spd Consp. Table (Per Day)") {
      groupKey = "Sea Spd Consp. Table (Per Day)";
      frm_code = "tab_tco_performance_form";
    }

    if (groupKey == "Bareboats") {
      groupKey = "bareboats";
      frm_code = "tab_tco_bareboat_form";
    }

    if (groupKey && delete_id && Math.sign(delete_id) > 0 && frm_code) {
      // let data1 = {
      //   id: delete_id,
      //   frm_code: frm_code,
      //   group_key: groupKey,
      //   key: data.key,
      // };

      let data1 = {
        id: delete_id,
        frm_code: frm_code,
        group_key: groupKey,
      };
      let url = `${URL_WITH_VERSION}/tr-delete`;
      // replacing tr delete with custom delete api
      if (groupKey == "hireterm") {
        data1 = {
          id: delete_id,
        };
        url = `${URL_WITH_VERSION}/tco/hire-delete`;
      }
      if (groupKey == "vesseloffhiredelay") {
        data1 = {
          id: delete_id,
        };
        url = `${URL_WITH_VERSION}/tco/vessel-offhire-delete`;
      }

      postAPICall(url, data1, "delete", (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
        } else {
          openNotificationWithIcon("error", response.message);
        }
      });
    }
  };

  const showShareEmail = () => {
    try {
      const pageUrl = window.location.href;
      const linkBlob = new Blob([pageUrl], { type: "text/plain" });

      const emailContent = pageUrl;

      setState((prev) => ({ ...prev, isShowShare: true }));
      setSharebtn(linkBlob);
      setShareLink(emailContent);
    } catch (err) {
      console.log("err", err);
    }
  };

  const onclose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const onok = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const {
    isLoading,
    isDoSchedule,
    loadComponent,
    title,
    visibleDrawer,
    isShowTcoReports,
    frmName,
    formData,
    modalStatus,
    modalHeader,
    modalBody,
    modalFooter,
    paymentData,
    rightMenuButtons,
    reportFormData,
    formReportdata,
    frmVisible,
    showSideListBar,
    isSetsLeftBtn,
  } = state;

  // const isSetsLeftBtnArr = isSetsLeftBtn.filter(
  //   (item) =>
  //     // !( formData && formData.hasOwnProperty("id") && formData["id"] === 0 && item.key == "delete")
  //     !(
  //       (formData &&
  //         formData.hasOwnProperty("id") &&
  //         formData["id"] === 0 &&
  //         item.key === "delete") ||
  //       (formData &&
  //         formData.hasOwnProperty("is_schedule") &&
  //         formData["is_schedule"] === 1 &&
  //         item.key === "save")
  //     )
  // );

  const Opensoa = (boolval) => {
    const { formData } = state;
    if (
      formData &&
      formData.hasOwnProperty("id") &&
      formData["id"] > 0 &&
      formData["is_schedule"] === 1
    ) {
      let deliveryterm = formData["deliveryterm"];

      let tcoID = {
        vessel_id: formData.vessel_id,
        tc_id: formData.tc_code,
        tco_id: formData.id,
        cp_date: formData.cp_date_fixed_by,
        tc_code: formData.tc_code,
        delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
        redelivery_date: deliveryterm[1]
          ? deliveryterm[1].actual_gmt
          : undefined,
        chartered_from: formData.company_fixed_with,
        commpany_fixed_with_name: formData.commpany_fixed_with_name,
      };

      setState((pre) => ({
        ...pre,
        isOpenSoa: boolval,
        tcoID: tcoID,
      }));
    } else {
      openNotification(
        "Statements",
        `Please  ${
          formData["id"] ? "" : "Save TCO Form first,"
        }Fix and Schedule it and then Click on Statements.`
      );
    }

    // let tcoID = {
    //   vessel_id: formData.vessel_id,
    //   tc_id: formData.tc_code,
    //   tco_id: formData.id,
    //   cp_date: formData.cp_date_fixed_by,
    //   tc_code: formData.tc_code,
    //   // chartrer_id: formData.owner,
    //   delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
    //   redelivery_date: deliveryterm[1] ? deliveryterm[1].actual_gmt : undefined,
    //   chartered_from: formData.company_fixed_with,
    //   company_fixed_with_name: formData.company_fixed_with_name,
    // };

    // if (boolval) {
    //   setState((pre) => ({
    //     ...pre,
    //     isOpenSoa: boolval,
    //     tcoID: tcoID,
    //   }));
    // } else {
    //   setState((pre) => ({
    //     ...pre,
    //     isOpenSoa: boolval,
    //   }));
    // }
  };

  const Opensoar = (boolval) => {
    const { formData } = state;

    if (
      formData &&
      formData.hasOwnProperty("id") &&
      formData["id"] > 0 &&
      formData["is_schedule"] === 1
    ) {
      let deliveryterm = formData["deliveryterm"];
      let tcoID = {
        vessel_id: formData.vessel_id,
        tc_id: formData.id,
        cp_date: formData.cp_date_fixed_by,
        tc_code: formData.tc_code,
        //  chartered_from:
        // chartered_from:formData.beneficiary_name1,
        company_fixed_with_name: formData.company_fixed_with_name,
        chartrer_id: formData.owner,
        delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
        redelivery_date: deliveryterm[1]
          ? deliveryterm[1].actual_gmt
          : undefined,
      };
      setState((pre) => ({
        ...pre,
        isOpenSoar: boolval,
        tcoID: tcoID,
      }));
    } else {
      openNotification(
        "Statements",
        `Please  ${
          formData["id"] ? "" : "Save TCO Form first,"
        }Fix and Schedule it and then Click on Statements.`
      );
    }

    // let deliveryterm = formData["deliveryterm"];
    // let tcoID = {
    //   vessel_id: formData.vessel_id,
    //   tc_id: formData.id,
    //   cp_date: formData.cp_date_fixed_by,
    //   tc_code: formData.tc_code,
    //   // chartered_from:formData.beneficiary_name1,

    //   chartrer_id: formData.owner,
    //   delivery_date: deliveryterm[0] ? deliveryterm[0].actual_gmt : undefined,
    //   redelivery_date: deliveryterm[1] ? deliveryterm[1].actual_gmt : undefined,
    // };

    // if (boolval) {
    //   setState((pre) => ({
    //     ...pre,
    //     isOpenSoar: boolval,
    //     tcoID: tcoID,
    //   }));
    // } else {
    //   setState((pre) => ({
    //     ...pre,
    //     isOpenSoar: boolval,
    //   }));
    // }
  };

  if (params.id === "undefined") {
    return <RippleComponent />;
  }

  return (
    <div className="wrap-rightbar full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {frmName && frmVisible ? (
                      <article className="article">
                        <div className="box box-default">
                          <div className="box-body">
                            <NormalFormIndex
                              key={"key_" + frmName + "_0"}
                              formClass="label-min-height"
                              formData={formData}
                              showForm={true}
                              frmCode={frmName}
                              addForm={true}
                              showToolbar={[
                                {
                                  isLeftBtn: [
                                    {
                                      key: "s1",
                                      //isSets: isSetsLeftBtnArr,
                                      isSets: [
                                        {
                                          id: "7",
                                          key: "menu-fold",
                                          type: <MenuFoldOutlined />,
                                          withText: "List",
                                          event: "menu-fold",
                                          showToolTip: true,
                                        },
                                        {
                                          id: "5",
                                          key: "plus",
                                          type: <PlusOutlined />,
                                          withText: "Add New",
                                          showToolTip: true,
                                          event: (key, data) =>
                                            _onCreateFormData(),
                                        },

                                        {
                                          id: "2",
                                          key: "save",
                                          type: <SaveOutlined />,
                                          withText: "Save",
                                          showToolTip: true,
                                          event: (key, data) =>
                                            saveFormData(data),
                                        },

                                        ...(state?.formData?.id !== 0 &&
                                        !props?.showDeleteIcon &&
                                        props.showDeleteIcon !== "noShow" &&
                                        state?.formData?.id !== undefined
                                          ? [
                                              {
                                                id: "6",
                                                key: "delete",
                                                type: <DeleteOutlined />,
                                                withText: "Delete",
                                                showToolTip: true,
                                                event: (key, data) =>
                                                  _onDeleteFormData(data),
                                              },
                                            ]
                                          : []),
                                        {
                                          id: "20",
                                          key: "refresh",
                                          type: <SyncOutlined />,
                                          withText: "Refresh",
                                          showToolTip: true,
                                          event: () => {
                                            reFreshForm();
                                          },
                                        },
                                      ],
                                    },
                                  ],
                                  isRightBtn: [
                                    {
                                      key: "s2",

                                      isSets: [
                                        {
                                          key: "share",
                                          isDropdown: 0,
                                          withText: "",
                                          type: "Share",
                                          menus: null,
                                          modalKey: "Share",
                                          event: (key, data) => {
                                            if (data["tc_code"]) {
                                              showShareEmail(data);
                                            } else {
                                              openNotificationWithIcon(
                                                "error",
                                                "Please save the form first",
                                                3
                                              );
                                            }
                                          },
                                        },

                                        {
                                          key: "make_payment",
                                          isDropdown: 0,
                                          withText: "Issue Bill",
                                          type: "",
                                          // showToolTip: true,
                                          menus: null,
                                          event: (key, data) => {
                                            data &&
                                            data.hasOwnProperty("id") &&
                                            data["id"] > 0 &&
                                            data["tco_status"] === 14
                                              ? onMakePayment(key, data)
                                              : data &&
                                                data.hasOwnProperty("id") &&
                                                data["id"] > 0 &&
                                                data["tco_status"] === 125
                                              ? openNotificationWithIcon(
                                                  "info",
                                                  "Please Fix/Schedule first.",
                                                  3
                                                )
                                              : openNotification(
                                                  "Issue Bill",
                                                  `Please ${
                                                    data["id"]
                                                      ? ""
                                                      : "save TCO Form first,"
                                                  } Fix and Schedule it and then Click on 'Issue Bill'.`
                                                );
                                          },
                                        },

                                        {
                                          key: "hire_payment",
                                          isDropdown: 1,
                                          withText: "To Check Statement",
                                          showToolTip: true,
                                          type: "Statements",
                                          menus: [
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Hire Statement",
                                              modalKey: null,
                                              event: (key, data) => {
                                                data &&
                                                data.hasOwnProperty("id") &&
                                                data["id"] > 0 &&
                                                data["tco_status"] === 14
                                                  ? onHirePayment(key, data)
                                                  : data["tco_status"] === 125
                                                  ? openNotificationWithIcon(
                                                      "info",
                                                      "Please Fix/Schedule first.",
                                                      3
                                                    )
                                                  : openNotification(
                                                      "Statements",
                                                      `Please ${
                                                        data["id"]
                                                          ? ""
                                                          : "save TCO Form first,"
                                                      } Fix and Schedule it and then Click on 'Statement'.`
                                                    );
                                              },
                                            },
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Statement of Account",
                                              modalKey: null,
                                              event: (key, data) =>
                                                Opensoa(true, data),
                                            },
                                            // {
                                            //   href: null,
                                            //   icon: null,
                                            //   label:
                                            //     "Statement of Account-Recap",
                                            //   modalKey: null,
                                            //   event: (key, data) =>
                                            //     Opensoar(true, data),
                                            // },
                                          ],
                                        },
                                        ...(state?.formData?.is_fixed !== 1
                                          ? [
                                              {
                                                key: "fix",
                                                isDropdown: 0,
                                                withText: "Fix",
                                                type: "",
                                                // showToolTip: true,
                                                menus: null,
                                                event: (key, data) => {
                                                  data &&
                                                  data.hasOwnProperty("id") &&
                                                  data["id"] > 0
                                                    ? Modal.confirm({
                                                        title: "Confirm",
                                                        content:
                                                          "Are you sure, you want to Fix it?",
                                                        onOk: () =>
                                                          performFix(),
                                                      })
                                                    : openNotificationWithIcon(
                                                        "info",
                                                        "Please save Tco Form first, then click on fix."
                                                      );
                                                },
                                              },
                                            ]
                                          : []),

                                        ...(state?.formData?.is_schedule !== 1
                                          ? [
                                              {
                                                key: "schedule",
                                                isDropdown: 0,
                                                withText: "Schedule",
                                                type: "",
                                                // showToolTip: true,
                                                menus: null,
                                                event: (key, data) => {
                                                  data &&
                                                  data.hasOwnProperty("id") &&
                                                  data["id"] > 0
                                                    ? //? scheduleTCIWithoutTrade()
                                                      Modal.confirm({
                                                        title: "Confirm",
                                                        content:
                                                          "Are you sure, you want to Schedule it?",
                                                        onOk: () =>
                                                          scheduleTCIWithoutTrade(),
                                                      })
                                                    : openNotification(
                                                        "Schedule"
                                                      );
                                                },
                                              },
                                            ]
                                          : []),

                                        {
                                          key: "Commision",
                                          isDropdown: 1,
                                          withText: "Commission",
                                          type: "Commision",
                                          showToolTip: true,
                                          menus: [
                                            {
                                              href: null,
                                              icon: +null,
                                              label: "TC Commission",
                                              modalKey: "tc_commission",
                                              event: (key, data) => {
                                                data &&
                                                data.hasOwnProperty("id") &&
                                                data["id"] > 0
                                                  ? onClickCommission(key, data)
                                                  : openNotification(
                                                      "TC Commission"
                                                    );
                                              },
                                            },
                                            {
                                              href: null,
                                              icon: null,
                                              label: "Commission Summary",
                                              modalKey: "claim_commission",
                                              event: (key, data) => {
                                                data &&
                                                data.hasOwnProperty("id") &&
                                                data["id"] > 0
                                                  ? onClickCommission(key, data)
                                                  : openNotification(
                                                      "Claim Commission"
                                                    );
                                              },
                                            },
                                          ],
                                        },

                                        {
                                          key: "report",
                                          isDropdown: 0,
                                          withText: "Report",
                                          type: "",
                                          // showToolTip: true,
                                          menus: null,
                                          event: (key, data) =>
                                            data && data.id
                                              ? openTcoReports(true, data.id)
                                              : openNotificationWithIcon(
                                                  "info",
                                                  "please save the form First!"
                                                ),
                                        },
                                      ],
                                    },
                                  ],
                                  isResetOption: false,
                                },
                              ]}
                              inlineLayout={true}
                              sideList={{
                                showList: true,
                                title: "TCO List",
                                uri: "/tco/list?l=0",
                                columns: [
                                  ["tc_code", "tco_status_name"],
                                  "vessel_name",
                                  "charterer_name",
                                ],
                                icon: true,
                                searchString:
                                  '"TCO Code","TCO Status","Vessel Name","Owner Name"',
                                rowClickEvent: (evt) =>
                                  _onLeftSideListClick(evt.tc_code),
                              }}
                              isShowFixedColumn={[
                                "Delivery Terms",
                                ".....",
                                "......",
                                "...",
                                "..",
                                "Hire Terms",
                                "Bareboats",
                                "purchase",
                                "OffHire Delays",
                                "Voyage Historys",
                                "Commission Term",
                                "Other Term",
                                "Bunkers",
                                "Port Consp. Table (Per Day)",
                                "Sea Spd Consp. Table (Per Day)",
                                "Vessel OffHire Delay",
                              ]}
                              showSideListBar={showSideListBar}
                              tableRowDeleteAction={(action, data) =>
                                onClickExtraIcon(action, data)
                              }
                              tabEvents={[
                                {
                                  tabName: "TC Bunker Adjustment",
                                  event: {
                                    from: "Bunkers",
                                  },
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </article>
                    ) : (
                      <CustomLoader />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>

        <RightBarUI
          pageTitle="tco-righttoolbar"
          callback={(data, options) => onClickRightMenu(data, options)}
        />
        {/* {console.log("state.width",state.width)} */}
        {/* {loadComponent !== undefined &&
        title !== undefined &&
        visibleDrawer === true ? (
          <Drawer
            title={state.title}
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={state.visibleDrawer}
            getContainer={false}
            style={{ position: "absolute", width: "60%", right: "0" }}
            width={state.width}
            maskClosable={false}
            className="drawer-wrapper-container"
          >
            <div className="tcov-wrapper">
              <div className="layout-wrapper scrollHeight">
                <div className="content-wrapper noHeight">
                  {state.loadComponent}
                </div>
              </div>
            </div>
          </Drawer>
        ) : undefined} */}
        {loadComponent !== undefined &&
        title !== undefined &&
        visibleDrawer === true ? (
          state?.title === "Attachments" ? (
            // Special Drawer for Attachments
            <Drawer
              title={state.title}
              placement="right"
              closable={true}
              onClose={onCloseDrawer}
              open={state.visibleDrawer}
              getContainer={false}
              style={{ position: "absolute", width: "60%", right: "0" }}
              maskClosable={false}
              className="drawer-wrapper-container attachments-drawer"
            >
              <div className="tcov-wrapper">
                <div className="layout-wrapper scrollHeight">
                  <div className="content-wrapper noHeight">
                    {state?.loadComponent}
                  </div>
                </div>
              </div>
            </Drawer>
          ) : (
            <Drawer
              title={state?.title}
              placement="right"
              closable={true}
              onClose={onCloseDrawer}
              open={state.visibleDrawer}
              getContainer={false}
              style={{ position: "absolute" }}
              width={state.width}
              maskClosable={false}
              className="drawer-wrapper-container"
            >
              <div className="tcov-wrapper">
                <div className="layout-wrapper scrollHeight">
                  <div className="content-wrapper noHeight">
                    {state?.loadComponent}
                  </div>
                </div>
              </div>
            </Drawer>
          )
        ) : undefined}
      </Layout>

      {/* 
      {state.visibleMakePayment === true ? (
        <Modal
          title="Issue Bill"
          open={state.visibleMakePayment}
          width="80%"
          onCancel={onCancelMakePayment}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
          maskClosable={false}
        >
          <MakePayment
            modalCloseEvent={onCancelMakePayment}
            formData={paymentData}
            remarksID={state.formData.tc_code}
          />
        </Modal>
      ) : undefined} */}

      {state.isOpenSoa === true ? (
        <Modal
          title="Statement of Account"
          open={state.isOpenSoa}
          width="80%"
          onCancel={() => Opensoa(false)}
          style={{
            maxHeight: 790,
            overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          footer={null}
        >
          <StatementofAccount
            data={state.tcoID}
            handlePrint={handlePrint}
            ref={statementRef}
          />
        </Modal>
      ) : undefined}

      {state.isOpenSoar === true ? (
        <Modal
          title="Statement of Account Recap"
          open={state.isOpenSoar}
          width="80%"
          onCancel={() => Opensoar(false)}
          style={{
            macHeight: 790,
            overflowY: "auto",
            padding: "0.5rem",
            top: "10px",
          }}
          footer={null}
        >
          <StatementofAccountRecap
            data={state.tcoID}
            handlePrint={handlePrint}
            ref={statementRef}
          />
        </Modal>
      ) : undefined}

      {isShowTcoReports ? (
        <Modal
          style={{ top: "2%" }}
          title="Reports"
          open={isShowTcoReports}
          // onOk={handleOk}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isShowTcoReports: false }))
          }
          width="95%"
          footer={null}
          maskClosable={false}
        >
          <TcoReports data={reportFormData} />
        </Modal>
      ) : undefined}

      {state.VoyageManagerInfoModal && state.vmInfoData.length ? (
        <Modal
          style={{ top: "2%" }}
          title="Voyage Manager Info"
          open={state.VoyageManagerInfoModal}
          onCancel={() =>
            setState((pre) => ({ ...pre, VoyageManagerInfoModal: false }))
          }
          width="95%"
          bodyStyle={{ overflowY: "auto", padding: "0.5rem" }}
          footer={null}
        >
          <VoyageManagerInfoTciTco vmInfoData={state.vmInfoData} />
        </Modal>
      ) : undefined}

      {state.isShowShare && (
        <Modal
          title="New Message"
          open={true}
          onCancel={() => onclose()}
          onOk={() => onok()}
          footer={null}
        >
          <Email
            title={window.corrector(`TCO  || ${state.formData.tc_code}`)}
            attachmentFile={sharebtn}
            // link={shareLink}
            showpage={true}
            condition={false}
            shareLink={shareLink}
            handleClose={handleClose}
          />
        </Modal>
      )}

      {/* 
        {isDoSchedule === true ? (
          <Modal
            title="Schedule TCO"
            open={isDoSchedule}
            width="40%"
            onCancel={() => scheduleTCO(false)}
            style={{ top: "10px" }}
            bodyStyle={{
              maxHeight: 790,
              overflowY: "auto",
              padding: "2rem 1rem",
            }}
            footer={null}
            maskClosable={false}
          >
            {/* <Button className="btn btn-primary mr-2 line-height-20px" onClick={linkTCIWithTCOV}>Link with TCOV</Button> 
            <Button
              className="btn btn-info mr-2 line-height-20px"
              onClick={linkTCIWithTCTO}
            >
              Link with TCTO
            </Button>
            {
              /* <Button className="btn btn-success mr-2 line-height-20px" onClick={linkTCIWithVoyageRelet}>Link with Voyage Relet</Button>
              <Button
                className="btn btn-secondary mr-2 line-height-20px"
                onClick={scheduleTCIWithoutTrade}
              >
                Schedule without Voyage
              </Button>
            }
          </Modal>
        ) : (
          undefined
        )} */}

      <ModalAlertBox
        modalStatus={modalStatus}
        modalHeader={modalHeader}
        modalBody={modalBody}
        modalFooter={modalFooter}
        onCancelFunc={() => onCancelModal()}
      />
      <ReactToPrint
        trigger={() => (
          <Button style={{ display: "none" }} id="print-button">
            Print
          </Button>
        )}
        content={() => statementRef.current}
      />
    </div>
  );
};

export default TCO;
