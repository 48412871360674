

import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Select } from "antd";
import { Icon } from "@iconify-icon/react/dist/iconify.mjs";


const { Option } = Select;

const ReminderDropdown = ({ onCustomReminder }) => {
  const reminderOptions = [
    // { value: 'none', option: "Don't remind me" },
    // { value: '0', option: 'At time of event' },
    { value: "5 m", option: "5 minutes before" },
    { value: "15 m", option: "15 minutes before" },
    { value: "30 m", option: "30 minutes before" },
    { value: "1 hr", option: "1 hour before" },
    { value: "2 hr", option: "2 hours before" },
    { value: "12 hr", option: "12 hours before" },
    // { value: '1d', option: '1 day before' },
    // { value: '1w', option: '1 week before' },
  ];
//   const [anchorEl, setAnchorEl] = (useState < null) | (HTMLElement > null);
  const [customTime, setCustomTime] = useState(null);
  const [isCustomDialogOpen, setIsCustomDialogOpen] = useState(false);

  const [reminderTiming, setSelectedReminder] = useState("15m");

  const handleCustomClick = () => {
    setIsCustomDialogOpen(true);
    // handleClose();
  };

  const handleCustomDialogClose = () => {
    setIsCustomDialogOpen(false);
  };

  const handleCustomTimeChange = (newValue) => {
    setCustomTime(newValue);
  };

  const handleCustomSubmit = () => {
    if (customTime) {
      onCustomReminder(dayjs(customTime).format("YYYY-MM-DD HH:mm")); // Send the formatted value to parent
    }
    handleCustomDialogClose();
  };

  const handleMenuItemClick = (value) => {
    console.log("valueeee",value)
    if (value === "Custom") {
      handleCustomClick();
    } else {
      setSelectedReminder(value);
      onCustomReminder(value);
    }
    
  };

  return (
    <div>
      {/* Clock Icon */}
      <Icon style={{fontSize:"30px", verticalAlign:"middle"}} icon="tabler:alarm"/>
      <Select
        style={{ width: 200 }}
        value={reminderTiming}
        onChange={handleMenuItemClick}
        placeholder="Select Reminder"
        dropdownMatchSelectWidth={false}
      >
        {reminderOptions.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.option}
          </Option>
        ))}
        <Option value="Custom">Custom</Option>
      </Select>
    </div>
  );
};

export default ReminderDropdown;
