import React, { Component } from "react";

import { Form, Input, DatePicker, Checkbox } from "antd";
//import DownloadInvoice from './DownloadInvoice';
import HireStatementReport from "../../../../shared/components/All-Print-Reports/HireStatementReport";
import moment from "moment";
import URL_WITH_VERSION, {
  awaitPostAPICall,
  getAPICall,
  openNotificationWithIcon,
} from "../../../../shared";
import dayjs from "dayjs" ;

const FormItem = Form.Item;
//const Option = Select.Option;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

class TcoConfirmStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      showInvoice: false,
      invoiceData: {},
      statementAC: {
        tco_id: this.props.tcoID || 0,
        vessel_id: this.props.vesselID || 0,
        vessel_name: undefined,
        chartrer_id: this.props.chartrerID || 0,
        chartrer_name: undefined,
        date_from: this.props.from || null,
        date_to: this.props.to || null,
        account_head: {
          TCOH: true,
          TCOADCOM: true,
          TCOBCOM: true,
          TCOBB: true,
          TCOILOHC: true,
          TCOIHC: true,
          BD: true,
          TCOREA: true,
          BR: true,
          TCOLAST: true,
          TCOGRA: true,
          TCOVIC: true,
          TCOOFFHD: true,
          TCOVOFHD: true,
        },
      },
    };
  }

  async componentDidMount() {
    let request = await getAPICall(
      `${URL_WITH_VERSION}/tco/edit?e=${this.props.tcocode}&sn=1`
    );
    let response = await request["data"];

    if (response) {
      let sac = Object.assign({}, this.state.statementAC);
      // console.log("LAST",sac);
      sac["vessel_name"] = response["vessel_name"];
      sac["chartrer_name"] = response["chartrer_name"];
      sac["date_from"] = sac["date_from"] ? moment(sac["date_from"]) : null;
      sac["date_to"] = sac["date_to"] ? moment(sac["date_to"]) : null;
      this.setState({ ...this.state, statementAC: sac }, () => {
        this.setState({ ...this.state, showForm: true });
      });
      // console.log("sac");
      
    } else {
      this.setState({ ...this.state, showForm: true });
    }

    // console.log("first",this.state);

  }

  onChangeEvent = (event, name) => {
    // console.log("------>>>>>>>>>>",event, name);
    
    let sac = Object.assign({}, this.state.statementAC);
    if (moment.isMoment(event)) {
      sac[name] = event;
    } else if (
      event &&
      event.hasOwnProperty("target") &&
      event["target"] &&
      event["target"].hasOwnProperty("checked") &&
      sac["account_head"].hasOwnProperty(name)
    ) {
      sac["account_head"][name] = event["target"]["checked"];
    }

    this.setState({ ...this.state, statementAC: sac });
  };

  onClickInvoiceButton = async () => {
    this.state.statementAC.hire_invoice = true;
    let request = await awaitPostAPICall(
      `${URL_WITH_VERSION}/report/tco-hire-statement`,
      this.state.statementAC
    );
    let response = await request;

    if (response.data) {
      this.setState({ ...this.state, invoiceData: response }, () => {
        this.setState({ ...this.state, showInvoice: true, showForm: false });
      });
    } else {
      openNotificationWithIcon("error", response.message, 5);
    }
  };

  onClickInvoiceTotalButton = async () => {
    let request = await awaitPostAPICall(
      `${URL_WITH_VERSION}/report/tco-hire-statement-total`,
      this.state.statementAC
    );
    let response = await request;

    if (response["data"]) {
      this.setState({ ...this.state, invoiceData: response["data"] }, () => {
        this.setState({ ...this.state, showInvoice: true, showForm: false });
      });
    } else {
      openNotificationWithIcon("error", response["message"]);
    }
  };

  render() {
    const { statementAC, showForm, showInvoice, invoiceData } = this.state;
    return (
      <>
        {showForm ? (
          <div className="body-wrapper modalWrapper">
            <article className="article toolbaruiWrapper">
              <div className="box box-default">
                <div className="box-body">
                  <Form>
                    <div className="row p10">
                      <div className="col-md-6">
                        <FormItem {...formItemLayout} label="Vessel Name">
                          <Input
                            type="text"
                            size="default"
                            defaultValue={statementAC.vessel_name}
                            readOnly
                          />
                        </FormItem>
                      </div>

                      <div className="col-md-6">
                        <FormItem {...formItemLayout} label="Chartered">
                          <Input
                            type="text"
                            size="default"
                            defaultValue={statementAC.chartrer_name}
                            readOnly
                          />
                        </FormItem>
                      </div>
                      {/* {console.log("statementAC.date_from",statementAC.date_from)
                      } */}
                      <div className="col-md-6">
                        <FormItem {...formItemLayout} label="Date From">
                          <DatePicker
                            // defaultValue={statementAC.date_from}
                            defaultValue={ dayjs(statementAC.date_from)}
                            format={"YYYY-MM-DD HH:mm"}
                            placeholder="YYYY-MM-DD HH:MM"
                            onChange={(evt) =>
                              this.onChangeEvent(evt, "date_from")
                            }
                            showTime={{
                              defaultValue: moment("00:00", "HH:mm"),
                            }}
                          />
                        </FormItem>
                      </div>

                      <div className="col-md-6">
                        <FormItem {...formItemLayout} label="Date To">
                          <DatePicker
                            defaultValue={ dayjs(statementAC.date_to)}
                            format={"YYYY-MM-DD HH:mm"}
                            placeholder="YYYY-MM-DD HH:MM"
                            onChange={(evt) =>
                              this.onChangeEvent(evt, "date_to")
                            }
                            showTime={{
                              defaultValue: moment("00:00", "HH:mm"),
                            }}
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className="row p10">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOH"]
                              }
                              checked={statementAC["account_head"]["TCOH"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOH")
                              }
                            >
                              <span>Hire Payment</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOADCOM"]
                              }
                              checked={statementAC["account_head"]["TCOADCOM"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOADCOM")
                              }
                            >
                              <span>Add Comm</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOBCOM"]
                              }
                              checked={statementAC["account_head"]["TCOBCOM"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOBCOM")
                              }
                            >
                              <span>Broker Comm</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOBB"]
                              }
                              checked={statementAC["account_head"]["TCOBB"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOBB")
                              }
                            >
                              <span>Ballast Bonus</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={true}
                              checked={true}
                              onChange={(evt) => this.onChangeEvent(evt, "")}
                            >
                              <span>Other Adjustment</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOILOHC"]
                              }
                              checked={statementAC["account_head"]["TCOILOHC"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOILOHC")
                              }
                            >
                              <span>ILHOC</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOGRA"]
                              }
                              checked={statementAC["account_head"]["TCOGRA"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOGRA")
                              }
                            >
                              <span>Gratuity</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOVIC"]
                              }
                              checked={statementAC["account_head"]["TCOVIC"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOVIC")
                              }
                            >
                              <span>Victualling</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOLAST"]
                              }
                              checked={statementAC["account_head"]["TCOLAST"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOLAST")
                              }
                            >
                              <span>Lashing & Stowage</span>
                            </Checkbox>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOOFFHD"]
                              }
                              checked={statementAC["account_head"]["TCOOFFHD"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOOFFHD")
                              }
                            >
                              <span>Voyage OffHire/delay</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOVOFHD"]
                              }
                              checked={statementAC["account_head"]["TCOVOFHD"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOVOFHD")
                              }
                              TCOOFFHD
                            >
                              <span>Vessel Offhire/delay</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            {/* <Checkbox defaultChecked={true} checked={true} onChange={(evt) => this.onChangeEvent(evt, "")}><span>Off Hire Adj.</span></Checkbox> */}
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOIHC"]
                              }
                              checked={statementAC["account_head"]["TCOIHC"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOIHC")
                              }
                            >
                              <span>IHC</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={statementAC["account_head"]["BD"]}
                              checked={statementAC["account_head"]["BD"]}
                              onChange={(evt) => this.onChangeEvent(evt, "BD")}
                            >
                              <span>Bunker Items</span>
                            </Checkbox>
                          </div>
                          <div className="col-md-4" style={{ paddingRight: 0 }}>
                            <Checkbox
                              defaultChecked={
                                statementAC["account_head"]["TCOREA"]
                              }
                              checked={statementAC["account_head"]["TCOREA"]}
                              onChange={(evt) =>
                                this.onChangeEvent(evt, "TCOREA")
                              }
                            >
                              <span>Rep. Exp/Allowance</span>
                            </Checkbox>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row p10">
                      <div className="col-md-12">
                        <button
                          style={{backgroundColor:"#003e78",color :"white"}}
                          className="btn ant-btn-primary mr-2"
                          onClick={this.onClickInvoiceButton}
                        >
                          Invoiced
                        </button>
                        {/* <button className="btn ant-btn-primary" onClick={this.onClickInvoiceTotalButton}>Total</button> */}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </article>
          </div>
        ) : undefined}

        {showInvoice ? (
          <HireStatementReport invData={invoiceData.data} />
        ) : undefined}
      </>
    );
  }
}

export default TcoConfirmStatement;
