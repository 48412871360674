import {
  HomeOutlined,
  SearchOutlined,
  FileTextOutlined,
  CloudUploadOutlined,
  LineChartOutlined,
  FilePdfOutlined,
  ContactsOutlined,
  AlertOutlined,
  EditOutlined,
  PieChartOutlined,
  HistoryOutlined,
  FilterOutlined,
  DiffOutlined,
  CaretUpOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  CodeSandboxOutlined,
  FileDoneOutlined,
  VerticalAlignTopOutlined,
  BuildOutlined,
  MailOutlined,
  HeatMapOutlined,
} from "@ant-design/icons";
import URL_WITH_VERSION, { IMAGE_PATH } from "../shared";
import Icon from "@ant-design/icons/lib/components/Icon";
import { color } from "echarts";
import { Icon as IconifyIcon } from "@iconify-icon/react/dist/iconify.js";

const RightBARCONFIGURATION = {
  "anlytical-dashboard-right-panel": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      { key: "search", title: "Search", icon: <SearchOutlined />, width: 1100 },
      {
        key: "chartring",
        title: "Chartring Dashboard",
        icon: <BuildOutlined />,
        width: 400,
      },
      {
        key: "accounting",
        title: "Accounting Dashboard",
        icon: <BuildOutlined />,
        width: 400,
      },
      {
        key: "summary",
        title: "summary",
        icon: <FileTextOutlined />,
        width: 400,
      },
      { key: "reports", title: "Reports", icon: <CloudUploadOutlined /> },
      { key: "vspm", title: "VSPM Dashboard", icon: <CloudUploadOutlined /> },
    ],
  },
  "vessel-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
        width: 300,
      },
      {
        key: "vm",
        title: "Voyage Manager Info",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "19px", marginLeft: "0px" }}
                src={IMAGE_PATH + "svgimg/vm.svg"}
                alt="vm"
              />
            )}
          />
        ),
      },
      {
        key: "sideMap",
        title: "Vessel Map",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/map-2-line.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
    ],
  },
  "tci-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      // { key: 'pl-summary', title: 'P&L', icon: <LineChartOutlined />, width: 1100 },
      {
        key: "summary",
        title: "summary",
        icon: <FileTextOutlined />,
        width: 400,
      },
      // { key: 'properties', title: 'Properties', icon: <BuildOutlined />, width: 400 },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
        width: 300,
      },
      {
        key: "vm",
        title: "Voyage Manager Info",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "19px", marginLeft: "0px" }}
                src={IMAGE_PATH + "svgimg/vm.svg"}
                alt="vm"
              />
            )}
          />
        ),
      },
      {
        key: "sideMap",
        title: "Vessel Map",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/map-2-line.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },

      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
    ],
  },

  "voyage-manager-righttoolbar": {
    menuoption: [
      {
        key: "voyage-home",
        title: "Home",
        icon: <HomeOutlined style={{ color: "white" }} />,
      },
      {
        key: "pl-summary",
        title: "P&L Summary",
        icon: <LineChartOutlined style={{ color: "white" }} />,
        width: 1100,
      },
      // // { key: 'estimates-summary', title: 'Summary Tree', icon: <FileDoneOutlined />, width: 400 },
      {
        key: "log",
        title: "Log",
        icon: <HistoryOutlined style={{ color: "white" }} />,
        width: 400,
      },
      {
        key: "invoice",
        title: "Voyage Invoice List",
        icon: <FilePdfOutlined style={{ color: "white" }} />,
        width: 400,
      },
      {
        key: "contact",
        title: "Voyage Contact List",
        icon: <ContactsOutlined style={{ color: "white" }} />,
        width: 400,
      },
      //{ key: 'proprties', title: 'Properties', icon: <BuildOutlined />, width: 400 },
      //{ key: 'position-report', title: 'Position Report', icon: 'pie-chart', width: 1100 },
      {
        key: "task-alert",
        title: "Task & alert",
        icon: <AlertOutlined style={{ color: "white" }} />,
        width: 1150,
      },
      //{ key: 'analytics', title: 'Analytics', icon: 'code-sandbox' },
      //{ key: 'port_distance', title: 'Port Distance', icon: 'environment', width: 1100 },
      // { key: 'port_route_details', title: 'Port Route Details', icon: 'share-alt', width: 1100 },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
        width: 1100,
      },
      {
        key: "remark",
        title: "Remarks",
        icon: <EditOutlined style={{ color: "white" }} />,
        width: 1100,
      },
      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
      // {
      //   key: "map",
      //   title: "Map",
      //   icon: (
      //     <Icon component={() => <img
      //       style={{ width: "25px", marginLeft: "-6px", color: 'white' }}
      //       src={IMAGE_PATH + "svgimg/map.svg"}
      //       alt="map"
      //     />}/>
      //   ),
      //   width: 1100,
      // },
      {
        key: "bunker",
        title: "Bunker",
        selector: "#bunker",
        id: "bunker",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px", marginLeft: "-2px", color: "white" }}
                src={IMAGE_PATH + "svgimg/oil-line.svg"}
                alt="Bunker"
              />
            )}
          />
        ),
        width: 1100,
      },
      {
        key: "weather",
        title: "Weather",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px", color: "white" }}
                src={IMAGE_PATH + "svgimg/cloud-line.svg"}
                alt="map"
              />
            )}
          />
        ),
        width: 1100,
      },

      {
        key: "Noon",
        title: "Noon",
        icon: (
          <a href="#/dynamic-vspm" target="_blank" rel="noopener noreferrer">
            <Icon
              component={() => (
                <img
                  style={{ width: "25px", paddingRight: "6px", color: "white" }}
                  src={IMAGE_PATH + "svgimg/ship-line.svg"}
                  alt="map"
                />
              )}
            />
          </a>
        ),
        width: 1100,
      },
      {
        key: "sideMap",
        title: "Vessel Map",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/map-2-line.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "port-congestion",
        title: "Port Congestion",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/position.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-bunker-analysis",
        title: "AI bunker analysis",
        icon: (
          <Icon
            style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <IconifyIcon
                  style={{ fontSize: "25px", color: "rgb(164, 198, 57)" }}
                  icon="mdi:petrol"
                />
                <span
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: "1px",
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-agent",
        title: "AI Agent",
        icon: (
          <Icon
            style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >

                <IconifyIcon
                  style={{
                    fontSize: "26px",
                    color: "rgb(43 222 222)",
                    animation: "heartbeat 1s infinite, shine 1s infinite",
                  }}
                  icon="material-symbols:person"
                />
                 <span
                  style={{
                    position: "absolute",
                    left: "0.6rem",
                    bottom: "1.3rem",
                    color: "red",
                    backgroundColor: "white",
                    borderRadius: "100%",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "0.8rem",
                    height: "0.8rem"
                  }}
                >
                  3
                </span>
                <span
                  style={{
                    position: "absolute",
                    bottom: "0.3rem",
                    left: 1,
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
    ],
  },
  "tco-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      // { key: 'pl-summary', title: 'P&L', icon: <LineChartOutlined />, width: 1100 },
      {
        key: "summary",
        title: "summary",
        icon: <FileTextOutlined />,
        width: 400,
      },
      // { key: 'properties', title: 'Properties', icon: <BuildOutlined />, width: 400 },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
        width: 300,
      },
      {
        key: "vm",
        title: "Voyage Manager Info",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "19px", marginLeft: "0px" }}
                src={IMAGE_PATH + "svgimg/vm.svg"}
                alt="vm"
              />
            )}
          />
        ),
      },
      {
        key: "sideMap",
        title: "Vessel Map",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/map-2-line.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },

      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
    ],
  },
  "tcov-righttoolbar": {
    menuoption: [
      {
        key: "home",
        title: "Home",
        icon: <HomeOutlined style={{ color: "white" }} />,
      },
      {
        key: "pl",
        title: "P & L",
        icon: <LineChartOutlined style={{ color: "white" }} />,
        width: 1100,
        selector: "#pnl",
        id: "pnl",
        content: "Check pnl",
      },

      {
        key: "attachment",
        title: "Attachment",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
      },
      {
        key: "remark",
        title: "Remarks",
        icon: <EditOutlined style={{ color: "white" }} />,
        width: 1100,
      },
      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
      // {
      //   key: "map",
      //   title: "Map",
      //   icon: (
      //     <Icon
      //       component={() => (
      //         <img
      //           style={{ width: "25px", marginLeft: "-6px", color: "white" }}
      //           src={IMAGE_PATH + "svgimg/map.svg"}
      //           alt="map"
      //         />
      //       )}
      //     />
      //   ),
      //   width: 1100,
      //   selector:"#map",
      //   id:"map",
      //   content:"Check Map Intelligence"
      // },
      {
        key: "bunker",
        title: "Bunker",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px", marginLeft: "-2px", color: "white" }}
                src={IMAGE_PATH + "svgimg/oil-line.svg"}
                alt="Bunker"
              />
            )}
          />
        ),
        width: 1100,
        selector: "#bunker",
        id: "bunker",
        content: "Check Bunker live prices",
      },
      {
        key: "weather",
        title: "Weather",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/cloud-line.svg"}
                alt="map"
              />
            )}
          />
        ),
        width: 1100,
      },
      {
        key: "sideMap",
        title: "Vessel Map",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/map-2-line.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "port-congestion",
        title: "Port congestion",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/position.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-bunker-analysis",
        title: "AI bunker analysis",
        icon: (
          <Icon
            style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <IconifyIcon
                  style={{ fontSize: "25px", color: "rgb(164, 198, 57)" }}
                  icon="mdi:petrol"
                />
                <span
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: "1px",
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-agent",
        title: "AI Agent",
        icon: (
          <Icon
            style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >

                <IconifyIcon
                  style={{
                    fontSize: "26px",
                    color: "rgb(43 222 222)",
                    animation: "heartbeat 1s infinite, shine 1s infinite",
                  }}
                  icon="material-symbols:person"
                />
                 <span
                  style={{
                    position: "absolute",
                    left: "0.6rem",
                    bottom: "1.3rem",
                    color: "red",
                    backgroundColor: "white",
                    borderRadius: "100%",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "0.8rem",
                    height: "0.8rem"
                  }}
                >
                  3
                </span>
                <span
                  style={{
                    position: "absolute",
                    bottom: "0.3rem",
                    left: 1,
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-port-cost-estimation",
        title: "AI Port Cost Estimation",
        icon: (
          <Icon
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <IconifyIcon
                  style={{ fontSize: "25px", color: "white" }}
                  icon="fluent:arrow-growth-24-filled"
                />
              </div>
            )}
          />
        ),
        width: 700,
      },
    ],
  },

  "tcov-quick-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      { key: "search", title: "Search", icon: <SearchOutlined />, width: 400 },
      { key: "attachment", title: "Attachment", icon: <CloudUploadOutlined /> },
      { key: "mail", title: "Mail", icon: <MailOutlined />, width: 1300 },
    ],
  },

  "voy-relet-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      {
        key: "pl",
        title: "P & L",
        icon: <LineChartOutlined />,
        width: 1100,
        selector: "#pnl",
        id: "pnl",
        content: "Check pnl",
      },
      { key: "attachment", title: "Attachment", icon: <CloudUploadOutlined /> },
      {
        key: "remark",
        title: "Remarks",
        icon: <EditOutlined style={{ color: "white" }} />,
        width: 1100,
      },
      { key: "mail", title: "Mail", icon: <MailOutlined />, width: 1300 },
      // {
      //   key: "map",
      //   title: "Map",
      //   icon: (
      //     <Icon
      //       component={() => (
      //         <img
      //           style={{ width: "25px", marginLeft: "-6px", color: "white" }}
      //           src={IMAGE_PATH + "svgimg/map.svg"}
      //           alt="map"
      //         />
      //       )}
      //     />
      //   ),
      //   width: 1100,
      //   selector: "#map",
      //   id: "map",
      //   content: "Check Map Intelligence",
      // },
      {
        key: "bunker",
        title: "Bunker",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px", marginLeft: "-2px", color: "white" }}
                src={IMAGE_PATH + "svgimg/oil-line.svg"}
                alt="Bunker"
              />
            )}
          />
        ),
        width: 1100,
        selector: "#bunker",
        id: "bunker",
        content: "Check Bunker live prices",
      },
      {
        key: "weather",
        title: "Weather",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/cloud-line.svg"}
                alt="map"
              />
            )}
          />
        ),
        width: 1100,
      },
      {
        key: "sideMap",
        title: "Vessel Map",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/map-2-line.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "port-congestion",
        title: "Port Congestion",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/position.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-bunker-analysis",
        title: "AI bunker analysis",
        icon: (
          <Icon
          style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <IconifyIcon
                  style={{ fontSize: "25px", color: "rgb(164, 198, 57)" }}
                  icon="mdi:petrol"
                />
                <span
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left:"1px",
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-agent",
        title: "AI Agent",
        icon: (
          <Icon
            style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >

                <IconifyIcon
                  style={{
                    fontSize: "26px",
                    color: "rgb(43 222 222)",
                    animation: "heartbeat 1s infinite, shine 1s infinite",
                  }}
                  icon="material-symbols:person"
                />
                 <span
                  style={{
                    position: "absolute",
                    left: "0.6rem",
                    bottom: "1.3rem",
                    color: "red",
                    backgroundColor: "white",
                    borderRadius: "100%",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "0.8rem",
                    height: "0.8rem"
                  }}
                >
                  3
                </span>
                <span
                  style={{
                    position: "absolute",
                    bottom: "0.3rem",
                    left: 1,
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
    ],
  },

  "voy-relet-quick-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      { key: "search", title: "Search", icon: <SearchOutlined />, width: 400 },
      { key: "attachment", title: "Attachment", icon: <CloudUploadOutlined /> },
    ],
  },

  "tcto-righttoolbar": {
    menuoption: [
      {
        key: "home",
        title: "Home",
        icon: <HomeOutlined style={{ color: "white" }} />,
      },
      {
        key: "pl-summary",
        title: "PL Summary",
        icon: <LineChartOutlined style={{ color: "white" }} />,
        width: 1100,
        selector: "#pnl",
        id: "pnl",
        content: "Check pnl",
      },
      // { key: 'estimates-summary', title: 'Summary Tree', icon: <FileDoneOutlined />, width: 400 },
      // { key: 'portinformation', title: 'Port Information', icon: <SettingOutlined />, width: 1100 },
      //  { key: 'port_distance', title: 'Port Distance', icon: 'environment', width: 1100 },
      //  { key: 'port_route_details', title: 'Port Route Details', icon: 'share-alt', width: 1100 },
      // { key: 'search', title: 'Search', icon: <SearchOutlined />, width: 400 },
      // { key: 'properties', title: 'Properties', icon: <BuildOutlined />, width: 400 },
      {
        key: "attachment",
        title: "Attachment",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
      },
      {
        key: "remark",
        title: "Remarks",
        icon: <EditOutlined style={{ color: "white" }} />,
        width: 1100,
      },
      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
      // {
      //   key: "map",
      //   title: "Map",
      //   icon: (
      //     <Icon
      //       component={() => (
      //         <img
      //           style={{ width: "25px", marginLeft: "-6px", color: "white" }}
      //           src={IMAGE_PATH + "svgimg/map.svg"}
      //           alt="map"
      //         />
      //       )}
      //     />
      //   ),
      //   width: 1100,
      //   selector: "#map",
      //   id: "map",
      //   content: "Check Map Intelligence",
      // },
      {
        key: "bunker",
        title: "Bunker",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px", marginLeft: "-2px", color: "white" }}
                src={IMAGE_PATH + "svgimg/oil-line.svg"}
                alt="Bunker"
              />
            )}
          />
        ),
        width: 1100,
        selector: "#bunker",
        id: "bunker",
        content: "Check Bunker live prices",
      },
      {
        key: "weather",
        title: "Weather",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/cloud-line.svg"}
                alt="map"
              />
            )}
          />
        ),
        width: 1100,
      },
      {
        key: "sideMap",
        title: "Vessel Map",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/map-2-line.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "port-congestion",
        title: "Port Congestion",
        icon: (
          <Icon
            component={() => (
              <img
                style={{ width: "20px" }}
                src={IMAGE_PATH + "svgimg/position.svg"}
                alt="sideMap"
              />
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-bunker-analysis",
        title: "AI bunker analysis",
        icon: (
          <Icon
            style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                <IconifyIcon
                  style={{ fontSize: "25px", color: "rgb(164, 198, 57)" }}
                  icon="mdi:petrol"
                />
                <span
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left:"1px",
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
      {
        key: "ai-agent",
        title: "AI Agent",
        icon: (
          <Icon
            style={{marginTop:"0.7rem"}}
            component={() => (
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >

                <IconifyIcon
                  style={{
                    fontSize: "26px",
                    color: "rgb(43 222 222)",
                    animation: "heartbeat 1s infinite, shine 1s infinite",
                  }}
                  icon="material-symbols:person"
                />
                 <span
                  style={{
                    position: "absolute",
                    left: "0.6rem",
                    bottom: "1.3rem",
                    color: "red",
                    backgroundColor: "white",
                    borderRadius: "100%",
                    fontWeight: "bold",
                    fontSize: "0.8rem",
                    width: "0.8rem",
                    height: "0.8rem"
                  }}
                >
                  3
                </span>
                <span
                  style={{
                    position: "absolute",
                    bottom: "0.3rem",
                    left: 1,
                    transform: "translateX(-50%)",
                    fontSize: "10px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  AI
                </span>
              </div>
            )}
          />
        ),
        width: 700,
      },
    ],
  },
  "tcto-quick-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      { key: "search", title: "Search", icon: <SearchOutlined />, width: 400 },
      { key: "attachment", title: "Attachment", icon: <CloudUploadOutlined /> },
    ],
  },
  "port-expenses": {
    menuoption: [
      { key: "voyage-home", title: "Home", icon: <HomeOutlined /> },
      {
        key: "pl-summary",
        title: "PL Summary",
        icon: <LineChartOutlined />,
        width: 1100,
      },
      {
        key: "estimates-summary",
        title: "Summary Tree",
        icon: <FileDoneOutlined />,
        width: 400,
      },
      { key: "log", title: "Log", icon: <HistoryOutlined />, width: 400 },
      {
        key: "invoice",
        title: "Voyage Invoice List",
        icon: <FilePdfOutlined />,
        width: 400,
      },
      {
        key: "contact",
        title: "Voyage Contact List",
        icon: <ContactsOutlined />,
        width: 400,
      },
      {
        key: "proprties",
        title: "Properties",
        icon: <BuildOutlined />,
        width: 400,
      },
      {
        key: "position-report",
        title: "Position Report",
        icon: <PieChartOutlined />,
      },
      {
        key: "task-alert",
        title: "Task & alert",
        icon: <AlertOutlined />,
        width: 1150,
      },
      { key: "analytics", title: "Analytics", icon: <CodeSandboxOutlined /> },
    ],
  },
  "quick-estimate": {
    menuoption: [
      {
        key: "quick-estimate",
        title: "Quick-Estimate",
        icon: <MailOutlined />,
      },
    ],
  },

  "voyage-fixture-righttoolbar": {
    menuoption: [
      { key: "voyage-home", title: "Home", icon: <HomeOutlined /> },
      // { key: 'pl-summary', title: 'PL Summary', icon: <LineChartOutlined />, width: 1100 },
      // { key: 'summary', title: 'Summary Tree', icon: <FileDoneOutlined />, width: 400 },
      // { key: 'log', title: 'Log', icon: <HistoryOutlined />, width: 400 },
      // { key: 'invoice', title: 'Voyage Invoice List', icon: <FilePdfOutlined />, width: 400 },
      // { key: 'contact', title: 'Voyage Contact List', icon: <ContactsOutlined />, width: 400 },
      // { key: 'proprties', title: 'Properties', icon: <BuildOutlined />, width: 400 },
      // { key: 'position-report', title: 'Position Report', icon: 'pie-chart' },
      // { key: 'task-alert', title: 'Task & alert', icon: <AlertOutlined />, width: 1150 },
      // { key: 'analytics', title: 'Analytics', icon: 'code-sandbox' },
    ],
  },

  "voyage-cargo-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      // {
      //   key: "summary",
      //   title: "Summary",
      //   icon: <FileTextOutlined />,
      //   width: 400,
      // },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
        width: 300,
      },
      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
    ],
  },

  "coa-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      // {
      //   key: "summary",
      //   title: "Summary",
      //   icon: <FileTextOutlined />,
      //   width: 400,
      // },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
        width: 400,
      },

      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
    ],
  },

  "voyage-cargo-list-righttoolbar": {
    menuoption: [
      { key: "caret-up", title: "", icon: <CaretUpOutlined /> },
      { key: "filter", title: "", icon: <FilterOutlined /> },
    ],
  },

  "address-form-righttoolbar": {
    menuoption: [
      { key: "properties", title: "Properties", icon: <SettingOutlined /> },
    ],
  },

  "add-contractform-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      // {
      //   key: "summary",
      //   title: "summary",
      //   icon: <FileTextOutlined />,
      //   width: 400,
      // },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined style={{ color: "white" }} />,
      },
      {
        key: "mail",
        title: "Mail",
        icon: <MailOutlined style={{ color: "white" }} />,
        width: 1300,
      },
    ],
  },

  "open-vessel-schedule-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      // { key: "attachment", title: "Attachment", icon: <CloudUploadOutlined /> },
    ],
  },

  "hire-schedule-righttoolbar": {
    menuoption: [
      { key: "filter", title: "Filter", icon: <FilterOutlined />, width: 600 },
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      {
        key: "hire-payment-list",
        title: "Hire Payment List",
        icon: <DiffOutlined />,
        width: 1100,
      },
      {
        key: "hire-issue-bill-list",
        title: "Hire Issue Bill List",
        icon: <UnorderedListOutlined />,
        width: 1100,
      },
      { key: "attachment", title: "Attachment", icon: <CloudUploadOutlined /> },
    ],
  },

  "finance-dashboard-righttoolbar": {
    menuoption: [
      { key: "home", title: "Cashflow", icon: <HomeOutlined />, width: 600 },
      {
        key: "portfolio-position",
        title: "Portfolio Position",
        icon: <DiffOutlined />,
        width: 1200,
      },
      {
        key: "tanker-pool-point",
        title: "Tanker Pool Point",
        icon: <VerticalAlignTopOutlined />,
      },
    ],
  },

  "report-analytics-righttoolbar": {
    menuoption: [
      { key: "home", title: "ome", icon: <HomeOutlined /> },
      { key: "filter", title: "Filter", icon: <FilterOutlined /> },
      { key: "setting", title: "Setting", icon: <SettingOutlined /> },
    ],
  },

  "vessel-file-righttoolbar": {
    menuoption: [
      { key: "home", title: "Home", icon: <HomeOutlined /> },
      // { key: 'summary', title: 'Summary', icon: <FileTextOutlined />, width: 400 },
      {
        key: "attachment",
        title: "Attachments",
        icon: <CloudUploadOutlined />,
      },
    ],
  },
};

export default RightBARCONFIGURATION;
