import React, { useEffect, useState } from "react";
import AiLoader from "../../../../shared/components/PL/tcov/AiLoader";
import { Select, Table } from "antd";
import URL_WITH_VERSION, { getAPICall, postAPICall } from "../../../../shared";
import "../tcov/AiTracker.css";
import moment from "moment";

const LiveOpenPosition = () => {
  const [loading, setLoading] = useState(true);
  const [portList, setPortList] = useState([]);
  const [selectedPort, setSelectedPort] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getPort();
  }, []);

  const getPort = async () => {
    try {
      const url = `${URL_WITH_VERSION}/port/all_port_list?p=1&l=10000`;
      const resp = await getAPICall(url);
      if (resp?.data == true) {
        setPortList(resp?.row);
        setLoading(false);
      } else {
        console.log("some error occured");
      }
    } catch (error) {
      console.error("Error fetching ports:", error);
    }
  };

  const getTableData = async (port) => {
    setTableLoading(true);
    const token = localStorage.getItem("oceanToken");
    try {
      const query = `Vessel schedule for ${port}`;
      const payload = {
        ports: [port],
      };
      const url = `${process.env.REACT_APP_MT_URL}marine/vessel-schedules`;
      const resp = await postAPICall(url, payload, "post");
      console.log("respppp", resp);
      if (resp?.success == true) {
        setTableData(resp?.data);
        setTableLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedPort(value);
    console.log("Selected Port ID:", value);
    if (value !== undefined) {
      getTableData(value);
    }
  };

  const columns = [
    {
      key: "VESSEL_NAME",
      dataIndex: "VESSEL_NAME",
      title: "Vessel Name",
      render: (text) => text || "N/A",
    },
    {
      key: "IMO",
      dataIndex: "IMO",
      title: "IMO Number",
      render: (text) => text || "N/A",
    },
    {
      key: "VESSEL_TYPE",
      dataIndex: "VESSEL_TYPE",
      title: "Vessel Type",
      render: (text) => text || "N/A",
    },
    {
      key: "TYPE_NAME",
      dataIndex: "TYPE_NAME",
      title: "Type Name",
      render: (text) => text || "N/A",
    },
    {
      key: "FLAG",
      dataIndex: "FLAG",
      title: "Flag",
      render: (text) => text || "N/A",
    },
    {
      key: "YEAR_BUILT",
      dataIndex: "YEAR_BUILT",
      title: "Year of Build",
      render: (text) => text || "N/A",
    },
    {
      key: "CURRENT_PORT",
      dataIndex: "CURRENT_PORT",
      title: "Current Port",
      render: (text) => text || "N/A",
    },
    {
      key: "NEXT_PORT",
      dataIndex: "NEXT_PORT",
      title: "Next Port",
      render: (text) => text || "N/A",
    },
    {
      key: "UPDATED_AT",
      dataIndex: "UPDATED_AT",
      title: "Updated At",
      render: (text) =>
        text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "N/A",
    },
  ];

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            overflow:"hidden"
          }}
        >
          <AiLoader text={"AI Tracking..."} />
        </div>
      ) : (
        <div
          style={{
            // padding: "20px",
            margin: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div style={{ marginBottom: "16px" }}>
            <label
              htmlFor="port-select"
              style={{ display: "block", color: "#a52323" }}
            >
              Select port for vessel schedules :
            </label>
            <Select
              id="port-select"
              placeholder="Select port"
              style={{ width: "100%" }}
              allowClear={true}
              onChange={handleSelectChange}
              options={portList.map((port) => ({
                value: port.port_name,
                label: port.port_name,
              }))}
              showSearch={true}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
            />
          </div>

          <Table
            className="ai-table"
            columns={columns}
            dataSource={tableData}
            loading={tableLoading}
          />
        </div>
      )}
    </>
  );
};

export default LiveOpenPosition;
