import React, { useState, useEffect } from 'react';
import { Table, Button, Spin } from 'antd';
import AiLoader from '../../../../shared/components/PL/tcov/AiLoader';

const AiVesselSuggestion = ({ vesselData,getVesselFromAI }) => {
  const [data, setData] = useState(vesselData);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setLoading(true);
  //   let timeout;

  //   if (vesselData && vesselData.length > 0) {
  //     setData(vesselData);;
  //     setLoading(false);
  //   } else {
  //     timeout = setTimeout(() => {
  //       setLoading(false);
  //     }, 8000);
  //   }

  //   return () => clearTimeout(timeout);
  // }, [vesselData]);

  useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => {
      setData(vesselData);
      setLoading(false);
    }, 5000); // 8 seconds timeout

    return () => clearTimeout(timeout); 
  }, [vesselData]);
  
  const columns = [
    {
      title: 'Vessel Name',
      dataIndex: 'vessel_name',
      key: 'vessel_name',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'Vessel Type',
      dataIndex: 'vessel_type',
      key: 'vessel_type',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'DWT',
      dataIndex: 'dwt',
      key: 'dwt',
      align: 'center',
    //   render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'New Open Port',
      dataIndex: 'new_open_port',
      key: 'new_open_port',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data||"-"}</p>,
    },
    {
      title: 'Open Date',
      dataIndex: 'open_date',
      key: 'open_date',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data||"-"}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleImport(record)}>Import</Button>
      ),
      align: 'center',
    },
  ];

  const handleImport = (record) => {
      getVesselFromAI(record);
    // console.log('Importing record:', record);
    // let data = {
    //     cargo_name:record?.cargo_name||"",
    //     cp_qty:record?.cargo_size||"",
    //     laycan_from:"",
    //     laycan_to:""
    // }
    // return record; 
  };

  return (
    <>
      {loading ? (
        <div style={{ display:"flex" , marginTop: '20px', height:"500px" ,justifyContent:"center"}}>
           <AiLoader
            text={(
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: '100%',
              }}>
                <p style={{ fontSize: "27px" }}>Loading...</p>
                <p style={{ fontSize: "20px" }}>AI Suggested Vessels </p>
              </div>
            )}
          />
        </div>
      ) : (
        <Table
          dataSource={data}
          columns={columns}
          rowKey="_id"
          className="custom-table"
          loading={loading}
        />
      )}
    </>
  );
};

export default AiVesselSuggestion;
