import React, { useState } from "react";
import { Tooltip, Modal, Dropdown, Input, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import Popover from "antd/lib/popover";
import EmailComments from "./mailRightbar/EmailComments";
import CargoTemplate from "../../components/email-templates/CargoTemplate";
import TonnageTemplate from "../../components/email-templates/TonnageTemplate";
import CargoFirmOffers from "../../components/email-templates/CargoFirmOffers";
import TonnageFirmOffers from "../../components/email-templates/TonnageFirmOffers";
import { openNotificationWithIcon, postAPICall } from "../../shared";
import { useDispatch } from "react-redux";
import { composeNewMail, setVesselName } from "../../actions/emailActions";

import { v4 as uuidv4 } from "uuid";



const GenerateMenuItems = ({ handleIconClick, currentMailIndex, mail, mailDetails, handlePrint,showIcons }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const [emailTemplateName, setemailTemplate] = useState('')
    const [showTemplate, setShowTemplate] = useState(false)
    const openModal = (title, content) => {
        setModalTitle(title);
        setModalContent(content);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setModalContent(null);
        setModalTitle("");
    };

    const menuItemStyle = {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "0.5rem",
        padding: "0.5rem 1rem",
    };

    const iconStyle = {
        fontSize: "15px",
        marginRight: "10px",
    };
    // console.log(mail)
    const dispatch = useDispatch()

    const items = [
        {
            key: "1",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "markAsRead", mail, mailDetails);
                    }}
                >
                    <Icon
                        style={iconStyle}
                        icon={
                            mail?.isRead
                                ? "material-symbols:mark-email-read-outline-sharp"
                                : "material-symbols:mail-outline"
                        }
                    />
                    {mail?.isRead ? "Mark as unread" : "Mark as read"}
                </div>
            ),
        },
        {
            key: "2",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "markImportant", mail, mailDetails);
                    }}
                >
                    <Icon
                        style={{ ...iconStyle, color: "orange" }}
                        icon={mail?.important ? "tabler:star-filled" : "tabler:star"}
                    />
                    {mail?.important ? "Unmark Important" : "Mark Important"}
                </div>
            ),
        },
        {
            key: "3",
            label: (
                <Popover
                    content={<EmailComments currentMail={mail} />}
                    title={null}
                    trigger="click"
                    placement="leftTop"
                >
                    <Tooltip title="Click to view or add comments related to this email or task." placement="left">
                        <div style={menuItemStyle}>
                            <Icon style={iconStyle} icon="ei:comment" />
                            Comment
                        </div>
                    </Tooltip>
                </Popover>
            ),
        },

        {
            key: "11",
            label: showIcons ? (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "delete", mail, mailDetails);
                    }}
                >
                    <DeleteOutlined style={{ ...iconStyle, color: "red" }} />
                    Delete
                </div>
            ) : null,
        },

        {
            key: "12",
            label: (
                <div
                    style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleIconClick(e, currentMailIndex, "archive", mail, mailDetails);
                    }}
                >
                    <Icon style={iconStyle} icon="tabler:square-arrow-down" />
                    Archive
                </div>
            ),
        },
        {
            key: "4",
            label: (
                <div style={menuItemStyle} onClick={(e) => {
                    e.stopPropagation();
                    const currentDate = new Date();
                    const formattedDate = currentDate.toISOString();
                    let newOptions;
                    const userData = localStorage.getItem(
                        "oceanToken"
                    )
                        ? JSON.parse(
                            atob(
                                localStorage
                                    .getItem("oceanToken")
                                    ?.split(".")[1] ?? ""
                            )
                        )
                        : null;
                    if (userData && userData?.inboxes) {
                        newOptions = userData?.inboxes?.map(
                            (item) => ({ value: item })
                        );
                    }
                    let data = {
                        composedData: {
                            Date: formattedDate,
                            to: [],
                            cc: [],
                            bcc: [],
                            body: "",
                            subject: "",
                            attachments: [],
                            //   editorStateKey: EditorState.createEmpty(),
                            HTMLBody: undefined,
                            owner_email: newOptions[0]?.value || "",
                            ThreadID: "",
                            GmailMessageId: "",
                            listOfFile: [],
                            totalSize: 0,
                            uid: uuidv4(),
                            _id: null,
                        },
                        max: false,
                        min: false,
                    }
                    dispatch(composeNewMail(data))
                    handleDropdownVisibleChange(false)

                }}>
                    <Icon style={iconStyle} icon="tabler:mail" />
                    Compose
                </div >
            ),
        },
        {
            key: "5",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => {
                        e.stopPropagation(); dispatch(setVesselName({ vesselName: mail?.vessel_name?.[0], isVisible: true }));
                        handleDropdownVisibleChange(false)
                    }}
                >
                    <Icon style={iconStyle} icon="tabler:radar" />
                    Track vessel
                </div>
            ),
        },
        {
            key: "6",
            label: (
                <div style={menuItemStyle} onClick={(e) => { e.stopPropagation(); handlePrint(0, mail) }}>
                    <Icon style={iconStyle} icon="tabler:printer" />
                    Print
                </div>
            ),
        },
        {
            key: "7",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => { e.stopPropagation(); openModal("Tonnage Template:", <TonnageFirmOffers />,) }}

                >
                    <Icon style={iconStyle} icon="tabler:certificate" />
                    Create Tonnage Firm Offer
                </div>
            ),
        },
        {
            key: "8",
            label: (
                <div style={menuItemStyle}
                    onClick={() => openModal("Cargo Template:", <CargoFirmOffers />,)}
                >
                    <Icon style={iconStyle} icon="tabler:certificate" />
                    Create Cargo Firm Offer
                </div>
            ),
        },
        {
            key: "9",
            label: (
                <div style={menuItemStyle}
                    onClick={() => openModal("Cargo Template:", <TonnageTemplate />,)}
                >
                    <Icon style={iconStyle} icon="tabler:phone-incoming" />
                    Create Tonnage Enquiry
                </div>
            ),
        },
        {
            key: "9",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => { e.stopPropagation(); openModal("Cargo Template:", <CargoTemplate />,) }}
                >
                    <Icon style={iconStyle} icon="tabler:phone-incoming" />
                    Create Cargo Enquiry
                </div>
            ),
        },
        {
            key: "10",
            label: (
                <div style={menuItemStyle}
                    onClick={(e) => { e.stopPropagation(); setShowTemplate(true) }}>
                    <Icon style={iconStyle} icon="tabler:template" />
                    Save as Template
                </div>
            ),
        },
    ];


    const handlesaveAstemplate = async () => {

        try {
            const payload = {
                send_to: '',
                cc: mail.CC,
                bcc: mail.BCC,
                send_from: '',
                subject: mail.Subject,
                template_name: emailTemplateName,
                body: mail.Body
            }
            const url = `${process.env.REACT_APP_MT_URL}accounts/add-email-template`;
            let response = await postAPICall(url, payload, "post");
            setShowTemplate(false);
            openNotificationWithIcon('info', response?.msg)

            //   const response = await postApiService({ url, isToken, payload, showMessage: true })
        } catch (err) {
            console.log('something went wrong', err)
        }
    }
    const handleDropdownVisibleChange = (visible) => {
        setIsDropdownOpen(visible);
    };

    // return items;
    return (
        <>
            {/* {items} */}
            <Dropdown
                menu={{
                    items: items.map((item) => ({
                        ...item,
                        onClick: (e) => {
                            e.domEvent.stopPropagation(); // Stop event propagation
                            item.onClick && item.onClick(); // Call the original onClick if it exists
                        },
                    })),
                    style: { maxHeight: "500px", overflowY: "auto" },

                }}
                placement="bottomRight"
                arrow
                trigger={["click"]}
                onClick={(e) => e.stopPropagation()}
                open={isDropdownOpen}
                onOpenChange={handleDropdownVisibleChange}
            >
                <Tooltip title="More options">
                    <div onClick={(e) => e.stopPropagation()}>
                        <Icon
                            className="current_mail_icons"
                            icon={"tabler:dots-vertical"}
                        />
                    </div>
                </Tooltip>
            </Dropdown>
            <Modal
                title={modalTitle}
                visible={isModalVisible}
                onCancel={closeModal}
                footer={null}
                width={1500}



            >

                {modalContent}
            </Modal>
            {showTemplate &&
                <Modal
                    title="Save Email Template"
                    open={showTemplate}
                    onCancel={() => setShowTemplate(false)}
                    footer={[
                        <Button key="close" onClick={() => setShowTemplate(false)}>
                            Close
                        </Button>,
                        <Button
                            key="save"
                            type="primary"
                            onClick={() => handlesaveAstemplate()}
                        >
                            Save
                        </Button>,
                    ]}
                >
                    <Input
                        placeholder="Enter Template Name"
                        value={emailTemplateName}
                        onChange={(e) => setemailTemplate(e.target.value)}
                    />
                </Modal>}
        </>
    );
};

export default GenerateMenuItems;
