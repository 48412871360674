import React, { useState, useEffect } from "react";
import { Form, Select, Layout, Row, Col, Spin } from "antd";
import NormalFormIndex from "../NormalForm/normal_from_index";
import URL_WITH_VERSION, { getAPICall, postAPICall } from "..";

const { Content } = Layout;

const DemDesForm = (props) => {
  const [formData, setFormData] = useState({});
  const [wsrId, setWsrId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Synchronize props.formData with local state
  useEffect(() => {
    if (props?.formData) {
      const portDays = props?.formData?.days ?? 0;
      const xtraPortDays = props?.formData?.xpd ?? 0;
      const esstimateDays = props?.formData?.e_p_days ?? 0;
      const demDesDays = esstimateDays - (portDays * 1 + xtraPortDays * 1);

      const updatedFormData = {
        e_p_days: isNaN(esstimateDays) ? 0 : esstimateDays,
        days: isNaN(portDays) ? 0 : portDays,
        xpd: isNaN(xtraPortDays) ? 0 : xtraPortDays,
        dem_des_days: isNaN(demDesDays) ? 0 : demDesDays.toFixed(2),
      };

      setFormData(updatedFormData);
    }
  }, [props.formData]);

  const ImportFormData = async (data) => {
    try {
      const saveUrl = `${URL_WITH_VERSION}/tcov/demdes-save`;
      const updateUrl = `${URL_WITH_VERSION}/tcov/demdes-update`;
      const wsId = props?.formData?.dem_des_id;
      const method = wsId ? "PUT" : "POST";
      const url = wsId ? updateUrl : saveUrl;
      const payload = wsId ? { ...data, id: wsId } : data;

      const response = await postAPICall(url, payload, method);
      const updatedData = response?.data;

      setFormData(updatedData);
      setWsrId(updatedData?.id);

      if (updatedData?.id) {
        props.modalCloseEvent({
          ...data,
          dem_des_id: updatedData?.id,
        });
      }
    } catch (error) {
      console.error("Error saving form data:", error);
    }
  };

  const getDemDesData = async () => {
    try {
      setIsLoading(true);
      const wsId = props?.formData?.dem_des_id;
      if (!wsId) return;

      const url = `${URL_WITH_VERSION}/tcov/demdes-edit?ae=${wsId}`;
      const response = await getAPICall(url);
      const fetchedData = response?.data[0];

      if (fetchedData) {
        const portDays = fetchedData?.days ?? 0;
        const xtraPortDays = fetchedData?.xpd ?? 0;
        const esstimateDays = fetchedData?.e_p_days ?? 0;
        // const demDesDays = esstimateDays - (portDays * 1 + xtraPortDays * 1);
        const demDesDays = fetchedData?.dem_des_days ?? 0;

        const demRate = fetchedData?.dem_rate ?? 0;
        const desRate = fetchedData?.des_rate ?? 0;
        const demDesAmt = fetchedData?.dem_des_amt ?? 0;

        const updatedFormData = {
          e_p_days: isNaN(esstimateDays) ? 0 : esstimateDays,
          days: isNaN(portDays) ? 0 : portDays,
          xpd: isNaN(xtraPortDays) ? 0 : xtraPortDays,
          dem_des_days: isNaN(demDesDays) ? 0 : Number(demDesDays).toFixed(2),
          dem_rate: isNaN(demRate) ? 0 : demRate,
          dem_des_amt: isNaN(demDesAmt) ? 0 : demDesAmt,
          des_rate: isNaN(desRate) ? 0 : desRate,
        };

        setFormData(updatedFormData);
        setWsrId(fetchedData?.id);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching form data:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (props?.formData?.dem_des_id) {
      getDemDesData();
    }
  }, [props.formData.dem_des_id]);

  return (
    <div className="body-wrapper">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <Row gutter={16} style={{ marginRight: 0 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="body-wrapper">
                  <article className="article toolbaruiWrapper">
                    <div className="box box-default">
                      <div className="box-body">
                        {isLoading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Spin />
                          </div>
                        ) : (
                          <NormalFormIndex
                            formClass="label-min-height"
                            formData={formData}
                            showForm={true}
                            frmCode="dem_des_form"
                            frmVisible={true}
                            addForm={true}
                            inlineLayout={true}
                            showButtons={[
                              {
                                id: "import",
                                title: "Import",
                                type: "primary",
                                event: (data) => ImportFormData(data),
                              },
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </article>
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default DemDesForm;
