import React from "react";
import "./AlLoader.css";

const AiLoader = (props) => {
  return (
    <div id="page">
      <div id="container">  
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div id="ring"></div>
        <div class="loader">{props?.text}</div>
      </div>
    </div>
  );
};

export default AiLoader;
