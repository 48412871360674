import React, { useEffect, useRef, useState } from "react";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
//import Tde from "../../routes/tde/Tde";
import Tde from "../../routes/tde/";
import { Modal, Spin, Alert, notification, Tooltip } from "antd";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router";
import { Icon } from "@iconify-icon/react/dist/iconify.js";
import DownloadInvoice from "../../routes/chartering/routes/make-payment/DownloadInvoice";
import moment from "moment";
import URL_WITH_VERSION, {
  postAPICall,
  getAPICall,
  apiDeleteCall,
  openNotificationWithIcon,
  useStateCallback,
} from "../../shared";
import {
  DeleteOutlined,
  SaveOutlined,
  EditOutlined,
  MenuFoldOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Attachment from "../../shared/components/Attachment";
import ISSUEBILL from "../../../src/assests/User Guide Issue Bill.pdf";
import Remarks from "../../shared/components/Remarks";
import {
  uploadAttachment,
  deleteAttachment,
  getAttachments,
} from "../../shared/attachments";
import Email from "../Email";
import CustomLoader from "../Loading/CustomLoader";

const openNotification = (keyName, tdespecific = false) => {
  let msg = "Please generate the Invoice No. First";
  if (tdespecific) {
    msg = "Payment status should not be PREPARED";
  }
  notification.info({
    message: `Can't Open ${keyName}`,
    description: msg,
    placement: "topRight",
  });
};

const MakePayment = (props) => {
  const paymentData = window.state;
  let localstoragepayment = JSON.parse(localStorage.getItem("issue-bill"));
  if (
    paymentData &&
    Object.keys(paymentData).length > 0 &&
    paymentData?.formData?.id
  ) {
    props = structuredClone(paymentData);
  } else if (
    localstoragepayment &&
    Object.keys(localstoragepayment).length > 0
  ) {
    props = structuredClone(localstoragepayment);
  }

  const [state, setState] = useStateCallback({
    showTDEForm: false,
    loadFrm: true,
    isRemarkModel: false,
    frmName: "tco_make_payment",
    responseData: { frm: [], tabs: [], active_tab: {} },
    frmOptions: [],
    formData: props.formData || {},
    //"period_to":'',
    checkbox: {},
    showItemList: false,
    invList: {},
    tdeData: null,
    // "showDownloadInvoice": false,
    passData: {
      bunkerdeliveryredeliveryterm: [
        { dr_name: "Delivery Cost" },
        { dr_name: "Adj on delivery" },
        { dr_name: "Redelivery Cost" },
        { dr_name: "Adj on redelivery" },
      ],
    },
    makepaymentid: props?.formData?.id,
    loadInvoiceNo: undefined,
    loadData: {},
    showInvoicePopup: false,
    editMode: true,
  });

  const [sharebtn, setSharebtn] = useState();
  const [shareLink, setShareLink] = useState();

  const navigate = useNavigate();
  const formdataref = useRef();
  const params = useParams();
  const location = useLocation();
  useEffect(() => {
    if (params.id) {
      editFormData(params.id);
    }
  }, [params.id]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setState((prevState) => ({ ...prevState, loadFrm: false }));
      const _data = location.state?.data;
      let _frmData = Object.assign(state.passData, state.formData);
      let tco_id =
        _frmData["tco_id"] || _frmData["actualID"] || _data["tco_id"];
      const response1 = await getAPICall(
        `${URL_WITH_VERSION}/tco/voyage/${tco_id}`
      );
      const respSData = await response1["data"];
      if (respSData) {
        const frmOptions = [{ key: "voyage_manager_id", data: respSData }];
        setState((prevState) => ({ ...prevState, frmOptions: frmOptions }));
      }
      const { formData } = state;
      let editmode = state.editMode;
      if (state.formData.hasOwnProperty("actualID")) {
        tco_id = _frmData["actualID"];
        const request = await getAPICall(
          `${URL_WITH_VERSION}/make_payment/payment?tco_id=` + tco_id
        );
        const response = await request["data"];
        if (response && response.length > 0) {
          _frmData["period_form"] = moment(response[0]["period_to"]).format(
            "YYYY-MM-DD HH:mm"
          );
        }
      }

      // setState({ ...state, 'formData': _frmData }, () => setState({ ...state, "loadFrm": true }));

      if (_frmData.hasOwnProperty("-") && _frmData["-"].length > 0) {
        _frmData["-"].map((key) => {
          key["disablefield"] = ["description", "amount"];
        });
        if (
          _frmData.hasOwnProperty("---------------") &&
          _frmData["---------------"].hasOwnProperty("disablefield")
        ) {
          _frmData["---------------"]["disablefield"] = [
            "misc_adjustment",
            "hire_adjustment",
            "gratuity",
            "other_exp",
            "victualling",
            "other_revenue",
            "ballast_bonus",
            "ilohc",
            "ihc",
            "lashing_stow",
            "exp_allowance",

            //bellow code is added by vikas to disable checkboxes
            "bad_ifo",
            "bad_ulsfo",
            "bad_mgo",
            "bad_lsmgo",
            "bad_vlsfo",
            "bor_ifo",
            "bor_ulsfo",
            "bor_mgo",
            "bor_lsmgo",
            "bor_vlsfo",

            "off_hire",
            "vessel_off_hire",
          ];
        }
        _frmData["disablefield"] = [
          // "po_number",
          "days",
          "purchase_type",
          "to",
          "period_form",
          "invoice_type",
          "final_amt_loc",

          // "curr_exe",
          // "exch_rate",
          // "voyage_manager_id"
        ];
      }

      if (
        _frmData.hasOwnProperty("id") &&
        _frmData["id"] > 0 &&
        _frmData["invoice_no"]
      ) {
        editmode = false;
      }

      setState((prevState) => ({
        ...prevState,
        formData: _frmData,
        loadFrm: true,
        editMode: editmode,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const saveFormData = async (vData) => {
    setState((prevState) => ({ ...prevState, loadFrm: false }));

    let ismakepaymentsave = null;
    let paymentsavedata = {};

    if (localStorage.getItem("savemakepayment") !== "undefined") {
      ismakepaymentsave =
        JSON.parse(localStorage.getItem("savemakepayment")) || null;
      paymentsavedata = JSON.parse(localStorage.getItem("paymentsavedata"));
    }

    if (vData["purchase_type"] != "1") {
      vData["period_to"] = vData["period_form"];
    }

    if (
      vData["purchase_type"] == "2" &&
      vData["-"] &&
      vData["-"].length > 0 &&
      !vData["-"].some((el) => el["code"])
    ) {
      openNotificationWithIcon(
        "info",
        +vData?.invoice_total !== 0
          ? "Invoice for special type is generated."
          : "No description found! Please add details to continue.",
        2
      );
      setState((prevState) => ({ ...prevState, loadFrm: true }));
      return;
    }

    if (
      vData["purchase_type"] == "3" &&
      vData["-"] &&
      vData["-"].length > 0 &&
      !vData["-"].some((el) => el["code"])
    ) {
      openNotificationWithIcon(
        "info",
        "Vessel still in progress, hire not raised till redelivery.",
        2
      );
      setState((prevState) => ({ ...prevState, loadFrm: true }));
      return;
    }

    if (paymentsavedata?.["id"]) {
      if (
        dayjs(paymentsavedata?.["period_form"]).format("YYYY-MM-DD HH:mm") ==
          dayjs(vData?.["period_form"]).format("YYYY-MM-DD HH:mm") &&
        !vData["invoice_no"] &&
        vData["purchase_type"] === "3"
      ) {
        openNotificationWithIcon(
          "info",
          "Invoice has already been created for the selected date.",
          2
        );
        setState((prevState) => ({ ...prevState, loadFrm: true }));
        return;
      }
    }
    let type = "save";
    let suMethod = "POST";
    if (vData.hasOwnProperty("id")) {
      type = "update";
      suMethod = "PUT";

      vData["-"].forEach((obj) => {
        if (obj.hasOwnProperty("pass_id")) {
          delete obj.pass_id;
        }
      });
    }

    // if(vData && vData.hasOwnProperty("account_head")) {
    //   delete vData["account_head"];
    // }
    // if(vData && vData.hasOwnProperty("date_from")) {
    //   delete vData["date_from"];
    // }
    // if(vData && vData.hasOwnProperty("date_to")) {
    //   delete vData["date_to"];
    // }
    // if(vData && vData.hasOwnProperty("chartrer_id")) {
    //   delete vData["chartrer_id"];
    // }
    // if(vData && vData.hasOwnProperty("chartrer_name")) {
    //   delete vData["chartrer_name"];
    // }
    // if(vData && vData.hasOwnProperty("vessel_name")) {
    //   delete vData["vessel_name"];
    // }

    const { frmName } = state;
    if (type === "save") {
      vData["-"].forEach((e) => delete e["id"]);
    }
    let suURL = `${URL_WITH_VERSION}/make_payment/${type}?frm=${frmName}`;
    if (vData && vData.hasOwnProperty("lob")) {
      delete vData["lob"];
    }

    const periodToDate = new Date(vData?.period_to);
    const reDeliveryDate = new Date(vData?.re_delivery);

    if (vData["purchase_type"] == "1" && periodToDate > reDeliveryDate) {
      openNotificationWithIcon(
        "info",
        "Period To Date Should Be Less OR Equal To Redelivery Date."
      );
      return;
    }

    formdataref.current = vData;

    if (ismakepaymentsave || vData["purchase_type"] != "1") {
      postAPICall(suURL, vData, suMethod, (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.message);
          // const _vData = { ...vData, invoice_no: data.row.invoice_no };
          // setState(prevState => ({ ...prevState, makepaymentid: data.row.payment_id, loadFrm: true }));
          localStorage.removeItem("ismakepaymentsave");

          localStorage.setItem(
            "paymentsavedata",
            JSON.stringify({
              id: data.row.payment_id,
              invoiceNo: data.row.invoice_no,
            })
          );

          if (
            props.hasOwnProperty("onUpdateMakePayment") &&
            type === "update"
          ) {
            setState((prevState) => ({
              ...prevState,
              makepaymentid: data.row.payment_id,
              loadFrm: true,
            }));
            props.onUpdateMakePayment(data.row.payment_id, "TCO");
          } else {
            //showDownloadInvoice(true, _vData);

            // const _vData = { ...vData, invoice_no: data.row.invoice_no, id: data.row.payment_id };

            // setState(
            //   (prevState) => ({
            //     ...prevState,
            //     makepaymentid: data.row.payment_id,
            //     formData: _vData,
            //     passData: _vData
            //   }),
            //   () => {
            //     setState((state) => ({
            //       ...state,
            //       loadFrm: true
            //     }));
            //   }
            // );

            editFormData(data.row.invoice_no);
          }
          // showDownloadInvoice(true, _vData);
          // if (props.hasOwnProperty('modalCloseEvent')) {
          //   props.modalCloseEvent();
          // }
        } else {
          openNotificationWithIcon("info", data.message);
          setState((prevState) => ({
            ...prevState,
            loadFrm: true,
            formData: formdataref.current,
          }));
        }
      });
    } else {
      // openNotificationWithIcon(
      //   "info",
      //   "Period To Date Should Be Less OR Equal To Redelivery Date."
      // );
      // setState((prevState) => ({
      //   ...prevState,
      //   loadFrm: true,
      //   formData: formdataref.current,
      // }));
      postAPICall(suURL, vData, suMethod, (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.message);
        } else {
          openNotificationWithIcon("error", data.message);
        }
        setState((prevState) => ({
          ...prevState,
          loadFrm: true,
          formData: formdataref.current,
        }));
      });
    }
  };

  const editFormData = async (eID) => {
    setState((prevState) => ({ ...prevState, loadFrm: false }));

    const request = await getAPICall(
      `${URL_WITH_VERSION}/make_payment/edit?e=${eID}&frm=tco_make_payment`
    );

    const response = await request["data"];

    if (response["-"].length > 0) {
      response["-"].map((key) => {
        key["disablefield"] = ["description", "amount"];
      });
      response["---------------"]["disablefield"] = [
        "misc_adjustment",
        "hire_adjustment",
        "gratuity",
        "other_exp",
        "victualling",
        "other_revenue",
        "ballast_bonus",
        "ilohc",
        "ihc",
        "lashing_stow",
        "exp_allowance",

        //bellow code is added by vikas to disable checkboxes
        "bad_ifo",
        "bad_ulsfo",
        "bad_mgo",
        "bad_lsmgo",
        "bad_vlsfo",
        "bor_ifo",
        "bor_ulsfo",
        "bor_mgo",
        "bor_lsmgo",
        "bor_vlsfo",

        "off_hire",
        "vessel_off_hire",
      ];
      response["disablefield"] = [
        "po_number",
        "days",
        "purchase_type",
        "period_to",
        "period_form",
        "invoice_type",
        "final_amt_loc",
        // "curr_exe",
        // "exch_rate",
        "voyage_manager_id",
      ];
    }

    const response1 = await getAPICall(
      `${URL_WITH_VERSION}/tco/edit?e=${response["tc_id"]}`
    );
    const data1 = await response1["data"];

    let bunker = [];
    bunker = [
      { dr_name: "Delivery Cost" },
      { dr_name: "Adj on delivery" },
      { dr_name: "Redelivery Cost" },
      { dr_name: "Adj on redelivery" },
    ];

    data1["bunkers"].map((e) => {
      if (e.description === 3 || e.description === 6 || e.description === 4) {
        let index = e.description === 3 ? 0 : 2;
        let cDesc = JSON.parse(e.c_fields_values);
        let item = { ifo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0, mgo: 0 };
        let iv = {
          IFO: "ifo",
          VLSFO: "vlsfo",
          ULSFO: "ulsfo",
          MGO: "mgo",
          LSMGO: "lsmgo",
        };
        cDesc.map(
          (_e) => (item[iv[_e.name]] = _e.consumption + " X " + _e.price)
        );
        bunker[index] = Object.assign(item, bunker[index]);
      }
    });

    response["bunkerdeliveryredeliveryterm"] = bunker;

    let _paymentsavedata =
      JSON.parse(localStorage.getItem("paymentsavedata")) || {};
    _paymentsavedata["period_form"] = response["period_form"];
    localStorage.setItem("paymentsavedata", JSON.stringify(_paymentsavedata));
    if (
      response.hasOwnProperty("id") &&
      response["id"] > 0 &&
      response["invoice_no"]
    ) {
      let editmode = false;

      setState(
        (prevState) => ({
          ...prevState,
          formData: response,
          loadFrm: true,
          editMode: editmode,
          makepaymentid: response.id,
        }),
        () => {
          navigate(`/issue-bill/${response.invoice_no}`);
        }
      );
    }
  };

  const _deleteInvoice = (postData) => {
    // const id = postData.id;
    // if (props?.lastInvoiceId) {
    //   if (props.lastInvoiceId !== id) {
    //     openNotificationWithIcon("error", "Delete latest invoice first...", 3);
    //     return;
    //   }
    // }
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure, you want to delete it?",
      onOk: () => deleteInvoice(postData),
    });
  };

  const deleteInvoice = (data) => {
    const { frmName } = state;
    let URL = `${URL_WITH_VERSION}/make_payment/delete?frm=${frmName}`;
    apiDeleteCall(
      URL,
      {
        id: data["id"],
        payment_status: data["payment_status"],
        tc_id: data["tc_id"],
      },
      (resp) => {
        if (resp && resp.data) {
          // openNotificationWithIcon("success", resp.message);
          // if (props.hasOwnProperty("modalCloseEvent")) {
          //   props.modalCloseEvent();
          // }

          // setTimeout(() => {
          //   if (props.hasOwnProperty("refreshTable")) {
          //     props.refreshTable();
          //   }
          // }, 500)

          setState(
            (prevstate) => ({
              ...prevstate,
              formData: {},
            }),
            () => {
              window.location.href = "#/issue-bill";
              window.location.reload();
            }
          );
        } else {
          openNotificationWithIcon("error", resp.message);
        }
      }
    );
  };

  const showDownloadInvoice = (boolVal, loadInvoice = undefined) => {
    if (loadInvoice && !loadInvoice.hasOwnProperty("invoice_no")) {
      openNotificationWithIcon("info", "Plese Create Invoice First");
      return;
    }
    // let period_to_date = document.getElementsByName("period_to");
    // if (period_to_date[0].value && loadInvoice) {
    //   loadInvoice["period_to"] = period_to_date[0].value;
    // }
    if (
      loadInvoice &&
      (loadInvoice["period_to"] === "" ||
        loadInvoice["period_to"] === undefined)
    ) {
      loadInvoice["period_to"] = new Date();
    }
    if (boolVal) {
      if (loadInvoice.hasOwnProperty("actualID")) {
        loadInvoice["tco_id"] = loadInvoice["actualID"];
      }

      setState((prevState) => ({
        ...prevState,
        loadInvoiceNo: loadInvoice,
        loadData: { TCOH: true, TCOBB: true },
        downloadInvoice: boolVal,
        //showInvoicePopup: true,
      }));
    } else {
      setState((prevState) => ({ ...prevState, downloadInvoice: boolVal }));
      if (props.hasOwnProperty("modalCloseEvent")) {
        // props.modalCloseEvent();
      }
    }
  };

  const closeDonloadInvoice = () => {
    //setState(prevState => ({ ...prevState, downloadInvoice: false }));
    setState(
      (prevState) => ({
        ...prevState,
        loadFrm: false,
        downloadInvoice: false,
        showInvoicePopup: false,
      }),
      () => {
        setState((state) => ({
          ...state,
          loadFrm: true,
        }));
      }
    );

    if (props.hasOwnProperty("modalCloseEvent")) {
      // props.modalCloseEvent();
    }
  };

  const showTDE = async (key, bolVal) => {
    const { formData } = state;

    const response = await getAPICall(`${URL_WITH_VERSION}/tde/list`);
    let respData = response["data"];

    let resp = null,
      target = undefined,
      tdeData = {};

    if (formData && formData.invoice_no && formData.invoice_no !== "") {
      if (respData && respData.length > 0) {
        target = respData.find(
          (item) => item.invoice_no === formData.invoice_no
        );
        if (target && target.hasOwnProperty("id") && target["id"] > 0) {
          resp = await getAPICall(
            `${URL_WITH_VERSION}/tde/edit?e=${target["id"]}`
          );
        }
      }

      let accounting = [];
      if (target && resp && resp["data"] && resp["data"].hasOwnProperty("id")) {
        tdeData = resp["data"];
        tdeData["----"] = tdeData["----"][0];

        formData &&
          formData["-"] &&
          formData["-"].length > 0 &&
          formData["-"].map((val, ind) => {
            accounting.push({
              company: target["bill_via"],
              amount: val.amount,
              lob: formData["company_lob"],
              vessel_code: formData["vessel_code"],
              description: val.description,
              editable: true,
              vessel_name: formData["vessel_id"],
              account: "",
              ap_ar_acct: target["ar_pr_account_no"],
              key: "table_row_" + ind,
              voyage: target["voyage_manager_id"],
              port: "",
              ic: "",
              id: -9e6 + ind,
            });
            return true;
          });
        //  tdeData['vessel']=
        tdeData["accounting"] = accounting;
        tdeData["--"] = { total: target["invoice_amount"] };
        //  tdeData['----'] = { 'total_due': target['invoice_amount'], 'total': target['invoice_amount'],"remittance_bank":resp['data']['----']["remittance_bank"]}
        //tdeData['----']=resp['data']['----'][0];
      } else {
        const responseData = await getAPICall(
          `${URL_WITH_VERSION}/address/edit?ae=${formData["charterer_from"]}`
        );
        const responseAddressData = responseData["data"];
        let account_no =
          responseAddressData &&
          responseAddressData["bank&accountdetails"] &&
          responseAddressData["bank&accountdetails"].length > 0
            ? responseAddressData["bank&accountdetails"][0] &&
              responseAddressData["bank&accountdetails"][0]["account_no"]
            : "";

        let accountCode =
          responseAddressData &&
          responseAddressData["bank&accountdetails"] &&
          responseAddressData["bank&accountdetails"].length > 0
            ? responseAddressData["bank&accountdetails"][0] &&
              responseAddressData["bank&accountdetails"][0]["swift_code"]
            : "";
        formData &&
          formData["-"] &&
          formData["-"].length > 0 &&
          formData["-"].map((val, ind) => {
            accounting.push({
              company: formData["my_company"],
              account: accountCode,
              // "lob": "",
              // "vessel_code": "",
              lob: formData["company_lob"],
              vessel_code: formData["vessel_code"],
              description: val.description,
              vessel_name: formData["vessel_id"],
              amount: val.amount,
              ap_ar_acct: account_no,
              voyage: formData["voyage_manager_id"],
              port: "",
              ic: "",
              id: -9e6 + ind,
            });
            return true;
          });
        tdeData = {
          invoice: formData["acc_type"],
          po_number: formData["po_number"],
          invoice_date: formData["due_date"],
          received_date: formData["received_date"],
          invoice_no: formData.invoice_no,
          ar_pr_account_no: account_no,
          inv_status: formData.payment_status,
          invoice_type: "",
          vendor: formData.charterer_from,
          invoice_amount: formData.amount,
          bill_via: formData.my_company,
          account_base: formData.amount,
          payment_term: formData.terms,
          vessel: formData["vessel_id"],
          // '--': { 'total': formData['amount'] },
          // '----': { 'total_due': formData['amount'], 'total': formData['amount'] },
          accounting: accounting,
        };
      }

      setState((prevState) => ({
        ...prevState,
        showTDEForm: bolVal,
        tdeData: tdeData,
      }));
    } else {
      openNotificationWithIcon("info", "Please generate the Invoice No. First");
    }
  };

  const getCheckBoxVal = (code, checkboxesValue, checkboxesInfo) => {
    let val = "";
    let checkboxesValueUpdated = { ...checkboxesValue };
    switch (code) {
      case "TCOH":
        //val = "add_tc_hire";
        checkboxesValueUpdated = { ...checkboxesValue, add_tc_hire: false };
        break;
      case "TCOGRA":
        //val = "gratuity";
        checkboxesValueUpdated = { ...checkboxesValue, gratuity: false };
        break;
      case "TCOOTHEXP":
        //val = "other_exp";
        checkboxesValueUpdated = { ...checkboxesValue, other_exp: false };
        break;
      case "TCOBCOM":
        // val = "broker_comm";
        checkboxesValueUpdated = { ...checkboxesValue, broker_comm: false };
        break;
      case "TCOVIC":
        //val = "victualling";
        checkboxesValueUpdated = { ...checkboxesValue, victualling: false };
        break;
      case "TCOOTHREV":
        //val = "other_revenue";
        checkboxesValueUpdated = { ...checkboxesValue, other_revenue: false };
        break;
      case "TCOBB":
        //val = "ballast_bonus";
        checkboxesValueUpdated = { ...checkboxesValue, ballast_bonus: false };
        break;
      case "TCOILOHC":
        //val = "ilohc";
        checkboxesValueUpdated = { ...checkboxesValue, ilohc: false };
        break;
      case "TCOIHC":
        // val = "ihc";
        checkboxesValueUpdated = { ...checkboxesValue, ihc: false };
        break;
      case "TCOREA":
        //val = "exp_allowance";
        checkboxesValueUpdated = { ...checkboxesValue, exp_allowance: false };
        break;
      case "TCOLAST":
        //val = "lashing_stow";
        checkboxesValueUpdated = { ...checkboxesValue, lashing_stow: false };
        break;
      case "TCOBADVLSFO":
        //val = "bad_vlsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_vlsfo: false };
        break;
      case "TCOBADIFO":
        //val = "bad_ifo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_ifo: false };
        break;
      case "TCOBADMGO":
        // val = "bad_mgo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_mgo: false };
        break;
      case "TCOBADLSMGO":
        //val = "bad_lsmgo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_lsmgo: false };
        break;
      case "TCOBADULSFO":
        // val = "bad_ulsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bad_ulsfo: false };
        break;
      case "TCOBARVLSFO":
        // val = "bor_vlsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_vlsfo: false };
        break;
      case "TCOBARMGO":
        // val = "bor_mgo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_mgo: false };
        break;
      case "TCOBARIFO":
        // val = "bor_ifo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_ifo: false };
        break;
      case "TCOBARLSMGO":
        // val = "bor_lsmgo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_lsmgo: false };
        break;
      case "TCOBARULSFO":
        // val = "bor_ulsfo";
        checkboxesValueUpdated = { ...checkboxesValue, bor_ulsfo: false };
        break;

      case "TCOOFFHD":
        // val = "off_hire";
        // if TCOOFFHD is more then once then it should not be false
        const no1 = checkboxesInfo.filter((obj) => obj.code === "TCOOFFHD");
        if (no1?.length < 2) {
          checkboxesValueUpdated = { ...checkboxesValue, off_hire: false };
        }
        break;

      case "TCOVOFHD":
        // val = "vessel_off_hire";
        // if TCOVOFHD is more then once then it should not be false
        const no = checkboxesInfo.filter((obj) => obj.code === "TCOVOFHD");
        if (no?.length < 2) {
          checkboxesValueUpdated = {
            ...checkboxesValue,
            vessel_off_hire: false,
          };
        }
        break;
    }
    // return val;
    return checkboxesValueUpdated;
  };

  const showShareEmail = () => {
    try {
      const pageUrl = window.location.href;
      const linkBlob = new Blob([pageUrl], { type: "text/plain" });

      const emailContent = pageUrl;

      setState((prev) => ({ ...prev, isShowShare: true }));
      setSharebtn(linkBlob);
      setShareLink(emailContent);
    } catch (err) {
      console.log("err", err);
    }
  };

  const onclose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const handleClose = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const onok = () => {
    setState((prev) => ({ ...prev, isShowShare: false }));
  };

  const delete_row = (action, data, fulldata) => {
    const checkboxesValue = fulldata["---------------"];
    const checkboxesInfo = fulldata["-"];

    let amount = fulldata["amount"] * 1 - data.amount * 1;

    let exch_rate = parseFloat(fulldata["exch_rate"]).toFixed(2);
    let final_amt_loc = "0.00";

    if (!isNaN(exch_rate)) {
      final_amt_loc = (amount * exch_rate).toFixed(2);
    } else {
      exch_rate = "";
    }

    //const checkboxesInfo2 = checkboxesInfo.filter((obj) => obj.code !== data.code);
    let checkboxesInfo2 = [];
    if (data.code === "TCOVOFHD" || data.code === "TCOOFFHD") {
      checkboxesInfo2 = checkboxesInfo.filter(
        (obj) => !(obj.code === data.code && obj.pass_id === data.pass_id)
      );
    } else {
      checkboxesInfo2 = checkboxesInfo.filter((obj) => obj.code !== data.code);
    }

    const updatedcheckboxesValue = getCheckBoxVal(
      data.code,
      checkboxesValue,
      checkboxesInfo
    );
    const updatedfulldata = {
      ...fulldata,
      "---------------": updatedcheckboxesValue,
      "-": checkboxesInfo2,
      amount: amount.toFixed(2),
      final_amt_loc: final_amt_loc,
      exch_rate: exch_rate,
      invoice_total: amount,
    };

    setState(
      (prevState) => ({
        ...prevState,
        loadFrm: false,
        formData: updatedfulldata,
      }),
      () => {
        setState((prevState) => ({ ...prevState, loadFrm: true }));
      }
    );
  };

  const {
    frmName,
    formData,
    loadFrm,
    showTDEForm,
    tdeData,
    downloadInvoice,
    loadInvoiceNo,
    loadData,
    makepaymentid,
    editMode,
    frmOptions,
    isRemarkModel,
  } = state;

  const ShowAttachment = async (isShowAttachment) => {
    let loadComponent = undefined;
    const { id } = state.formData;
    if (id && isShowAttachment) {
      const attachments = await getAttachments(id, "EST");
      const callback = (fileArr) =>
        uploadAttachment(fileArr, id, "EST", "port-expense");
      loadComponent = (
        <Attachment
          uploadType="Estimates"
          attachments={attachments}
          onCloseUploadFileArray={callback}
          deleteAttachment={(file) =>
            deleteAttachment(file.url, file.name, "EST", "port-expense")
          }
          tableId={0}
        />
      );
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: loadComponent,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isShowAttachment: isShowAttachment,
        loadComponent: undefined,
      }));
    }
  };

  const handleRemark = () => {
    setState((prevState) => ({
      ...prevState,
      isRemarkModel: true,
    }));
  };

  // const _onLeftSideListClick = async (evt) => {
  //   console.log("evt.....", evt);
  //   const newWindow = window.open(`#/issue-bill/${evt.invoice_no}`, "_blank");
  //   newWindow.state = {
  //     formData: evt,
  //     disableEdit: true,
  //     // lastInvoiceId: state.lastInvoiceId,
  //   };
  // };

  const openPdf = (pdf) => {
    window.open(pdf, "_blank");
  };

  return (
    <>
      <div className="body-wrapper">
        {frmName && loadFrm === true ? (
          <article className="article">
            <div className="box box-default">
              <div style={{ float: "right", marginTop: "-24px" }}>
                <Tooltip title="User Guide">
                  <a
                    onClick={() => openPdf(ISSUEBILL)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      color: "#12406A",
                      fontSize: "1.1em",
                      fontWeight: "600",
                    }}
                  >
                    <Icon
                      icon="fluent:book-information-24-regular"
                      style={{
                        padding: "0.2rem",
                        borderRadius: "5px",
                        fontSize: "1.4rem",
                        background: "#003e78",
                        color: "white",
                      }}
                    />
                    <span>User Guide</span>
                  </a>
                </Tooltip>
              </div>

              <div className="box-body">
                <NormalFormIndex
                  key={"key_" + frmName + "_0"}
                  formClass="label-min-height"
                  formData={formData}
                  showForm={true}
                  frmCode={frmName}
                  addForm={true}
                  editMode={editMode}
                  showButtons={[
                    // { "id": "cancel", "title": "Reset", "type": "danger" },
                    {
                      id: "save",
                      title: "Save",
                      type: "primary",
                      event: (data) => {
                        saveFormData(data);
                      },
                    },
                  ]}
                  showToolbar={[
                    {
                      isLeftBtn: [
                        {
                          key: "s1",
                          isSets: [
                            {
                              id: "7",
                              key: "menu-fold",
                              type: <MenuFoldOutlined />,
                              withText: "List",
                              showToolTip: true,
                              event: "menu-fold",
                            },
                            {
                              id: "1",
                              key: "save",
                              type: <SaveOutlined />,
                              withText: "Save",
                              showToolTip: true,
                              event: (key, data) => saveFormData(data),
                            },
                            makepaymentid && {
                              id: "2",
                              key: "delete",
                              type: <DeleteOutlined />,
                              withText: "Delete",
                              showToolTip: true,
                              event: (key, data) => _deleteInvoice(data),
                            },

                            {
                              id: "3",
                              key: "edit",
                              type: <EditOutlined />,
                              withText: "Remark",
                              showToolTip: true,
                              event: (key, data) => handleRemark(),
                            },

                            {
                              id: "22",
                              key: "mail",
                              type: <MailOutlined />,
                              withText: "Mail",
                              showToolTip: true,
                            },
                          ],
                        },
                      ],

                      isRightBtn: [
                        {
                          key: "s2",
                          isSets: [
                            {
                              key: "Tco",
                              isDropdown: 0,
                              withText: "",
                              type: "TCO Page",
                              menus: null,
                              modalKey: "",
                              event: (key, data) => {
                                navigate(`/TC-OUT/${data.tc_id}`);
                              },
                            },

                            {
                              key: "share",
                              isDropdown: 0,
                              withText: "",
                              type: "Share",
                              menus: null,
                              modalKey: "Share",
                              event: (key, data) => {
                                if (data["invoice_no"]) {
                                  showShareEmail(data);
                                } else {
                                  openNotificationWithIcon(
                                    "error",
                                    "Please save the form first",
                                    3
                                  );
                                }
                              },
                            },

                            {
                              key: "download_invoice",
                              isDropdown: 0,
                              withText: "Create Invoice",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                // showDownloadInvoice(
                                //   true,
                                //   props.formData &&
                                //     props.formData.hasOwnProperty("id")
                                //     ? props.formData
                                //     : undefined
                                // );
                                showDownloadInvoice(true, data);
                              },
                            },
                            {
                              key: "tde",
                              isDropdown: 0,
                              withText: "TDE",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                //showTDE(key, true);
                                if (formData?.invoice_no) {
                                  if (data?.payment_status === 241) {
                                    openNotification("tde", true);
                                    return;
                                  }
                                  setState((prevState) => ({
                                    ...prevState,
                                    showTDEForm: true,
                                  }));
                                } else {
                                  openNotification("tde");
                                }
                              },
                            },
                            {
                              key: "attachment",
                              isDropdown: 0,
                              withText: "Attachment",
                              type: "",
                              menus: null,
                              event: (key, data) => {
                                data &&
                                data.hasOwnProperty("id") &&
                                data["id"] > 0
                                  ? ShowAttachment(true)
                                  : openNotificationWithIcon(
                                      "info",
                                      "Please save Invoice First.",
                                      3
                                    );
                              },
                            },
                          ],
                        },
                      ],
                      isResetOption: false,
                    },
                  ]}
                  inlineLayout={true}
                  sideList={{
                    showList: true,
                    title: "Issue Bill List",
                    uri: "/make_payment/tco-list?l=0",
                    columns: [
                      "invoice_no",
                      "payment_status",
                      "tc_id",
                      "vessel",
                    ],
                    icon: true,
                    searchString:
                      '"vessel Name","Invoice No", "TCO ID", "Invoice Status" ',
                    rowClickEvent: (evt) => editFormData(evt.invoice_no),
                  }}
                  isShowFixedColumn={["-", "Bunker Delivery / Redelivery Term"]}
                  frmOptions={frmOptions}
                  showSideListBar={state.showItemList}
                  tableRowDeleteAction={(action, data, fullData) => {
                    delete_row(action, data, fullData);
                  }}
                  summary={[{ gKey: "-", showTotalFor: ["amount_usd"] }]}
                />
              </div>
            </div>
          </article>
        ) : (
          <CustomLoader />
        )}
      </div>

      {showTDEForm ? (
        <Modal
          title="TDE"
          open={state.showTDEForm}
          width="80%"
          //onCancel={() => showTDE(undefined, false)}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, showTDEForm: false }))
          }
          style={{ top: "10px", overflowY: "auto", padding: "0.5rem" }}
          footer={null}
          maskClosable={false}
        >
          <Tde
            invoiceType="hire_payable"
            //isEdit={ tdeData != null && tdeData.id && tdeData.id > 0 ? true : false}
            //deleteTde={() => showTDE(undefined, false)}
            modalCloseEvent={() =>
              setState((prevState) => ({ ...prevState, showTDEForm: false }))
            }
            //formData={tdeData}
            formData={formData}
            saveUpdateClose={() =>
              setState((prevState) => ({ ...prevState, isVisible: false }))
            }
            invoiceNo={formData.invoice_no}
          />
        </Modal>
      ) : undefined}

      {state.isShowAttachment ? (
        <Modal
          style={{ top: "2%" }}
          title="Upload Attachment"
          open={state.isShowAttachment}
          onCancel={() => ShowAttachment(false)}
          width="50%"
          footer={null}
        >
          {state.loadComponent}
        </Modal>
      ) : undefined}

      {downloadInvoice ? (
        <Modal
          title="Invoice Print"
          open={downloadInvoice}
          width="80%"
          //onCancel={() => showDownloadInvoice(false)}
          onCancel={() => closeDonloadInvoice()}
          style={{ top: "10px" }}
          bodyStyle={{ maxHeight: 790, overflowY: "auto", padding: "0.5rem" }}
          footer={null}
          maskClosable={false}
        >
          {/* <ConfirmStatement vesselID={tciID.vessel_id} chartrerID={tciID.chartrer_id} tciID={tciID.tco_id} from={tciID.delivery_date} to={tciID.redelivery_date} /> */}
          <DownloadInvoice
            loadInvoice={loadInvoiceNo}
            loadData={loadData}
            invoiceType={"TCO"}
            makepaymentid={makepaymentid}
            showInvoicePopup
            closeDonloadInvoice={closeDonloadInvoice}
          />
        </Modal>
      ) : undefined}

      {state.isShowShare && (
        <Modal
          title="New Message"
          open={true}
          onCancel={() => onclose()}
          onOk={() => onok()}
          footer={null}
        >
          <Email
            title={window.corrector(
              `Issue Bill || ${state.formData.invoice_no}`
            )}
            attachmentFile={sharebtn}
            // link={shareLink}
            showpage={true}
            condition={false}
            shareLink={shareLink}
            handleClose={handleClose}
          />
        </Modal>
      )}

      {isRemarkModel && (
        <Modal
          width={600}
          title="Remark"
          open={isRemarkModel}
          onOk={() => {
            setState({ isRemarkModel: true });
          }}
          onCancel={() =>
            setState((prevState) => ({ ...prevState, isRemarkModel: false }))
          }
          footer={false}
        >
          <Remarks
            remarksID={props.remarksID}
            remarkType="make_payment"
            // idType="Bunker_no"
          />
        </Modal>
      )}
    </>
  );
};

export default MakePayment;
