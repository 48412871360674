import React from "react";
import ReactECharts from "echarts-for-react";

const DashboarBarGrph = ({ data }) => {
  const { labels, datasets } = data;
  const { label, data: chartData, borderColor, backgroundColor } = datasets[0];

  const chartOptions = {
    title: {
      text: label,
      subtext: `Last seven days overview of ${label=="Sent Mails"?"Sent Mails":"Recieved Mails"}`,
      left: "center",
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333', // Main title color
      },
      subtextStyle: {
        fontSize: 14,
        fontWeight: 'normal',
        color: 'linear-gradient(90deg, #FF7F50, #87CEFA, #32CD32)', // Gradient color effect
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      
    },
    
    xAxis: {
      type: "category",
      data: labels, // Use dynamic labels
      axisLabel: {
        rotate: 45,
        fontSize: 12,
        fontWeight: 'bold',
        
      },
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: label,
        type: "bar",
        data: chartData, // Use dynamic data
        //   itemStyle: {
        //     color: borderColor,
        //   },
        itemStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0.6, color: borderColor }, // Top color
              { offset: 1, color: backgroundColor }, // Bottom color
            ],
          },
          shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.3)',
        shadowOffsetX: 5,
        shadowOffsetY: 5,
        },
        emphasis: {
          itemStyle: {
            color: backgroundColor, // Use dynamic hover color
          },
        },
        // label: {
        //     show: true,
        //     position: 'top', // Position the label on top of the bars
        //     fontSize: 12,
        //    fontWeight:"bold"
        //   },
        barWidth: "50%",
        
      },
      {
        name: "Fluctuations",
        type: "line",
        data: chartData, // Same data for the line
        // smooth: true,
        lineStyle: {
          color: "#00CCCC", // Line color
          width: 3,
        },
        itemStyle: {
          color: "rgb(100, 149, 237)", // Point color
        },
        tooltip: {
          show: false, // Disable tooltip for the line
        },
      },
    ],
    grid: {
      left: "10%",
      right: "10%",
      bottom: "20%",
      containLabel: true,
    },
    animationEasing: 'elasticOut',
    animationDuration: 1000,
  };

  return <ReactECharts option={chartOptions} style={{ height: "400px" }} />;
};

export default DashboarBarGrph;
