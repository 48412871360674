import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { Select, Button } from "antd";
import URL_WITH_VERSION, {
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
  postAPICall,
} from "../../..";
import "./AlLoader.css";
import AiLoader from "./AiLoader";

const { Option } = Select;
const barColors = ["#247291", "#f8da5b", "#35bcbf", "#ff8364"];
const pieColors = ["#40a8c4", "#235784", "#f7aa00", "#de703c"];

const AiPLComparator = ({ actualPL, savedPLEntries, selectOptions }) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedActualPL, setSelectedActualPL] = useState("Actual P&L");
  const [selectedSavedPL, setSelectedSavedPL] = useState(null);
  const [isAnalyzerActive, setAnalyzerActive] = useState(false);
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [summery, setSummery] = useState(null);
  const comparedOption = selectOptions?.filter(
    (item) => !(item.value === "actual" && item.label === "Actual")
  );

  useEffect(() => {
    if (selectedActualPL && selectedSavedPL) {
      setAnalyzerActive(true);
    } else {
      setAnalyzerActive(false);
    }
    if (selectedSavedPL) {
      setAnalyzerActive(false);
    }
  }, [selectedActualPL, selectedSavedPL]);

  const getData = async (data) => {
    try {
      setLoading(true);
      const url = `${URL_WITH_VERSION}/recommendation_bp/pnl_comp_analytics`;
      const payload = { actualData: actualPL, changedData: data };
      const resp = await postAPICall(url, payload, "POST");
      if (resp?.status === 200) {
        setGraphData(resp?.data?.graph_queries);
        setSummery(resp?.data?.summary);
        setLoading(false);
      } else {
        console.error("Error connecting");
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleSelectChange = async (value) => {
    try {
      setLoading(true);
      const qParamString = objectToQueryStringFunc({ ae: value });
      const url = `${URL_WITH_VERSION}/voyage-manager/pnl/edit?ae=${value}`;
      const response = await getAPICall(url);
      if (response.data?.pnl_data) {
        setGraphData(null);
        getData(response.data.pnl_data);
        setAnalyzerActive(true);
        // setLoading(false);
      }
    } catch (err) {
      console.error("Error fetching data", err);
      openNotificationWithIcon("error", "Something went wrong", 3);
      setLoading(false);
    }
  };

  const generateChartOptions = (data) => {
    const { graph_type, description, x_axis, y_axis, total } = data;
    switch (graph_type) {
      case "bar":
        return {
          title: {
            text: description,
            left: "center",
            textStyle: { fontSize: 11 },
          },
          tooltip: { trigger: "axis", axisPointer: { type: "shadow" } },
          xAxis: { type: "category", data: x_axis },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: (value) =>
                value >= 1000 ? `${value / 1000}k` : value,
            },
          },
          series: [
            {
              data: y_axis.map((value, index) => ({
                value,
                itemStyle: { color: barColors[index % barColors.length] },
              })),
              type: "bar",
              barWidth: "40%",
            },
          ],
        };

      case "pie":
        return {
          title: {
            text: description,
            subtext: `Total: ${total}`,
            left: "center",
            textStyle: { fontSize: 11 },
          },
          tooltip: { trigger: "item" },
          series: [
            {
              type: "pie",
              radius: "50%",
              data: x_axis.map((label, index) => ({
                value: y_axis[index],
                name: label,
                itemStyle: { color: pieColors[index % pieColors.length] },
              })),
            },
          ],
        };

      case "line":
      case "two_line_bar":
        return {
          title: {
            text: description,
            left: "center",
            textStyle: { fontSize: 11 },
          },
          xAxis: {
            type: "category",
            data: x_axis,
            axisLabel: { interval: 0, rotate: 30 },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: (value) =>
                value >= 1000 ? `${value / 1000}k` : value,
            },
          },
          series: [
            {
              name: "Actual",
              data: y_axis.actual || [],
              type: graph_type === "line" ? "line" : "bar",
              smooth: true,
            },
            {
              name: "Previous",
              data: y_axis.previous || [],
              type: graph_type === "line" ? "line" : "bar",
              smooth: true,
            },
          ],
          tooltip: { trigger: "axis" },
          legend: { top: "10%", data: ["Actual", "Previous"] },
        };

      default:
        return {};
    }
  };

  return (
    <div className="ai-pl-comparator">
      {/* <Button
        type="primary"
        onClick={() => setDropdownVisible(!isDropdownVisible)}
      >
        AI Powered P&L Comparator
      </Button>

      {isDropdownVisible && ( */}
      <div className="ai-dropdown-container">
        <div className="dropdown">
          <label style={{ padding: "1rem 0rem", fontWeight: "bold" }}>
            Actual P&L:
          </label>
          <Select defaultValue="Actual P&L" disabled style={{ width: "100%" }}>
            <Option value="Actual P&L">Actual P&L</Option>
          </Select>
        </div>

        <div className="dropdown">
          <label style={{ padding: "1rem 0rem", fontWeight: "bold" }}>
            Saved P&L:
          </label>
          <Select
            placeholder="Select a Saved P&L"
            style={{ width: "100%" }}
            onChange={(value) => {
              setSelectedSavedPL(value);
              handleSelectChange(value);
            }}
            disabled={loading}
          >
            {comparedOption?.map((entry) => (
              <Option key={entry.value} value={entry.value}>
                {entry.label || "Unnamed Entry"}
              </Option>
            ))}
          </Select>
        </div>

        {/* <Button
          type="primary"
          className="ai-analyzer-button"
          disabled={!isAnalyzerActive}
        >
          AI Analyzer
        </Button> */}
      </div>
      {/* )} */}

      {graphData?.length > 0 ? (
        <div
          className="graphs-container"
          style={{ position: "relative", marginTop: "1rem" }}
        >
          <h3 style={{ textAlign: "center", padding: "1rem", color: "red" }}>
            AI-Generated Graphs
          </h3>
          <div
            style={{ display: "grid", gridTemplateColumns: "repeat(2,1fr)" }}
          >
            {graphData.map((data, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  position: "relative",
                  marginTop: "1rem",
                }}
              >
                <ReactECharts
                  option={generateChartOptions(data)}
                  style={{ height: "300px", width: "100%" }}
                />
              </div>
            ))}
          </div>
        </div>
      ) : !loading ? (
        <h3 style={{ textAlign: "center", padding: "1rem" }}>
          Kindly select an option from saved P&L to view the graphs.
        </h3>
      ) : (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            height: "500px",
            justifyContent: "center",
          }}
        >
          <AiLoader text="Generating Graphs..." />
        </div>
      )}
      <hr />
      {summery && graphData?.length > 0 && (
        <p>
          <b>Summery : </b>
          {summery}
        </p>
      )}
    </div>
  );
};

export default AiPLComparator;
