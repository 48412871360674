import React, { useEffect, useState } from "react";
import Icon, { ClockCircleOutlined } from "@ant-design/icons";

const notifications = [
    {
      title: "🚨 Missing Vessel Speed",
      text: "Please enter the vessel speed for 'MV Oceanic Breeze' to proceed with accurate calculations.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "🚩 Incomplete Port Details",
      text: "The port function and expenses are not assigned for 'Singapore.' Update the information to avoid calculation errors.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "⚠️ Missing Fuel Type",
      text: "No fuel type is selected for the bunker plan at 'Rotterdam.' Please update the bunker details.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "🛠️ Missing C/P Date",
      text: "The Charter Party date is not entered for this voyage estimate. Add the date to proceed.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "🔍 Incomplete Cargo Details",
      text: "The cargo weight or type for the selected voyage is missing. Please complete the details.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "📈 Bunker Price Update",
      text: "The bunker price at 'Singapore' has increased to $580/MT. Review your fuel cost estimates.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "📉 Bunker Price Drop",
      text: "Good news! The bunker price at 'Rotterdam' has decreased to $540/MT. Update your voyage plan for potential savings.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "⚡ Price Change Alert",
      text: "The HSFO bunker price at 'Fujairah' has just changed to $620/MT. Review your budget.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "📧 New ETA Email Received",
      text: "An email related to ETA for voyage 'VOY-12345' has been received. Subject: 'ETA Update for MV Oceanic Breeze.'",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "📤 Revised Port Schedule",
      text: "An email containing port schedule updates has been received. Check your inbox for more details.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "📩 Fuel Quote Available",
      text: "New bunker fuel quotes for 'Singapore' have been sent to your email. Subject: 'Bunker Quote - January 2025.'",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "📨 New Cost Update Email",
      text: "Cost adjustments related to the selected voyage have been received. Review the email for details.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "📊 AI Suggestion",
      text: "The current voyage speed is suboptimal for fuel efficiency. Reduce speed to 12 knots to save costs.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "🌍 Route Optimization Alert",
      text: "A more fuel-efficient route via 'Cape Town' is available. Review the updated AI recommendations.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "💡 AI Reminder",
      text: "Recheck the port expenses for 'Shanghai' to ensure all charges are included.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "✅ All Set",
      text: "All voyage details are complete. You're good to proceed!",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "🔔 Update Required",
      text: "Estimated fuel consumption is missing for the selected route. Update the details to ensure accurate calculations.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "🕒 Time Alert",
      text: "Estimated Time of Arrival (ETA) for 'Singapore' has not been entered. Please update the schedule.",
      date: "2025-01-13",
      time: "9:30 AM"
    },
    {
      title: "🔄 Data Sync Completed",
      text: "All voyage data has been synchronized with the latest inputs. No pending notifications.",
      date: "2025-01-13",
      time: "9:30 AM"
    }
  ];
  
  const colorArr = ["#81BFDA","#E195AB","#A8CD89","#659287","#8B5DFF","#4335A7","#D76C82","#F3C623",
                    "#384B70","#229799","#C683D7","#36BA98","#E88D67","#8E3E63","#874CCC","#D6589F",
                    "#5F374B","#EE4266","#219C90","#3D3B40","#527853"]

const AiAgent = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div style={{ cursor: "pointer" }}>
      {notifications.map((notification, index) => (
        <div
          key={index}
          style={{
            color: hoveredIndex === index ? "black" : "black",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: hoveredIndex === index ? colorArr[index] : "white", 
            transition: "background-color 0.3s ease, color 0.3s ease", 
          }}
          onMouseEnter={() => setHoveredIndex(index)} 
          onMouseLeave={() => setHoveredIndex(null)} 
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4
                style={{
                  cursor: "pointer",
                  padding: "1px 7px",
                }}
              >
                {notification.title}
              </h4>
              <p>{notification.text}</p>
            </div>
            <div style={{ textAlign: "right" }}>
              <p>{notification.date}</p>
              <p>
                <ClockCircleOutlined style={{ marginRight: "5px" }} />
                {notification.time}
              </p>
            </div>
          </div>
          <hr
            style={{
              border: "1px solid black",
              width: "100%",
              marginTop: "0",
              marginBottom: "0"
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default AiAgent;
