import React, { useEffect, useState } from "react";
import { Upload, message, Spin } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import URL_WITH_VERSION, { postAPICall } from "../../shared/index";
// import ShareLink from "../../shared/ShareLink";

const Email = ({
  handleClose,
  title,
  attachmentFile,
  link,
  showpage,
  close,
  shareLink,
}) => {
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState(title);
  const [description, setDescription] = useState("");
  const [uploadedFile, setUploadedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [pageLink, setPagelink] = useState(shareLink);
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    const newData = [...uploadedFile, attachmentFile];
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    if (newData.length > 0 && newData.length < 2) {
      setFileName(`${title.split("||")[0]}(${formattedDate}).pdf`);
    }
    setUploadedFile(newData);
  }, []);

  useEffect(() => {
    if (uploadedFile[0]?.type === "application/pdf") {
      setShowUpload(false);
    }
  }, [uploadedFile]);

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const fileName = "defaultFileName.pdf";
      onSuccess();
      message.success(`${fileName} file uploaded successfully`);
    } catch (error) {
      console.error("File upload error:", error);
      onError(error);
      message.error(`${file.name} file upload failed.`);
    }
  };

  const renderFileLink = () => {
    if (uploadedFile && uploadedFile.length > 0) {
      const fileUrl = URL.createObjectURL(uploadedFile[0]);
      return (
        <p>
          File:{" "}
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            {uploadedFile.length < 2 && uploadedFile.length > 0
              ? fileName
              : uploadedFile.pop()}
          </a>
        </p>
      );
    }
    return null;
  };

  const beforeUpload = (file) => {
    return true;
  };

  // const handleFileChange = (info) => {
  //   if (info.file.status === "done") {
  //     const recentlyUploadedFile = info.file.originFileObj;

  //     // Optional: Convert to Blob if needed (not usually necessary)
  //     const fileBlob = new Blob([recentlyUploadedFile], {
  //       type: recentlyUploadedFile.type,
  //     });
  //     if (attachmentFile === undefined) {
  //       setUploadedFile([fileBlob]); // Ensure setUploadedFile expects an array or adjust as needed
  //     }
  //   } else if (info.file.status === "error") {
  //     console.error("File upload failed:", info.file);
  //   }
  // };
  const handleFileChange = (info) => {
    if (info.file.status === "done") {
      const recentlyUploadedFile = info.file;
      setUploadedFile([recentlyUploadedFile]);
    } else if (info.file.status === "error") {
      console.error("File upload failed:", info.file);
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!uploadedFile) {
      message.error("Please select File also");
      return;
    }
    const validFiles = uploadedFile.filter(
      (file) => file.type !== "text/plain" && file.type?.trim() !== ""
    );

    const formData = new FormData();
    if (showpage) {
      formData.append("link", pageLink);
    }
    console.log("?????", validFiles);
    if (validFiles.length > 0) {
      formData.append(
        "attachments",
        validFiles[0]?.type === "application/pdf"
          ? uploadedFile[0]
          : validFiles[0]?.originFileObj,
        fileName
      );
    }
    // formData.append("attachments", uploadedFile[0], fileName);
    formData.append("message", description);
    formData.append("recepients", to);
    formData.append("subject", subject);
    try {
      // let suURL = `https://apibeta.theoceann.com/api/v1/email/send-with-file`;
      // let suURL='http://192.168.0.107:5001/api/v1/email/send-with-file'
      let suURL = `${process.env.REACT_APP_ATTACHMENT}/email/send-with-file`;
      let suMethod = "POST";
      await postAPICall(suURL, formData, suMethod, (data) => {
        if (data.data == true) {
          setTo("");
          setDescription("");
          setUploadedFile("");
          message.success("Email sent successfully!");
          setLoading(false);
          handleClose();
          setPagelink("");
        } else {
          setLoading(false);
          message.error("Failed to send email. Please Check Email Address.");
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("API Error:", error);
      message.error("Failed to send email. Please try again.");
    }
  };

  const handelDelete = () => {
    setTo("");
    setDescription("");
    // setUploadedFile(null);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 border-bottom">
          <input
            type="email"
            className="form-control shadow-none"
            id="email"
            placeholder="To"
            style={{ border: "none" }}
            value={to}
            onChange={(e) => setTo(e.target.value)}
            required
          />
        </div>
        <div className="mb-3 border-bottom">
          <input
            type="text"
            className="form-control shadow-none"
            id="subject"
            placeholder="Subject"
            style={{ border: "none" }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="mb-3 border-bottom">
          <textarea
            className="form-control shadow-none"
            id="description"
            rows="10"
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            style={{ border: "none", outline: "none" }}
          />

          {showpage ? <a href={pageLink}>{pageLink}</a> : renderFileLink()}
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-3 align-items-center">
            <button
              type="submit"
              className="btn text-white "
              style={{ backgroundColor: "#003E78" }}
              disabled={loading}
            >
              Send
            </button>
            {showUpload ? (
              <Upload
                customRequest={customRequest}
                beforeUpload={beforeUpload}
                showUploadList={true}
                // fileList={uploadedFile[uploadedFile.length - 1]}
                onChange={(info) => handleFileChange(info)}
                accept=".pdf, .png, .jpg, .jpeg, .svg"
              >
                <PaperClipOutlined className="fs-5 fw-bold align-items-center" />
              </Upload>
            ) : null}
          </div>
          <div className="d-flex">
            <button
              className="btn"
              onClick={handelDelete}
              style={{
                backgroundColor: "#003E78",
                color: "white",
                border: "none",
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </form>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default Email;
