import React, { forwardRef, useRef, useState, useEffect } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import Email from "../../components/Email";
import html2canvas from "html2canvas";
import moment from "moment";

const ComponentToPrint = forwardRef((props, ref) => {
  const [formReportdata, setFormReportdata] = useState(
    Object.assign({}, props.data || {})
  );

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <span className="title">
                  <img
                    className="reportlogo"
                    src={formReportdata.logo}
                    crossOrigin="anonymous"
                    alt="no img"
                  />
                </span>
                <p className="sub-title m-0">
                  {formReportdata.company_fixed_with_name
                    ? formReportdata.company_fixed_with_name
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{formReportdata.address ? formReportdata.address : "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="row p10">
            <div className="col-md-12">
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Vessel Name :</td>
                    <td>{formReportdata.vessel_name || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Type:</td>
                    <td className="">{formReportdata.vessel_type || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Sub Type:</td>
                    <td className="">
                      {formReportdata.vessel_sub_type || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">TCO Code :</td>
                    <td>{formReportdata.tc_code || "N/A"}</td>
                    <td className="font-weight-bold">My Company / LOB :</td>
                    <td className="">
                      {formReportdata.company_fixed_with_name || "N/A"} /{" "}
                      {formReportdata.company_lob_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Payment Terms :</td>
                    <td>{formReportdata.payment_type_name || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Charterer :</td>
                    <td>{formReportdata.charterer_name || "N/A"}</td>

                    <td className="font-weight-bold">CP Date :</td>
                    <td>{formReportdata.cp_date_fixed_by || "N/A"}</td>

                    <td className="font-weight-bold">Config Date :</td>
                    <td>{formReportdata.config_date || "N/A"}</td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Bill Via :</td>
                    <td className="">
                      {formReportdata.bill_via_name || "N/A"}
                    </td>
                    <td className="font-weight-bold">Fixed By User :</td>
                    <td>{formReportdata.fixed_by_user || "N/A"}</td>

                    <td className="font-weight-bold">Billing Period :</td>
                    <td>
                      {formReportdata.billing_period
                        ? formReportdata.billing_period
                        : "N/A"}
                    </td>
                  </tr>

                  <tr></tr>

                  <tr>
                    <td className="font-weight-bold">Laycan from :</td>
                    <td>{formReportdata.laycan_from || "N/A"}</td>
                    <td className="font-weight-bold">Contract Type :</td>
                    <td className="">
                      {formReportdata.contract_type_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Ops User :</td>
                    <td>
                      {formReportdata.ops_user_name
                        ? formReportdata.ops_user_name
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laycan To :</td>
                    <td>{formReportdata.laycan_to || "N/A"}</td>
                    <td className="font-weight-bold">Trade Area :</td>
                    <td>{formReportdata.trade_area_name || "N/A"}</td>

                    <td className="font-weight-bold">Status :</td>
                    <td className="">
                      {formReportdata.tco_status_name || "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold"> Voy. ops type :</td>
                    <td>{formReportdata.laycan_from || "N/A"}</td>
                    <td className="font-weight-bold">Currency :</td>
                    <td>
                      {formReportdata.currency_exchange_rate_name || "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">Delivery Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Delivery / Redelivery</th>
                    <th>Port</th>
                    <th>Estimated Date</th>
                    <th>Actual Date</th>
                    <th>Difference ( Days )</th>
                    <th>Min Days</th>
                    <th>Max Days</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.deliveryterm &&
                  formReportdata.deliveryterm &&
                  formReportdata.deliveryterm.length > 0
                    ? formReportdata.deliveryterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.d_t_name || "N/A"}</td>
                              <td>{e.port_name || "N/A"}</td>
                              <td>{e.est_gmt || "N/A"}</td>
                              <td>{e.actual_gmt || "N/A"}</td>
                              <td>
                                {e.difference_days ? e.difference_days : "0.00"}
                              </td>
                              <td>{e.min_days ? e.min_days : "0.00"}</td>
                              <td>{e.max_days ? e.max_days : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">Pricing</h4>
              <hr className="invoice-line" />
              <h4 className="font-weight-bold">Hire Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Hire Rate</th>
                    <th>Period</th>
                    <th>Rate Type</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Duration</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.hireterm &&
                  formReportdata.hireterm &&
                  formReportdata.hireterm.length > 0
                    ? formReportdata.hireterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.hire_rate || "N/A"}</td>
                              <td>{e.period_name || "N/A"}</td>
                              <td>{e.rate_type_name || "N/A"}</td>
                              <td>{e.from_date || "N/A"}</td>
                              <td>{e.to_date || "N/A"}</td>
                              <td>{e.duration || "N/A"}</td>
                              <td>{e.comment || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Commission Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Commission</th>
                    <th>Rate Type</th>
                    <th>Rate Figure</th>
                    <th>Payment Method</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Estimate Amount</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.commissionterm &&
                  formReportdata.commissionterm &&
                  formReportdata.commissionterm.length > 0
                    ? formReportdata.commissionterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.commission_name || "N/A"}</td>
                              <td>{e.rate_type_name || "N/A"}</td>
                              <td>{e.rate_figure || "N/A"}</td>
                              <td>{e.pay_method_name || "N/A"}</td>
                              <td>{e.from_gmt || "N/A"}</td>
                              <td>{e.to_gmt || "N/A"}</td>
                              <td>{e.estimate_amount || "N/A"}</td>
                              <td>{e.comment || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Other Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Short Code</th>
                    <th>Description</th>
                    <th>Rate Type</th>
                    <th>Amount</th>
                    <th>Commission</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.otherterm &&
                  formReportdata.otherterm &&
                  formReportdata.otherterm.length > 0
                    ? formReportdata.otherterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.short_code || "N/A"}</td>
                              <td>{e.description_name || "N/A"}</td>
                              <td>{e.rate_type_name || "N/A"}</td>
                              <td>{e.amount || "N/A"}</td>
                              <td>
                                <td>{e["commission"] == 1 ? "Yes" : "No"}</td>
                              </td>
                              <td>{e.from_gmt || "N/A"}</td>
                              <td>{e.to_gmt || "N/A"}</td>
                              <td>{e.remarks || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">Bunkers</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Short Code</th>
                    <th>Description</th>
                    <th>IFO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>ULSFO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>VLSFO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>LSMGO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>MGO Qty</th>
                    <th>CP Price ($/MT)</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.bunkers &&
                  formReportdata.bunkers &&
                  formReportdata.bunkers.length > 0
                    ? formReportdata.bunkers.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.short_code || "N/A"}</td>
                              <td>{e.description_name || "N/A"}</td>
                              <td>{e.f_IFO ? e.f_IFO : "0.00"}</td>
                              <td>{e.f_IFO_p ? e.f_IFO_p : "0.00"}</td>
                              <td>{e.f_ULSFO ? e.f_ULSFO : "0.00"}</td>
                              <td>{e.f_ULSFO_p ? e.f_ULSFO_p : "0.00"}</td>
                              <td>{e.f_VLSFO ? e.f_VLSFO : "0.00"}</td>
                              <td>{e.f_VLSFO_p ? e.f_VLSFO_p : "0.00"}</td>
                              <td>{e.f_LSMGO ? e.f_LSMGO : "0.00"}</td>
                              <td>{e.f_LSMGO_p ? e.f_LSMGO_p : "0.00"}</td>
                              <td>{e.f_MGO ? e.f_MGO : "0.00"}</td>
                              <td>{e.f_MGO_p ? e.f_MGO_p : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">
                TC Bunker Adjustment
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>BOD</th>
                    <th>BOD Value</th>
                    <th>BOR</th>
                    <th>BOR Value</th>
                    <th>NET Bunker</th>
                    <th>NET Bunker Value</th>
                    <th>% Allowed</th>
                    <th>Allocated Bunker Amount</th>
                    <th>Voyage No.</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.tcbunkeradjustment &&
                  formReportdata.tcbunkeradjustment &&
                  formReportdata.tcbunkeradjustment.length > 0
                    ? formReportdata.tcbunkeradjustment.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.bod ? e.bod : "0.00"}</td>
                              <td>{e.bod_value ? e.bod_value : "0.00"}</td>
                              <td>{e.bor ? e.bor : "0.00"}</td>
                              <td>{e.bor_value ? e.bor_value : "0.00"}</td>
                              <td>{e.net_bunker ? e.net_bunker : "0.00"}</td>
                              <td>
                                {e.net_bunker_value
                                  ? e.net_bunker_value
                                  : "0.00"}
                              </td>
                              <td>{e.per_allowed ? e.per_allowed : "0.00"}</td>
                              <td>
                                {e.all_bunker_amount
                                  ? e.all_bunker_amount
                                  : "0.00"}
                              </td>
                              <td>
                                {e.voyage_number ? e.voyage_number : "0.00"}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              {/* <h4 className="font-weight-bold tc-sub-header">OffHire Delay</h4> */}

              <h4 className="font-weight-bold tc-sub-header">
                OffHire Invoiced
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Voyage No</th>
                    <th>P&L Status </th>
                    <th>Activity</th>

                    <th>Reason </th>
                    <th>From Date Time</th>

                    <th>To Date Time</th>

                    <th>Hours</th>

                    <th>Days</th>

                    <th>Miles </th>

                    <th>Hire Rate</th>

                    <th>Contract Type</th>

                    <th>IFO QTY</th>
                    <th>IFO Value</th>

                    <th>VLSFO QTY</th>
                    <th>VLSFO Value</th>

                    <th>ULSFO QTY</th>
                    <th>ULSFO Value</th>

                    <th>LSMGO QTY</th>
                    <th>LSMGO Value</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.offhiredelays &&
                  formReportdata.offhiredelays.length > 0
                    ? formReportdata.offhiredelays.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.voyage_no || "N/A"}</td>
                              <td>{e.pnl_status == 1 ? "Yes" : "No"}</td>

                              <td>
                                {e.activity_name ? e.activity_name : "N/A"}
                              </td>
                              <td>{e.reason_name ? e.reason_name : "N/A"}</td>
                              <td>{e.delay_from ? e.delay_from : "0.00"}</td>
                              <td>{e.delay_to ? e.delay_to : "0.00"}</td>
                              <td>{e.hours ? e.hours : "0.00"}</td>
                              <td>{e.days ? e.days : "0.00"}</td>
                              <td>{e.miles ? e.miles : "0.00"}</td>
                              <td>{e.hire_rate ? e.hire_rate : "0.00"}</td>
                              <td>
                                {e.contract_type_name
                                  ? e.contract_type_name
                                  : "0.00"}
                              </td>
                              <td>{e.ifo_qty ? e.ifo_qty : "0.00"}</td>
                              <td>{e.ifo_value ? e.ifo_value : "0.00"}</td>
                              <td>{e.vlsfo_qty ? e.vlsfo_qty : "0.00"}</td>
                              <td>{e.vlsfo_amt ? e.vlsfo_amt : "0.00"}</td>
                              <td>{e.ulsfo_qty ? e.ulsfo_qty : "0.00"}</td>
                              <td>{e.ulsfo_value ? e.ulsfo_value : "0.00"}</td>
                              <td>{e.lsmgo_qty ? e.lsmgo_qty : "0.00"}</td>
                              <td>{e.lsmgo_value ? e.lsmgo_value : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>MGO QTY</th>
                    <th>MGO Value</th>
                    <th>Net Bunker Amount</th>
                    <th>Time Loss Value</th>
                    <th>Final Amount</th>
                    <th>Payment</th>
                  </tr>
                </thead>

                <tbody>
                  {formReportdata.offhiredelays &&
                  formReportdata.offhiredelays.length > 0
                    ? formReportdata.offhiredelays.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.mgo_qty ? e.mgo_qty : "0.00"}</td>
                              <td>{e.mgo_amt ? e.mgo_amt : "0.00"}</td>
                              <td>
                                {e.net_bunker_amt ? e.net_bunker_amt : "0.00"}
                              </td>
                              <td>
                                {e.time_loss_value ? e.time_loss_value : "0.00"}
                              </td>
                              <td>{e.final_amt ? e.final_amt : "0.00"}</td>
                              <td>{e.in_payment == 1 ? "Yes" : "No"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">
                Vessel OffHire Delay
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Voyage No</th>
                    <th>P&L Status</th>
                    <th>Offhire Activity Type</th>
                    <th>Reason</th>

                    <th>From Date Time</th>
                    <th>To Date Time</th>
                    <th>Hours</th>
                    <th>Days</th>
                    <th>Miles</th>
                    <th>Hire Rate</th>
                    <th>Contract Type</th>
                    <th>IFO QTY</th>
                    <th>IFO Value</th>
                    <th>VLSFO QTY</th>
                    <th>VLSFO Value</th>
                    <th>ULSFO QTY</th>
                    <th>ULSFO Value</th>
                    <th>LSMGO QTY</th>
                    <th>LSMGO Value</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.vesseloffhiredelay &&
                  formReportdata.vesseloffhiredelay.length > 0
                    ? formReportdata.vesseloffhiredelay.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.voyage_numer || "N/A"}</td>
                              <td>{e.pnl_status == 1 ? "Yes" : "No"}</td>
                              <td>{e.activity_name || "N/A"}</td>
                              <td>{e.reason_name ? e.reason_name : "N/A"}</td>

                              <td>{e.delay_from ? e.delay_from : "0.00"}</td>
                              <td>{e.delay_to ? e.delay_to : "0.00"}</td>
                              <td>{e.hours ? e.hours : "0.00"}</td>
                              <td>{e.days ? e.days : "0.00"}</td>
                              <td>{e.miles ? e.miles : "0.00"}</td>
                              <td>{e.hire_rate ? e.hire_rate : "0.00"}</td>
                              <td>
                                {e.contract_type_name
                                  ? e.contract_type_name
                                  : "N/A"}
                              </td>
                              <td>{e.ifo_qty ? e.ifo_qty : "0.00"}</td>
                              <td>{e.ifo_value ? e.ifo_value : "0.00"}</td>
                              <td>{e.vlsfo_qty ? e.vlsfo_qty : "0.00"}</td>
                              <td>{e.vlsfo_amt ? e.vlsfo_amt : "0.00"}</td>
                              <td>{e.ulsfo_qty ? e.ulsfo_qty : "0.00"}</td>
                              <td>{e.ulsfo_amt ? e.ulsfo_amt : "0.00"}</td>
                              <td>{e.lsmgo_qty ? e.lsmgo_qty : "0.00"}</td>
                              <td>{e.lsmgo_value ? e.lsmgo_value : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>MGO QTY</th>
                    <th>MGO Value</th>
                    <th>Net Bunker Amount</th>
                    <th>Hire Loss Value</th>
                    <th>Final Amount</th>
                    <th>Remark</th>
                  </tr>
                </thead>

                <tbody>
                  {formReportdata?.vesseloffhiredelay &&
                  formReportdata?.vesseloffhiredelay?.length > 0
                    ? formReportdata?.vesseloffhiredelay.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.mgo_qty ? e.mgo_qty : "0.00"}</td>
                              <td>{e.mgo_amt ? e.mgo_amt : "0.00"}</td>
                              <td>
                                {e.net_bunker_amt ? e.net_bunker_amt : "0.00"}
                              </td>
                              <td>
                                {e.time_loss_value ? e.time_loss_value : "0.00"}
                              </td>
                              <td>{e.final_amt ? e.final_amt : "0.00"}</td>
                              <td>{e.description ? e.description : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              {/* <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr>
                    <th>Activity</th>
                    <th>Reason</th>
                    <th>From</th>

                    <th>To</th>

                    <th>Hours</th>
                    <th>Ded Adj (TCI)</th>
                    <th>Miles</th>
                    <th>HFO Qty</th>
                      <th>HFO ROB</th>
                      <th>MDO Qty</th>
                      <th>MDO ROB</th>
                    <th>MGO Qty</th>
                    <th>MGO ROB</th>
                    <th>TCI %</th>
                    <th>TCI Daily Cost</th>
                    <th>TCI L</th>
                    <th>TCI Lumpsum</th>
                    <th>TCI Stmt</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.offhiredelays &&
                  formReportdata.offhiredelays.length > 0
                    ? formReportdata.offhiredelays.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.activity || "N/A"}</td>
                              <td>{e.reason || "N/A"}</td>
                              <td>{e.delay_from ? e.delay_from : "0.00"}</td>
                              <td>{e.delay_to ? e.delay_to : "0.00"}</td>
                              <td>{e.hours || "N/A"}</td>
                              <td>{e.ded_adj_tci || "N/A"}</td>
                              <td>{e.miles ? e.miles : "0.00"}</td>
                              <td>{e.mgo_qty ? e.mgo_qty : "0.00"}</td>
                              <td>{e.mgo_rob ? e.mgo_rob : "0.00"}</td>
                              <td>{e.tci_per ? e.tci_per : "0.00"}</td>
                              <td>
                                {e.tci_daily_cost ? e.tci_daily_cost : "0.00"}
                              </td>
                              <td>{e.tci_l ? e.tci_l : "0.00"}</td>
                              <td>{e.tci_lumpsum ? e.tci_lumpsum : "0.00"}</td>
                              <td>{e.tci_stmt ? e.tci_stmt : "0.00"}</td>
                              <td>{e.remarks || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr>
                    <th>Last Updated</th>
                    <th>Last Updated By</th>
                    <th>ULSFO Qty</th>
                    <th>ULSFO$/MT</th>
                    <th>VLSFO Qty</th>
                    <th>VLSFO$/MT</th>
                    <th>IFO Qty</th>
                    <th>IFO$/MT</th>
                    <th>LSMGO Qty</th>
                    <th>LSMGO$/MT</th>
                    <th>TCO Final Hire Amount</th>
                    <th>TCO Bunker Value</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.offhiredelays &&
                  formReportdata.offhiredelays.length > 0
                    ? formReportdata.offhiredelays.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.last_updated || "N/A"}</td>
                              <td>{e.last_updated_by || "N/A"}</td>
                              <td>{e.ulsfo_qty ? e.ulsfo_qty : "0.00"}</td>
                              <td>{e.ulsfo_mt ? e.ulsfo_mt : "0.00"}</td>
                              <td>{e.vlsfo_qty ? e.vlsfo_qty : "0.00"}</td>
                              <td>{e.vlsfo_mt ? e.vlsfo_mt : "0.00"}</td>
                              <td>{e.ifo_qty ? e.ifo_qty : "0.00"}</td>
                              <td>{e.ifo_mt ? e.ifo_mt : "0.00"}</td>
                              <td>{e.lsmgo_qty ? e.lsmgo_qty : "0.00"}</td>
                              <td>{e.lsmgo_mt ? e.lsmgo_mt : "0.00"}</td>
                              <td>
                                {e.tco_final_hire_amt
                                  ? e.tco_final_hire_amt
                                  : "0.00"}
                              </td>
                              <td>
                                {e.tco_bunker_value
                                  ? e.tco_bunker_value
                                  : "0.00"}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table> */}

              <h4 className="font-weight-bold tc-sub-header">Voyage History</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Voyage No</th>
                    <th>Voyage Type</th>
                    <th>Code</th>
                    <th>Voyage Result</th>
                    <th>Gross Income</th>
                    <th>Gross Expenses</th>
                    <th>Voyage Days</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.voyagehistory &&
                  formReportdata.voyagehistory.length > 0
                    ? formReportdata.voyagehistory.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.voyage_no || "N/A"}</td>
                              <td>{e.voyage_type || "N/A"}</td>
                              <td>{e.code || "N/A"}</td>
                              <td>{e.voyage_result || "N/A"}</td>
                              <td>
                                {e.gross_income ? e.gross_income : "0.00"}
                              </td>
                              <td>
                                {e.gross_expenses ? e.gross_expenses : "0.00"}
                              </td>
                              <td>{e.voyage_days ? e.voyage_days : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              {/* <h4 className="font-weight-bold tc-sub-header">
                Performing Speed
              </h4> */}
              <hr className="invoice-line" />
              <h4 className="font-weight-bold">Port Consp. Table (Per Day)</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Fuel Type</th>
                    <th>Grade</th>
                    <th>Unit</th>
                    <th>Loading</th>
                    <th>Disch</th>
                    <th>Ideal On</th>
                    <th>Capacity</th>
                    <th>Margin</th>
                    <th>Heat</th>
                    <th>Heat+</th>
                    <th>Heat+2</th>
                    <th>IGS</th>
                    <th>Clean</th>
                    <th>Menu UV</th>
                    <th>AV</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["portconsp.tableperday"] &&
                  formReportdata["portconsp.tableperday"].length > 0
                    ? formReportdata["portconsp.tableperday"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.con_type_name || "N/A"}</td>
                              <td>{e.con_g_name || "N/A"}</td>
                              <td>{e.con_unit_name || "N/A"}</td>
                              <td>{e.con_loading || "N/A"}</td>
                              <td>{e.con_disch || "N/A"}</td>
                              <td>{e.con_ideal_on || "N/A"}</td>
                              <td>{e.con_capacity || "N/A"}</td>
                              <td>{e.con_margin || "N/A"}</td>
                              <td>{e.con_heat || "N/A"}</td>
                              <td>{e.con_heat_p || "N/A"}</td>
                              <td>{e.con_heat_pp || "N/A"}</td>
                              <td>{e.con_igs || "N/A"}</td>
                              <td>{e.con_clean || "N/A"}</td>
                              <td>{e.con_maneuv || "N/A"}</td>
                              <td>{e.con_av || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">
                Sea Spd Consp. Table (Per Day)
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Speed Type</th>
                    <th>Speed (Kt)</th>
                    <th>Passage Type</th>
                    <th>Engine Load (%)</th>
                    <th>IFO Consp.</th>
                    <th>VLSFO Consp.</th>
                    <th>LSMGO Consp.</th>
                    <th>MGO Consp.</th>
                    <th>ULSFO Consp.</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["seaspdconsp.tableperday"] &&
                  formReportdata["seaspdconsp.tableperday"].length > 0
                    ? formReportdata["seaspdconsp.tableperday"].map(
                        (e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>{e.spd_type_name || "N/A"}</td>
                                <td>{e.speed || "N/A"}</td>
                                <td>{e.passagename || "N/A"}</td>
                                <td>{e.engine_load || "N/A"}</td>
                                <td>{e.ifo || "N/A"}</td>
                                <td>{e.vlsfo || "N/A"}</td>
                                <td>{e.mgo || "N/A"}</td>
                                <td>{e.lsmgo || "N/A"}</td>
                                <td>{e.ulsfo || "N/A"}</td>
                              </tr>
                            </>
                          );
                        }
                      )
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Notices Alerts</h4>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="border-0">
                      <table className="table custom-table-bordered tc-table">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Email Body</th>
                            <th>Notice Type</th>
                            <th>Delivary Date</th>
                            <th>Expected Days</th>

                            <th>Notice Sent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata[".."] &&
                          formReportdata[".."].length > 0
                            ? formReportdata[".."].map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>{e.email_body || "N/A"}</td>
                                      <td>{e.notice_type || "N/A"}</td>
                                      <td>
                                        {e.project_delivary !=
                                        "0000-00-00 00:00:00"
                                          ? moment(e.project_delivary).format(
                                              "YYYY-MM-DD HH:MM"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td align="center">
                                        {e.email_content || "N/A"}
                                      </td>

                                      <td>
                                        {e["notice_sent"] == 1 ? "Yes" : "No"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>

                      <table className="table custom-table-bordered tc-table">
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">
                              {" "}
                              Delivery Date :
                            </td>
                            <td>
                              {formReportdata["."][0]["delivery_date"] || "N/A"}
                            </td>

                            <td className="font-weight-bold">Notification :</td>
                            <td>
                              {formReportdata["."][0]["d_notification"] == 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="border-0">
                      {/* <table className="table custom-table-bordered tc-table">
                          <tbody>
                            <tr>
                              <td className="font-weight-bold">
                                Redelivery Date :
                              </td>
                              <td>
                                {formReportdata["-"][0]["redelivery_date"]}
                              </td>

                              <td className="font-weight-bold">
                                Notification :
                              </td>
                              <td>
                                {formReportdata["-"][0]["r_notification"] == 1
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          </tbody>
                        </table> */}
                      <table className="table custom-table-bordered tc-table">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Email Body</th>
                            <th>Notice Type</th>
                            <th>Redelivary Date</th>
                            <th>Expected Days</th>

                            <th>Notice Sent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata["..."] &&
                          formReportdata["..."].length > 0
                            ? formReportdata["..."].map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>{e.email_body}</td>
                                      <td>{e.notice_type}</td>
                                      <td>
                                        {e.project_delivary !=
                                        "0000-00-00 00:00:00"
                                          ? moment(e.project_delivary).format(
                                              "YYYY-MM-DD HH:MM"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td align="center">{e.email_content}</td>
                                      <td>
                                        {e["notice_sent"] == 1 ? "Yes" : "No"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>
                      <table className="table custom-table-bordered tc-table">
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">
                              Redelivery Date :
                            </td>
                            <td>
                              {formReportdata["-"][0]["redelivery_date"] ||
                                "N/A"}
                            </td>

                            <td className="font-weight-bold">Notification :</td>
                            <td>
                              {formReportdata["-"][0]["r_notification"] == 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-weight-bold">Bareboat</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Code</th>
                    <th>Beneficiary</th>
                    <th>Date</th>
                    <th>Contract Start</th>
                    <th>Contract End</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.bareboats &&
                  formReportdata.bareboats.length > 0
                    ? formReportdata.bareboats.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.code || "N/A"}</td>
                              <td>{e.beneficiary_name || "N/A"}</td>
                              <td>{e.date || "N/A"}</td>

                              <td>{e["contract_start"] || "N/A"}</td>
                              <td>{e["contract_end"] || "N/A"}</td>
                              <td>{e.remarks || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
});

const TcoReports = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mailTitlePayload, setMailTitlePayload] = useState({});

  const componentRef = useRef(null);

  useEffect(() => {
    // console.log("dataaa", props.data);
    // const{vessel_name,charterer_name,company_lob_name,tco_status_name}=props.data
    // const tempArray={vessel_name,charterer_name,company_lob_name,tco_status_name}
    // setTitleArray(tempArray)
    setUserInput(props.data);
  }, []);

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      // Use the blob as needed (e.g., send it to the server, create a download link, etc.)
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // this.setState({ loading: false });
      // Handle errors here
    }
  };

  const printDocument = () => {
    const quotes = document.getElementById("divToPrint");
    // alert("tco");
    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      // const link = document.createElement("a");
      // link.download = "html-to-img.png";
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm");
      let position = 8;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      const imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 10,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("TCO.pdf");
    });
  };

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection"></div>
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li onClick={sendEmailReportModal}>Send Email</li>
                    <li onClick={printDocument}>Download</li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined />
                            Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint ref={componentRef} data={props.data} />
          </div>
        </div>
      </article>

      {emailModal && (
        <Modal
          title="New Message"
          visible={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              // title={window.emailTitltCorrectFunction('TC-Out_List Report',titleArray) }
              title={window.corrector(
                `TC-Out_List Report||${userInput.vessel_name}||${userInput.charterer_name}||${userInput.company_lob_name}||${userInput.tco_status_name}`
              )}
              // title={`TC-Out_List Report|| ${userInput.vessel_name}(${userInput.charterer_name})||${userInput.company_lob_name}||${userInput.tco_status_name}`}
            />
          )}
        </Modal>
      )}
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default TcoReports;
