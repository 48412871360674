import { Button, Dropdown, message, Spin, Tooltip } from "antd";
// import SplitView from "./SplitView";
import { useEffect, useState } from "react";
import { postAPICall } from "../../shared";
import { Icon } from "@iconify/react/dist/iconify.js";
// import ListView from "./ListView";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllEmails,
  setArchivedMail,
  setBinMail,
  setDraftMail,
  setFilterData,
  setFolderMail,
  setHasMore,
  setImpMail,
  setImportedMailInbox,
  setIsFiltering,
  setLabelMail,
  setResizeMail,
  setSentMail,
  setShowAiMails,
  setSpamMail,
  setFilterPayload,
} from "../../actions/emailActions";
import { useLocation, useParams } from "react-router";
import {
  EMAILS_PER_PAGE,
  highlightSearchKeywords,
} from "../mail-folder/constants";
import SplitViewDrawer from "./SplitViewDrawer";
import ListviewDrawer from "./ListViewDrawer";
// import { EMAILS_PER_PAGE, highlightSearchKeywords } from "./constants";

const MailLayoutDrawer = (props) => {
  const [view, setView] = useState("list");
  const [page, setPage] = useState(1);
  const [totalMailCount, setTotalMailCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const [mailType, setMailType] = useState("");
  const [searchText, setSearchtext] = useState("");
  const [loading, setLoading] = useState(true);
  const [refreshCount, setRefreshCount] = useState(0);
  const [mailData, setMailData] = useState([]);
  const [isFilters, setIsfilters] = useState(false);
  const [filterPagination, setFilterPagination] = useState({
    page: 1,
    pageSize: 50,
  });
  // const [filterPayload, setFilterPayload] = useState([]);
  const dispatch = useDispatch();
  const { label_name, folder_name, imported_mail_identifier } = useParams();
  const isSearching = useSelector((state) => state.email.isSearching);
  const isFiltering = useSelector((state) => state.email.isFiltering);
  const filterData = useSelector((state) => state.email.filterData);
  const filterPayload = useSelector((state) => state.email.filterPayload);
  const importedMails = useSelector((state) => state.email.importedMails);

  const allMails = useSelector((state) => state.email.allEmails);
  const showAiEmails = useSelector((state) => state.email.showAiMails);
  const sentMails = useSelector((state) => state.email.sentList);
  const draftMails = useSelector((state) => state.email.draftList);
  const binMails = useSelector((state) => state.email.binList);
  const archivedMails = useSelector((state) => state.email.archivedList);
  const importantMails = useSelector((state) => state.email.importantList);
  const spamMails = useSelector((state) => state.email.spamList);
  const labelMails = useSelector((state) => state.email.labelMailList);
  const importedMailList = useSelector((state) => state.email.importedMailList);
  const composeMails = useSelector((state) => state.email.composeMails);
  const userData = localStorage.getItem("oceanToken")
    ? JSON.parse(atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? ""))
    : null;

    

  const location = useLocation();
  useEffect(() => {
    dispatch(setIsFiltering(false));
    setPage(1);
  }, [location]);

  const websocketListning = (event) => {
    try {
      const data = JSON.parse(event.data); // Parse the incoming data

      if (
        data.event === "INBOXMAIL" &&
        mailType === "inbox" &&
        !imported_mail_identifier
      ) {
        // Handle INBOXMAIL event
        // console.log("INBOXMAIL event received:", data);

        // Add the new mail object to the beginning and remove the last element
        const updatedEmails = [
          data?.content,
          ...allMails.slice(0, allMails.length - 1),
        ];

        dispatch(setAllEmails({ emails: updatedEmails }));
      }
    } catch (error) {
      console.error("Error processing WebSocket message:", error);
    }
  };

  useEffect(() => {
    const socket = window.mySocketMail;

    // Setup WebSocket listener for the "message" event
    socket.addEventListener("message", websocketListning);

    // Cleanup event listener on component unmount
    return () => {
      socket.removeEventListener("message", websocketListning);
    };
  }, [allMails, dispatch]); // Dependency array to re-run useEffect if allMails or dispatch changes

  const items = [
    {
      key: "1",
      label: (
        <div onClick={() => handleView("list")}>
          <Icon
            style={{ color: "white !important", fontSize: "16px" }}
            className=""
            icon={"tabler:list"}
          />
          <span> List View</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div onClick={() => handleView("split")}>
          <Icon
            style={{ color: "white !important", fontSize: "16px" }}
            className=""
            icon={"tabler:layout-board-split"}
          />
          <span> Split View</span>
        </div>
      ),
    },
  ];

  //   useEffect(() => {
  //     if (searchData.length > 0) {
  //       setMailData(searchData);
  //       return;
  //     } else if (mailType === "sent") {
  //       setMailData(sentMails);
  //       return;
  //     } else if (mailType === "draft") {
  //       setMailData(draftMails);
  //       return;
  //     } else if (mailType === "inbox" && imported_mail_identifier) {
  //       setMailData(importedMailList);
  //       return;
  //     } else if (mailType === "bin") {
  //       setMailData(binMails);
  //       return;
  //     } else if (mailType === "archived") {
  //       setMailData(archivedMails);
  //       return;
  //     } else if (mailType === "important") {
  //       setMailData(importantMails);
  //       return;
  //     } else if (mailType === "spam") {
  //       setMailData(spamMails);
  //       return;
  //     } else if (mailType === "labels") {
  //       setMailData(labelMails);
  //       return;
  //     } else if (isFiltering) {
  //       setMailData(filterData);
  //       return;
  //     } else {
  //       setMailData(allMails);
  //     }
  //   }, [
  //     searchData,
  //     allMails,
  //     mailType,
  //     filterPagination,
  //     filterData,
  //     isFilters,
  //     draftMails,
  //     importedMailList,
  //     view,
  //     sentMails,
  //     binMails,
  //     draftMails,
  //     archivedMails,
  //     importantMails,
  //     spamMails,
  //     labelMails,
  //   ]);

  const fetchEmailCount = async (mailType) => {
    try {
      setMailType(mailType);
      // setLoading(true);
      const url = `${process.env.REACT_APP_MT_URL}mail/getCounts`;
      const data = {
        [mailType]: "*",
      };

      if (mailType === "bin") {
        data.trash = "*";
      } else if (mailType === "labels") {
        data.current_mail = "*";
        data.labels = label_name || "";
      } else if (mailType === "folder") {
        data.owner_email = "*";
        data.folder =
          folder_name.trim().toLowerCase().replace(/\s+/g, "") || "";
      } else if (mailType === "inbox" && imported_mail_identifier) {
        // const importedMails = localStorage.getItem('im')?JSON.parse(localStorage.getItem('im')):null;
        function findMatch(value) {
          return importedMails?.find((item) => {
            return (
              item.MailLabel === value ||
              item.import_email.split("@")[0] === value
            );
          });
        }
        data.inbox = findMatch(imported_mail_identifier)?.import_email || "";
      }
      const method = "POST";
      await postAPICall(url, data, method, (data) => {
        if (data && data?.totalCount > 0) {
          setTotalMailCount(data?.totalCount);
        }
        // if (data?.emails.length !== 0) {
        //   dispatch(setHasMore(true));
        // } else {
        //   dispatch(setHasMore(false));
        // }

        // if (mailType === "draft") {
        //   dispatch(setDraftMail(data));
        // } else if (mailType === "sent") {
        //   dispatch(setSentMail(data));
        // } else if (mailType === "spam") {
        //   dispatch(setSpamMail(data));
        // } else if (mailType === "bin") {
        //   dispatch(setBinMail(data));
        // } else if (mailType === "archived") {
        //   dispatch(setArchivedMail(data));
        // } else if (mailType === "important") {
        //   dispatch(setImpMail(data));
        // } else if (mailType === "inbox" && !imported_mail_identifier) {
        //   dispatch(setAllEmails(data));
        // } else if (mailType === "labels") {
        //   dispatch(setLabelMail(data));
        // } else if (mailType === "folder") {
        //   dispatch(setFolderMail(data));
        // } else if (mailType === "inbox" && imported_mail_identifier) {
        //   dispatch(setImportedMailInbox(data));
        // }
        // setSearchData(data?.data || []); // Safely accessing data and handling undefined
        // setMailData(data?.data || []);
        // setLoading(false);
      });
    } catch (err) {
      console.error("Error fetching emails", err);
      // setLoading(false);
    }
  };

  //   useEffect(() => {
  //     if (props.sent) {
  //       fetchEmailCount("sent");
  //     } else if (props.draft) {
  //       fetchEmailCount("draft");
  //     } else if (props.bin) {
  //       fetchEmailCount("bin");
  //     } else if (props.archived) {
  //       fetchEmailCount("archived");
  //     } else if (props.important) {
  //       fetchEmailCount("important");
  //     } else if (props.spam) {
  //       fetchEmailCount("spam");
  //     } else if (props.labels) {
  //       fetchEmailCount("labels");
  //     } else if (props.folder) {
  //       fetchEmailCount("folder");
  //     } else if (imported_mail_identifier) {
  //       fetchEmailCount("inbox");
  //       return;
  //     } else if (isFiltering) {
  //       // handleFilterPagination();
  //     } else if (!isSearching) {
  //       fetchEmailCount("inbox");
  //     }
  //   }, [location]);

  const fetchEmails = async (mailType = "inbox") => {
    try {
      setMailType(mailType);
      setLoading(true);

      const url = `${process.env.REACT_APP_MT_URL}mail/search-mail${
        // page > 1 ? "?page=" + page + "&pageSize=20" : ""
        "?page=" + page + `&pageSize=${EMAILS_PER_PAGE}`
      }`;
      const data = {
        [mailType]: "*",
      };

      //   if (mailType === "bin") {
      //     data.trash = "*";
      //   } else if (mailType === "labels") {
      //     data.current_mail = "*";
      //     data.labels = label_name || "";
      //   } else if (mailType === "folder") {
      //     data.owner_email = "*";
      //     data.folder =
      //       folder_name.trim().toLowerCase().replace(/\s+/g, "") || "";
      //   } else if (mailType === "inbox" && imported_mail_identifier) {
      //     // const importedMails = localStorage.getItem('im')?JSON.parse(localStorage.getItem('im')):null;
      //     function findMatch(value) {
      //       return importedMails?.find((item) => {
      //         return (
      //           item.MailLabel === value ||
      //           item.import_email.split("@")[0] === value
      //         );
      //       });
      //     }
      //     data.inbox = findMatch(imported_mail_identifier)?.import_email || "";
      //   }
      const payload = {
        ...(props?.estimateID
          ? { search: props.estimateID , email:userData?.email }
          : { vessel_name: props?.shipName || "", email:userData?.email }),
      };
      if(props?.companyName){
        payload.search = props?.companyName
      }
      await postAPICall(
        url,
        // {"vessel_name": 'Marina'},
        payload,
        "post",
        (data) => {
          if (data.status) {
            setMailData(data?.data);
            setTotalMailCount(data?.totalCount);
          }
          // if (data?.emails.length !== 0) {
          //   dispatch(setHasMore(true));
          // } else {
          //   dispatch(setHasMore(false));
          // }

          // if (mailType === "draft") {
          //   dispatch(setDraftMail(data));
          // } else if (mailType === "sent") {
          //   dispatch(setSentMail(data));
          // } else if (mailType === "spam") {
          //   dispatch(setSpamMail(data));
          // } else if (mailType === "bin") {
          //   dispatch(setBinMail(data));
          // } else if (mailType === "archived") {
          //   dispatch(setArchivedMail(data));
          // } else if (mailType === "important") {
          //   dispatch(setImpMail(data));
          // } else if (mailType === "inbox" && !imported_mail_identifier) {
          //   dispatch(setAllEmails(data));
          // } else if (mailType === "labels") {
          //   dispatch(setLabelMail(data));
          // } else if (mailType === "folder") {
          //   dispatch(setFolderMail(data));
          // } else if (mailType === "inbox" && imported_mail_identifier) {
          //   dispatch(setImportedMailInbox(data));
          // }
          setLoading(false);
        }
      );
    } catch (err) {
      console.error("Error fetching emails", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails("voyageEstimate");
  }, [page]);

  //   useEffect(() => {
  //     const { sent, draft, bin, archived, important, spam, labels, folder } =
  //       props;
  //       const hasData = filterPayload?.query?.length>0  && Object.values(filterPayload?.query?.[0])?.some((field) =>
  //         Array.isArray(field) ? field?.length > 0 : field
  //       );
  //     if (isFiltering||hasData) {
  //       handleFilterPagination();
  //       return;
  //     }

  //     if (isSearching) {
  //       handleSearch(searchText);
  //       return;
  //     }

  //     let emailCategory = "inbox"; // Default to "inbox"

  //     if (sent) {
  //       emailCategory = "sent";
  //     } else if (draft) {
  //       emailCategory = "draft";
  //     } else if (bin) {
  //       emailCategory = "bin";
  //     } else if (archived) {
  //       emailCategory = "archived";
  //     } else if (important) {
  //       emailCategory = "important";
  //     } else if (spam) {
  //       emailCategory = "spam";
  //     } else if (labels) {
  //       emailCategory = "labels";
  //     } else if (folder) {
  //       emailCategory = "folder";
  //     }

  //     if (imported_mail_identifier) {
  //       emailCategory = "inbox";
  //     }
  //     // if (!isFilters) {
  //       // fetchEmails(emailCategory);
  //       fetchEmailCount(emailCategory);
  //       if (!showAiEmails ) {
  //         fetchEmails(emailCategory);
  //       }
  //     // }

  //     return () => {
  //       dispatch(setShowAiMails(false));
  //     };
  //   }, [
  //     props.sent,
  //     props.draft,
  //     props.bin,
  //     props.archived,
  //     props.important,
  //     props.spam,
  //     props.labels,
  //     props.folder,
  //     isSearching,
  //     isFiltering,
  //     searchText,
  //     page,
  //     label_name,
  //     folder_name,
  //     refreshCount,
  //     imported_mail_identifier,
  //     importedMails,
  //     filterPayload,
  //   ]);

  useEffect(() => {
    const MailView = localStorage.getItem("MailView");
    setView(MailView);
  }, []);

  function handleView(view) {
    dispatch(setResizeMail(null));
    localStorage.setItem("MailView", view);
    setView(view);
  }

  // SEARCH IN THE MAILS
  const handleSearch = async (text) => {
    try {
      setLoading(true);
      setSearchtext(text);

      const url = `${process.env.REACT_APP_MT_URL}mail/Search${
        isSearching ? "?page=" + page + "&pageSize=20" : ""
      }`;
      const data = {
        query: text,
        owner_email: "*",
      };
      const method = "POST";
      const resp = await postAPICall(url, data, method, (data) => {
        if (data?.data?.length !== 0) {
          dispatch(setHasMore(true));
          setSearchData(data?.data || []);
          setLoading(false);
          highlightSearchKeywords(mailData, text);
        } else {
          dispatch(setHasMore(false));
        }
      });
    } catch (err) {
      setLoading(false);
      console.error("Error searching emails", err);
    }
  };

  const handleFilterPagination = async (data) => {
    try {
      // setFilterPayload(data);
      setLoading(true);
      const url = `${process.env.REACT_APP_MT_URL}mail/getFIlterTonnageMails?page=${page}&pageSize=${filterPagination.pageSize}`;

      // const payload = {
      //   owner_email: "*",
      //   query: [filterPayload],
      // };
      // console.log({payload})
      const method = "POST";
      const resp = await postAPICall(url, filterPayload, method, (data) => {
        if (data?.emails?.length !== 0) {
          dispatch(setHasMore(true));
          dispatch(setFilterData(data));
          setTotalMailCount(data?.count_after_filter);
          setLoading(false);
        } else {
          setLoading(false);
          dispatch(setFilterData({ emails: [] }));
          // dispatch(setHasMore(false));
          dispatch(setIsFiltering(false));
        }
      });
    } catch (err) {
      setLoading(false);
      console.error("Error searching emails", err);
    }
  };

  const paginationHandler = (action) => {
    if (action === "next" && page * EMAILS_PER_PAGE < totalMailCount) {
      setPage(page + 1);
      setLoading(true);
    } else if (action === "prev" && page > 1) {
      setPage(page - 1);
      setLoading(true);
    }
    // if (isFilters) {
    //   handleFilterPagination();
    // } else {
    //   if (mailType?.trim().length > 0) {
    //     fetchEmails(mailType);
    //   }
    // }
  };

  const handleRefresh = () => {
    if (isFiltering) {
      if (page == 1) {
        handleFilterPagination();
      } else {
        setPage(1);
      }
    } else {
      if (page == 1) {
        fetchEmails(mailType);
      } else {
        setPage(1);
      }
    }
  };

  return (
    <>
      <div className="mail_upper_header">
        <span className="upper_header_btn me-2" onClick={handleRefresh}>
          Refresh
          <Icon
            style={{
              color: "white !important",
              fontSize: "20px",
              paddingLeft: "5px",
            }}
            className=""
            icon={"solar:refresh-bold"}
          />
        </span>
        <div className="mail_header_icons">
          <Tooltip title="Click to change the view">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <div className="upper_header_btn">
                <Icon
                  style={{ color: "white !important", fontSize: "20px" }}
                  className=""
                  icon={"tabler:layout-list"}
                />
              </div>
            </Dropdown>
          </Tooltip>
        </div>
      </div>

      <Spin fullscreen spinning={loading} />

      {/* ////// Conditionally Rendering the VIEWS ////////// */}

      {view === "split" ? (
        <SplitViewDrawer
          mailType={mailType}
          searchData={searchData}
          page={page}
          setPage={setPage}
          handleSearch={handleSearch}
          setRefreshCount={setRefreshCount}
          mailData={mailData}
          setMailData={setMailData}
          totalMailCount={totalMailCount}
          paginationHandler={paginationHandler}
          fetchEmailCount={fetchEmailCount}
        />
      ) : (
        <ListviewDrawer
          mailType={mailType}
          page={page}
          setPage={setPage}
          handleSearch={handleSearch}
          searchData={searchData}
          refreshCount={refreshCount}
          setRefreshCount={setRefreshCount}
          mailData={mailData}
          setMailData={setMailData}
          totalMailCount={totalMailCount}
          paginationHandler={paginationHandler}
          setTotalMailCount={setTotalMailCount}
          fetchEmails={fetchEmails}
          fetchEmailCount={fetchEmailCount}
          setFilterPayload={setFilterPayload}
          isFilters={isFilters}
          setIsfilters={setIsfilters}
          setFilterData={setFilterData}
          filterData={filterData}
        />
      )}
    </>
  );
};

export default MailLayoutDrawer;
