import React, { useEffect, useState } from "react";
import URL_WITH_VERSION, { postAPICall } from "../../..";
import ReactECharts from "echarts-for-react";
import { size } from "lodash";
import { Button, Spin } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import AiLoader from "./AiLoader";
import { DownloadOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const barColors = ["#247291", "#f8da5b", "#35bcbf", "#ff8364"];
const pieColors = ["#40a8c4", "#235784", "#f7aa00", "#de703c"];

const PnlGraph = (props) => {
  const { estimateData } = props;
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const printDocument = () => {
    var quotes = document.getElementById("documentdownload");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 30;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 30,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("P&L Analysis.pdf");
    });
  };

  const getData = async () => {
    try {
      setLoading(true);
      const url = `${URL_WITH_VERSION}/recommendation_bp/pnl_analytics`;
      const payload = estimateData;
      const resp = await postAPICall(url, payload, "POST");
      // console.log("resp", resp);
      if (resp?.status == 200) {
        setGraphData(resp?.data?.graph_queries);
        setText(resp?.data?.summary);
        setLoading(false);
      } else {
        console.log("error connecting");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateChartOptions = (data) => {
    const { graph_type, description, x_axis, y_axis, total } = data;
    switch (graph_type) {
      case "bar":
        return {
          title: {
            text: description,
            size: "15px",
            left: "center",
            width: "100%",
            textStyle: {
              fontSize: 11,
            },
            subtextStyle: {
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter: (params) => {
              const { name, value } = params[0];
              return `${name}: ${value}`;
            },
          },
          xAxis: {
            type: "category",
            data: x_axis,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: (value) => {
                if (value >= 1000) {
                  return `${value / 1000}k`;
                }
                return value;
              },
            },
          },

          series: [
            {
              data: y_axis.map((value, index) => ({
                value,
                itemStyle: {
                  color: barColors[index % barColors.length],
                  shadowBlur: 25,
                  shadowOffsetX: 8,
                  shadowOffsetY: 8,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: [5, 5, 0, 0],
                  backgroundColor: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 1,
                    colorStops: [
                      { offset: 0, color: barColors[index % barColors.length] },
                      { offset: 1, color: "#ffffff" },
                    ],
                    global: false,
                  },
                },
              })),
              type: "bar",
              barWidth: "40%",
              label: {
                show: true,
                position: "top",
                fontSize: 12,
                color: "#333",
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 30,
                  shadowOffsetX: 10,
                  shadowOffsetY: 10,
                  shadowColor: "rgba(0, 0, 0, 0.6)",
                },
              },
            },
          ],
        };
      case "pie":
        return {
          title: {
            text: description,
            subtext: `{total|Total: ${total}}`,
            size: "15px",
            left: "center",
            width: "100%",
            textStyle: {
              fontSize: 11,
            },
            subtextStyle: {
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              rich: {
                total: {
                  color: "#ec729c",
                  fontSize: 14,
                  fontWeight: "bold",
                },
              },
            },
          },
          tooltip: {
            trigger: "item",
          },
          graphic: {
            type: "text",
            left: "center",
            top: "middle",
            style: {
              text: `Total\n${total}`,
              textAlign: "center",
              fill: "#333",
              fontSize: 16,
              fontWeight: "bold",
            },
          },
          series: [
            {
              name: description,
              type: "pie",
              radius: "50%",
              data: x_axis.map((label, index) => ({
                value: y_axis[index],
                name: label,
                itemStyle: {
                  color: pieColors[index % pieColors.length],
                  borderColor: "#fff",
                  borderWidth: 2,
                },
              })),
              label: {
                show: true,
                formatter: (params) => {
                  // Display value inside and name outside
                  if (params.position === "inside") {
                    return `{value|${params.value}}`; // Value inside
                  }
                  return `{name|${params.name}}`; // Name outside
                },
                rich: {
                  name: {
                    fontSize: 12,
                    color: "#333",
                    fontWeight: "normal",
                  },
                  value: {
                    fontSize: 12,
                    color: "#fff",
                    fontWeight: "bold",
                  },
                },
              },
              labelLine: {
                show: true,
                length: 15, // Line leading from pie slice
                length2: 10, // Line leading to label text
                smooth: true, // Smooth the label lines
                lineStyle: {
                  width: 1,
                  color: "#ccc",
                },
              },
              itemStyle: {
                borderRadius: 10,
                shadowColor: "rgba(0, 0, 0, 0.3)",
              },
            },
          ],
        };

      case "line":
        return {
          title: {
            text: description,
            size: "15px",
            left: "center",
            width: "100%",
            textStyle: {
              fontSize: 14,
            },
            subtextStyle: {
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          },
          xAxis: {
            type: "category",
            data: x_axis,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: (value) => {
                if (value >= 1000) {
                  return `${value / 1000}k`;
                }
                return value;
              },
            },
          },
          series: [
            {
              data: y_axis,
              type: "line",
              smooth: true,
              areaStyle: {},
              label: {
                show: true,
                position: "top",
              },
              lineStyle: {
                width: 2,
                color: "#ec729c",
              },
            },
          ],
        };

      case "two_line":
        return {
          title: {
            text: description,
            left: "center",
            size: "15px",
            width: "100%",
            textStyle: {
              fontSize: 11,
            },
            subtextStyle: {
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          },
          xAxis: {
            type: "category",
            data: x_axis,
            axisLabel: {
              interval: 0,
              rotate: 30,
              align: "right",
              verticalAlign: "top",
              margin: 10,
              formatter: (value) => {
                const maxLength = 10;
                return value.length > maxLength
                  ? `${value.slice(0, maxLength)}...`
                  : value;
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: (value) => {
                if (value >= 1000 || value <= -1000) {
                  return `${value / 1000}k`;
                }
                return value;
              },
            },
          },
          series: [
            {
              name: "Actual",
              data: y_axis.actual,
              type: "line",
              smooth: true,
              label: {
                show: true,
                position: "bottom",
              },
              lineStyle: {
                width: 3,
                color: "#ec3d7a",
              },
            },
            {
              name: "Estimated",
              data: y_axis.estimated,
              type: "line",
              smooth: true,
              label: {
                show: true,
                position: "bottom",
              },
              lineStyle: {
                width: 3,
                color: "#3590eb",
              },
            },
            {
              name: "Actual Bars",
              data: y_axis.actual,
              type: "bar",
              barWidth: "20%",
              itemStyle: {
                color: "#ec729c",
              },
              label: {
                show: false,
              },
              tooltip: {
                show: false,
              },
            },
            {
              name: "Estimated Bars",
              data: y_axis.estimated,
              type: "bar",
              barWidth: "20%",
              itemStyle: {
                color: "#73b9ff",
              },
              label: {
                show: false,
              },
              tooltip: {
                show: false,
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "line",
            },
            formatter: (params) => {
              const xAxisLabel = params[0].axisValue; // Get the x-axis value from the params
              const content = params
                .map((item) => {
                  return `
                      <div style="display: flex; align-items: center; margin-bottom: 5px;">
                        <span style="width: 10px; height: 10px; background-color: ${item.color}; border-radius: 50%; display: inline-block; margin-right: 5px;"></span>
                        <span>${item.seriesName}: ${item.value}</span>
                      </div>`;
                })
                .join("");
              return `
                  <div>
                    <div style="font-weight: bold; margin-bottom: 5px;">${xAxisLabel}</div>
                    ${content}
                  </div>`;
            },
          },

          legend: {
            data: [
              {
                name: "Actual",
                icon: "circle",
                itemStyle: {
                  color: "#ec3d7a",
                },
              },
              {
                name: "Estimated",
                icon: "circle",
                itemStyle: {
                  color: "#3590eb",
                },
              },
            ],
            top: "10%",
          },
        };

      case "two_line_bar":
        return {
          title: {
            text: description,
            left: "center",
            size: "15px",
            width: "100%",
            textStyle: {
              fontSize: 11,
            },
            subtextStyle: {
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          },
          xAxis: {
            type: "category",
            data: x_axis,
            axisLabel: {
              interval: 0,
              rotate: 30,
              align: "right",
              verticalAlign: "top",
              margin: 10,
              formatter: (value) => {
                const maxLength = 10;
                return value.length > maxLength
                  ? `${value.slice(0, maxLength)}...`
                  : value;
              },
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: (value) => {
                if (value >= 1000 || value <= -1000) {
                  return `${value / 1000}k`;
                }
                return value;
              },
            },
          },
          series: [
            {
              name: "Actual",
              data: y_axis.actual,
              type: "line",
              smooth: true,
              label: {
                show: true,
                position: "bottom",
              },
              lineStyle: {
                width: 4,
                color: "#247291",
              },
            },
            {
              name: "Estimated",
              data: y_axis.estimated,
              type: "line",
              smooth: true,
              label: {
                show: true,
                position: "bottom",
              },
              lineStyle: {
                width: 4,
                color: "#f8da5b",
              },
            },
            {
              name: "Actual Bars",
              data: y_axis.actual,
              type: "bar",
              barWidth: "20%",
              itemStyle: {
                color: "#247291",
              },
              label: {
                show: false,
              },
              tooltip: {
                show: false,
              },
            },
            {
              name: "Estimated Bars",
              data: y_axis.estimated,
              type: "bar",
              barWidth: "20%",
              itemStyle: {
                color: "#f8da5b",
              },
              label: {
                show: false,
              },
              tooltip: {
                show: false,
              },
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "line",
            },
            formatter: (params) => {
              const xAxisLabel = params[0].axisValue; // Get the x-axis value from the params
              const content = params
                .map((item) => {
                  return `
                      <div style="display: flex; align-items: center; margin-bottom: 5px;">
                        <span style="width: 10px; height: 10px; background-color: ${item.color}; border-radius: 50%; display: inline-block; margin-right: 5px;"></span>
                        <span>${item.seriesName}: ${item.value}</span>
                      </div>`;
                })
                .join("");
              return `
                  <div>
                    <div style="font-weight: bold; margin-bottom: 5px;">${xAxisLabel}</div>
                    ${content}
                  </div>`;
            },
          },

          legend: {
            data: [
              {
                name: "Actual",
                icon: "circle",
                itemStyle: {
                  color: "#247291",
                },
              },
              {
                name: "Estimated",
                icon: "circle",
                itemStyle: {
                  color: "#f8da5b",
                },
              },
            ],
            top: "10%",
          },
        };

      default:
        return {};
    }
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Arrange items in a column
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          {/* <div
               style={{
                 animation: "spin 1s linear infinite",
               }}
             >
               <Icon icon="fluent-emoji:robot" style={{ width: "50px", height: "50px" }} />
             </div>
             <div style={{ marginTop: "10px", fontSize: "16px", color: "#1a80df", fontWeight:"bold" }}>
               AI Analyzing...
             </div> */}
          {/* <Spin /> */}
          <AiLoader text={"AI Analysing..."} />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.3rem",
            }}
          >
            <Button icon={<ReloadOutlined />} onClick={getData}>
              Refresh
            </Button>
            <Button icon={<DownloadOutlined />} onClick={printDocument}>
              Download
            </Button>
          </div>
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <div
              id="documentdownload"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1rem",
              }}
            >
              {Array.isArray(graphData) && graphData?.length>0 && graphData?.map((data, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <ReactECharts
                    option={generateChartOptions(data)}
                    style={{ height: "300px", width: "70%" }}
                  />
                </div>
              ))}
            </div>
            <h2>Summary</h2>
            <div style={{ fontSize: "20px" }}>{text}</div>
          </div>
        </>
      )}
    </>
  );
};

export default PnlGraph;
