import React, { useEffect } from "react";
import URL_WITH_VERSION, {
  ResizeableTitle,
  getAPICall,
  objectToQueryStringFunc,
  useStateCallback,
} from "../../shared";
import { Modal, Table } from "antd";
import ToolbarUI from "../../components/CommonToolbarUI/toolbar_index";
import { FIELDS } from "../../shared/tableFields";
import { EditOutlined } from "@ant-design/icons";
import GenerateCargoEnquiry from "../../routes/chartering/routes/tcov/modals/GenerateCargoEnquiry";
import SidebarColumnFilter from "../../shared/SidebarColumnFilter";
import redux_state from "../../services/redux_state";

const CargoEnquiryTemplates = (props) => {
  // console.log("props", props);
  const [state, setState] = useStateCallback({
    loading: false,
    columns: [],
    responseData: [],
    pageOptions: { pageIndex: 1, pageLimit: 20, totalRows: 0 },
    isAdd: true,
    isVisible: false,
    sidebarVisible: false,
    formData: {},
    invoceModal: false,
    selectedListData: {},
    typesearch: {},
    donloadArray: [],
    isGraphModal: false,
    ClusterDataxAxis: [],
    clusterMinMax: [],
    ClusterDataSeries: [],
    sortedData: [],
    LineCharSeriesData: [],
    LineCharxAxisData: [],
    DoughNutChartSeriesData: [],
    PieChartData: [],
    allVessels: null,
    selectedFilter: null,
    filteredSelectedData: [],
    lastfilter: "",
    isCargoEnquireModal: false,
    id: null,
  });

  useEffect(() => {
    const tableAction = {
      title: "Action",
      key: "action",
      fixed: "right",
      // width: 100,
      width: 70,
      render: (text, record) => {
        return (
          <div className="editable-row-operations">
            <span
              className="iconWrapper"
              onClick={(e) => redirectToAdd(e, record)}
            >
              <EditOutlined />
            </span>
          </div>
        );
      },
    };
    let tableHeaders = Object.assign(
      [],
      FIELDS && FIELDS["cargo-enquiry-templates-list"]
        ? FIELDS["cargo-enquiry-templates-list"]["tableheads"]
        : []
    );
    tableHeaders.push(tableAction);

    setState(
      (prevState) => ({ ...prevState, columns: tableHeaders }),
      () => {
        getTableData();
      }
    );
  }, [props?.estimateID]);

  const components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  const getTableData = async (searchtype = {}) => {
    const { pageOptions } = state;
    let qParams = { p: pageOptions.pageIndex, l: pageOptions.pageLimit };
    let headers = { order_by: { id: "desc" } };
    // let qParamsGraph = { p: pageOptions.pageIndex, l: 0 }
    let _search =
      searchtype &&
      searchtype.hasOwnProperty("searchOptions") &&
      searchtype.hasOwnProperty("searchValue")
        ? searchtype
        : state.typesearch;

    if (
      _search &&
      _search.hasOwnProperty("searchValue") &&
      _search.hasOwnProperty("searchOptions") &&
      _search["searchOptions"] !== "" &&
      _search["searchValue"] !== ""
    ) {
      let wc = {};
      _search["searchValue"] = _search["searchValue"].trim();

      if (_search["searchOptions"].indexOf(";") > 0) {
        let so = _search["searchOptions"].split(";");
        wc = { OR: {} };
        so.map((e) => (wc["OR"][e] = { l: _search["searchValue"] }));
      } else {
        wc = {
          OR: { [_search["searchOptions"]]: { l: _search["searchValue"] } },
        };
      }

      if (headers.hasOwnProperty("where")) {
        // If "where" property already exists, merge the conditions
        headers["where"] = { ...headers["where"], ...wc };
      } else {
        // If "where" property doesn't exist, set it to the new condition
        headers["where"] = wc;
      }

      state.typesearch = {
        searchOptions: _search.searchOptions,
        searchValue: _search.searchValue,
      };
    }

    setState((prevState) => ({
      ...prevState,
      loading: true,
      responseData: [],
    }));

    let qParamString = objectToQueryStringFunc(qParams);
    let _state = {},
      dataArr = [],
      totalRows = 0;
    let formData1 = redux_state.reduxStore?.["tcov_full_estimate_form"];
    const { estimate_id } = formData1;
    const payload = { ...headers, tcov_id: estimate_id };

    let _url = `${URL_WITH_VERSION}/voyagecargo/cargo-enquiry-list?${qParamString}`;
    const response = props.estimateID
      ? await getAPICall(_url, payload, headers)
      : [];
    const data = await response;
    totalRows = data && data.total_rows ? data.total_rows : 0;
    dataArr = data && data.data ? data.data : [];
    // console.log("sataArr", dataArr);
    // let filtered = [...dataArr].sort((a, b) => {
    //   return (
    //     parseInt(b.grand_total.replace(/,/g, "")) -
    //     parseInt(a.grand_total.replace(/,/g, ""))
    //   );
    // });

    let fuel = {
      ULSFO: 0,
      MGO: 0,
      LSMGO: 0,
      IFO: 0,
      VLSFO: 0,
    };
    let allVessels = new Set();
    let allInvoices = [];
    if (dataArr && dataArr.length > 0) {
      dataArr.map((e) => {
        if (e.vessel_name) allVessels.add(e.vessel_name);
        if (e.invoice_no) {
          allInvoices.push({ label: e.invoice_no, value: e.invoice_no });
        }

        let fuel_type = "";
        let i_qty = 0;
        e["...."] &&
          e["...."].length > 0 &&
          e["...."].map((val) => {
            if (val.fuel_type) {
              fuel[val.fuel_type] += parseFloat(val.invoice_qty);
            }
            fuel_type =
              fuel_type != "" && fuel_type != null
                ? fuel_type + ", " + val.fuel_type
                : val.fuel_type != null && val.fuel_type;
            i_qty += parseFloat(val.invoice_qty);
          });
        e["fuel_type"] = fuel_type;
        e["i_qty"] = i_qty;
        // fuel[fuel_type] += i_qty
      });
    }

    let vessels = [];
    for (let vessel of allVessels) {
      vessels.push({ label: vessel, value: vessel });
    }

    let DoughNut = [];
    for (let key in fuel) {
      DoughNut.push({ value: fuel[key], name: key + " " + fuel[key] + " MT" });
    }

    let donloadArr = [];
    if (dataArr.length > 0 && totalRows > 0) {
      dataArr.forEach((d) => donloadArr.push(d["id"]));
      _state["responseData"] = dataArr;
    }

    // setGraphData(filtered)
    let slicedData = [];
    let amount = [];
    let series = [
      {
        name: "Total Amount",
        type: "bar",
        // barGap: 0,

        data: [],
      },
    ];
    let invoiceNo = [];
    let minMax = [];
    // for (let i = 0; i < 5; i++) {
    //   slicedData.push(filtered[i].vessel_name);
    //   series[0].data.push(parseInt(filtered[i].grand_total.replace(/,/g, "")));
    //   amount.push(parseInt(filtered[i].grand_total.replace(/,/g, "")));
    //   invoiceNo.push(filtered[i].invoice_no);
    // }

    setState((prevState) => ({
      ...prevState,
      ..._state,
      donloadArray: donloadArr,
      // sortedData: filtered,
      pageOptions: {
        pageIndex: pageOptions.pageIndex,
        pageLimit: pageOptions.pageLimit,
        totalRows: totalRows,
      },
      DoughNutChartSeriesData: [...DoughNut],
      allVessels: vessels,
      allInvoices: allInvoices,
      ClusterDataxAxis: slicedData,
      ClusterDataSeries: series,
      LineCharSeriesData: [...amount],
      LineCharxAxisData: [...invoiceNo],
      clusterMinMax: [
        series.length && series[0].data[4],
        series.length && series[0].data[0],
      ],
      loading: false,
    }));
  };

  const InvoiceModal = (st, data) => {
    setState((prevState) => ({
      ...prevState,
      isCargoEnquireModal: st,
      id: data.id,
    }));
  };

  const closeModale = (st, data) => {
    setState(
      (prevState) => ({
        ...prevState,
        invoceModal: st,
        selectedListData: data,
      }),
      () => {
        getTableData();
      }
    );
  };

  const redirectToAdd = async (e, record = null) => {
    if (record.id) {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/voyagecargo/cargo-enquiry-edit?ae=${record.id}`
      );

      let respData = response.data;
      setState((prevState) => ({
        ...prevState,
        formData: respData,
        isCargoEnquireModal: true,
        id: record.id,
      }));
    }
    InvoiceModal(true, record);
  };

  const onCancel = () => {
    getTableData();
    setState((prevState) => ({ ...prevState, isAdd: true, isVisible: false }));
  };

  const callOptions = (evt) => {
    let _search = {
      searchOptions: evt["searchOptions"],
      searchValue: evt["searchValue"],
      isAll: evt["isAll"],
    };
    if (
      evt.hasOwnProperty("searchOptions") &&
      evt.hasOwnProperty("searchValue")
    ) {
      let pageOptions = state.pageOptions;

      pageOptions["pageIndex"] = 1;
      setState(
        (prevState) => ({
          ...prevState,
          search: _search,
          pageOptions: pageOptions,
        }),
        () => {
          getTableData(_search);
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "reset-serach"
    ) {
      let pageOptions = state.pageOptions;
      pageOptions["pageIndex"] = 1;

      setState(
        (prevState) => ({ ...prevState, search: {}, pageOptions: pageOptions }),
        () => {
          getTableData();
        }
      );
    } else if (
      evt &&
      evt.hasOwnProperty("actionName") &&
      evt["actionName"] === "column-filter"
    ) {
      // column filtering show/hide
      let responseData = state.responseData;
      let columns = Object.assign([], state.columns);

      if (responseData.length > 0) {
        for (var k in responseData[0]) {
          let index = columns.some(
            (item) =>
              (item.hasOwnProperty("dataIndex") && item.dataIndex === k) ||
              (item.hasOwnProperty("key") && item.key === k)
          );
          if (!index) {
            let title = k
              .split("_")
              .map((snip) => {
                return snip[0].toUpperCase() + snip.substring(1);
              })
              .join(" ");
            let col = Object.assign(
              {},
              {
                title: title,
                dataIndex: k,
                key: k,
                invisible: "true",
                isReset: true,
              }
            );
            columns.splice(columns.length - 1, 0, col);
          }
        }
      }

      setState((prevState) => ({
        ...prevState,
        sidebarVisible: evt.hasOwnProperty("sidebarVisible")
          ? evt.sidebarVisible
          : !prevState.sidebarVisible,
        columns: evt.hasOwnProperty("columns") ? evt.columns : columns,
      }));
    } else {
      let pageOptions = state.pageOptions;
      pageOptions[evt["actionName"]] = evt["actionVal"];

      if (evt["actionName"] === "pageLimit") {
        pageOptions["pageIndex"] = 1;
      }

      setState(
        (prevState) => ({ ...prevState, pageOptions: pageOptions }),
        () => {
          getTableData();
        }
      );
    }
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return { ...state, columns: nextColumns };
      });
    };

  const onActionDonwload = async (downType, pageType) => {
    const token = localStorage.getItem("oceanToken");
    let params = `t=${pageType}`,
      cols = [];
    const { columns, pageOptions, donloadArray } = state;

    let qParams = { p: pageOptions.pageIndex, l: pageOptions.pageLimit };

    // Collect visible columns except those with key "action"
    columns.forEach((e) => {
      if (e.invisible === "false" && e.key !== "action") {
        cols.push(e.dataIndex);
      }
    });

    if (cols && cols.length > 0) {
      params = params + "&c=" + cols.join(",");
    }

    const filter = donloadArray.join(",");
    const url = `${process.env.REACT_APP_ATTACHMENT}/download/file/${downType}?${params}&l=${qParams.l}&ids=${filter}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      const urlBlob = window.URL.createObjectURL(blob);
      link.href = urlBlob;
      link.download = `Cargo_Enquiry_Template.${downType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error("There was an error with the fetch operation:", error);
    }
  };

  const tableCol = state?.columns
    ?.filter((col) => (col && col.invisible !== "true" ? true : false))
    .map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
    }));

  const onclose = () => {
    setState((prevState) => ({ ...prevState, isCargoEnquireModal: false }));
    getTableData();
  };

  return (
    <>
      <div className="body-wrapper">
        <article className="article">
          <div className="box box-default">
            <div className="box-body">
              <div className="form-wrapper">
                <div className="form-heading">
                  <h4 className="title">
                    {/* <span>Cargo Enquiry Templates</span> */}
                  </h4>
                </div>
                {/* <div className="action-btn">
                  <Link to="add-tcov">Add Bunker Invoice</Link>
                </div> */}
              </div>
              <div
                className="section"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                {state.loading === false ? (
                  <ToolbarUI
                    routeUrl={"cargo-enquiry-list-toolbar"}
                    optionValue={{
                      pageOptions: state.pageOptions,
                      columns: state.columns,
                      search: state.search,
                    }}
                    // showGraph={showGraphs}
                    callback={(e) => callOptions(e)}
                    dowloadOptions={[
                      {
                        title: "CSV",
                        event: () => onActionDonwload("csv", "cargo-enquiry"),
                      },
                      {
                        title: "PDF",
                        event: () => onActionDonwload("pdf", "cargo-enquiry"),
                      },
                      {
                        title: "XLS",
                        event: () => onActionDonwload("xlsx", "cargo-enquiry"),
                      },
                    ]}
                  />
                ) : undefined}
              </div>
              <div>
                <Table
                  // rowKey={record => record.id}
                  className="inlineTable resizeableTable"
                  bordered
                  columns={tableCol}
                  // components={components}
                  size="small"
                  scroll={{ x: "max-content" }}
                  dataSource={state.responseData}
                  loading={state.loading}
                  pagination={false}
                  rowClassName={(r, i) =>
                    i % 2 === 0
                      ? "table-striped-listing"
                      : "dull-color table-striped-listing"
                  }
                />
              </div>
            </div>
          </div>
        </article>

        {state.isVisible === true ? (
          <>Add TCOV</>
        ) : /* <Modal
            title={(isAdd===false?"Edit":"Add")+" TCI Form"}
           open={isVisible}
            width='95%'
            onCancel={onCancel}
            style={{top: '10px'}}
            bodyStyle={{ height: 790, overflowY: 'auto', padding: '0.5rem' }}
            footer={null}
          >
            {
              isAdd === false ?
              <TCI formData={formDataValues} modalCloseEvent={onCancel} />
              :
              <TCI formData={{}} modalCloseEvent={onCancel} />
            }
          </Modal> */
        undefined}
        {/* column filtering show/hide */}
        {state.sidebarVisible ? (
          <SidebarColumnFilter
            columns={state.columns}
            sidebarVisible={state.sidebarVisible}
            callback={(e) => callOptions(e)}
          />
        ) : null}
        {/* {state.invoceModal && (
          <Modal
            style={{ top: "2%" }}
            title="Edit Bunker Invoice"
            open={state.invoceModal}
            onCancel={() => closeModale(false, null)}
            width="90%"
            footer={null}
          >
            <BunkerInvoice
              closeModale={() => {
                closeModale(false, null);
              }}
              isEdit={true}
              formData={state.selectedListData}
            />
          </Modal>
        )} */}
        {state.isCargoEnquireModal && (
          <Modal
            style={{ top: "2%" }}
            title="Cargo Enquiry Templates"
            open={state.isCargoEnquireModal}
            // onCancel={() =>
            //   setState((prevState) => ({ ...prevState, isCargoEnquireModal: false }))
            // }
            onCancel={onclose}
            width="80%"
            footer={null}
          >
            <GenerateCargoEnquiry formData={state.formData} id={state.id} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default CargoEnquiryTemplates;
