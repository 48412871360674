import React from "react";
import { Row, Col, Typography, Tag, Card} from "antd";
// import { chipStyles } from 'src/constant/chipStyles';
import { chipStyles } from "../constants";

const Tags = () => {




  const Chartering = {
    "sections": [
      {
        "title": "Vessel Information",
        "fields": [
          {
            "label": "Vessel Name",
            "key": "vesselName"
          },
          {
            "label": "IMO",
            "key": "IMO"
          },
          {
            "label": "Draft",
            "key": "draft"
          },
          {
            "label": "DWT",
            "key": "DWT"
          },
          {
            "label": "Beam",
            "key": "beam"
          },
          {
            "label": "Loa",
            "key": "loa"
          },
          {
            "label": "Build Year",
            "key": "buildYear"
          }
        ]
      },
      {
        "title": "Cargo Details",
        "fields": [
          {
            "label": "Cargo Name",
            "key": "cargoName"
          },
          {
            "label": "Load Port",
            "key": "loadPort"
          },
          {
            "label": "Discharge Port",
            "key": "dischargePort"
          },
          {
            "label": "Quantity (Qty)",
            "key": "quantity"
          },
          {
            "label": "Load Rate",
            "key": "loadRate"
          },
          {
            "label": "Discharge Rate",
            "key": "dischargeRate"
          }
        ]
      },
      {
        "title": "Contracts and Rates",
        "fields": [
          {
            "label": "Charter Party",
            "key": "charterParty"
          },
          {
            "label": "Hire",
            "key": "hire"
          },
          {
            "label": "Freight",
            "key": "freight"
          },
          {
            "label": "Address Commission",
            "key": "addressCommission"
          },
          {
            "label": "Load Rate",
            "key": "loadRate"
          },
          {
            "label": "Redelivery Date",
            "key": "redeliveryDate"
          },
          {
            "label": "Delivery Date",
            "key": "deliveryDate"
          }
        ]
      },
      {
        "title": "Status Tracking",
        "fields": [
          {
            "label": "Open Area",
            "key": "openArea"
          },
          {
            "label": "Vessel Anchored",
            "key": "vesselAnchored"
          },
          {
            "label": "Vessel Delivered",
            "key": "vesselDelivered"
          },
          {
            "label": "Redelivery Status",
            "key": "redeliveryStatus"
          }
        ]
      },
      {
        "title": "Operational Terms",
        "fields": [
          {
            "label": "Laycan",
            "key": "laycan"
          },
          {
            "label": "TCT",
            "key": "tct"
          },
          {
            "label": "Duration",
            "key": "duration"
          },
          {
            "label": "Add Com",
            "key": "addCom"
          },
          {
            "label": "Cranes",
            "key": "cranes"
          },
          {
            "label": "Hold and Hatches",
            "key": "holdAndHatches"
          }
        ]
      }
    ]
  }


  const opf = {
    "sections": [
      {
        "title": "Invoices and Payments",
        "fields": [
          { "label": "Freight Invoice", "key": "freightInvoice" },
          { "label": "Bunker Invoice", "key": "bunkerInvoice" },
          { "label": "Hire Invoice", "key": "hireInvoice" },
          { "label": "PDA Invoice", "key": "pdaInvoice" },
          { "label": "FDA Invoice", "key": "fdaInvoice" },
          { "label": "Claim Invoice", "key": "claimInvoice" },
          { "label": "Brokerage Invoice", "key": "brokerageInvoice" },
          { "label": "Credit Note", "key": "creditNote" },
          { "label": "Debit", "key": "debit" },
          { "label": "Payable", "key": "payable" },
          { "label": "Receivable", "key": "receivable" }
        ]
      },
      {
        "title": "Voyage Management",
        "fields": [
          { "label": "Voyage No", "key": "voyageNo" },
          { "label": "CP Date", "key": "cpDate" },
          { "label": "Fixture No", "key": "fixtureNo" },
          { "label": "B/L", "key": "bl" },
          { "label": "Pilot", "key": "pilot" },
          { "label": "Fixture Note", "key": "fixtureNote" },
          { "label": "Voyage Schedule", "key": "voyageSchedule" },
          { "label": "Voyage Instruction", "key": "voyageInstruction" }
        ]
      },
      {
        "title": "Status Tracking",
        "fields": [
          { "label": "ETA", "key": "eta" },
          { "label": "ETD", "key": "etd" },
          { "label": "Departure Date", "key": "departureDate" },
          { "label": "Arrival Date", "key": "arrivalDate" },
          { "label": "Redelivery Notice", "key": "redeliveryNotice" },
          { "label": "PFHS", "key": "pfhs" },
          { "label": "FFA", "key": "ffa" },
          { "label": "Delivery Notice", "key": "deliveryNotice" },
          { "label": "Charter Party", "key": "charterParty" }
        ]
      },
      {
        "title": "Compliance and Reports",
        "fields": [
          { "label": "Noon Report", "key": "noonReport" },
          { "label": "Survey Invoice", "key": "surveyInvoice" },
          { "label": "NOR", "key": "nor" },
          { "label": "SOF (Statement of Fact)", "key": "sof" },
          { "label": "PFHS", "key": "pfhsCompliance" },
          { "label": "FDA Due", "key": "fdaDue" },
          { "label": "Port DA", "key": "portDA" },
          { "label": "Port Disbursement", "key": "portDisbursement" }
        ]
      }
    ]
  }


  const shipingManagment = {
    "sections": [
      {
        "title": "Crew and Compliance",
        "fields": [
          { "label": "Crew Rotation", "key": "crewRotation" },
          { "label": "Seafarer Certificates", "key": "seafarerCertificates" },
          { "label": "Crew Sign-On/Off", "key": "crewSignOnOff" }
        ]
      },
      {
        "title": "Ship Maintenance",
        "fields": [
          { "label": "Dry Docking", "key": "dryDocking" },
          { "label": "Technical Maintenance", "key": "technicalMaintenance" },
          { "label": "Flag State Compliance", "key": "flagStateCompliance" },
          { "label": "Health and Safety", "key": "healthAndSafety" }
        ]
      },
      {
        "title": "Performance and Training",
        "fields": [
          { "label": "Vessel Performance", "key": "vesselPerformance" },
          { "label": "ISM/ISPS Compliance", "key": "ismIspsCompliance" },
          { "label": "Training Schedules", "key": "trainingSchedules" },
          { "label": "Onboard Drills", "key": "onboardDrills" }
        ]
      },
      {
        "title": "Payroll",
        "fields": [
          { "label": "Payroll Processing", "key": "payrollProcessing" },
          { "label": "Crew Welfare", "key": "crewWelfare" }
        ]
      }
    ]
  }

  const comodity = {
    "sections": [
      {
        "title": "Trade Operations",
        "fields": [
          { "label": "Cargo Allocation", "key": "cargoAllocation" },
          { "label": "Hedge Positions", "key": "hedgePositions" },
          { "label": "Pricing Updates", "key": "pricingUpdates" },
          { "label": "Trade Confirmation", "key": "tradeConfirmation" }
        ]
      },
      {
        "title": "Shipment Management",
        "fields": [
          { "label": "Shipment Schedules", "key": "shipmentSchedules" },
          { "label": "Load Port", "key": "loadPort" },
          { "label": "Discharge Port", "key": "dischargePort" },
          { "label": "Cargo Status", "key": "cargoStatus" }
        ]
      },
      {
        "title": "Market Insights",
        "fields": [
          { "label": "Commodity Prices", "key": "commodityPrices" },
          { "label": "Market Trends", "key": "marketTrends" },
          { "label": "Demand & Supply Data", "key": "demandSupplyData" }
        ]
      },
      {
        "title": "Invoice and Financials",
        "fields": [
          { "label": "Payment Due Dates", "key": "paymentDueDates" },
          { "label": "Freight Costs", "key": "freightCosts" },
          { "label": "Claim Invoices", "key": "claimInvoices" },
          { "label": "Overdue Alerts", "key": "overdueAlerts" }
        ]
      }
    ]
  }

  const freight = {
    "sections": [
      {
        "title": "Shipment Details",
        "fields": [
          { "label": "Bill of Lading (B/L)", "key": "billOfLading" },
          { "label": "Shipment Schedules", "key": "shipmentSchedules" },
          { "label": "Container Tracking", "key": "containerTracking" },
          { "label": "POD (Proof of Delivery)", "key": "pod" }
        ]
      },
      {
        "title": "Status Updates",
        "fields": [
          { "label": "Loading Stop", "key": "loadingStop" },
          { "label": "Discharging Start", "key": "dischargingStart" },
          { "label": "Port Congestion", "key": "portCongestion" },
          { "label": "Arrival/Departure Dates", "key": "arrivalDepartureDates" }
        ]
      },
      {
        "title": "Invoicing and Finance",
        "fields": [
          { "label": "Freight Invoices", "key": "freightInvoices" },
          { "label": "Customs Charges", "key": "customsCharges" },
          { "label": "PDA/FDA", "key": "pdaFda" },
          { "label": "Demurrage and Detention Notices", "key": "demurrageDetentionNotices" }
        ]
      },
      {
        "title": "Coordination",
        "fields": [
          { "label": "Port Agent Communication", "key": "portAgentCommunication" },
          { "label": "Load Port/Discharge Port Notifications", "key": "loadPortDischargePortNotifications" },
          { "label": "ETA/ETD Updates", "key": "etaEtdUpdates" }
        ]
      }
    ]
  }


  const oilAndGas = {
    "sections": [
      {
        "title": "Cargo Management",
        "fields": [
          { "label": "Cargo Allocation", "key": "cargoAllocation" },
          { "label": "Pricing Updates", "key": "pricingUpdates" },
          { "label": "Trade Confirmation", "key": "tradeConfirmation" }
        ]
      },
      {
        "title": "Operational Details",
        "fields": [
          { "label": "Shipment Schedules", "key": "shipmentSchedules" },
          { "label": "Loading Start", "key": "loadingStart" },
          { "label": "Loading Stop", "key": "loadingStop" },
          { "label": "Quality Reports", "key": "qualityReports" }
        ]
      }
    ]
  }


  const subcategory = {
    "sections": [
      {
        "title": "Status Updates",
        "fields": [
          { "label": "Vessel Open Position", "key": "vesselOpenPosition" },
          { "label": "Port Congestion", "key": "portCongestion" },
          { "label": "Weather Updates", "key": "weatherUpdates" },
          { "label": "Laycan Notifications", "key": "laycanNotifications" }
        ]
      },
      {
        "title": "Payment Alerts",
        "fields": [
          { "label": "Due Date", "key": "dueDate" },
          { "label": "Overdue Payments", "key": "overduePayments" },
          { "label": "Financial Follow-ups", "key": "financialFollowUps" },
          { "label": "Customs Clearances", "key": "customsClearances" }
        ]
      },
      {
        "title": "Compliance Alerts",
        "fields": [
          { "label": "CII Tracking", "key": "ciiTracking" },
          { "label": "GHG Emissions Reports", "key": "ghgEmissionsReports" },
          { "label": "Environmental Notices", "key": "environmentalNotices" }
        ]
      },
      {
        "title": "Notifications",
        "fields": [
          { "label": "Live Bunker Prices", "key": "liveBunkerPrices" },
          { "label": "Freight Rate Updates", "key": "freightRateUpdates" },
          { "label": "Decarbonization Alerts", "key": "decarbonizationAlerts" }
        ]
      }
    ]
  }



  const chunkSize = 14;
  const tagChunks = [];

  // for (let i = 0; i < Taglist.length; i += chunkSize) {
  //   tagChunks.push(Taglist.slice(i, i + chunkSize));
  // }

  return (
    <Row
      style={{
        padding: "0.5rem",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "white",
      }}
    >
      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(173 216 216)" 
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
            textAlign:"center"
          }}
        >
          Chartering
        </Typography>
        {/* <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
          }}
        >
          {Chartering.map((tag, index) => (
            <Tag
              key={`chartering-${index}`}
              style={{
                backgroundColor:
                  chipStyles[index % chipStyles.length].backgroundColor,
                color: chipStyles[index % chipStyles.length].color,
                borderColor: chipStyles[index % chipStyles.length].color,
                fontSize: "12px",
                borderRadius: "4px",
              }}
            >
              {tag}
            </Tag>
          ))}
        </div> */}

        <div className="custom-tag-card" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {Chartering.sections.map((section, sectionIndex) => (
            <Card
              key={`section-${sectionIndex}`}
              style={{
                // padding: '0.6rem',
                // marginBottom: '0.1rem',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Render Section Title */}
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
              >
                {section.title}
              </Typography>

              {/* Render Tags (Field Labels) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {section.fields.map((field, index) => (
                  <Tag
                  key={`chartering-${index}`}
                  style={{
                    backgroundColor:
                      chipStyles[index % chipStyles.length].backgroundColor,
                    color: chipStyles[index % chipStyles.length].color,
                    borderColor: chipStyles[index % chipStyles.length].color,
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                >
                  {field.label}
                </Tag>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </Col>

       <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(173 216 216)" 
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
            textAlign:"center"
          }}
        >
          Operation & finance and General Email
        </Typography>
        <div className="custom-tag-card" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {opf.sections.map((section, sectionIndex) => (
            <Card
              key={`section-${sectionIndex}`}
              style={{
                // padding: '0.6rem',
                // marginBottom: '0.1rem',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Render Section Title */}
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
              >
                {section.title}
              </Typography>

              {/* Render Tags (Field Labels) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {section.fields.map((field, index) => (
                  <Tag
                  key={`chartering-${index}`}
                  style={{
                    backgroundColor:
                      chipStyles[index % chipStyles.length].backgroundColor,
                    color: chipStyles[index % chipStyles.length].color,
                    borderColor: chipStyles[index % chipStyles.length].color,
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                >
                  {field.label}
                </Tag>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </Col>
       <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(173 216 216)" 
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
            textAlign:"center"
          }}
        >
          Ship Management
        </Typography>
        <div className="custom-tag-card" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {shipingManagment.sections.map((section, sectionIndex) => (
            <Card
              key={`section-${sectionIndex}`}
              style={{
                // padding: '0.6rem',
                // marginBottom: '0.1rem',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Render Section Title */}
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
              >
                {section.title}
              </Typography>

              {/* Render Tags (Field Labels) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {section.fields.map((field, index) => (
                  <Tag
                  key={`chartering-${index}`}
                  style={{
                    backgroundColor:
                      chipStyles[index % chipStyles.length].backgroundColor,
                    color: chipStyles[index % chipStyles.length].color,
                    borderColor: chipStyles[index % chipStyles.length].color,
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                >
                  {field.label}
                </Tag>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </Col>
      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(173 216 216)" 
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
            textAlign:"center"
          }}
        >
          Commodity Trading
        </Typography>
        <div className="custom-tag-card" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {comodity.sections.map((section, sectionIndex) => (
            <Card
              key={`section-${sectionIndex}`}
              style={{
                // padding: '0.6rem',
                // marginBottom: '0.1rem',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Render Section Title */}
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
              >
                {section.title}
              </Typography>

              {/* Render Tags (Field Labels) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {section.fields.map((field, index) => (
                  <Tag
                  key={`chartering-${index}`}
                  style={{
                    backgroundColor:
                      chipStyles[index % chipStyles.length].backgroundColor,
                    color: chipStyles[index % chipStyles.length].color,
                    borderColor: chipStyles[index % chipStyles.length].color,
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                >
                  {field.label}
                </Tag>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </Col> 

      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(173 216 216)" 
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
            textAlign:"center"
          }}
        >
          Freight Forwarder
        </Typography>
        <div className="custom-tag-card" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {freight.sections.map((section, sectionIndex) => (
            <Card
              key={`section-${sectionIndex}`}
              style={{
                // padding: '0.6rem',
                // marginBottom: '0.1rem',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Render Section Title */}
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
              >
                {section.title}
              </Typography>

              {/* Render Tags (Field Labels) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {section.fields.map((field, index) => (
                  <Tag
                  key={`chartering-${index}`}
                  style={{
                    backgroundColor:
                      chipStyles[index % chipStyles.length].backgroundColor,
                    color: chipStyles[index % chipStyles.length].color,
                    borderColor: chipStyles[index % chipStyles.length].color,
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                >
                  {field.label}
                </Tag>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </Col> 

      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(173 216 216)" 
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
            textAlign:"center"
          }}
        >
          Oil And Gas
        </Typography>
        <div  className="custom-tag-card" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {oilAndGas.sections.map((section, sectionIndex) => (
            <Card
              key={`section-${sectionIndex}`}
              style={{
                // padding: '0.6rem',
                // marginBottom: '0.1rem',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Render Section Title */}
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
              >
                {section.title}
              </Typography>

              {/* Render Tags (Field Labels) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {section.fields.map((field, index) => (
                  <Tag
                  key={`chartering-${index}`}
                  style={{
                    backgroundColor:
                      chipStyles[index % chipStyles.length].backgroundColor,
                    color: chipStyles[index % chipStyles.length].color,
                    borderColor: chipStyles[index % chipStyles.length].color,
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                >
                  {field.label}
                </Tag>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </Col> 

      <Col
        style={{
          padding: "0.75rem",
          border: "1px solid #e0e0e0",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(173 216 216)" 
        }}
      >
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "0.5rem",
            textAlign:"center"
          }}
        >
          Additional Sub-Categories for Efficiency
        </Typography>
        <div className="custom-tag-card" style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {subcategory.sections.map((section, sectionIndex) => (
            <Card
              key={`section-${sectionIndex}`}
              style={{
                // padding: '0.6rem',
                // marginBottom: '0.1rem',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {/* Render Section Title */}
              <Typography
                variant="h6"
                style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}
              >
                {section.title}
              </Typography>

              {/* Render Tags (Field Labels) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
                {section.fields.map((field, index) => (
                  <Tag
                  key={`chartering-${index}`}
                  style={{
                    backgroundColor:
                      chipStyles[index % chipStyles.length].backgroundColor,
                    color: chipStyles[index % chipStyles.length].color,
                    borderColor: chipStyles[index % chipStyles.length].color,
                    fontSize: "12px",
                    borderRadius: "4px",
                  }}
                >
                  {field.label}
                </Tag>
                ))}
              </div>
            </Card>
          ))}
        </div>
      </Col> 
    </Row>
  );
};

export default Tags;
