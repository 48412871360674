import {
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import redux_state from "../../services/redux_state";
import URL_WITH_VERSION, {
  URL_WITHOUT_VERSION,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
  postAPICallService,
} from "../../shared";
import TableHeaderToolTip from "./TableHeaderToolTip";
import { Icon } from "@iconify-icon/react/dist/iconify.js";
import TCBUNKERADJUSTMENT  from "../../../src/assests/TC BUNKER ADJUSTMENT.pdf"
import { laytimeEvent } from "../../services/utils";

const reverseRowColor = { backgroundColor: "#fffdaf" };

class IndexTableForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainFormName: this.props.mainFormName || undefined,
      responseData: { columns: [], objectKeys: [], dataArray: {}, group: {} },
      ids: -9e6,
      index: 0,
      isDisplayOnly: this.props.displayOnly === true,
      editMode: this.props.editMode,
      isShowFixedColumn: this.props.isShowFixedColumn,
      extraTableButton: this.props.extraTableButton || undefined,
      dataArray: this.props.formData || {},
      groupKey: this.props.groupKey || undefined,
      fullWidth: true,
      className: this.props.className || "",
      summary: this.props.summary || [],
      dyncGroupName: this.props.dyncGroupName || undefined,
      fieldModalEvent: this.props.modalEvent || undefined,
      disableTab: this.props.disableTab || undefined,
      tableRowDeleteAction: this.props.tableRowDeleteAction || (() => {}),
      prevVessel: null,
      tableDataLoading:false,
    };
    this.dynamicForm = this.props.dynamicForm;
    this.createStore = this.props.createStore;
  }

  componentDidMount = async () => {
    let data = {},
      headers = { extraCols: [] };
    if (this.props.frmCode) {
      this.props.originalForm.map((e) => {
        if (e.f_dyn_col && e.f_dyn_col !== "") {
          let item = JSON.parse(e.f_dyn_col);
          item["tableColumnValue"] = this.props.formData[item["keyColumn"]];
          headers["extraCols"].push(item);
          headers["formCode"] = e.frm_code;
        }
      });
      const response = await getAPICall(
        `${URL_WITHOUT_VERSION}get/${this.props.frmCode}`,
        headers
      );
      data = await response["data"];
    } else if (this.props.frmConfig) {
      data = this.props.frmConfig;
    }
    if (this.props.dyncGroupName && this.props.dyncGroupName !== "") {
      data["groups"].map((e, i) => {
        data["groups"][i] = Object.assign({}, e, {
          group_name: this.props.dyncGroupName,
          group_key: this.dynamicForm.getGroupKey(this.props.dyncGroupName),
        });
      });

      data["frm"].map((e, i) => {
        data["frm"][i] = Object.assign({}, e, {
          group_name: this.props.dyncGroupName,
        });
      });
    }

    this.dynamicForm.originalState.setFormFields(data["frm"]);
    this.setState({
      ...this.state,
      responseData: this.createColumns(data),
      dataArray: await this.getDataArray(this.props.dataURL, data),
      fullWidth: this.props.fullWidth ? true : false,
    });
  };
  //To solve issue of selecting a vessel and data not reflecting in bunker tab in tci and tco
  componentDidUpdate = async (prevProps, prevState) => {
    let currState = this.dynamicForm.getState();
    let currVessel = localStorage.getItem("currVessel");
    let prevVessel = this.state.prevVessel;

    if (
      prevState.frmCode === "tab_time_charter_in_bunker_form" ||
      prevState.frmCode === "tab_tco_bunker_form" ||
      this.props.frmCode === "tab_time_charter_in_bunker_form" ||
      this.props.frmCode === "tab_tco_bunker_form"
    ) {
      if (prevVessel !== currVessel) {
        async function getAPICallWithHeader(url, header) {
          const response = await getAPICall(url, {
            extraCols: [
              {
                ...header,

                table: "toc_bunker_columns",
                groupName: "Bunkers",
                keyColumn: "vessel_id",
                groupKeyId: 368,
                tableKeyColumn: "id",
                tableColumnValue: currState.vessel_id,
              },
            ],
            formCode: "tab_time_charter_in_bunker_form",
          });
          return response;
        }

        // Then you can use it like this:
        const response = await getAPICallWithHeader(
          `${URL_WITHOUT_VERSION}get/${"tab_time_charter_in_bunker_form"}`,
          {}
        );
        const data = await response.data;

        this.dynamicForm.originalState.setFormFields(data["frm"]);
        this.setState({
          ...this.state,
          responseData: this.createColumns(data),
          dataArray: await this.getDataArray(this.props.dataURL, data),
          fullWidth: this.props.fullWidth ? true : false,
          prevVessel: currVessel,
        });
      }
    }

    // Handle dyncGroupName changes
    if (
      this.props.dyncGroupName &&
      this.props.dyncGroupName !== prevProps.dyncGroupName
    ) {
      const groupKey = this.dynamicForm.getGroupKey(this.props.dyncGroupName);
      const updatedDataArray = {
        ...this.state.dataArray,
        [this.props.dyncGroupName]: currState[groupKey],
      };

      this.setState({
        dataArray: updatedDataArray,
      });
    }
  };

  getDataArray = async (codeURL, dataObject) => {
    // alert('fetcingdata')
    let dataPart = {};
    let objectKeys = [];
    dataObject.frm.map((e) => {
      if (e.group_name && objectKeys.indexOf(e.group_name) === -1) {
        objectKeys.push(e.group_name);
      } else if (!e.group_name && objectKeys.indexOf() === -1) {
        objectKeys.push("g");
      }
    });

    if (codeURL) {
      const response = await getAPICall(`${URL_WITH_VERSION}/${codeURL}`);
      const dataResp = await response["data"];

      Object.keys(dataResp).forEach((e) => {
        objectKeys.forEach((f) => {
          if (dataResp[e] && dataResp[e].hasOwnProperty(f)) {
            dataPart[f] = dataResp[e][f];
          }
        });
      });
    } else if (this.props.formData) {
      objectKeys.forEach((fo) => {
        let groupKey = this.dynamicForm.getGroupKey(fo);

        if (
          this.props.formData.hasOwnProperty(groupKey) &&
          this.props.formData[groupKey]
        ) {
          let data = this.props.formData[groupKey];
          let id = this.state.ids;
          data &&
            data?.length > 0 &&
            data?.forEach((d, i) => {
              data[i]["index"] = i;
              data[i]["key"] =
                groupKey + "_table_row_" + i + "_" + new Date().toISOString();
              data[i]["id"] = d?.id ? d?.id : id + i;
            });
          dataPart[fo] = data;
        }
      });
    }

    objectKeys.map((e) => {
      if (dataPart && dataPart?.[e])
        dataPart[e]?.map((e) => (e["editable"] = true));
    });
    return dataPart;
  };

   openPdf = (pdf) => {
    window.open(pdf, "_blank");
  };

  extartButtons = (data, record, e) => {
    let rows = [];
    data.map((b) => {
      if (!b.hasOwnProperty("isMenu") && !b.hasOwnProperty("onClickAction")) {
        rows.push(
          <span
            key={uuidv4()}
            className={
              "iconWrapper " +
              b.icon +
              " " +
              (b.class && b.class !== "" ? b.class : undefined)
            }
            onClick={() =>
              this.onAddNewBelow({
                gKey: e,
                index: record.index,
                callType: data[0]["callType"],
              })
            }
          >
            {b.icon}
          </span>
        );
      } else if (
        !b.hasOwnProperty("isMenu") &&
        b.hasOwnProperty("onClickAction") &&
        typeof b.onClickAction === "function"
      ) {
        rows.push(
          <span
            key={uuidv4()}
            className={
              "iconWrapper " +
              b.icon +
              " " +
              (b.class && b.class !== "" ? b.class : undefined)
            }
            onClick={() =>
              b.onClickAction(
                { ID: record.id, gKey: e, index: record.index },
                this.dynamicForm.stateObject
              )
            }
          >
            {b.icon}
          </span>
        );
      } else if (b.hasOwnProperty("isMenu") && b["isMenu"] === true) {
        const menu = (
          <Menu>
            {b["options"].map((bo) => (
              <Menu.Item
                onClick={() =>
                  bo.onClickAction({
                    ID: record.id,
                    gKey: e,
                    index: record.index,
                    eventType: bo.key,
                    eventData: record,
                  })
                }
                key={bo.key}
              >
                {bo.text}
              </Menu.Item>
            ))}
          </Menu>
        );
        rows.push(
          <Dropdown overlay={menu}>
            <span key={uuidv4()} className={"iconWrapper " + b.icon}>
              <SettingOutlined />
            </span>
          </Dropdown>
        );
      }
    });

    return rows;
  };

  createColumns = (data) => {
    const {
      isDisplayOnly,
      isShowFixedColumn,
      extraTableButton,
      fieldModalEvent,
      editMode,
    } = this.state;
    let groups = {};
    let objectKeys = [];
    let groupWidth = {};
    let itemData = [];
    let subItems = [];
    let skipCols = [];
    let objWidth = {};
    let addItems = {};
    if (data && data.groups) {
      data.groups.map((e) => (groupWidth[e.group_name] = e.g_width * 1));
    }

    data.frm.map((e, i) => {
      let colSettings = "";
      if (
        objectKeys.indexOf(e.group_name) === -1 &&
        e.group_name &&
        !groups.hasOwnProperty(e.group_name)
      ) {
        groups[e.group_name] = [];
        objectKeys.push(e.group_name);
      } else if (
        objectKeys.indexOf("g") === -1 &&
        !e.group_name &&
        !groups.hasOwnProperty(e.group_name)
      ) {
        groups["g"] = [];
        objectKeys.push("g");
      }

      if (e.f_default && e.f_default !== "") {
        addItems[e.f_name] = e.f_default;
      }

      if (!objWidth[e.group_name || "g"]) {
        objWidth[e.group_name || "g"] = 0;
      }

      if (
        e.f_t_col_span &&
        e.f_t_col_span !== "" &&
        e.f_t_col_span !== JSON.stringify({})
      ) {
        colSettings = JSON.parse(e.f_t_col_span);

        if (
          typeof colSettings.skipCol === "object" &&
          colSettings.skipCol.hasOwnProperty("length")
        ) {
          colSettings.skipCol.map((e) => skipCols.push(e));
        } else {
          skipCols.push(colSettings.skipCol);
        }

        colSettings.colDetails.children.map((ec, ic) => {
          let ecID = ec.f_id * 1;
          let colValue = Object.assign({}, e);

          if (
            ic >= 1 &&
            (colSettings.skipCol === ecID ||
              colSettings.skipCol.indexOf(ecID) >= 0)
          ) {
            colValue = data.frm.find((e) => e.f_id * 1 === ecID);
          }
          colSettings.colDetails.children[ic] = {
            title: ec.title,
            id: ec.f_name,
            dataIndex: ec.dataIndex,
            key: ec.key,
            width: ec.width,
            actualField: colValue,
            render: (text, record) => {
              return (
                <div id={ec.key}>
                  {this.dynamicForm.emptyCell(
                    colValue,
                    text,
                    record,
                    false,
                    false,
                    fieldModalEvent
                  )}
                </div>
              );
            },
          };
          objWidth[e.group_name || "g"] =
            objWidth[e.group_name || "g"] + ec.width * 1;
        });
        groups[e.group_name || "g"].push(colSettings.colDetails);
      } else if (skipCols.indexOf(e.f_id) < 0) {
        groups[e.group_name || "g"].push({
          title: <TableHeaderToolTip currentRow={e} />,
          dataIndex: e.f_name,
          key: e.f_name,
          actualField: e,
          width:
            e.f_width && e.f_width !== ""
              ? isNaN(e.f_width * 1)
                ? e.f_width
                : e.f_width * 1
              : undefined,
          render: (text, record) => {
            // console.log("e", e);
            // console.log("record",record)

            return (
              <div id={e.f_name}>
                {this.dynamicForm.emptyCell(
                  e,
                  text,
                  record,
                  false,
                  false,
                  fieldModalEvent
                )}
              </div>
            );
          },
        });
        objWidth[e.group_name || "g"] =
          objWidth[e.group_name || "g"] +
          (e.f_width && e.f_width !== "" ? e.f_width * 1 : 0);
      }
    });

    if (isDisplayOnly === false) {
      objectKeys.map((e) => {
        if (
          (data["groups"] &&
            data["groups"].length > 0 &&
            data["groups"][0].hasOwnProperty("isShowActionButtons") &&
            data["groups"][0]["isShowActionButtons"] === 1) ||
          (data["groups"] && data["groups"].length == 0)
        ) {
          objWidth[e] += 100;
          groups[e].push({
            title: "Actions",
            id: "actions",
            dataIndex: "actions",
            key: "actions",
            className: "actions-column",

            fixed:
              isShowFixedColumn &&
              (e === isShowFixedColumn || isShowFixedColumn.indexOf(e) >= 0)
                ? "right"
                : undefined,
            width:
              isShowFixedColumn &&
              (e === isShowFixedColumn || isShowFixedColumn.indexOf(e) >= 0)
                ? 100
                : 120,
            render: (text, record) => {
              const { editable } = record;
              let gpkey = this.dynamicForm.getGroupKey(e);
              let completestate = this.dynamicForm.getState();
              return (
                <div className="editable-row-operations">
                  {editable ? (
                    <div>
                      {editMode ? (
                        <span className="iconWrapper cancel">
                          <Popconfirm
                            disabled={
                              completestate?.["tcov_status"] == "2"
                                ? true
                                : false
                            }
                            style={{
                              backgroundColor: "white",
                              maxWidth: "auto",
                            }}
                            overlayStyle={{
                              backgroundColor: "white",
                              maxWidth: "auto",
                            }}
                            placement={
                              gpkey == "portitinerary" ? "left" : "top"
                            }
                            title={
                              gpkey == "portitinerary" && record?.p_status == "102" ? (
                                <p>To delete, first unfreeze the row.</p>
                              ) : gpkey == "portitinerary" ? (
                                <p>
                                  Deleting this port will require reselecting <br /> the below port again
                                  to match the distance.
                                </p>
                              ) : (
                                "Sure to delete?"
                              )
                            }
                            onConfirm={() =>
                              this.editTableData({
                                editable: false,
                                ID: record.id,
                                gKey: e,
                                index: record.index,
                                ocd: record.ocd,
                                action: "delete",
                              })
                            }
                            okButtonProps={{
                              style: {
                                display: gpkey == "portitinerary" && record?.p_status == "102" ? 'none' : 'inline-block'
                              }
                            }}
                            cancelButtonProps={{
                              style: {
                                display: gpkey == "portitinerary" && record?.p_status == "102" ? 'none' : 'inline-block'
                              }
                            }}
                          >
                            <Tooltip placement="left" title="Delete">
                              <DeleteOutlined />
                            </Tooltip>
                          </Popconfirm>
                        </span>
                      ) : (
                        ""
                      )}

                      {extraTableButton &&
                      extraTableButton.hasOwnProperty(e) &&
                      extraTableButton[e].length > 0
                        ? this.extartButtons(extraTableButton[e], record, e)
                        : undefined}
                    </div>
                  ) : (
                    <>
                      <span
                        className="iconWrapper edit"
                        onClick={(ev) =>
                          this.editTableData({
                            editable: true,
                            ID: record.id,
                            gKey: e,
                            index: record.index,
                          })
                        }
                      >
                        <EditOutlined />
                      </span>
                      {extraTableButton &&
                      extraTableButton.hasOwnProperty(e) &&
                      extraTableButton[e].length > 0
                        ? this.extartButtons(extraTableButton[e], record, e)
                        : undefined}
                    </>
                  )}
                </div>
              );
            },
          });
        } else if (
          (data["groups"] &&
            data["groups"].length > 0 &&
            data["groups"][0].hasOwnProperty("isShowActionButtons") &&
            data["groups"][0]["isShowActionButtons"] === 0 &&
            extraTableButton &&
            extraTableButton.hasOwnProperty(e) &&
            extraTableButton[e].length > 0) ||
          (data["groups"] && data["groups"].length === 0)
        ) {
          objWidth[e] += 100;
          groups[e].push({
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            className: "actions-column",
            fixed:
              isShowFixedColumn &&
              (e === isShowFixedColumn || isShowFixedColumn.indexOf(e) >= 0)
                ? "right"
                : undefined,
            width:
              isShowFixedColumn &&
              (e === isShowFixedColumn || isShowFixedColumn.indexOf(e) >= 0)
                ? 100
                : 120,
            render: (text, record) => {
              const { editable } = record;
              return (
                <div className="editable-row-operations">
                  {editable
                    ? this.extartButtons(extraTableButton[e], record, e)
                    : undefined}
                </div>
              );
            },
          });
        }
      });
    }

    objectKeys.map((e) => {
      if (groupWidth[e] < 100) {
        subItems.push(e);
      } else if (groupWidth[e] === 100 || !groupWidth[e]) {
        if (subItems.length > 0) {
          itemData.push(subItems);
          subItems = [];
        }
        itemData.push(e);
      }
    });

    if (subItems.length > 0) {
      itemData.push(subItems);
      subItems = [];
    }

    return {
      columns: groups,
      objectKeys: itemData,
      group: data["groups"],
      groupWidth: groupWidth,
      objectLength: objWidth,
      addItemValue: addItems,
    };
  };

  editTableData = (editInfo) => {
    if (
      editInfo.action === "delete" &&
      this.props?.formData?.vivo_status === "2"
    ) {
      //This code is only for voyage relet full
      openNotificationWithIcon(
        "info",
        "Can't delete as this estimate has been Scheduled",
        3
      );
      return;
    }
    const { summary, mainFormName } = this.state;
    let summaryTotal = {};
    let sKeys = [];
    let dArray = this.state.dataArray;
    let eArray = [];
    let _id = -9e6;
    let delrow = {};
    let upObj = {};
    let gpKey = this.dynamicForm.getGroupKey(editInfo.gKey);
    try {
      dArray[editInfo.gKey].map((dag, index) => {
        if (dag.id === editInfo.ID) {
          dArray[editInfo.gKey][index] = {
            ...dag,
            editable: editInfo.editable === false ? undefined : true,
          };
        }
      });
      if (
        editInfo.ocd ||
        (editInfo.hasOwnProperty("action") && editInfo["action"] === "delete")
      ) {
        dArray[editInfo.gKey].map((dag, index) => {
          if (
            dag.id === editInfo.ID &&
            dag.ocd === editInfo.ocd &&
            dag.editable == undefined
          ) {
            const completeState = this.dynamicForm.getState();
            this.state.tableRowDeleteAction(
              editInfo,
              dArray[editInfo.gKey][index],
              completeState
            );

            if (eArray.length === 0) {
              dArray[editInfo.gKey].map((p, pi) => {
                if (pi !== index) {
                  upObj = {
                    id: _id + eArray.length,
                    index: eArray.length,
                    key: gpKey + "_table_row_" + eArray.length,
                  };
                  if (p["id"] > 0)
                    upObj = {
                      index: eArray.length,
                      key: gpKey + "_table_row_" + eArray.length,
                    };
                  eArray.push(Object.assign(p, upObj));
                }
              });
              dArray[editInfo.gKey] = eArray;
              this.dynamicForm.deleteRow(editInfo);
            }
          }
        });

        if (
          this.props.mainFormName === "tc_make_payment" ||
          this.props.mainFormName === "tco_make_payment" ||
          this.props.mainFormName === "tc_commission_entry" ||
          this.props.mainFormName === "tco_commission_entry" ||
          this.props.mainFormName === "other_cost_detail_form"
        ) {
          summary.map((s) => {
            let fObj = this.dynamicForm.getStateObject(
              this.dynamicForm.getGroupKey(s.gKey)
            );
            if (
              this.dynamicForm.getGroupKey(s.gKey) === gpKey &&
              fObj &&
              fObj.length > 0
            ) {
              s.showTotalFor.map((st) => {
                summaryTotal[st] = 0;
              });
              fObj.map((e) => {
                s.showTotalFor.map((st) => {
                  if (e.hasOwnProperty(st) && s.dhm === true) {
                    summaryTotal[st] =
                      (summaryTotal[st] ? summaryTotal[st] : 0) +
                      this.dynamicForm.stringToDaysHoursMinutsObject(
                        e[st],
                        true
                      );
                  } else if (e.hasOwnProperty(st) && !s.dhm) {
                    summaryTotal[st] =
                      (summaryTotal[st] ? summaryTotal[st] : 0) + e[st] * 1;
                  }
                });
                if (
                  this.props.mainFormName === "tc_commission_entry" ||
                  this.props.mainFormName === "tco_commission_entry"
                ) {
                  // parameter for this.dynamicForm.setData  function =>(fieldName, fieldData, groupData, index = undefined, calledFrom = undefined)
                  this.dynamicForm.setData(
                    "total",
                    summaryTotal["commission"],
                    ".",
                    undefined,
                    "COPYST"
                  );

                  this.dynamicForm.setData(
                    "invoice_total",
                    summaryTotal["commission"],
                    ".",
                    undefined,
                    "COPYST"
                  );
                } else if (
                  this.props.mainFormName === "other_cost_detail_form"
                ) {
                  this.dynamicForm.setData(
                    "total_sum",
                    summaryTotal["amount"].toFixed(2),
                    undefined,
                    undefined,
                    "COPYST"
                  );
                } else {
                  this.dynamicForm.setData(
                    "amount",
                    summaryTotal["amount_usd"],
                    undefined,
                    undefined,
                    "COPYST"
                  );
                }
              });
            } else {
              // if there is no any row in makepayment table, then total amount willbe taken from daily rates as like previous.
              if (
                this.props.mainFormName === "tc_commission_entry" ||
                this.props.mainFormName === "tco_commission_entry"
              ) {
                // parameter for this.dynamicForm.setData  function =>(fieldName, fieldData, groupData, index = undefined, calledFrom = undefined)
                this.dynamicForm.setData(
                  "total",
                  "0",
                  ".",
                  undefined,
                  "COPYST"
                );
                this.dynamicForm.setData(
                  "invoice_total",
                  0,
                  ".",
                  undefined,
                  "COPYST"
                );
              }
              if (this.props.mainFormName === "other_cost_detail_form") {
                // parameter for this.dynamicForm.setData  function =>(fieldName, fieldData, groupData, index = undefined, calledFrom = undefined)
                this.dynamicForm.setData(
                  "total_sum",
                  0,
                  undefined,
                  undefined,
                  "COPYST"
                );
              } else {
                let el = document.getElementById("daily_rates").value;
                this.dynamicForm.setData(
                  "amount",
                  el,
                  undefined,
                  undefined,
                  "COPYST"
                );
              }
            }
          });
        }
        if (this.props.mainFormName == "freight-commission-invoice") {
          summary.map((s) => {
            let fObj = this.dynamicForm.getStateObject(
              this.dynamicForm.getGroupKey(s.gKey)
            );
            if (
              this.dynamicForm.getGroupKey(s.gKey) === gpKey &&
              fObj &&
              fObj.length > 0
            ) {
              s.showTotalFor.map((st) => {
                summaryTotal[st] = 0;
              });
              fObj.map((e) => {
                s.showTotalFor.map((st) => {
                  if (e.is_select && e.hasOwnProperty(st)) {
                    summaryTotal[st] =
                      (summaryTotal[st] ? summaryTotal[st] : 0) + e[st] * 1;
                  }
                });
                if (this.props.mainFormName == "freight-commission-invoice") {
                  // parameter for this.dynamicForm.setData  function =>(fieldName, fieldData, groupData, index = undefined, calledFrom = undefined)
                  this.dynamicForm.setData(
                    "total_amount",
                    summaryTotal["commission_amount"],
                    undefined,
                    undefined,
                    "COPYST"
                  );
                }
              });
            } else {
              // if there is no any row in  table, then total amount willbe taken from daily rates as like previous.

              // parameter for this.dynamicForm.setData  function =>(fieldName, fieldData, groupData, index = undefined, calledFrom = undefined)
              this.dynamicForm.setData(
                "total_amount",
                0,
                undefined,
                undefined,
                "COPYST"
              );
            }
          });
        }
      }
      _id = _id + eArray.length;
      const tabledata = this.dynamicForm.getState(editInfo.gKey);
      this.setState(
        {
          ...this.state,
          dataArray: { ...dArray },
          ids: _id,
          index: eArray.length - 1,
        },
        () => {
          let gpKey = this.dynamicForm.getGroupKey(editInfo.gKey);
          let value = {};
          value[gpKey] = dArray[gpKey] ? dArray[gpKey] : dArray[editInfo.gKey];

          if (
            mainFormName == "tcov_port_itinerary" ||
            mainFormName == "tab_tcto_port_itinerary" ||
            mainFormName == "voyage_manager_port_itinerary" ||
            this.props.mainFormName == "port_expense_pda_port_expense_adv" ||
            this.props.mainFormName == "port_expense_fda"
          ) {
            // this.createStore.dispatch({
            //   type: "delete",
            //   groupName: gpKey,
            //   formName: mainFormName,
            //   index: undefined,
            //   value: value,
            // });
            //  this is creating issue. data is coming nested. in port iteniary if we delete port from middle.
            //  this.createStore.dispatch({ "type": 'add', "groupName": gpKey, "formName": mainFormName, "index": undefined, "value": value })
          } else if (this.props.mainFormName == "INITIAL_FRIEGHT_INVOICE") {
            this.createStore.dispatch({
              type: "add",
              groupName: gpKey,
              formName: mainFormName,
              index: undefined,
              value: value,
            });
            const currentstate = this.dynamicForm.getState();
            let descdata = [];
            let suURL = `${URL_WITH_VERSION}/freight-invoice/calculation`;
            postAPICall(suURL, currentstate, "POST", (data) => {
              if (data?.data?.length > 0) {
                descdata = data.data;
                this.createStore.dispatch({
                  type: "add",
                  groupName: "-----",
                  formName: mainFormName,
                  index: undefined,
                  value: {
                    "-----": descdata,
                  },
                });
                let totalsum = descdata.reduce((ac, el) => {
                  if (el.acc_code === "FRTCOM") {
                    return ac + parseFloat(el.amount) * -1;
                  } else {
                    return ac + parseFloat(el.amount) * 1;
                  }
                }, 0);
                this.createStore.dispatch({
                  type: "add",
                  groupName: undefined,
                  formName: mainFormName,
                  index: undefined,
                  value: {
                    total_amount: totalsum.toFixed(2),
                    invoice_total: totalsum.toFixed(2),
                  },
                });
              }
            });
          }

          /* Note: Commenting below code fixes - Row Deletion Issue in Cargo and Tankers Section Removes Data */

          // else if (
          //   this.props.mainFormName == "tcov_full_estimate_form" ||
          //   this.props.mainFormName == "VOYAGE_RELET"
          // ) {
          //   this.createStore.dispatch({
          //     type: "add",
          //     groupName: gpKey,
          //     formName: mainFormName,
          //     index: undefined,
          //     value: value,
          //   });
          // }
        }
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  onAddNewBelow = (event) => {
    const { isDisplayOnly, responseData, mainFormName } = this.state;
    let rows = [];
    let mainFormName1, todate, totime;
    let eventGroup = this.dynamicForm.getGroupKey(event.gKey);
    let newRow = this.dynamicForm.getStateObject(eventGroup);
    let newRow1 = [],
      idx = -9e6,
      _index = 0;

    newRow.map((e) => newRow1.push(e));
    newRow1.map((e, i) => {
      let p = Object.assign({}, e);
      p["id"] = idx;
      p["index"] = _index;
      p["key"] = e.key;
      p["editable"] = true;
      rows.push(p);

      if (i === event.index) {
        if (newRow[i]["to_date"]) {
          todate = newRow[i]["to_date"];
          totime = newRow[i]["to_time"];
        }

        _index = _index + 1;
        idx = idx + 1;

        let agp = Object.assign(
          {
            editable: true,
            ocd: true,
            id: idx,
            index: _index,
            key:
              eventGroup +
              "_table_row_" +
              _index +
              "_" +
              new Date().toISOString(),
          },
          responseData.addItemValue
        );

        if (mainFormName === "new_laytime_calculation_form") {
          agp.from_date = rows[_index - 1]?.to_date || "";
        }

        if (mainFormName === "voyage_manager_port_itinerary") {
          let currState = this.dynamicForm.getState();
          agp.wf_per = currState?.dwf ? currState?.dwf : 5;
        }

        rows.push(agp);
        idx = idx + 1;
        _index = _index + 1;
      } else {
        idx = idx + 1;
        _index = _index + 1;
      }
    });

    mainFormName1 = mainFormName;
    if (mainFormName === "tcov_port_itinerary") {
      mainFormName1 = "tcov_full_estimate_form";
    } else if (mainFormName === "tab_tcto_port_itinerary") {
      mainFormName1 = "tcto_full_estimate_form";
    } else if (mainFormName === "TAB_VOYAGE_RELET_PORT_ITINERARY") {
      mainFormName1 = "VOYAGE_RELET";
    } else if (mainFormName === "voyage_manager_port_itinerary") {
      mainFormName1 = "voyage_manager_form";
    }

    let obj = {};

    obj[event.gKey] = rows;

    this.setState({ ...this.state, dataArray: obj }, () => {
      let value = {};
      value[eventGroup] = rows;
      this.createStore.dispatch({
        type: "add",
        groupName: eventGroup,
        formName: mainFormName1,
        index: undefined,
        value: value,
      });
    });
  };

  onAddNew = (editInfo) => {
    const { isDisplayOnly, responseData, mainFormName, dataArray } = this.state;
    let gpKey = this.dynamicForm.getGroupKey(editInfo.gKey);
    if (
      editInfo.gKey == "Port Itinerary" &&
      (redux_state.reduxStore.hasOwnProperty("tcov_full_estimate_form") ||
        redux_state.reduxStore.hasOwnProperty("tcto_full_estimate_form") ||
        redux_state.reduxStore.hasOwnProperty("VOYAGE_RELET") ||
        redux_state.reduxStore.hasOwnProperty("voyage_manager_form")) &&
      // dataArray["Port Itinerary"] &&
      // dataArray["Port Itinerary"].length > 0
      redux_state?.reduxStore?.[this.dynamicForm.formName]?.[gpKey]?.length > 1
    ) {
      this.onAddNewBelow({
        gKey: editInfo.gKey,
        index:
          redux_state?.reduxStore?.[this.dynamicForm.formName]?.[gpKey].length >
          1
            ? redux_state?.reduxStore?.[this.dynamicForm.formName]?.[gpKey]
                .length - 1
            : 0,
      });
      return;
    }

    if (!isDisplayOnly) {
      const { index, ids, dataArray } = this.state;

      let gpKey = this.dynamicForm.getGroupKey(editInfo.gKey);
      let _ids = ids;
      let _index = null;
      let actualData = {};
      let existingData = {};
      let _dataArray = [];
      _dataArray = Object.assign([], dataArray);
      _index = index;
      // console.log(_dataArray,"fdf",gpKey);
      if (index < 0) {
        // Logic for index < 0
      } else {
        actualData = this.dynamicForm.getStateObject(gpKey);
        // console.log(actualData,"actual data",index);
      }
      if (_dataArray && _dataArray[gpKey]) {
        // alert('case1')
        //existing key matched now check if it has data
        if (_dataArray[gpKey].length === 0) {
          _ids = -9e6;
          _index = 0;
        } // ELSE do nothing as state values will be in sync hopefully
        else {
          // else id or index should be in sync hoefully
          if (
            _dataArray[editInfo.gKey].length > 0 &&
            (index === 0 || _dataArray[editInfo.gKey].length !== index)
          ) {
            // mismatch situation where state is not in sync like two tables displayed in same form and common index continues
            _index = actualData.length;
            _ids = -9e6 + actualData.length;
          }
        }
      } else {
        // alert('case2')
        //SCENARIOS 2: no match for gpKey so editInfo.gKey has to be checked
        if (_dataArray && _dataArray[editInfo.gKey]) {
          if (_dataArray[editInfo.gKey].length === 0) {
            _ids = -9e6;
            _index = 0;
          } else {
            // else id or index should be in sync hoefully
            if (
              _dataArray[editInfo.gKey].length > 0 &&
              (index === 0 || _dataArray[editInfo.gKey].length !== index)
            ) {
              // mismatch situation where state is not in sync like two tables displayed in same form and common index continues
              _index = actualData.length;
              _ids = -9e6 + actualData.length;
            }
          }
        } else {
          //SCENARIOS 3: nokey match means there is nothing yet
          _ids = -9e6;
          _index = 0;
        }
      }

      // Deep copy of dataArray to ensure immutability
      _dataArray = JSON.parse(JSON.stringify(dataArray));

      // Modify _dataArray based on conditions
      if (_index > 0) {
        let agp = {};
        // if (mainFormName === "new_laytime_calculation_form") {
        //   // Logic for new_laytime_calculation_form
        // } else {

        agp = {
          editable: editInfo.editable,
          ocd: true,
          id: _ids,
          index: _index,
          key: gpKey + "_table_row_" + _index + "_" + new Date().toISOString(),
        };
        //}
        // debugger;

        if (!_dataArray.hasOwnProperty(editInfo.gKey)) {
          _dataArray[editInfo.gKey] = [agp];
        } else {
          if (mainFormName === "new_laytime_calculation_form") {
            // Logic for new_laytime_calculation_form
            agp.from_date = actualData[_index - 1]?.to_date || "";
            agp.counting = actualData[_index]?.counting || 100;
            // agp.counting = 100;
            // _dataArray[gpKey] = [...actualData,agp]
            _dataArray[editInfo.gKey].push(agp);
            
          } else if ((mainFormName === "tab_time_charter_in_pricing_form" || mainFormName === "tab_tco_pricing_form") && editInfo?.gKey=="Hire Term"){
            agp.from_date = actualData[_index - 1]?.to_date || "";
            _dataArray[editInfo.gKey].push(agp);
          }else if ((mainFormName === "tab_time_charter_in_pricing_form" || mainFormName === "tab_tco_pricing_form") && editInfo?.gKey=="Commission Term"){
            agp.from_gmt = actualData[_index - 1]?.to_gmt || "";
            _dataArray[editInfo.gKey].push(agp);
          }
           else {
            _dataArray[editInfo.gKey].push(agp);
          }
          // if (mainFormName === "new_laytime_calculation_form") {
          //   // Logic for new_laytime_calculation_form
          // } else {

          // _dataArray[editInfo.gKey].push(agp);

          //}
        }
      } else if (_index === 0) {
        if (!actualData) actualData = {};
        actualData = {
          editable: editInfo.editable,
          ocd: true,
          id: _ids,
          index: _index,
          key: gpKey + "_table_row_" + _index + "_" + new Date().toISOString(),
        };
        if (mainFormName === "new_laytime_calculation_form") {
          actualData.counting = actualData[_index]?.counting || 100;
        }
        _dataArray[editInfo.gKey] = [actualData];
      }

      // console.log("data array befor setting local state",{_dataArray,actualData,editInfo,mainFormName});
      this.setState(
        {
          ...this.state,
          dataArray: _dataArray,
          ids: _ids + 1,
          index: _index + 1,
        },
        () => {
          let value = {};
          value[gpKey] = _dataArray[gpKey]
            ? _dataArray[gpKey]
            : _dataArray[editInfo.gKey];

          if (
            mainFormName === "tcov_port_itinerary" ||
            mainFormName === "tab_tcto_port_itinerary" ||
            mainFormName === "voyage_manager_port_itinerary" ||
            mainFormName === "port_expense_fda" ||
            mainFormName == "port_expense_pda_port_expense_adv" ||
            mainFormName === "new_laytime_calculation_form" ||
            mainFormName === "TAB_VOYAGE_RELET_PORT_ITINERARY" ||
            mainFormName === "coa_vci_linked_trade_cargos" ||
            mainFormName === "tab_coa_linked_trade_cargos" ||
            mainFormName === "vcargo_in_contract_pricing" ||
            mainFormName === "coa_vci_pricing" ||
            mainFormName === "tab_coa_pricing_form" ||
            mainFormName === "cargo_contract_pricing" ||
            mainFormName === "tab_time_charter_in_purchase_form" ||
            mainFormName === "tab_time_charter_in_sales_form" ||
            mainFormName === "tab_time_charter_in_pricing_form"||
            mainFormName === "tab_tco_pricing_form"
          ) {
            let mainFormName1 = mainFormName;
            if (mainFormName === "tcov_port_itinerary") {
              mainFormName1 = "tcov_full_estimate_form";
            } else if (mainFormName === "tab_tcto_port_itinerary") {
              mainFormName1 = "tcto_full_estimate_form";
            }

            // else if (mainFormName === "TAB_VOYAGE_RELET_PORT_ITINERARY") {
            //   mainFormName1 = "VOYAGE_RELET";
            // }   // beacuse of issue in  ticket 1844
            else if (mainFormName === "voyage_manager_port_itinerary") {
              mainFormName1 = "voyage_manager_form";
            } else if (
              mainFormName === "coa_vci_linked_trade_cargos" ||
              mainFormName === "coa_vci_pricing"
            ) {
              mainFormName1 = "coa_vci_form";
            } else if (
              mainFormName === "tab_coa_linked_trade_cargos" ||
              mainFormName === "tab_coa_pricing_form"
            ) {
              mainFormName1 = "coa_form";
            } else if (mainFormName === "vcargo_in_contract_pricing") {
              mainFormName1 = "vcargo_in_contract_form";
            } else if (mainFormName === "cargo_contract_pricing") {
              mainFormName1 = "cargo_contract_form";
            } else if (
              mainFormName === "tab_time_charter_in_purchase_form" ||
              mainFormName === "tab_time_charter_in_sales_form"
            ) {
              mainFormName1 = "time_charter_in_form";
            }else if(mainFormName === "tab_time_charter_in_pricing_form"){
              mainFormName1 = "time_charter_in_form";
            }else if(mainFormName === "tab_tco_pricing_form"){
              mainFormName1 = "tco_form";
            }else if(mainFormName === "port_expense_pda_port_expense_adv" ||
              mainFormName === "port_expense_fda"
            ){
              mainFormName1 = "port_expenses";
            }

            this.createStore.dispatch({
              type: "add",
              groupName: gpKey,
              // formName: mainFormName,
              formName: mainFormName1,
              index: _index,
              value: value,
            });
          }
          // Logic for dispatching in specific cases
          else {
            this.createStore.dispatch({
              type: "add",
              groupName: gpKey,
              formName: mainFormName,
              index: _index,
              value: value,
              ids: _ids + 1,
            });
          }
        }
      );
    }
  };

  handleActivitiesUpload = async (e, tab_name, groupName) => {
    // const files = Array.from(e.target.files);
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      let data = [];

      formData.append("file", file);
      let url = `${process.env.REACT_APP_ATTACHMENT}/laytime-calculator/excel-data-upload`;
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("oceanToken")}`,
        },
      });
      const res = await response?.json();

      if (res?.status) {
        // https://devapiservices.theoceann.com/api/v1/laytime-calculator/time-sorting
        const _url = `${process.env.REACT_APP_URL}v1/laytime-calculator/time-sorting`;

        let payload = res.data.map((item) => {
          let entry = {
            counting: 100,  // Always include counting
          };
        
          if (item?.activity) {
            entry.activity = item.activity.toString();
          }
        
          if (item?.date_from) {
            entry.from_date = moment(item.date_from).format("YYYY-MM-DD HH:mm");
          }
        
          if (item?.date_to) {
            entry.to_date = moment(item.date_to).format("YYYY-MM-DD HH:mm");
          }
        
          return entry;
          // activity: item?.activity.toString(),
          // from_date: item?.date_from ? moment(item?.date_from).format("YYYY-MM-DD HH:MM"):"",
          // to_date: item?.date_to ? moment(item?.date_to).format("YYYY-MM-DD HH:MM"):"",
          // counting: 100,
        });
        const response1 = await postAPICallService({
          url: _url,
          payload: { laytime: payload },
        });
        if(response1?.data){

        }else{
          openNotificationWithIcon("error",response1?.message)
        }
        if (response1?.data?.change === true) {
          data = response1?.data?.timeSorttedList;
          this.addDynamicRowWithData(data, groupName);
        } else {
          data = payload;
          this.addDynamicRowWithData(data, groupName);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  downloadLaytimeExcelTemplate = async() =>{
    try{
      let url = `${process.env.REACT_APP_ATTACHMENT}/port-bunker-activity/laytime_port-activity-template`;
      const token = localStorage.getItem("oceanToken");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      const urlBlob = window.URL.createObjectURL(blob);
      link.href = urlBlob;
      link.download = `Laytime Activities.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    }catch(err){
      console.log(err)
    }
  }


  downloadExcelTemplate = async() =>{
    try{
      let url = `${process.env.REACT_APP_ATTACHMENT}/port-bunker-activity/port-activity-template`;
      const token = localStorage.getItem("oceanToken");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });
      const blob = await response.blob();
      const link = document.createElement("a");
      const urlBlob = window.URL.createObjectURL(blob);
      link.href = urlBlob;
      link.download = `Port Activities.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob);
    }catch(err){
      console.log(err)
    }
  }

  handlePortActivitiesUpload = async (e, tab_name, groupName) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
  
      let url = `${process.env.REACT_APP_ATTACHMENT}/port-bunker-activity/import-port-activity`;
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `${localStorage.getItem("oceanToken")}`,
        },
      });
  
      const res = await response?.json();
      let gName = this.dynamicForm.getGroupKey(groupName);
      const resData = res?.row || [];
  
      if (resData && resData.length > 0) {
        this.setState({...this.state,tableDataLoading:true})

        const finalData = resData.map((item, i) => ({
          gKey: groupName,
          editable: true,
          ocd: true,
          id: -9000000 + i,
          index: i,
          key: `${groupName}_table_row_${i}_${new Date().toISOString()}`,
          port_activity: item?.Activity || 0,
          bl_code: item?.["BL Code"] || "",
          port_cargo: item?.Cargo || "",
          remarks: item?.Remarks || "",
          date_from: item?.["Date From"] || "",
        }));
        
        this.setState((prev)=>({...prev,
          dataArray: {...prev.dataArray,
            [groupName]:finalData,
          },
        }))

        this.createStore.dispatch({
          type: "add",
          groupName: gName,
          formName: "port_bunker_and_activity",
          index: undefined,
          value: {
            [gName]: finalData,
          },
        });
        setTimeout(() => {
          this.setState({...this.state,tableDataLoading:false})
        }, 2000);
  
      } else if(resData && resData.length === 0) {
        openNotificationWithIcon('info', "Activities not found.");
      } 
      else {
        openNotificationWithIcon('error', res?.message);
      }
    } catch (err) {
      console.error("Error:", err);
      openNotificationWithIcon("error",err.message)
    }
  };

  addDynamicRowWithData = (data, groupName) => {
    let gName = this.dynamicForm.getGroupKey(groupName);
    let finalData = [];
    // const actualData = this.dynamicForm.getStateObject(gName);
    // for (let i = 0; i < data.length - 1; i++) {
    //   setTimeout(() => {
    //     const { index } = this.state;
    //     const editInfo = {
    //       gKey: groupName,
    //       editable: true,
    //       ocd: true,
    //       id: -9000000 + (index + i),
    //       index: index + i,
    //       key: `${groupName}_table_row_${
    //         index + i
    //       }_${new Date().toISOString()}`,
    //       activity: data[i]?.activity,
    //       from_date: data[i]?.from_date,
    //       to_date: data[i]?.to_date,
    //       duration: data[i]?.duration,
    //       counting: data[i]?.counting
    //     };
    //     if (actualData?.length == data.length) return;
    //     this.onAddNew(editInfo);
    //   }, i * 100); // Small delay to ensure state updates properly
    // }
    // setTimeout(() => {
      // data.forEach((item, i) => {
      //   const obj = {
      //     editable: true,
      //     ocd: true,
      //     id: -9000000 + i,
      //     index: i,
      //     key: gName + "_table_row_" + i + "_" + new Date().toISOString(),
      //     activity: item.activity,
      //     from_date: item.from_date,
      //     to_date: item.to_date,
      //     duration: item.duration,
      //     counting: item.counting,
      //   };
      //   finalData.push(obj);
      //   setTimeout(() => {
      //     this.dynamicForm.eventChanin(laytimeEvent['eventLoops'],gName,i)
      //   },(data.length * 100) );
      // });
      // this.createStore.dispatch({
      //   type: "add",
      //   groupName: gName,
      //   formName: "new_laytime_calculation_form",
      //   index: undefined,
      //   value: {
      //     [gName]: finalData,
      //   },
      // });

    // }, data.length * 100);

    if (data && data.length > 0) {
      this.setState({...this.state,tableDataLoading:true})

      data.forEach((item, i) => {
        const obj = {
          editable: true,
          ocd: true,
          id: -9000000 + i,
          index: i,
          key: gName + "_table_row_" + i + "_" + new Date().toISOString(),
          activity: item.activity,
          from_date: item.from_date,
          to_date: item.to_date,
          duration: item.duration,
          counting: item.counting,
        };
        finalData.push(obj);
        setTimeout(() => {
          this.dynamicForm.eventChanin(laytimeEvent['eventLoops'],gName,i)
        },(data.length * 100) );
      });

      this.setState((prev)=>({...prev,
        dataArray: {...prev.dataArray,
          [groupName]:finalData,
        },
      }))

      this.createStore.dispatch({
        type: "add",
        groupName: gName,
        formName: "new_laytime_calculation_form",
        index: undefined,
        value: {
          [gName]: finalData,
        },
      });
      setTimeout(() => {
        this.setState({...this.state,tableDataLoading:false})
      }, 2000);

    }
  };

  bodyStyle = (index, objectKeys) => {
    return {
      marginBottom: index + 1 === objectKeys.length ? undefined : "5px",
    };
  };

  synkData = async (stateKey) => {
    let ele = {};
    if (this.props.frmConfig) {
      ele[stateKey] = await this.getDataArray(
        this.props.dataURL,
        this.props.frmConfig
      );
      this.setState({ ...this.state, ...ele });
    }
  };

  rowClassName = (record, config) => {
    let className = "";
    if (config && typeof config === "string") {
      let _config = JSON.parse(config);
      _config.colors.map((e) => {
        if (
          className === "" &&
          e.condition.indexOf(record[e.coloumnName] + "") >= 0
        ) {
          className = e.className;
        }
      });
    }
    return className;
  };

  render() {
    const regex = /^(\-+)|(\.+)$/g;
    const {
      responseData,
      dataArray,
      isDisplayOnly,
      isShowFixedColumn,
      groupKey,
      fullWidth,
      className,
      summary,
      disableTab,
    } = this.state;
    if (
      this.props.mainFormName !== "port_expense_pda_port_expense_adv" &&
      this.props.mainFormName !== "port_expense_fda" &&
      groupKey &&
      groupKey !== "" &&
      dataArray[groupKey] &&
      this.props.formData[groupKey] &&
      JSON.stringify(this.props.formData[groupKey]) !==
        JSON.stringify(dataArray[groupKey])
    ) {
      // alert('synk')
      this.synkData("dataArray");
    }

    if (
      dataArray["Port Itinerary"] &&
      groupKey &&
      groupKey === "portitinerary"
    ) {
      dataArray["Port Itinerary"].map((ele) => {
        if (ele.p_status == "102") {
          // dataArray['Port Itinerary'][0]['disabled'] = true
          ele["style"] = "background-color: #f2f2f2; color: #999;";
        }
      });
    }

    return (
      <>
        {/* {this.props.showDiv === true ? (
          <div className="table-group w-100p ssest"></div>
        ) : undefined} */}

        {responseData.objectKeys.map((e, i) => {
          let eg2 = "";
          if (typeof e !== "object") {
            eg2 = e.match(regex);
            eg2 = eg2 && eg2.hasOwnProperty("length") ? eg2[0] : "";
          }

          return (
            <div
              id={className}
              key={className + "_table-group_" + i}
              className={
                className +
                " table-group " +
                (fullWidth === true
                  ? "w-100p"
                  : `w-${responseData.group[0]["actual_width"]}p`) +
                " " +
                (disableTab && disableTab.hasOwnProperty("tabName")
                  ? "pa"
                  : undefined)
              }
            >
              {disableTab && disableTab.hasOwnProperty("message") ? (
                <div className="disable-info">{disableTab.message}</div>
              ) : disableTab && !disableTab.hasOwnProperty("message") ? (
                <div className="disable-info"></div>
              ) : undefined}
              
              {typeof e === "object" ? (
                <div
                  key={"row-" + i}
                  className="row"
                  style={{ marginLeft: "15px", marginRight: "15px" }}
                >
                  {e.map((e1, i1) => {
                    let eg = !e1.match(regex);
                    eg = eg && eg.hasOwnProperty("length") ? eg[0] : "";
                    return (
                      <div
                        className={
                          "col-md-" +
                          (12 / (100 / responseData.groupWidth[e1])).toString()
                        }
                        style={{ padding: 0, marginBottom: "5px" }}
                        key={e1 + "-" + i1}
                      >
                        {this.dynamicForm.getStateObject("processing") ===
                        true ? (
                          <div className="processing"></div>
                        ) : undefined}
                        
                        <Card
                          title={
                            e1 !== "g"
                              ? e1.length !== eg.length
                                ? e1
                                : undefined
                              : undefined
                          }
                          bodyStyle={{ padding: 0 }}
                          key={e1}
                        >

                          <Table
                            key={this.dynamicForm.getGroupKey(e1)}
                            className="inlineTable"
                            bordered
                            columns={responseData.columns[e1]}
                            dataSource={
                              dataArray &&
                              dataArray[e1] &&
                              dataArray[e1].length > 0
                                ? dataArray[e1]
                                : this.onAddNew({ editable: true, gKey: e1 })
                            }
                            pagination={false}
                            scroll={
                              typeof isShowFixedColumn === "string" &&
                              e1 === isShowFixedColumn
                                ? {
                                    x:
                                      responseData.objectLength[e1] > 400
                                        ? responseData.objectLength[e1]
                                        : 400,
                                  }
                                : typeof isShowFixedColumn === "object" &&
                                  isShowFixedColumn.indexOf(e1) > 0
                                ? {
                                    x:
                                      responseData.objectLength[e1] > 400
                                        ? responseData.objectLength[e1]
                                        : 400,
                                  }
                                : undefined
                            }
                            rowClassName={(record) =>
                              this.rowClassName(
                                record,
                                responseData &&
                                  responseData.group &&
                                  responseData.group.hasOwnProperty("length") &&
                                  responseData.group.length > 0 &&
                                  responseData.group[0].hasOwnProperty(
                                    "color_config"
                                  )
                                  ? responseData.group[0]["color_config"]
                                  : undefined
                              )
                            }
                            footer={(pg) => {
                              let summaryTotal = {},
                                sKeys = [],
                                sum = "",
                                button = "";

                              summary.map((s) => {
                                let fObj = this.dynamicForm.getStateObject(
                                  this.dynamicForm.getGroupKey(s.gKey)
                                );
                                if (
                                  this.dynamicForm.getGroupKey(s.gKey) ===
                                    this.dynamicForm.getGroupKey(e) &&
                                  fObj
                                ) {
                                  s.showTotalFor.map((st) => {
                                    summaryTotal[st] = 0;
                                  });
                                  fObj.map((e) => {
                                    s.showTotalFor.map((st) => {
                                      if (
                                        e.hasOwnProperty(st) &&
                                        s.dhm === true
                                      ) {
                                        summaryTotal[st] =
                                          (summaryTotal[st]
                                            ? summaryTotal[st]
                                            : 0) +
                                          this.dynamicForm.stringToDaysHoursMinutsObject(
                                            e[st],
                                            true
                                          );
                                      } else if (
                                        e.hasOwnProperty(st) &&
                                        !s.dhm
                                      ) {
                                        summaryTotal[st] =
                                          (summaryTotal[st]
                                            ? summaryTotal[st]
                                            : 0) +
                                          e[st] * 1;
                                      }
                                    });
                                  });
                                }
                              });
                              sKeys = Object.keys(summaryTotal);
                              if (sKeys && sKeys.length > 0) {
                                sum = (
                                  <Row className="summary-total">
                                    <Col span={4}>Total</Col>
                                    {sKeys.map((sk) => {
                                      return (
                                        <Col span={2} className="rt">
                                          {summaryTotal[sk].toFixed(2)}
                                        </Col>
                                      );
                                    })}
                                  </Row>
                                );
                              }
                              if (isDisplayOnly === false) {
                                if (
                                  responseData &&
                                  (!responseData.hasOwnProperty("group") ||
                                    (responseData.hasOwnProperty("group") &&
                                      !responseData.group) ||
                                    (responseData.hasOwnProperty("group") &&
                                      responseData.group &&
                                      !responseData.group.hasOwnProperty(
                                        "isShowAddButton"
                                      )) ||
                                    (responseData.hasOwnProperty("group") &&
                                      responseData.group &&
                                      responseData.group.hasOwnProperty(
                                        "isShowAddButton"
                                      ) &&
                                      responseData.group.isShowAddButton === 1))
                                ) {
                                  button = (
                                    <div className="text-center">
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          this.onAddNew({
                                            editable: true,
                                            gKey: e1,
                                          })
                                        }
                                      >
                                        Add New
                                      </Button>
                                    </div>
                                  );
                                }
                              }
                              return (
                                <>
                                  {sum}
                                  {button}
                                </>
                              );
                            }}
                          />
                        </Card>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className={
                    "col-md-" +
                    (
                      12 /
                      (100 /
                        (responseData.groupWidth[e]
                          ? responseData.groupWidth[e]
                          : 100))
                    ).toString()
                  }
                  style={this.bodyStyle(i, responseData.objectKeys)}
                  key={e + "-" + i}
                >
                  {this.dynamicForm.getStateObject("processing") === true ? (
                    <div className="processing"></div>
                  ) : undefined}
                  
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {e == "Cargos"
                            ? "Cargos/Tankers"
                            : e !== "g"
                            ? e.length !== eg2.length
                              ? e
                              : ""
                            : ""}
                        </div>
                        
                        {e === "TC Bunker Adjustment" && (
                          <Tooltip
                           title="User Guide">
                            <a onClick={() => this.openPdf(TCBUNKERADJUSTMENT)}>
                           <Icon icon= "fluent:book-information-24-regular"
                           style={{
                             padding:"0.2rem",
                             borderRadius:"5px",
                             fontSize:"1.4rem",
                             background: "#003e78",
                             color: "white",
                           }}
                         />       
                         </a>           
                         </Tooltip>
                        )}
                        

                        {this.props.frmCode ==
                          "tab_new_laytime_port_form_all_ports" && (
                            <span>
                            <label
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              marginLeft: "15px",
                              backgroundColor: "#003e78",
                              borderRadius: "5px",
                              padding: "2px 5px",
                              color: "#fff",
                              fontSize: "14px",
                            }}
                            onClick={this.downloadLaytimeExcelTemplate}
                          >
                            <span>Download Excel Template</span>
                          </label>
                            
                          <label
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              marginLeft: "15px",
                              backgroundColor: "#003e78",
                              borderRadius: "5px",
                              padding: "2px 5px",
                              color: "#fff",
                              fontSize: "14px",
                            }}
                          >
                            <input
                              type="file"
                              accept=".xlsx,.xls"
                              id="upload_laytime_activities"
                              onChange={(event) =>{
                                this.handleActivitiesUpload(event, e, e);
                                event.target.value = null;
                              }}
                              style={{ display: "none" }}
                            />
                            <span>+ Upload Activities</span>
                          </label>
                          </span>
                        )}

                        {this.props.groupKey == "portactivity" && (
                          <span>
                          <label
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              marginLeft: "15px",
                              backgroundColor: "#003e78",
                              borderRadius: "5px",
                              padding: "2px 5px",
                              color: "#fff",
                              fontSize: "14px",
                            }}
                            onClick={this.downloadExcelTemplate}
                          >
                            <span>Download Excel Template</span>
                          </label>
                          <label
                            style={{
                              cursor: "pointer",
                              fontSize: "18px",
                              marginLeft: "15px",
                              backgroundColor: "#003e78",
                              borderRadius: "5px",
                              padding: "2px 5px",
                              color: "#fff",
                              fontSize: "14px",
                            }}
                          >
                            <input
                              type="file"
                              accept=".xlsx,.xls"
                              id="upload_port_activities"
                              onChange={(event) =>{
                                this.handlePortActivitiesUpload(event, e, e);
                                event.target.value = null;
                              }}
                              style={{ display: "none" }}
                            />
                            <span>+ Upload Activities</span>
                          </label>
                          </span>
                        )}
                      </div>
                    }
                    bodyStyle={{ padding: 0 }}
                    key={e}
                  >
                    {this.state.tableDataLoading?
                    <Spin tip="Loading...!!" size="large">
                      <div style={{textAlign:"center", padding:"30px 20px" , fontWeight:"bold"}}> </div>
                    </Spin>
                    :
                    <Table
                      key={this.dynamicForm.getGroupKey(e)}
                      className="inlineTable"
                      bordered
                      columns={responseData.columns[e]}
                      dataSource={
                        dataArray && dataArray[e] && dataArray[e].length > 0
                          ? dataArray[e]
                          : this.onAddNew({ editable: true, gKey: e })
                      }
                      pagination={false}
                      scroll={
                        typeof isShowFixedColumn === "string" &&
                        e === isShowFixedColumn
                          ? {
                              x:
                                responseData.objectLength[e] > 400
                                  ? responseData.objectLength[e]
                                  : 400,
                            }
                          : typeof isShowFixedColumn === "object" &&
                            isShowFixedColumn.indexOf(e) >= 0
                          ? {
                              x:
                                responseData.objectLength[e] > 400
                                  ? responseData.objectLength[e]
                                  : 400,
                            }
                          : undefined
                      }
                      rowClassName={(record) =>
                        this.rowClassName(
                          record,
                          responseData &&
                            responseData.group &&
                            responseData.group.hasOwnProperty("length") &&
                            responseData.group.length > 0 &&
                            responseData.group[0].hasOwnProperty("color_config")
                            ? responseData.group[0]["color_config"]
                            : undefined
                        )
                      }
                      // onRow={(record) => ({
                      //   className:
                      //     (record.p_status && record.p_status == 102) || record.disable == 1 ? "disabled-row": null,
                      // })}
                      onRow={(record) => {
                        //console.log(record);
                        let style = {};
                        if (
                          typeof record?.description === "string" &&
                          record?.description.startsWith("Reverse")
                        ) {
                          style = { ...style, ...reverseRowColor };
                        }
                        return {
                          className:
                            (record.p_status && record.p_status == 102) ||
                            record.disable == 1
                              ? "disabled-row"
                              : null,
                          style: style,
                        };
                      }}
                      footer={(pg) => {
                        let summaryTotal = {},
                          sKeys = [],
                          sum = "",
                          button = "";
                        summary.map((s) => {
                          let fObj = this.dynamicForm.getStateObject(
                            this.dynamicForm.getGroupKey(s.gKey)
                          );
                          summaryTotal["dhm"] = s.dhm && s.dhm === true;
                          if (
                            this.dynamicForm.getGroupKey(s.gKey) ===
                              this.dynamicForm.getGroupKey(e) &&
                            fObj
                          ) {
                            s.showTotalFor.map((st) => {
                              summaryTotal[st] = 0;
                            });
                            fObj.map((e) => {
                              if (e) {
                                s.showTotalFor.map((st) => {
                                  if (e.hasOwnProperty(st) && s.dhm === true) {
                                    summaryTotal[st] =
                                      (summaryTotal[st]
                                        ? summaryTotal[st]
                                        : 0) +
                                      this.dynamicForm.stringToDaysHoursMinutsObject(
                                        e[st],
                                        true
                                      );
                                  } else if (e.hasOwnProperty(st) && !s.dhm) {
                                    summaryTotal[st] =
                                      (summaryTotal[st]
                                        ? summaryTotal[st]
                                        : 0) +
                                      e[st] * 1;
                                  }
                                });
                              }
                            });
                          }
                        });
                        let dhm = summaryTotal["dhm"];
                        delete summaryTotal["dhm"];
                        sKeys = Object.keys(summaryTotal);
                        if (
                          this.props.mainFormName !== "tc_make_payment" &&
                          this.props.mainFormName !== "tco_make_payment" &&
                          this.props.mainFormName !== "tc_commission_entry" &&
                          this.props.mainFormName !== "tco_commission_entry" &&
                          this.props.mainFormName !==
                            "freight-commission-invoice" &&
                          this.props.mainFormName !== "other_cost_detail_form"
                        ) {
                          if (sKeys && sKeys.length > 0) {
                            sum = (
                              <Row className="summary-total">
                                <Col span={4}>Total</Col>
                                {sKeys.map((sk) => {
                                  if (dhm === true) {
                                    let ele =
                                      this.dynamicForm.numberToDaysHoursMinutsObject(
                                        summaryTotal[sk].toFixed(2)
                                      );
                                    return (
                                      <Col span={2} className="rt">
                                        {this.dynamicForm.numberPad(
                                          ele["days"],
                                          2
                                        ) +
                                          "D" +
                                          this.dynamicForm.numberPad(
                                            ele["hours"],
                                            2
                                          ) +
                                          "H:" +
                                          this.dynamicForm.numberPad(
                                            ele["minutes"],
                                            2
                                          ) +
                                          "M"}
                                      </Col>
                                    );
                                  } else {
                                    return (
                                      <Col span={2} className="rt">
                                        {summaryTotal[sk].toFixed(2)}
                                      </Col>
                                    );
                                  }
                                })}
                              </Row>
                            );
                          }
                        }
                        if (
                          isDisplayOnly === false &&
                          responseData &&
                          responseData.hasOwnProperty("group") &&
                          responseData.group &&
                          ((responseData.group[0] &&
                            responseData.group[0].hasOwnProperty(
                              "isShowAddButton"
                            ) &&
                            responseData.group[0]["isShowAddButton"] === 1) ||
                            responseData.group.length === 0)
                        ) {
                          button = (
                            <div className="text-center">
                              <Button
                                type="link"
                                style={{ width: "100%" }}
                                onClick={() =>
                                  this.onAddNew({ editable: true, gKey: e })
                                }
                              >
                                Add New
                              </Button>
                            </div>
                          );
                        }

                        return (
                          <>
                            {sum}
                            {button}
                          </>
                        );
                      }}
                    />
        }
                  </Card>
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
}

export default IndexTableForm;
