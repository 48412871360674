import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Modal,
  Form,
  Tag,
  Row,
  Col,
  AutoComplete,
  message,
  Typography,
} from "antd";
// import ReminderDropdown from './reminder';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getAPICall, openNotificationWithIcon, postAPICall } from "../../../shared";
import ReminderDropdown from "./ReminderDropdown";

// Extend dayjs with the UTC plugin
dayjs.extend(utc);

const AddCalendar = ({ open, title, data, onClose, getCalendar }) => {
  const [formData, setFormData] = useState({
    owner_email: "",
    subject: "",
    body: "",
    start: "",
    end: "",
    location: "",
    attendees: [{ email: "", name: "", type: "required" }],
  });
  const [mailsection, setMailsection] = useState([]);

  const callMails = async () => {
    try {
      let url = `${process.env.REACT_APP_MT_URL}accounts/outlook-mails`;
      const response = await getAPICall(url);
      // console.log(response);
      if (response) {
        let mails = response?.data?.map((item) => item.email);
        // console.log("mails777", mails);
        if (mails !== null) {
          setMailsection(mails);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callMails();
    if (data) {
      const date = new Date(data);
      const formattedDate = date.toISOString().slice(0, 16); // Extract the YYYY-MM-DDTHH:MM part
      setFormData((prev) => ({
        ...prev,
        start: formattedDate,
      }));
    }
  }, [data]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [moreItems, setMoreItems] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [attendeename, setattendeename] = useState("");
  const [attendeemail, setattendeeMail] = useState("");

  const [selectedReminder, setSelectedReminder] = useState("15 m");

  const handleAutoCompleteChange = async (value) => {
    let url = `${process.env.REACT_APP_MT_URL}mail/all-mails?query=${value}`;
    const response = await getAPICall(url);
    // console.log(response);
    if (response) {
      if (response?.emails !== null) {
        let responseData = response?.emails;
        if (responseData.length > 0) {
          setSuggestions(responseData);
        }
      }
    }
  };

  useEffect(() => {
    handleAutoCompleteChange("");
  }, []);

  const handleSearchChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Set Reminder API
  const Reminder = async () => {
    try {
      if (formData?.subject && formData?.owner_email && selectedReminder) {
        let usermail = formData?.attendees?.map((item) => item?.email);
        const localDate = new Date(formData.start);
        localDate.setSeconds(0); // Set seconds to 0
        const utcDateString = localDate.toISOString().replace(".000Z", "Z"); // Convert to ISO and format
        let payload = {
          remainder_name: formData?.subject,
          date: utcDateString,
          time: selectedReminder,
          user_email: usermail,
          description: formData?.body,
        };
        let url = `${process.env.REACT_APP_MT_URL}accounts/add-reminder`;
        const response = await postAPICall(url, payload, "post");
        if (response.status === 200 && response?.data?.msg) {
          // toast.success(response?.data?.msg);
        }
      } else {
        message.info("Please fill all fields");
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      if (
        formData?.subject &&
        formData?.owner_email &&
        formData.start &&
        selectedReminder
      ) {
        if (
          formData?.attendees[0]?.email === "" ||
          formData?.attendees[0]?.name === ""
        ) {
          if (attendeemail === "") {
            message.info("Please add the attendee's email.");
            return;
          }
          if (attendeename === "") {
            message.info("Please add name.");
            return;
          }
          if (!(attendeemail === "") && !(attendeename === "")) {
            message.info("Please click on the add button.");
            return;
          }
        } else if (
          !(
            formData?.attendees[formData?.attendees?.length - 1]?.email === ""
          ) ||
          !(formData?.attendees[formData?.attendees?.length - 1]?.name === "")
        ) {
          if (!(attendeemail === "") && !(attendeename === "")) {
            message.info("Please click on the add button.");
            return;
          }
          if (!(attendeemail === "") || !(attendeename === "")) {
            if (attendeemail === "") {
              message.info("Please add the attendee' email.");
              return;
            }
            if (attendeename === "") {
              message.info("Please add name.");
              return;
            }
          }
        } else {
          addAttending();
        }
        setIsSubmitting(true);
        let payload = formData;
        let url = `${process.env.REACT_APP_MT_URL}mail/add-outlook-calendar`;
        const response = await postAPICall(url, payload, "post");
        await Reminder();
        if (response.status === true && response?.msg) {
          // toast.success(response?.data?.msg);
          setIsSubmitting(false);
          onClose()
          openNotificationWithIcon("success","Event Created")
          getCalendar()
          
        }
      } else {
        message.info("Please fill all fields");
      }
    } catch (error) {}
  };

  const handleChangeName = (event) => {
    setattendeename(event.target.value);
  };

  const addAttending = () => {
    if (attendeemail.length > 0 && attendeename?.length > 0) {
      const newAttendee = {
        email: attendeemail,
        name: attendeename,
        type: "required",
      };
      let updatedAttendees = [];
      if (formData.attendees[0].email?.length > 0) {
        updatedAttendees = [...formData.attendees, newAttendee];
      } else {
        updatedAttendees = [newAttendee];
      }

      setFormData((prev) => ({
        ...prev,
        attendees: updatedAttendees,
      }));
      setattendeename("");
      setattendeeMail("");
    } else if (
      formData?.attendees?.length > 0 &&
      attendeemail.length === 0 &&
      attendeename?.length === 0
    ) {
    } else {
      message.info("Please add both attendee email and name.");
    }
  };

  const handleRemoveEmail = (index) => {
    setFormData((prev) => {
      const updatedAttendees = prev.attendees.filter((_, i) => i !== index);
      return {
        ...prev,
        attendees:
          updatedAttendees.length > 0
            ? updatedAttendees
            : [{ email: "", name: "", type: "required" }],
      };
    });
  };

  const handleCustomReminder = (value) => {
    setSelectedReminder(value);
  };

  // console.log("formdataa", formData);

  return (
    <Modal
      open={open}
      onCancel={() => onClose()}
      footer={null}
      title="Add Calendar"
      width={600}
      bodyStyle={{ padding: "20px" }}
    >
      <div style={{marginBottom:"20px"}}>
      <ReminderDropdown onCustomReminder={handleCustomReminder} />
      </div>
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Form.Item label="Title" name="subject" required>
          <Input
            size="large"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            fullWidth
          />
        </Form.Item>

        <Row gutter={6}>
          <Col span={12}>
            <AutoComplete
              size="large"
              options={suggestions.map((email) => ({ value: email }))}
              onSearch={handleAutoCompleteChange}
              value={attendeemail}
              onChange={(event, newValue) => {
                // console.log("attendee email", newValue);
                setattendeeMail(newValue.value);
              }}
              placeholder="Add Attendees"
            />
          </Col>
          <Col span={9}>
            <Input
              placeholder="Name"
              name="Name"
              value={attendeename}
              onChange={handleChangeName}
              size="large"
              style={{marginTop:"8px"}}
            />
          </Col>
          <Col span={2}>
            <Button
              onClick={addAttending}
              type="primary"
              icon="+"
              style={{ width: "100%", marginTop:"8px" }}
            />
          </Col>
        </Row>

        <div style={{ marginBottom: 16 }}>
          {formData?.attendees?.length > 0 &&
            formData?.attendees[0].email?.length > 0 && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {formData?.attendees?.map((attendee, index) => (
                  <div>
                    <Tag
                      closable
                      onClose={() => handleRemoveEmail(index ? index - 1 : 0)}
                    >
                      {attendee.email}-
                      {attendee.name}
                    </Tag>
                  </div>
                ))}
              </div>
            )}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item label="Start Time" name="start">
            <Input
              size="large"
              type="datetime-local"
              name="start"
              value={formData.start}
              onChange={handleChange}
              style={{ width: "250px" }}
              placeholder="Start Date"
            />
          </Form.Item>

          <Form.Item label="End Time" name="end">
            <Input
              size="large"
              type="datetime-local"
              name="end"
              value={formData.end}
              onChange={handleChange}
              style={{ width: "250px" }}
            />
          </Form.Item>
        </div>

        <Form.Item label="Description" name="body">
          <Input.TextArea
            size="small"
            name="body"
            value={formData.body}
            onChange={handleChange}
            rows={3}
            bordered
          />
        </Form.Item>
        <Form.Item label="Location" name="location">
          <Input
            placeholder="Location"
            name="location"
            value={formData.location}
            size="large"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item label="Owner Email" name="owner">
          <AutoComplete
            size="large"
            options={mailsection.map((email) => ({ value: email }))}
            onSearch={handleAutoCompleteChange}
            value={formData?.owner_email}
            onChange={(event, newValue) =>
              setFormData((prev) => ({ ...prev, owner_email: newValue.value }))
            }
            placeholder="Owner Email"
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Save Event
        </Button>
      </Form>
    </Modal>
  );
};

export default AddCalendar;
