import React, { useEffect, useState } from "react";
import { Form, Button, Select, Spin, Layout, Drawer, Modal, Alert } from "antd";
import NormalFormIndex from "../../../../../shared/NormalForm/normal_from_index";
import {
  DeleteOutlined,
  MenuFoldOutlined,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import URL_WITH_VERSION, {
  apiDeleteCall,
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../../../../shared";
import redux_state from "../../../../../services/redux_state";

const FormItem = Form.Item;
const Option = Select.Option;
const { Content } = Layout;
const GenerateTonnageEnquiry = (props) => {
  const [state, setState] = useState({
    refreshForm: false,
    frmName: "tcov_full_tonnage_enq_form",
    formData: props.formData || {},
    frmVisible: true,
    id: props.id,
  });

  const saveFormData = (vData, innercb) => {
    let formData1 = redux_state.reduxStore?.["tcov_full_estimate_form"];
    const { estimate_id } = formData1;
    const vDatawithId = { ...vData, tcov_id: estimate_id };
    let type = "save";
    let suMethod = "POST";

    if (vData.hasOwnProperty("id")) {
      type = "update";
      suMethod = "PUT";
    }
    const { frmName } = state;
    setState((prevState) => ({ ...prevState, frmVisible: false }));
    let suURL = `${URL_WITH_VERSION}/voyagecargo/tonnage-enquiry-${type}?frm=${frmName}`;
    postAPICall(suURL, vDatawithId, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        setState(
          (prevState) => ({
            ...prevState,
            formData: {
              id: data.data.id,
            },
          }),
          () => {
            // onEditClick(data.data.id);
          }
        );
      } else {
        openNotificationWithIcon("error", data.message);

        setState((prevState) => ({
          ...prevState,
          frmVisible: true,
        }));
      }
    });
  };

  const onEditClick = async (id) => {
    if (props.id) {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/voyagecargo/tonnage-enquiry-edit?ae=${id}`
      );
      let respData = response.data;
      setState((prevState) => ({
        ...prevState,
        formData: respData,
        // frmVisible: true,
      }));
    }
  };

  const deleteData = (id, tcov_id, innercb) => {
    let URL = `${URL_WITH_VERSION}/voyagecargo/tonnage-enquiry-delete`;
    if (id) {
      apiDeleteCall(URL, { id: id }, (resp) => {});
    } else {
      openNotificationWithIcon("info", "Please fill the form first.", 3);
    }
  };

  const sendMail = async (vData) => {
    const selectedCargo = await updateCargoName();

    let id = null;
    let sendData = {
      recepients: vData.send_to,
      message: `<h3>Owner: ${vData.owner} </h3>
      <h3>Open From/To: ${vData.owner} </h3>
      <h3>Open Postion: ${vData.open_from} </h3>
      <h3>Bunker On Delivery: ${vData.open_from} </h3>
      <h3>LOA: ${vData.loa} </h3>
      <h3>Beam: ${vData.beam} </h3>
      <h3>Hatches: ${vData.hatches}</h3>
      <h3>Speed:${vData.speed}</h3>
      <h3>Cons:${vData.cons}</h3>
      <h3>DWT:${vData.dwt}</h3>
      <h3>Flag:${vData.dwt}</h3>
      <h3>Built Year:${vData.built_year}</h3>
      <h3>Other Vessel Details:${vData.vessel_other_details}</h3>
      <h3>Other Vessel Details:${selectedCargo.bunker_on_delivery}</h3>
      <h3>Other Vessel Details:${selectedCargo.vessel_name}</h3>
      `,
      subject: vData.subject,
      link: "",
    };

    if (!props.id) {
      let type = "save";
      let suMethod = "POST";
      let suURL = `${URL_WITH_VERSION}/voyagecargo/tonnage-enquiry-${type}?frm=${state.frmName}`;
      await postAPICall(suURL, vData, suMethod, (data) => {
        if (data && data.data) {
          // openNotificationWithIcon("success", data.message);
          setState(
            (prevState) => ({
              ...prevState,
              formData: {
                id: data.row.id,
              },
            }),
            () => {
              onEditClick(data.data.id);
            }
          );
          id = data.row.id;
          postAPICall(
            `${URL_WITH_VERSION}/email/send`,
            sendData,
            "post",
            (dataR) => {
              if (dataR && dataR.data) {
                openNotificationWithIcon("success", dataR.message);

                postAPICall(
                  `${URL_WITH_VERSION}/voyagecargo/send-tonnage-enquiry`,
                  { id: data.row.id },
                  "post",
                  (dataRes) => {}
                );
              } else {
                openNotificationWithIcon("error", dataR.message);
              }
            }
          );
        } else {
          openNotificationWithIcon("error", data.message);

          setState((prevState) => ({
            ...prevState,
            frmVisible: true,
          }));
        }
      });
    } else if (props.id) {
      postAPICall(
        `${URL_WITH_VERSION}/email/send`,
        sendData,
        "post",
        (dataR) => {
          if (dataR && dataR.data) {
            openNotificationWithIcon("success", dataR.message);

            postAPICall(
              `${URL_WITH_VERSION}/voyagecargo/send-tonnage-enquiry`,
              { id: props.id },
              "post",
              (dataRes) => {}
            );
          } else {
            openNotificationWithIcon("error", dataR.message);
          }
        }
      );
    } else {
    }
  };

  const updateCargoName = async () => {
    // Initialize an object to store cargo names

    // Array containing selectors for the elements to observe
    const var_drop = [
      // 'div[name="open_position"]',
      'div[name="bunker_on_delivery"]',
      'div[name="vessel_name"]',
      'div[name="dwt"]',
      'div[name="open_position"]',
    ];
    let arr = [];
    // Loop through the selectors
    for (let i = 0; i < var_drop.length; i++) {
      const selector = var_drop[i];

      // Select the element using the current selector
      const cargoDiv = document.querySelector(selector);

      // Check if the cargoDiv exists and has child elements
      if (cargoDiv && cargoDiv.children.length > 0) {
        let dropdownName = "";
        // Iterate through the child elements
        Array.from(cargoDiv.children).forEach(function (child) {
          // Check if the child element has text content
          if (child.textContent.trim() !== "") {
            dropdownName = child.textContent.trim();
            arr.push(dropdownName);
          }
        });
        // Store the cargo name in var_drop_name based on the index
      }
    }

    let obj = {
      // open_position: arr[0],
      bunker_on_delivery: arr[0],
      vessel_name: arr[1],
      dwt: arr[2],
      open_position: arr[3],
      // freight_type: arr[1],
      // load_port_options: arr[2],
      // discharge_port_options: arr[3],
      // load_term: arr[4],
      // discharge_load_term: arr[5],
    };

    return obj;
  };

  return (
    <div className="tcov-wrapper full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                <article className="article">
                  <div className="box box-default">
                    <div className="box-body common-fields-wrapper">
                      {state.refreshForm === false && state.formData ? (
                        <NormalFormIndex
                          key={"key_" + state.frmName + "_0"}
                          formClass="label-min-height"
                          formData={state.formData}
                          showForm={true}
                          frmCode={state.frmName}
                          formDevideInCols={1}
                          showToolbar={
                            !props.isMailSent && [
                              {
                                isLeftBtn: [
                                  {
                                    key: "s1",
                                    isSets: [
                                      // {
                                      //   id: "7",
                                      //   key: "menu-fold",
                                      //   type: <MenuFoldOutlined />,
                                      //   withText: "List",
                                      //   showToolTip: true,
                                      //   event: "menu-fold",
                                      // },
                                      // { id: '8', key: 'plus', type: 'plus', withText: '', event: (key, saveData, innerCB) => (innerCB && typeof innerCB === 'function' ? innerCB() : undefined) },
                                      {
                                        id: "2",
                                        key: "save",
                                        type: <SaveOutlined />,
                                        withText: "Save",
                                        showToolTip: true,
                                        event: (key, saveData, innerCB) => {
                                          saveFormData(saveData, innerCB);
                                        },
                                      },
                                      {
                                        id: "3",
                                        key: "delete",
                                        type: <DeleteOutlined />,
                                        withText: "Delete",
                                        showToolTip: true,
                                        event: (key, saveData, innerCB) => {
                                          if (
                                            saveData["id"] &&
                                            saveData["id"] > 0
                                          ) {
                                            Modal.confirm({
                                              title: "Confirm",
                                              content:
                                                "Are you sure, you want to delete it?",
                                              onOk: () =>
                                                deleteData(
                                                  saveData["id"],
                                                  saveData["tcov_id"],
                                                  innerCB
                                                ),
                                            });
                                          }
                                        },
                                      },
                                      {
                                        id: "4",
                                        key: "send",
                                        type: <SendOutlined />,
                                        withText: "send",
                                        showToolTip: true,
                                        event: (key, saveData, innerCB) => {
                                          sendMail(saveData);
                                        },
                                      },
                                    ],
                                  },
                                ],
                                isRightBtn: [
                                  {
                                    key: "rbs1",
                                    isSets: [
                                      // {
                                      //   key: "reports",
                                      //   isDropdown: 0,
                                      //   withText: "Reports",
                                      //   type: "",
                                      //   menus: [],
                                      // },
                                    ],
                                  },
                                ],
                                isResetOption: false,
                              },
                            ]
                          }
                          inlineLayout={true}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default GenerateTonnageEnquiry;
