import { Icon } from "@iconify/react/dist/iconify.js";
import { message, Tooltip } from "antd";
import moment from "moment";
// import { useSelector } from "react-redux";
import Swal from "sweetalert2";
export const EMAILS_PER_PAGE = 30;

// const allMails = useSelector((state) => state.email.allEmails);

export const getAvtarColors = () => {

  return {
    A: '#FF5733', B: '#FFC300', C: '#FF9C73', D: '#FF6699', E: '#66FF99',
    F: '#FF6666', G: '#BF0000', H: '#6666FF', I: '#FF9933', J: '#CC99FF',
    K: '#d12a1b', L: '#B8001F', M: '#BF2EF0', N: '#3366FF', O: '#FF6699',
    P: '#b719bf', Q: '#FF6666', R: '#FF99CC', S: '#898121', T: '#FF9933',
    U: '#CC99FF', V: '#99FFCC', W: '#cb09ed', X: '#FFFF66', Y: '#FF5733',
    Z: '#FFC300'
  }

}

export const events = {
  handleAcknowledgment: (event, callback) => {
    const acknowledgment = JSON.parse(event.data);
    if (acknowledgment.event === "spamMail" || acknowledgment.event === "markImportant" || acknowledgment.event === "markRead") {
      if (acknowledgment.status && acknowledgment.content?.AcknowledgmentID) {
        callback(true); // Successful acknowledgment
      } else {
        callback(false); // Failed acknowledgment
      }
    }
  },

  markImportant: (userInput, callback) => {
    console.log("socket event", userInput);
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      if (userInput.important === true) {
        console.log("Marked as important.");
      } else {
        console.log("Marked as not important.");
      }
    } else {
      console.error("WebSocket is not open.");
    }
  },

  markSpam: (userInput, callback) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },

  markIsRead: (userInput, callback) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },

  deleteMail: (userInput) => {
    console.log("DELETE SOCKET", userInput);
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }

    if (userInput?.trashed) {
      console.log("Mail Deleted");
    }
  },

  sendEvent: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      console.log("userinput", userInput);
    } else {
      console.error("WebSocket is not open.");
    }
  },

  applylabel: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      console.log("Label Applied.");
    } else {
      console.error("WebSocket is not open.");
    }
  },

  sendDraftMail: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },

  applytags: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },

  archivedMail: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
      if (userInput.Archive) {
        console.log("Conversation archived.");
      } else {
        console.log("Conversation unarchived.");
      }
    } else {
      console.error("WebSocket is not open.");
    }
  },

  chatEvent: (userInput) => {
    if (window.mySocketMail?.readyState === WebSocket.OPEN) {
      window.mySocketMail.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },

  groupChatEvent: (userInput) => {
    if (window.myGroupSocket?.readyState === WebSocket.OPEN) {
      window.myGroupSocket.send(JSON.stringify(userInput));
    } else {
      console.error("WebSocket is not open.");
    }
  },
};


export const handleSocketEvent1 = async (payLoad) => {
  try {
    // Check if webSocketManager is available and WebSocket is open
    const webSocketManager = window?.webSocketManager;
    if (
      webSocketManager &&
      webSocketManager.socket.readyState === WebSocket.OPEN
    ) {
      // Create a unique responseId to track this request
      const responseId = Date.now();

      // Create a promise to handle the delete response
      const responsePromise = new Promise((resolve, reject) => {
        // Define a temporary event handler
        const handleResponse = (response) => {
          if (response?.content?.AcknowledgmentID) {
            //   if (payLoad?.event === "spamMail") {
            //     payLoad?.Spam
            //       ? openNotificationWithIcon(
            //           "success",
            //           "Mail successfully marked as spam."
            //         )
            //       : openNotificationWithIcon(
            //           "success",
            //           "Email successfully unmarked as spam."
            //         );
            //   } else if (payLoad?.event === "archivedMail") {
            //     payLoad?.Archive
            //       ? openNotificationWithIcon(
            //           "success",
            //           "Mail successfully archived."
            //         )
            //       : openNotificationWithIcon(
            //           "success",
            //           "Email successfully removed from archive."
            //         );
            //   }
            //   setCurrentMail({});
            //   handleCurrentMailClose();
            //   const filteredMails = allMails?.filter(
            //     (mail) => mail?._id !== payLoad?._id
            //   );
            //   setMailData(filteredMails);

            return true;
          } else {
            message.error("Please try again.");
          }
          resolve(response);
          // Remove the event listener after handling the response
          webSocketManager.off(payLoad?.event, handleResponse);
        };

        // Register the temporary event handler
        webSocketManager.on(payLoad?.event, handleResponse);
      });

      // Send payload with the unique responseId
      await webSocketManager.send({ ...payLoad, responseId });

      // Await the response promise
      await responsePromise;
    } else {
      message.error("Please try again.");
      console.error("WebSocket is not open or not initialized.");
    }
  } catch (error) {
    console.error("WebSocket error:", error);
    message.error("WebSocket error");
  }
};

export const sendWebSocketMessage = async (event, payload, handleAcknowledgment) => {
  try {
    const webSocketManager = window?.webSocketManager;
    if (
      webSocketManager &&
      webSocketManager.socket.readyState === WebSocket.OPEN
    ) {
      const responseId = Date.now();

      const responsePromise = new Promise((resolve, reject) => {
        const handleResponse = (response) => {
          if (handleAcknowledgment(response)) {
            resolve(response);
          } else {
            reject(new Error("Acknowledgment failed"));
          }
          webSocketManager.off(event, handleResponse);
        };

        webSocketManager.on(event, handleResponse);
      });

      await webSocketManager.send({ ...payload, responseId });

      await responsePromise;
    } else {
      message.error("Please try again.");
      console.error("WebSocket is not open or not initialized.");
    }
  } catch (error) {
    console.error("WebSocket error:", error);
    message.error("Failed due to WebSocket error");
  }
};


export function formatArrayToString(array) {
  return `${array}`;
}

export const disabledDate = (current) => {
  // Disable dates after today
  return current && current > moment().endOf("day");
};

// export function formatTimestamp(timestamp) {
//   // Check if the timestamp is in seconds (10 digits) and convert to milliseconds
//   if (timestamp.toString().length === 10) {
//     timestamp = timestamp * 1000; // Convert to milliseconds
//   }

//   const date = new Date(timestamp); // Use the timestamp directly after checking format
//   const now = new Date();

//   const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
//   const yesterday = new Date(today);
//   yesterday.setDate(today.getDate() - 1);

//   const isToday = date >= today;
//   const isYesterday = date >= yesterday && date < today;

// Convert time to IST (Indian Standard Time) manually
const toIST = (date) => {
  // IST is UTC +5:30, so adding 5.5 hours to UTC time
  // const istOffset = 5.5 * 60 * 60 * 1000; 
  const istOffset = 0; // in milliseconds
  return new Date(date.getTime() + istOffset);
};

//   const dateInIST = toIST(date); // Convert date to IST

// Helper to format time as `HH:MM AM/PM`
const formatTime = (date) => {
  const options = { timeZone: 'Asia/Kolkata', hour: 'numeric', minute: 'numeric', hour12: true };
  return date.toLocaleTimeString(undefined, options);
};

//   // Helper to format date as `Month Day` (e.g., August 15)
//   const formatDate = (date) => {
//     const options = { month: 'long', day: 'numeric' };
//     return date.toLocaleDateString(undefined, options);
//   };

//   // Helper to format date as `Month Day, Year` (e.g., August 15, 2023)
//   const formatDateWithYear = (date) => {
//     const options = { month: 'long', day: 'numeric', year: 'numeric' };
//     return date.toLocaleDateString(undefined, options);
//   };

//   if (isToday) {
//     return formatTime(dateInIST); // If today, return time in IST
//   } else if (isYesterday) {
//     return `Yesterday at ${formatTime(dateInIST)}`; // If yesterday, return "Yesterday"
//   } else if (now.getFullYear() === date.getFullYear()) {
//     return formatDate(dateInIST); // If this year but not today or yesterday, return Month and Day
//   } else {
//     return formatDateWithYear(dateInIST); // If older than this year, return Month, Day, Year
//   }
// }


export const getInitial = (sender) => {
  if (!sender) return "A";
  const firstAlpha = sender.match(/[A-Za-z]/);
  return firstAlpha ? firstAlpha[0].toUpperCase() : "A";
};

export const returnccbcc = (mail) => {
  let arr = [];
  if (mail?.mailClient == "outlook") {
    if (mail.ccRecipients || mail.bccRecipients) {
      mail.ccRecipients.map((data) => {
        arr.push({
          name: data?.emailAddress?.name,
          email: data?.emailAddress?.address,
        });
      });
    }
    if (mail.bccRecipients) {
      mail.bccRecipients.map((data) => {
        arr.push({
          name: data?.emailAddress?.name,
          email: data?.emailAddress?.address,
        });
      });
    }

    // [{emailAddress: {address: "dry@trinity-ship.com", name: "Trinity-Ship Brokers"}}]
  } else if (mail?.mailClient == "custom") {
    if (mail.ccRecipients || mail.bccRecipients) {
      mail.CC.map((data) => {
        const parsedNail = parseSender(data);
        arr.push({
          name: parsedNail?.name,
          email: parsedNail?.email,
        });
      });
    }
    if (mail.BCC) {
      mail.BCC.map((data) => {
        const parsedNail = parseSender(data);
        arr.push({
          name: parsedNail?.name,
          email: parsedNail?.email,
        });
      });
    }
  } else {
    if (mail.ccRecipients || mail.bccRecipients) {
      mail.CC.map((data) => {
        const parsedNail = parseSender(data);
        arr.push({
          name: parsedNail?.name,
          email: parsedNail?.email,
        });
      });
    }
    if (mail?.BCC) {
      Array.isArray(mail?.BCC) &&
        mail?.BCC?.map((data) => {
          const parsedNail = parseSender(data);
          arr.push({
            name: parsedNail?.name,
            email: parsedNail?.email,
          });
        });
    }
  }
  return arr;
};

export function parseSender(sender) {
  // debugger
  let name = "";
  let email = "";
  if (sender) {
    // Regular expression to match the name and email pattern
    const match = sender?.match(/(.*?)\s*<(.+?)>/);
    let name = "";
    let email = "";

    if (match) {
      // Extract name and email if pattern is matched
      name = match[1].trim().replace(/['"]/g, ""); // Remove quotes from the name
      email = match[2].trim();
    } else {
      // If no match, check if the sender string is a valid email
      const emailMatch = sender?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (emailMatch) {
        email = sender.trim();
      }
    }

    // If email is still empty, set name as the sender string without quotes
    if (!email) {
      name = sender.trim().replace(/['"]/g, "");
    }

    return { name, email };
  }
  return { name, email };
}

export const formatNumber = (num) => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1) + "B"; // Billion
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + "M"; // Million
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + "K"; // Thousand
  } else {
    return num?.toString(); // Less than thousand
  }
};

const getFileType = (filename) => {
  const wordRegex = new RegExp("(.*?).(docx|doc)$");
  const isWordFile = wordRegex.test(filename);

  const excelRegex = new RegExp("(.*?).(xlsx|xls)$");
  const isExcelFile = excelRegex.test(filename);

  const pdfRegex = new RegExp("(.*?).(pdf)$");
  const isPDFFile = pdfRegex.test(filename);

  const imageRegex = new RegExp("(.*?).(jpg|jpeg|png|gif)$");
  const isImageFile = imageRegex.test(filename);

  let fileType = "";
  if (isWordFile) {
    fileType = "word";
  }

  if (isExcelFile) {
    fileType = "excel";
  }

  if (isPDFFile) {
    fileType = "pdf";
  }

  if (isImageFile) {
    fileType = "image";
  }

  return fileType;
};

const attachmentsBaseURL = "https://files.theoceann.com";

export const getAttachmentsURL = (email, attachment, GmailMessageId) => {
  const { attachment_id, filename } = attachment;

  const fileType = getFileType(filename);

  const url = `${attachmentsBaseURL}/view_attachment?email=${email}&attachment_id=${attachment_id}
      &GmailMessageId=${GmailMessageId}&file_name=${filename}`;

  return { url, fileType };
};

export const capitaliseFirstLetter = (userInput) => {
  try {
    if (!userInput) {
      return userInput;
    }

    if (Array.isArray(userInput)) {
      // If userInput is an array, map over it to capitalize each element
      return userInput.map((data) =>
        data ? data.slice(0, 1).toUpperCase() + data.slice(1) : data
      );
    }
    // For a single string, capitalize the first letter and return
    if (typeof userInput === "string") {
      // return userInput?.slice(0, 1).toUpperCase() + userInput.slice(1);
      return userInput
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      // If userInput is not a string (e.g., number), return it as is
      return [userInput];
    }
  } catch (error) {
    console.log(error);
  }
};

export const rowDataFormatter = (userInput) => {
  try {
    {
      if (Array.isArray(userInput)) {
        return (
          <div>
            {userInput.map((owner, index) => (
              <div key={index}>{owner}</div>
            ))}
          </div>
        );
      } else {
        return userInput;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const formatDate121 = (time, dateString, fullDate) => {
  const currentDate = new Date();
  const givenDate = new Date(Date.parse(dateString));

  // Extracting day, month, and year separately from givenDate
  const day = givenDate.getDate();
  const monthIndex = givenDate.getMonth();
  const year = givenDate.getFullYear();

  // const day = givenDate.getDate();
  // const monthIndex = givenDate.getMonth();
  // const year = givenDate.getFullYear();
  const hours = givenDate.getHours();
  const minutes = givenDate.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (!fullDate) {
    if (isNaN(givenDate.getTime())) {
      return "N/A"; // Handle invalid date string
    } else if (givenDate.toDateString() === currentDate.toDateString()) {
      return time;
    } else if (year === currentDate.getFullYear()) {
      // If the year is the same as the current year, return only the date and month
      return `${day} ${monthNames[monthIndex]}`;
    } else {
      // Otherwise, return the full date
      return `${day} ${monthNames[monthIndex]} ${year}`;
    }
  } else
    return `${day} ${monthNames[monthIndex]} ${year} ${formattedHours}:${minutes} ${ampm}`;
};

export const convertToCSV = (objArray) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  const headers = Object.keys(array[0]).join(",");
  str += headers + "\r\n";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
};

export const colorArrayCustomTag = [
  '#cadae5', // Light blue
  '#91ddb1',
  '#d3b37f',
  '#ded6e1',
  '#ded0df',
  '#e5c59f',
  '#ebe1b0'
];
export const chipStyles = [
  {
    color: "#4B0082",
    border: "1px solid #4B0082",
    backgroundColor: "#cadae5",
    fontSize: "10px",
  },
  {
    color: "#2B5179",
    border: "1px solid #2B5179",
    backgroundColor: "#d3b37f",
    fontSize: "10px",
  },
  {
    color: "#556B2F",
    border: "1px solid #556B2F",
    backgroundColor: "#F0E68C",
    fontSize: "10px",
  },
  {
    color: "#D2691E",
    border: "1px solid #D2691E",
    backgroundColor: "#FFDEAD",
    fontSize: "10px",
  },
  {
    color: "#B22222",
    border: "1px solid #B22222",
    backgroundColor: "#FFDEAD",
    fontSize: "10px",
  },
  {
    color: "#146F6F",
    border: "1px solid #146F6F",
    backgroundColor: "#E2FDFD",
    fontSize: "10px",
  },
  {
    color: "#6F551B",
    border: "1px solid #6F551B",
    backgroundColor: "#FDF4E2",
    fontSize: "10px",
  },
  {
    color: "#3D1B6F",
    border: "1px solid #3D1B6F",
    backgroundColor: "#EDE2FD",
    fontSize: "10px",
  },
  {
    color: "#6F3D1B",
    border: "1px solid #6F3D1B",
    backgroundColor: "#FDE9E2",
    fontSize: "10px",
  },
  {
    color: "#1B6F44",
    border: "1px solid #1B6F44",
    backgroundColor: "#E2FDF1",
    fontSize: "10px",
  },
  {
    color: "#1B4F6F",
    border: "1px solid #1B4F6F",
    backgroundColor: "#E2F2FD",
    fontSize: "10px",
  },
  {
    color: "#4F6F1B",
    border: "1px solid #4F6F1B",
    backgroundColor: "#F4FDE2",
    fontSize: "10px",
  },
  {
    color: "#1B6F6F",
    border: "1px solid #1B6F6F",
    backgroundColor: "#E2FDFD",
    fontSize: "10px",
  },
  {
    color: "#6F5A1B",
    border: "1px solid #6F5A1B",
    backgroundColor: "#FDF1E2",
    fontSize: "10px",
  },
  {
    color: "#1B3D6F",
    border: "1px solid #1B3D6F",
    backgroundColor: "#E2EDF2",
    fontSize: "10px",
  },
  {
    color: "#3D6F1B",
    border: "1px solid #3D6F1B",
    backgroundColor: "#EDFDE2",
    fontSize: "10px",
  },
  {
    color: "#1B6F2B",
    border: "1px solid #1B6F2B",
    backgroundColor: "#E2FDF4",
    fontSize: "10px",
  },
  {
    color: "#6F2B1B",
    border: "1px solid #6F2B1B",
    backgroundColor: "#FDE2E2",
    fontSize: "10px",
  },
  {
    color: "#2B6F1B",
    border: "1px solid #2B6F1B",
    backgroundColor: "#E2FDE2",
    fontSize: "10px",
  },
  {
    color: "#6F1B2B",
    border: "1px solid #6F1B2B",
    backgroundColor: "#FDE2E2",
    fontSize: "10px",
  },
];

export const timeHandler = (mail) => {


  // console.log(mail?.Date, mail)
  let givenDate = new Date(mail?.Date);
  // console.log(givenDate, " given data")
  if (mail?.mailClient === 'custom') {
    const inputDate = JSON.stringify(mail?.Date)

    // Replace 'IST' with 'UTC+05:30' to ensure the Date object parses the time zone correctly
    const modifiedDate = inputDate.replace('IST', 'UTC+05:30');

    // Parse the modified date string into a Date object
    const parsedDate = new Date(modifiedDate);

    // Convert the Date object to ISO 8601 format
    const isoDate = parsedDate.toISOString();

    givenDate = new Date(isoDate);

    // Check if the parsed date is valid
    // let parseddate = JSON.stringify(mail?.Date)
    // givenDate = new Date(parseddate);
  }
  const today = new Date();
  if (
    givenDate.getFullYear() === today.getFullYear() &&
    givenDate.getMonth() === today.getMonth() &&
    givenDate.getDate() === today.getDate()
  ) {
    // If the given date is today, display the time in 12-hour format with AM/PM
    return givenDate.toLocaleTimeString('en-US', {
      timeZone: 'Asia/Kolkata',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  // Check if the given date is in the current year
  if (givenDate.getFullYear() === today.getFullYear()) {
    // Display the date and month, e.g., "27 May"
    return `${givenDate.getDate()} ${givenDate.toLocaleString('en-GB', { month: 'short' })}`;
  }

  // Otherwise, display the full date, e.g., "27 May 2024"
  return `${givenDate.getDate()} ${givenDate.toLocaleString('en-GB', { month: 'short' })} ${givenDate.getFullYear()}`;

}

export function formatTimestamp(timestamp) {
  // Check if the timestamp is in seconds (10 digits) and convert to milliseconds
  if (timestamp.toString().length === 10) {
    timestamp = timestamp * 1000; // Convert to milliseconds
  }

  const date = new Date(timestamp); // Use the timestamp directly after checking format
  const now = new Date();

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday = date >= today;
  const isYesterday = date >= yesterday && date < today;

  // Convert time to IST (Indian Standard Time) manually
  const toIST = (date) => {
    // IST is UTC +5:30, so adding 5.5 hours to UTC time
    // const istOffset = 5.5 * 60 * 60 * 1000; // in milliseconds
    const istOffset = 0; // in milliseconds
    return new Date(date.getTime() + istOffset);
  };

  const dateInIST = toIST(date); // Convert date to IST
  // Helper to format time as `HH:MM AM/PM`
  const formatTime = (date) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return date.toLocaleTimeString(undefined, options);
  };

  // Helper to format date as `Month Day` (e.g., August 15)
  const formatDate = (date) => {
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  // Helper to format date as `Month Day, Year` (e.g., August 15, 2023)
  const formatDateWithYear = (date) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  if (isToday) {
    return formatTime(dateInIST); // If today, return time in IST
  } else if (isYesterday) {
    return `Yesterday at ${formatTime(dateInIST)}`; // If yesterday, return "Yesterday"
  } else if (now.getFullYear() === date.getFullYear()) {
    return formatDate(dateInIST); // If this year but not today or yesterday, return Month and Day
  } else {
    if (date.getFullYear() === today.getFullYear()) {
      return formatTime(dateInIST)
    }
    else {
      return formatDateWithYear(dateInIST); // If older than this year, return Month, Day, Year
    }

  }
}

export async function highlightSearchKeywords(SearchKey, mail) {
  try {
    if (!SearchKey || !mail) {
      throw new Error("SearchKey and mail parameters are required.");
    }

    // Escape special characters in the SearchKey
    const escapeRegex = (str) =>
      str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");

    SearchKey = SearchKey.trim();
    // console.log({ SearchKey });

    // Create the dynamic regex
    const regex = new RegExp(`\\b${escapeRegex(SearchKey)}\\b`, 'gi');

    // Text replacement function
    const textReplaceFunction = (input) =>
      input.replace(
        regex,
        `<span style="background-color: yellow; font-weight: 900;">$&</span>`
      );

    const highlightSections = [
      'Body', 'Subject', 'Recipient', 'CC', 'To', 'Sender', 'From', 'Bcc'
    ];

    const tempCurrentMail = { ...mail };

    // Iterate through sections and apply text replacement
    highlightSections.forEach((section) => {
      const sectionValue = tempCurrentMail[section];
      if (
        typeof sectionValue === "string" &&
        sectionValue.toLowerCase().includes(SearchKey.toLowerCase())
      ) {
        tempCurrentMail[section] = textReplaceFunction(sectionValue);
      }
    });

    return tempCurrentMail;
  } catch (error) {

    console.error("Error in highlightSearchKeywords:", error);
    return mail
    throw error;

  }
}



//  for mail tag colour
export let tagdata = [
  'vessel_name',
  'open_area',
  'owner',
  'dwt',
  'build_year',
  'bod',
  'cranes',
  'hatches',
  // 'tags',
  'teamWork',
  'open_date',
  'cargo',
  'cargo_size',
  'discharge_port',
  'load_port',
  'load_term',
  'laycan',
  'bank_name',
  'sof',
  'nor',
  'invoice',
  'hire',
  'balance',
  'agent',
  'supplier',
  'charterer',
  'owner',
  'broker',
  'pda',
  'fda',
  'due_date',
  'freight_invoice',
  'hire_invoice',
  'loading_start',
  'loading_top',
  'discharging_start',
  'discharging_stop',
  'vessel_moored',
  'vessel_anchored',
  'Vessel_delivered',
  'delivery_date',
  'redelivery_date',
  'noon_report',
  'eta',
  'etd',
  'arrival',
  'departure',
  'bunker_invoice',
  'port_da',
  'port_disbursement',
  'portcall',
  'fda _due',
  'port_agent',
  'master',
  'ifo',
  'vlsfo',
  'ulsfo',
  'lsmgo',
  'mgo',
  'dry_vessel',
  'tanker_vessel',
  'grt_and_nrt',
  'scrubber_fitted',
  'speed_and_consumption',
  'sea_cons',
  //
  'expenses',
  'loa'

  // 'flag',
]
export const Tags = [
  'Vessel name',
  'open area',
  'open date',
  'owner',
  'dwt',
  'draft',
  'build year',
  'flag',
  'bod',
  'cranes',
  'hatches',
  'teamWork',
  'Comments',
  'Payable',
  'Receivable',
  'Invoice',
  'Invoice no',
  'Hire',
  'Bank Name',
  'Balance',
  'Agent',
  'Supplier',
  'Charterer',
  'Owner',
  'Broker',
  'PDA',
  'FDA',
  'Due date',
  'Freight Invoice',
  'Hire Invoice',
  'SOF (Statement Of Fact)',
  'NOR (Notice of Readiness)',
  'Loading Start',
  'Loading Stop',
  'Discharging Start',
  'Discharging Stop',
  'Vessel Moored',
  'Vessel Anchored',
  'Vessel Delivered',
  'Delivery Date',
  'Redelivery Date',
  'Noon Report',
  'ETA',
  'ETD',
  'Arrival',
  'Departure',
  'Date & time',
  'Bunker Invoice',
  'Port DA',
  'Port Disbursement',
  'Portcall',
  'FDA Due',
  'Survey Invoice',
  'Delivery Report',
  'Redelivery Report',
  'PFHS',
  'FHS',
  'Port agent',
  'Master',
  'IFO',
  'VLSFO',
  'ULSFO',
  'LSMGO',
  'MGO',
  'Dry Vessel',
  'Tanker Vessel'
]

export const systemtag1 = [
  'fixture',
  'clean fixture',
  'fixture note',
  'clean recap',
  'recap',
  'main terms',
  'vessel name',
  'imo',
  'open area',
  'fixture',
  'open date',
  'build year',
  'cranes',
  'flag',
  'loa',
  'beam',
  'bod',
  'dwt',
  'draft',
  'hold and hatches',
  'grt and nrt',
  'speed and consumption',
  'grain and bale',
  'tpc',
  'cargo name',
  'laycan',
  'load port',
  'discharge port',
  'address com',
  'qty',
  'discharge term',
  'broker com',
  'load term',
  'account',
  'load rate',
  'discharge rate',
  'delivery',
  're delivery',
  'add com',
  'duration',
  'freight',
  'hire',
  'tct',
  'charterer',
  'owner',
  'vessel moored',
  'vessel anchored',
  'vessel delivered',
  'delivery date',
  'redelivery date',
  'date& time',
  'ifo',
  'vlsfo',
  'ulsfo',
  'lsmgo',
  'mgo',
  'dry vessel',
  'tanker vessel'
]

export const systemtag2 = [
  'fixture',
  'clean fixture',
  'fixture note',
  'clean recap',
  'recap',
  'main terms',
  'hire invoice',
  'freight invoice',
  'commission invoice',
  'brokerage invoice',
  'credit note',
  'debit',
  'bunker invoice',
  'on hire',
  'off hire',
  'revenue',
  'expenses',
  'hire commission',
  'pda invoice',
  'fda invoice',
  'claim invoice',
  'charter party',
  'fixture note',
  'agent',
  'voyage instruction',
  'b/l',
  'pilot',
  'due date',
  'invoice no',
  'voyage no',
  'cp date',
  'fixture no',
  'delivery notice',
  'redelivery notice',
  'pfhs',
  'ffa',
  'voyage schedule',
  'noon report',
  'eta',
  'etd',
  'arrival date',
  'departure date',
  'date & time',
  'bunker invoice',
  'port da',
  'portcall appointment',
  'fda due',
  'payable',
  'receivable',
  'invoice',
  'invoice no.',
  'bank name',
  'balance',
  'pda',
  'fda',
  'due date',
  'sof (statement of fact)',
  'nor (notice of readiness)',
  'loading start',
  'loading stop',
  'discharging start',
  'discharging stop',
  'noon report',
  'arrival',
  'departure',
  'port disbursement',
  'fda due',
  'survey invoice',
  'delivery report',
  'redelivery report',
  'pfhs',
  'fhs',
  'port agent',
  'master',
  'charter party',
  'fixture note',
  'voyage instruction',
  'invoice no',
  'voyage no.',
  'voyage',
  'fixture no.',
  'fixture',
  'ffa',
  'voyage schedule',
  'arrival date',
  'departure date',
  'portcall appointment'
]

const colorsDict = {

  "vessel_name": { "foreground": "#4B0082", "background": "#E6E6FA" },
  "imo": { "foreground": "#008080", "background": "#E0FFFF" },
  "open_area": { "foreground": "#556B2F", "background": "#F0E68C" },
  "open_date": { "foreground": "#D2691E", "background": "#FFE4B5" },
  "build_year": { "foreground": "#B22222", "background": "#FFDEAD" },
  "cranes": { "foreground": "#FF69B4", "background": "#FFF0F5" },
  "flag": { "foreground": "#4B0082", "background": "#E6E6FA" },
  "loa": { "foreground": "#4682B4", "background": "#F0F8FF" },
  "beam": { "foreground": "#008080", "background": "#E0FFFF" },
  "sea_cons": { "foreground": "#2E8B57", "background": "#F5FFFA" },
  "port_cons": { "foreground": "#D2691E", "background": "#FAEBD7" },
  "bod": { "foreground": "#8B0000", "background": "#F5F5DC" },
  "dwt": { "foreground": "#B8860B", "background": "#FDF5E6" },
  "draft": { "foreground": "#FF8C00", "background": "#FFF8DC" },
  "hold_and_hatches": { "foreground": "#4682B4", "background": "#F8F8FF" },
  "loa_and_beam": { "foreground": "#008080", "background": "#E0FFFF" },
  "grt_and_nrt": { "foreground": "#rgb(128 85 29)", "background": "#FAF0E6" },
  "speed_and_consumption": { "foreground": "#2E8B57", "background": "#FFFFF0" },
  "grain_and_bale": { "foreground": "#FF69B4", "background": "#FFF0F5" },
  "tpc": { "foreground": "#B8860B", "background": "#FFFACD" },
  "tags": { "foreground": "#4B0082", "background": "#E6E6FA" },
  "cargo": { "foreground": "#FF8C00", "background": "#FFF8DC" },
  "laycan": { "foreground": "#B8860B", "background": "#FDF5E6" },
  "load_port": { "foreground": "#4682B4", "background": "#F8F8FF" },
  "discharge_port": { "foreground": "#DAA520", "background": "#FAFAD2" },
  "cargo_size": { "foreground": "#FF4500", "background": "#FFF5EE" },
  "tct": { "foreground": "#B8860B", "background": "#FFFACD" }
};
export const totaltag = [...new Set([...Tags, ...tagdata, ...systemtag1, ...systemtag2])]
const iconsDict = {
  "vessel_name": 'tabler:ship',
  "dwt": 'uil:weight', // Adjusted example for clarity
  "build_year": "tabler:calendar-pin", // Adjusted example for clarity
  "open_date": 'tabler:calendar-pin',
  "open_area": 'tabler:current-location',
  "cargo": 'tabler:packages',
  "laycan": "tabler:calendar-pin",
  "load_port": 'lucide:import',
  "discharge_port": 'entypo:export',
  "cargo_size": 'mdi:resize',
  'loa': 'material-symbols:trail-length',
  'bod': 'openmoji:oil-spill',
  'cranes': 'hugeicons:crane'
};

const tagColourIcon = {};
tagdata.forEach(tag => {
  const formattedTag = tag;
  tagColourIcon[formattedTag] = {
    color: colorsDict[formattedTag]?.foreground || '#000',
    backgroundColor: colorsDict[formattedTag]?.background || '#fff',
    icon: iconsDict[formattedTag] || '❓' // Default icon if not found
  };
});

export { tagColourIcon };