import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tag,
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import URL_WITH_VERSION, {
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
} from "../../..";
import { BunkerDetailsCalculation } from "../../../NormalForm/normalhelper";
//import ToolbarUI from "components/ToolbarUI";
import { BarChartOutlined, SaveOutlined } from "@ant-design/icons";
import ToolbarUI from "../../../../components/ToolbarUI";

import VoyageReletPnl, {
  VoyageReletCalcArr,
} from "../plcomponents/VoyageReletPnl";
import PnlGraph from "../tcov/PnlGraph";
import AiButton from "../../AiButton/AiButton";
import AiPLComparator from "../tcov/AiPLComparator";
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const FormItem = Form.Item;

const columns = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
    ellipsis: true,
  },

  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "8%",
  },
  {
    title: "Actual",
    dataIndex: "actual",
    key: "actual",
    width: "8%",
  },
  {
    title: "Posted",
    dataIndex: "posted",
    key: "posted",
    width: "8%",
  },
  {
    title: "Cash In",
    dataIndex: "cash_in",
    key: "cash_in",
    width: "8%",
  },
  {
    title: `Diff(Act.-Est.)`,
    dataIndex: "Diff",
    key: "Diff",
    width: "8%",
  },
  {
    title: "%Diff",
    dataIndex: "perDiff",
    key: "perDiff",
    width: "8%",
  },
  {
    title: "Post Vs Cash",
    dataIndex: "sec_variance",
    key: "sec_variance",
    width: "8%",
  },
  {
    title: "% Post Vs Cash",
    dataIndex: "sec_per",
    key: "sec_per",
    width: "8%",
  },
];

const columns2 = [
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "20%",
    ellipsis: true,
  },
  {
    title: "Estimated",
    dataIndex: "estimate",
    key: "estimate",
    width: "12%",
    align: "right",
  },
  { title: "", dataIndex: "", key: "blank", width: "68%" },
];

class PL extends Component {
  // this profit loss summary is from the voyage manager vivo
  callback = (evt) => {};

  constructor(props) {
    super(props);

    this.state = {
      dollarUSLocale: Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      formData: this.props.formData || {},
      estimateDatavalue: this.props.estimateData || {},
      showPL: false,
      showEstimatePl: this.props.showEstimatePl,
      vesselAmount: 0,
      viewTabs: this.props.viewTabs || ["Actual &  Operation View"],
      tooltipData: {},
      openGraphModal: false,
      openAICompModal: false,
      estimateData: [
        {
          key: "revenue",
          description: "Revenue",
          estimate: 0,
          children: [
            {
              key: "rev00",
              description: "Freight (Cargo )",
              estimate: 0,
              children: [],
            },
            {
              key: "rev01",
              description: "VC Cargo Commision",
              estimate: 0,
              children: [],
            },
            {
              key: "rev02",
              description: "VC Cargo Add. Commision",
              estimate: 0,
            },
            {
              key: "rev03",
              description: "VC Cargo Bro. Commision",
              estimate: 0,
            },
            { key: "rev04", description: "Demurrage", estimate: 0 },
            { key: "rev05", description: "Despatch", estimate: 0 },
            {
              key: "rev06",
              description: "Other Revenue",
              estimate: 0,
              children: [],
            },
            { key: "rev07", description: "Gross Revenue", estimate: 0 },
            { key: "rev08", description: "Net Revenue", estimate: 0 },
          ],
        },
        {
          key: "expenses",
          description: "Expenses",
          estimate: 0,
          children: [
            {
              key: "ex10",
              description: "VC Frieght",
              estimate: 0,
              children: [],
            },
            {
              key: "ex11",
              description: "VC Frieght Commission",
              estimate: 0,
              children: [],
            },
            {
              key: "ex12",
              description: "VC Pur Add. Commission",
              estimate: 0,
            },
            {
              key: "ex13",
              description: "VC Pur Bro. Commission",
              estimate: 0,
            },
            { key: "ex14", description: "Demmurrage", estimate: 0 },
            { key: "ex15", description: "Despatch", estimate: 0 },

            {
              key: "ex16",
              description: "Other Expenses",
              estimate: 0,
              children: [],
            },

            { key: "ex17", description: "Gross Expenses", estimate: 0 },
            { key: "ex18", description: "Net Expenses", estimate: 0 },
          ],
        },
        {
          key: "voyage-result",
          description: "Voyage Result",
          estimate: 0,
          children: [
            { key: "vr20", description: "Profit (Loss)", estimate: 0 },
            { key: "vr21", description: "Daily Profit (Loss)", estimate: 0 },
            { key: "vr22", description: "Total Sea Days", estimate: 0 },
            { key: "vr23", description: "Total Port Days", estimate: 0 },
            { key: "vr24", description: "Net Voyage Days", estimate: 0 },
          ],
        },
      ],
      selectOptions: [
        {
          value: "actual",
          label: "Actual",
        },
      ],
      actualSelection: false,
      selectedOption: "Actual",
      remark: "",
    };

    this.elementRef = React.createRef();
    this.loading = false;
    this.saveLoading = false;
  }

  dollarFormatedValue = (value) => {
    const { dollarUSLocale } = this.state;
    return !value || isNaN(value)
      ? "0.00"
      : dollarUSLocale.format(parseFloat(value).toFixed(2));
  };

  DemurrageDespatchCalculation = (itenaryarr) => {
    let demurrage = 0;
    let despatch = 0;
    itenaryarr?.forEach((el) => {
      if (el?.dem_disp === "Despatch") {
        despatch += parseFloat(el.dem_disp_amt);
      } else if (el?.dem_disp === "Demmurage") {
        demurrage += parseFloat(el.dem_disp_amt);
      }
    });
    return { demurrage, despatch };
  };

  checkNanAndFormat = (value) => {
    return !value || isNaN(value) ? 0 : value.replaceAll(",", "") * 1;
  };

  sumFromObj = (obj) => {
    let sum = 0;
    let arr = Object.values(obj);
    if (arr.length > 0) {
      sum = arr.reduce((ac, el) => {
        if (typeof el === "string") {
          return ac + parseFloat(el.replaceAll(",", ""));
        } else {
          return ac + parseFloat(el);
        }
      }, 0);
    }
    return sum;
  };

  findCpPassage = (fuelType, passageType, consArr) => {
    let cp_price = 0;
    let fuel_cons = 0;
    consArr?.map((el) => {
      const { fuel_type, fuel_code } = el;
      if (fuel_type == fuelType) {
        cp_price = parseFloat(el.cp_price);
        if (passageType == 1) {
          fuel_cons = isNaN(el?.ballast_value)
            ? 0
            : parseFloat(el.ballast_value);
        } else if (passageType == 2) {
          fuel_cons = isNaN(el?.laden_value) ? 0 : parseFloat(el.laden_value);
        }
      }
    });

    cp_price = isNaN(cp_price) ? 0 : parseFloat(cp_price);
    fuel_cons = isNaN(fuel_cons) ? 0 : parseFloat(fuel_cons);
    return { cp_price, fuel_cons };
  };

  EcaSeaconsCalculation = (formdata) => {
    let ecaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let fuelType = formdata?.["eca_fuel_grade"] ?? "7";

    let ecafuelConsPrice = 0;

    formdata?.portitinerary?.map((el) => {
      const { eca_days = 0, passage } = el;
      const { cp_price, fuel_cons } = this.findCpPassage(
        fuelType,
        passage,
        formdata["."]
      );

      ecafuelConsPrice += cp_price * parseFloat(eca_days) * fuel_cons;
    });

    switch (fuelType) {
      case "3": // IFO
        ecaSeaCons["ifo"] = ecafuelConsPrice;
        break;

      case "4": // MGO
        ecaSeaCons["mgo"] = ecafuelConsPrice;
        break;

      case "5": //Vlsfo
        ecaSeaCons["vlsfo"] = ecafuelConsPrice;
        break;

      case "7": // lsmgo
        ecaSeaCons["lsmgo"] = ecafuelConsPrice;
        break;

      case "10": // ulsfo
        ecaSeaCons["ulsfo"] = ecafuelConsPrice;
        break;
      case "11": // HFO
        ecaSeaCons["hfo"] = ecafuelConsPrice;
        break;
    }

    return ecaSeaCons;
  };

  nonEcaSeaconsCalculation = (formdata) => {
    let nonEcaSeaCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };

    let nonifo;
    let nonmgo;
    let nonlsmgo;
    let nonvlsfo;
    let nonulsfo;

    let ecafuelType = formdata?.["eca_fuel_grade"];
    formdata?.portitinerary?.map((el) => {
      const { eca_days = 0, passage, tsd = 0 } = el;
      let nonEcadays = tsd - eca_days;
      nonifo = this.findCpPassage(3, passage, formdata["."]);
      nonmgo = this.findCpPassage(4, passage, formdata["."]);
      nonlsmgo = this.findCpPassage(7, passage, formdata["."]);
      nonvlsfo = this.findCpPassage(5, passage, formdata["."]);
      nonulsfo = this.findCpPassage(10, passage, formdata["."]);

      if (ecafuelType == 7) {
        if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (!nonvlsfo.fuel_cons && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;
          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        } else if (nonvlsfo.fuel_cons > 0 && !nonifo.fuel_cons) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        }
      } else if (ecafuelType == 10) {
        // ULSFO

        if (nonulsfo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonulsfo;
          nonEcaSeaCons["ulsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonvlsfo.fuel_cons > 0 && nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonvlsfo;
          nonEcaSeaCons["vlsfo"] += parseFloat(
            fuel_cons * nonEcadays * cp_price
          );
        } else if (nonifo.fuel_cons > 0) {
          let { cp_price, fuel_cons } = nonifo;

          nonEcaSeaCons["ifo"] += parseFloat(fuel_cons * nonEcadays * cp_price);
        }
      } else {
        if (
          nonifo.fuel_cons > 0 &&
          nonmgo.fuel_cons > 0 &&
          nonlsmgo.fuel_cons > 0 &&
          nonvlsfo.fuel_cons > 0 &&
          nonulsfo.fuel_cons > 0
        ) {
          let { cp_price, fuel_cons } = nonvlsfo;

          nonEcaSeaCons["vlsfo"] += parseFloat(fuel_cons * tsd * cp_price);
        } else {
          nonEcaSeaCons["ifo"] += nonifo.fuel_cons
            ? parseFloat(nonifo.fuel_cons * tsd * nonifo.cp_price)
            : 0;
          nonEcaSeaCons["lsmgo"] += nonlsmgo.fuel_cons
            ? parseFloat(nonlsmgo.fuel_cons * tsd * nonlsmgo.fuel_cons)
            : 0;
          nonEcaSeaCons["mgo"] += nonmgo.fuel_cons
            ? parseFloat(nonmgo.fuel_cons * tsd * nonmgo.cp_price)
            : 0;
          nonEcaSeaCons["vlsfo"] += nonvlsfo.fuel_cons
            ? parseFloat(nonvlsfo.fuel_cons * tsd * nonvlsfo.cp_price)
            : 0;
          nonEcaSeaCons["ulsfo"] += nonulsfo.fuel_cons
            ? parseFloat(nonulsfo.fuel_cons * tsd * nonulsfo.cp_price)
            : 0;
        }
      }
    });
    return nonEcaSeaCons;
  };

  // nonEcaPortConsCalculation = (formdata) => {
  //   let nonEcaPortCons = {
  //     ifo: 0,
  //     mgo: 0,
  //     lsmgo: 0,
  //     vlsfo: 0,
  //     ulsfo: 0,
  //   };

  //   formdata?.bunkerdetails?.map((el) => {
  //     nonEcaPortCons["ifo"] += isNaN(el["pc_ifo"])
  //       ? 0
  //       : parseFloat(el["pc_ifo"]);
  //     nonEcaPortCons["mgo"] += isNaN(el["pc_mgo"])
  //       ? 0
  //       : parseFloat(el["pc_mgo"]);
  //     nonEcaPortCons["lsmgo"] += isNaN(el["pc_lsmgo"])
  //       ? 0
  //       : parseFloat(el["pc_lsmgo"]);
  //     nonEcaPortCons["vlsfo"] += isNaN(el["pc_vlsfo"])
  //       ? 0
  //       : parseFloat(el["pc_vlsfo"]);
  //     nonEcaPortCons["ulsfo"] += isNaN(el["pc_ulsfo"])
  //       ? 0
  //       : parseFloat(el["pc_ulsfo"]);
  //   });
  //   return nonEcaPortCons;
  // };

  totalEcaSecafuelCons = (consObj) => {
    let fuelValue = Object.values(consObj);
    return fuelValue.reduce((ac, el) => ac + el, 0);
  };

  ecaPortConsCalculation = () => {
    let ecaPortCons = {
      ifo: 0,
      mgo: 0,
      lsmgo: 0,
      vlsfo: 0,
      ulsfo: 0,
    };
    // till now we dont have any update on this, how to calculate.
    return ecaPortCons;
  };

  __getEstimatePL = (calData, colName) => {
    try {
      let {
        expenses = {},
        revenue = {},
        voyage_result = {},
      } = calData?.["estimate"] ?? {};

      const {
        demmurage = 0,
        demmurageCommission = 0,
        desmurage = 0,
        desmurageCommission = 0,
        freight = 0,
        freight_commission = 0,
        gross_revenue = 0,
        mis_revenue = 0,
        net_revenue = 0,
      } = revenue ?? {};

      const {
        exp_freight = 0,
        exp_frt_cmsn = 0,
        exp_add_cmsn = 0,
        exp_bro_cmsn = 0,
        exp_dem = 0,
        exp_des = 0,
        exp_other_expense = 0,
        gross_expense = 0,
        net_expense = 0,
      } = expenses ?? {};

      const {
        CO2_adj_profit,
        avargefright,
        breakeven_and_freight_rate,
        co2_adjusted_tce,
        // co2expense,
        daily_profit_loss,
        freight_rate,
        gross_tce,
        net_voyage_days,
        off_hire_days,
        profit_loss,
        tce_hire_net_daily,
        total_port_days,
        total_sea_days,
      } = voyage_result;

      const { dollarUSLocale } = this.state;
      let totalSeaConsumption = 0,
        vessel_expense = 0,
        totalPortConsumption = 0,
        vesselExpenses = 0;
      let ballast_bonus = 0;
      let _estimateData = Object.assign([], this.state.estimateData);

      // Revenue values

      _estimateData[0][colName] = this.dollarFormatedValue(
        net_revenue.replaceAll(",", "") * 1
      );
      _estimateData[0]["children"][0][colName] = this.dollarFormatedValue(
        freight.replaceAll(",", "") * 1
      );
      _estimateData[0]["children"][1][colName] = this.dollarFormatedValue(
        freight_commission.replaceAll(",", "") * 1
      );
      _estimateData[0]["children"][2][colName] = this.dollarFormatedValue(0);
      _estimateData[0]["children"][3][colName] = this.dollarFormatedValue(0);
      _estimateData[0]["children"][4][colName] = this.dollarFormatedValue(
        demmurage.replaceAll(",", "") * 1
      );
      _estimateData[0]["children"][5][colName] = this.dollarFormatedValue(
        desmurage.replaceAll(",", "") * 1
      );
      _estimateData[0]["children"][6][colName] =
        this.dollarFormatedValue(mis_revenue);

      _estimateData[0]["children"][7][colName] = this.dollarFormatedValue(
        gross_revenue.replaceAll(",", "") * 1
      );

      _estimateData[0]["children"][8][colName] = this.dollarFormatedValue(
        net_revenue.replaceAll(",", "") * 1
      );

      // expenses values
      _estimateData[1][colName] = this.dollarFormatedValue(
        net_expense.replaceAll(",", "") * 1
      );

      _estimateData[1]["children"][0][colName] = this.dollarFormatedValue(
        exp_freight.replaceAll(",", "") * 1
      );
      _estimateData[1]["children"][1][colName] = this.dollarFormatedValue(
        exp_frt_cmsn.replaceAll(",", "") * 1
      );
      _estimateData[1]["children"][2][colName] = this.dollarFormatedValue(
        exp_add_cmsn.replaceAll(",", "") * 1
      );
      _estimateData[1]["children"][3][colName] = this.dollarFormatedValue(
        exp_bro_cmsn.replaceAll(",", "") * 1
      );
      _estimateData[1]["children"][4][colName] =
        this.dollarFormatedValue(exp_dem);
      _estimateData[1]["children"][5][colName] =
        this.dollarFormatedValue(exp_des);
      _estimateData[1]["children"][6][colName] = this.dollarFormatedValue(
        exp_other_expense.replaceAll(",", "") * 1
      );

      _estimateData[1]["children"][7][colName] = this.dollarFormatedValue(
        gross_expense.replaceAll(",", "") * 1
      );
      _estimateData[1]["children"][8][colName] = this.dollarFormatedValue(
        net_expense.replaceAll(",", "") * 1
      );

      // voyage Result
      _estimateData[2][colName] = this.dollarFormatedValue(
        profit_loss.replaceAll(",", "") * 1
      );
      _estimateData[2]["children"][0][colName] = this.dollarFormatedValue(
        profit_loss.replaceAll(",", "") * 1
      );
      _estimateData[2]["children"][1][colName] = this.dollarFormatedValue(
        daily_profit_loss.replaceAll(",", "") * 1
      );
      _estimateData[2]["children"][2][colName] = this.dollarFormatedValue(
        total_sea_days.replaceAll(",", "") * 1
      );
      _estimateData[2]["children"][3][colName] = this.dollarFormatedValue(
        total_port_days.replaceAll(",", "") * 1
      );
      _estimateData[2]["children"][4][colName] = this.dollarFormatedValue(
        net_voyage_days.replaceAll(",", "") * 1
      );

      return _estimateData;
    } catch (err) {
      console.log("err", err);
    }
  };

  __pl = () => {
    let revenue;
    try {
      let {
        estimateData,
        dollarUSLocale,
        formData,
        estimateDatavalue,
        showEstimatePl,
      } = this.state;
      let objdata = { ...this.state.formData };
      if (!objdata.hasOwnProperty("bunkerdetails")) {
        const bunkerconsp = objdata["portitinerary"]?.map((el, ind) => {
          const values = BunkerDetailsCalculation(objdata, ind);
          return values;
        });
        objdata["bunkerdetails"] = bunkerconsp;
      }
      formData = { ...objdata };

      let totalSeaConsumption = 0,
        totalPortConsumption = 0,
        totalArriveConsumption = 0,
        totalDepConsumption = 0,
        totalAdjConsumption = 0;
      let colName = "actual";
      if (showEstimatePl === true) {
        estimateData = this.__getEstimatePL(estimateDatavalue, "estimate");
      }
      let totalVoyageDays = formData["total_days"]
        ? isNaN(("" + formData["total_days"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["total_days"]).replaceAll(",", "") * 1
        : 0;
      let tsd = 0,
        tpd = 0,
        pi = 0;
      let {
        frightInvoice_posted,
        frightComm_posted,
        miscRev_posted,
        demAmt_posted,
        demComAmt_posted,
        desAmt_posted,
      } = estimateDatavalue.post;

      let {
        frightInvoice_cash,
        frightComm_cash,
        miscRev_cash,
        demAmt_cash,
        demComAmt_cash,
        desAmt_cash,
      } = estimateDatavalue.cash;

      let grossExpenses_posted = 0,
        grossExpenses_cash = 0,
        netExpenses_posted = 0,
        netExpenses_cash = 0;

      let gross_revenue_posted = 0,
        gross_revenue_cash = 0,
        net_revenue_posted = 0,
        net_revenue_cash = 0;

      let cpQtyOpt_exp = 0;
      const FirstVSSecond = (p, c) => {
        let diff = 0;
        if (p && c) {
          diff = (p - c).toFixed(2);
        } else if (p && !c) {
          diff = (p * 1).toFixed(2);
        } else if (!p && c) {
          diff = (c * -1).toFixed(2);
        } else {
          diff = "0.00";
        }
        return diff;
      };

      const perFirstVSSecond = (p, c) => {
        let perdiff = 0;
        if (p && c) {
          perdiff = ((p * 1 - c * 1) / p) * 1 * 100;
        } else if (p && !c) {
          perdiff = 100;
        } else if (!p && c) {
          perdiff = 0;
        }
        return perdiff.toFixed(2);
      };

      const checkNanAndFormat = (value) => {
        return !value || isNaN(value) ? 0 : value.replaceAll(",", "") * 1;
      };
      let bb = formData["bb"]
        ? isNaN(("" + formData["bb"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["bb"]).replaceAll(",", "") * 1
        : 0;

      let otherCost = formData["other_cost"]
        ? isNaN(("" + formData["other_cost"]).replaceAll(",", "") * 1)
          ? 0
          : ("" + formData["other_cost"]).replaceAll(",", "") * 1
        : 0;

      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portItinerary = formData["portitinerary"];
        portItinerary?.map((e) => {
          tsd += (e.tsd + "").replaceAll(",", "") * 1;
          tpd += (e.t_port_days + "").replaceAll(",", "") * 1;
        });
        tsd = tsd * 1;
        tpd = tpd * 1;
      }

      totalVoyageDays =
        tpd + tsd > 0 && tpd + tsd > totalVoyageDays
          ? tpd + tsd
          : totalVoyageDays;

      let hire = formData["tci_d_hire"]
        ? (formData["tci_d_hire"] + "").replaceAll(",", "") * 1
        : 0;

      let addPercentage = formData["add_percentage"]
        ? (formData["add_percentage"] + "").replaceAll(",", "") * 1
        : 0;

      // let amt_add_percentage = hire * totalVoyageDays * addPercentage * 0.01;  // no need to calculate here

      let amt_add_percentage = 0;
      let broPercentage = formData["bro_percentage"]
        ? (formData["bro_percentage"] + "").replaceAll(",", "") * 1
        : 0;
      // let amt_bro_percentage = hire * totalVoyageDays * broPercentage * 0.01; // no need to calculate
      let amt_bro_percentage = 0;
      let portExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        seaExpenses = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivalrev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        deprev = { ifo: 0, mgo: 0, vlsfo: 0, ulsfo: 0, lsmgo: 0 },
        arrivaladjuestmentrev = {
          ifo: 0,
          mgo: 0,
          vlsfo: 0,
          ulsfo: 0,
          lsmgo: 0,
        };

      if (formData && formData.hasOwnProperty("bunkerdetails")) {
        let bunkerDetails = formData["bunkerdetails"];
        let i = 0;
        bunkerDetails?.map((e, i, { length }) => {
          // console.log((("" + e["ifo"]).replaceAll(",", "") * 1).toFixed(2)

          seaExpenses["ifo"] += e["ifo"]
            ? isNaN(("" + e["ifo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat((("" + e["ifo"]).replaceAll(",", "") * 1).toFixed(2))
            : 0;
          seaExpenses["mgo"] += e["mgo"]
            ? isNaN(("" + e["mgo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat((("" + e["mgo"]).replaceAll(",", "") * 1).toFixed(2))
            : 0;
          seaExpenses["vlsfo"] += e["vlsfo"]
            ? isNaN(("" + e["vlsfo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["vlsfo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          seaExpenses["lsmgo"] += e["lsmgo"]
            ? isNaN(("" + e["lsmgo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["lsmgo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          seaExpenses["ulsfo"] += e["ulsfo"]
            ? isNaN(("" + e["ulsfo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["ulsfo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;

          portExpenses["ifo"] += e["pc_ifo"]
            ? isNaN(("" + e["pc_ifo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_ifo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["mgo"] += e["pc_mgo"]
            ? isNaN(("" + e["pc_mgo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_mgo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["vlsfo"] += e["pc_vlsfo"]
            ? isNaN(("" + e["pc_vlsfo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_vlsfo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["lsmgo"] += e["pc_lsmgo"]
            ? isNaN(("" + e["pc_lsmgo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_lsmgo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;
          portExpenses["ulsfo"] += e["pc_ulsfo"]
            ? isNaN(("" + e["pc_ulsfo"]).replaceAll(",", "") * 1)
              ? 0
              : parseFloat(
                  (("" + e["pc_ulsfo"]).replaceAll(",", "") * 1).toFixed(2)
                )
            : 0;

          if (i === 0) {
            arrivalrev["ifo"] += e["arob_ifo"]
              ? isNaN(("" + e["arob_ifo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ifo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["mgo"] += e["arob_mgo"]
              ? isNaN(("" + e["arob_mgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_mgo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["vlsfo"] += e["arob_vlsfo"]
              ? isNaN(("" + e["arob_vlsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_vlsfo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["lsmgo"] += e["arob_lsmgo"]
              ? isNaN(("" + e["arob_lsmgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_lsmgo"]).replaceAll(",", "") * 1
              : 0;
            arrivalrev["ulsfo"] += e["arob_ulsfo"]
              ? isNaN(("" + e["arob_ulsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["arob_ulsfo"]).replaceAll(",", "") * 1
              : 0;
          }

          //last element
          if (i + 1 === length) {
            deprev["ifo"] += e["dr_ifo"]
              ? isNaN(("" + e["dr_ifo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ifo"]).replaceAll(",", "") * 1
              : 0;
            deprev["mgo"] += e["dr_mgo"]
              ? isNaN(("" + e["dr_mgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_mgo"]).replaceAll(",", "") * 1
              : 0;
            deprev["vlsfo"] += e["dr_vlsfo"]
              ? isNaN(("" + e["dr_vlsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_vlsfo"]).replaceAll(",", "") * 1
              : 0;
            deprev["lsmgo"] += e["dr_lsmgo"]
              ? isNaN(("" + e["dr_lsmgo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_lsmgo"]).replaceAll(",", "") * 1
              : 0;
            deprev["ulsfo"] += e["dr_ulsfo"]
              ? isNaN(("" + e["dr_ulsfo"]).replaceAll(",", "") * 1)
                ? 0
                : ("" + e["dr_ulsfo"]).replaceAll(",", "") * 1
              : 0;
          }
        });
      }

      if (formData && formData.hasOwnProperty(".")) {
        let cpData = formData["."];
        let price_cp_eco =
          formData.hasOwnProperty("price_cp_eco") && formData["price_cp_eco"]
            ? 1
            : 0; // 1 is P$ else CP$
        cpData?.map((e) => {
          let _price = 0;
          let _pprice = 0;
          if (
            e["cp_price"] &&
            !isNaN(("" + e["cp_price"]).replaceAll(",", "") * 1)
          ) {
            _price = ("" + e["cp_price"]).replaceAll(",", "") * 1;
          }
          if (
            e["purchase_price"] &&
            !isNaN(("" + e["purchase_price"]).replaceAll(",", "") * 1)
          ) {
            _pprice = ("" + e["purchase_price"]).replaceAll(",", "") * 1;
          }

          switch (e.fuel_code) {
            case "IFO":
              //console.log(seaExpenses["ifo"].toFixed(2))
              seaExpenses["ifo"] =
                price_cp_eco === 1
                  ? seaExpenses["ifo"] * _pprice
                  : seaExpenses["ifo"] * _price;
              portExpenses["ifo"] =
                price_cp_eco === 1
                  ? portExpenses["ifo"] * _pprice
                  : portExpenses["ifo"] * _price;
              arrivalrev["ifo"] = arrivalrev["ifo"] * _price;
              deprev["ifo"] = deprev["ifo"] * _pprice;
              arrivaladjuestmentrev["ifo"] = deprev["ifo"] - arrivalrev["ifo"];
              break;

            case "MGO":
              seaExpenses["mgo"] =
                price_cp_eco === 1
                  ? seaExpenses["mgo"] * _pprice
                  : seaExpenses["mgo"] * _price;
              portExpenses["mgo"] =
                price_cp_eco === 1
                  ? portExpenses["mgo"] * _pprice
                  : portExpenses["mgo"] * _price;
              arrivalrev["mgo"] = arrivalrev["mgo"] * _price;
              deprev["mgo"] = deprev["mgo"] * _pprice;
              arrivaladjuestmentrev["mgo"] = deprev["mgo"] - arrivalrev["mgo"];
              break;

            case "VLSFO":
              seaExpenses["vlsfo"] =
                price_cp_eco === 1
                  ? seaExpenses["vlsfo"] * _pprice
                  : seaExpenses["vlsfo"] * _price;
              portExpenses["vlsfo"] =
                price_cp_eco === 1
                  ? portExpenses["vlsfo"] * _pprice
                  : portExpenses["vlsfo"] * _price;
              arrivalrev["vlsfo"] = arrivalrev["vlsfo"] * _price;
              deprev["vlsfo"] = deprev["vlsfo"] * _pprice;
              arrivaladjuestmentrev["vlsfo"] =
                deprev["vlsfo"] - arrivalrev["vlsfo"];
              break;

            case "LSMGO":
              seaExpenses["lsmgo"] =
                price_cp_eco === 1
                  ? seaExpenses["lsmgo"] * _pprice
                  : seaExpenses["lsmgo"] * _price;
              portExpenses["lsmgo"] =
                price_cp_eco === 1
                  ? portExpenses["lsmgo"] * _pprice
                  : portExpenses["lsmgo"] * _price;
              arrivalrev["lsmgo"] = arrivalrev["lsmgo"] * _price;
              deprev["lsmgo"] = deprev["lsmgo"] * _pprice;
              arrivaladjuestmentrev["lsmgo"] =
                deprev["lsmgo"] - arrivalrev["lsmgo"];
              break;

            case "ULSFO":
              seaExpenses["ulsfo"] =
                price_cp_eco === 1
                  ? (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _pprice
                  : (isNaN(seaExpenses["ulsfo"]) ? 0 : seaExpenses["ulsfo"]) *
                    _price;
              portExpenses["ulsfo"] =
                price_cp_eco === 1
                  ? (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price
                  : (isNaN(portExpenses["ulsfo"]) ? 0 : portExpenses["ulsfo"]) *
                    _price;
              arrivalrev["ulsfo"] =
                (isNaN(arrivalrev["ulsfo"]) ? 0 : arrivalrev["ulsfo"]) * _price;
              deprev["ulsfo"] =
                (isNaN(deprev["ulsfo"]) ? 0 : deprev["ulsfo"]) * _pprice;
              arrivaladjuestmentrev["ulsfo"] =
                deprev["ulsfo"] - arrivalrev["ulsfo"];
              break;
          }
        });
      }

      Object.keys(seaExpenses)?.map(
        (e) => (totalSeaConsumption += seaExpenses[e])
      );
      Object.keys(portExpenses)?.map(
        (e) => (totalPortConsumption += portExpenses[e])
      );

      Object.keys(arrivalrev)?.map(
        (e) => (totalArriveConsumption += arrivalrev[e])
      );
      Object.keys(deprev)?.map((e) => (totalDepConsumption += deprev[e]));
      Object.keys(arrivaladjuestmentrev)?.map(
        (e) => (totalAdjConsumption += arrivaladjuestmentrev[e])
      );

      if (formData && formData.hasOwnProperty("portitinerary")) {
        let portitinerary = formData["portitinerary"];
        portitinerary?.map(
          (e) =>
            (pi += isNaN(("" + e.p_exp).replaceAll(",", "") * 1)
              ? 0
              : ("" + e.p_exp).replaceAll(",", "") * 1)
        );
      }

      if (formData && formData.hasOwnProperty("portitinerary")) {
        const { demurrage, despatch } = this.DemurrageDespatchCalculation(
          formData.portitinerary
        );
        // calculateDemurrage = demurrage;
        // calculatedespatch = despatch;
      }

      //-----------------------revenue  and expense Calculation---------------

      // let cpQtyOpt_exp = 0;

      let expenses_frate = 0,
        revenues_frate = 0,
        freightCommission_exp = 0,
        freightCommission_rev = 0,
        cpQty_exp = 0;

      let extra_rev = 0,
        cpQtyOpt_rev = 0,
        demmurage_amt_rev = 0;
      let desmurage_amt_rev = 0;

      if (formData && formData.hasOwnProperty("cargos")) {
        let cargos_purchage = formData["cargos"]?.filter(
          (cargo) => cargo.sp_type === 187
        );
        let cargos_sell = formData["cargos"]?.filter(
          (cargo) => cargo.sp_type === 186
        );

        cargos_purchage?.map((e) => {
          let frtRate = 0,
            commission = 0,
            frtAmount = 0;

          let frt_rate =
            e.frt_type === "259"
              ? parseFloat(e.world_scale)
              : e.freight_rate || e.frat_rate || e.f_rate || 0;
          let lumsum_amt = e.lumsum || 0;
          let frt_type_opt = e.frt_type || e.frt_type || e.frt_type || 0;
          let demmurage = e.dem_rate_pd || e.dem_rate_pd || e.dem_rate_pd || 0;
          let desmurage = e.des_rate_pd || e.des_rate_pd || e.des_rate_pd || 0;

          let cp_qty = e.cp_qty || e.quantity || 0;
          let b_commission = e.b_commission || e.commission || 0;

          let opt_per =
            parseFloat(e.option_percentage) ||
            parseFloat(e.opt_percentage) ||
            0;

          cpQty_exp += cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;
          cpQtyOpt_exp += cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;

          if (opt_per !== 0) {
            let _cpq = cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;
            let _fr = frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0;
            opt_per = opt_per ? (opt_per + "").replaceAll(",", "") : 0;
            let cpOptQty = _cpq + (_cpq * opt_per) / 100;
            cpQtyOpt_exp = cpQtyOpt_exp - _cpq + cpOptQty; //way to use opt% of cargo qty : refactor
            frtAmount = cpOptQty * _fr;
            if (frt_type_opt === "104") {
              frtAmount = lumsum_amt
                ? (lumsum_amt + "").replaceAll(",", "") * 1
                : 0;
            }
            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "").replaceAll(",", "") * 1
                  : 0)) /
              100;
            frtRate =
              (cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0);
          } else {
            frtAmount =
              (cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0);
            if (frt_type_opt === "104") {
              frtAmount = lumsum_amt
                ? (lumsum_amt + "").replaceAll(",", "") * 1
                : 0;
            }
            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "").replaceAll(",", "") * 1
                  : 0)) /
              100;
            frtRate = frtAmount;
          }

          expenses_frate += frtAmount;
          freightCommission_exp += commission;
        });

        //----- for revenue -----//

        cargos_sell?.map((e) => {
          let frtRate = 0,
            commission = 0,
            frtAmount = 0;

          let frt_rate =
            e.frt_type === "259"
              ? parseFloat(e.world_scale)
              : e.freight_rate || e.frat_rate || e.f_rate || 0;
          let lumsum_amt = e.lumsum || 0;
          let frt_type_opt = e.frt_type || e.frt_type || e.frt_type || 0;
          let demmurage = e.dem_rate_pd || e.dem_rate_pd || e.dem_rate_pd || 0;
          let desmurage = e.des_rate_pd || e.des_rate_pd || e.des_rate_pd || 0;

          let cp_qty = e.cp_qty || e.quantity || 0;
          let b_commission = e.b_commission || e.commission || 0;

          let opt_per =
            parseFloat(e.option_percentage) ||
            parseFloat(e.opt_percentage) ||
            0;
          extra_rev += e.extra_rev
            ? (e.extra_rev + "").replaceAll(",", "") * 1
            : 0;

          cpQtyOpt_exp += cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;

          if (opt_per !== 0) {
            let _cpq = cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0;
            let _fr = frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0;
            opt_per = opt_per ? (opt_per + "").replaceAll(",", "") : 0;
            let cpOptQty = _cpq + (_cpq * opt_per) / 100;
            cpQtyOpt_rev = cpQtyOpt_rev - _cpq + cpOptQty; //way to use opt% of cargo qty : refactor
            frtAmount = cpOptQty * _fr;
            if (frt_type_opt === "104") {
              frtAmount = lumsum_amt
                ? (lumsum_amt + "").replaceAll(",", "") * 1
                : 0;
            }
            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "").replaceAll(",", "") * 1
                  : 0)) /
              100;
            frtRate =
              (cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0);
          } else {
            frtAmount =
              (cp_qty ? (cp_qty + "").replaceAll(",", "") * 1 : 0) *
              (frt_rate ? (frt_rate + "").replaceAll(",", "") * 1 : 0);
            if (frt_type_opt === "104") {
              frtAmount = lumsum_amt
                ? (lumsum_amt + "").replaceAll(",", "") * 1
                : 0;
            }
            commission =
              (frtAmount *
                (b_commission
                  ? (b_commission + "").replaceAll(",", "") * 1
                  : 0)) /
              100;
            frtRate = frtAmount;
          }

          revenues_frate += frtAmount;
          freightCommission_rev += commission;

          // demmurage_amt_rev += demmurage * 1; // no need
          // desmurage_amt_rev += desmurage*1  // no need
        });
      }

      //let rev_gross_rev = rev_frt + rev_extra_rev + (demmurage_amt_sales - desmurage_amt_sales);
      let rev_gross_rev =
        revenues_frate + extra_rev + (demmurage_amt_rev - desmurage_amt_rev);

      rev_gross_rev = rev_gross_rev.toFixed(2);

      let rev_net_rev = rev_gross_rev - freightCommission_rev;

      rev_net_rev = rev_net_rev.toFixed(2);
      let otherexp = otherCost + bb;

      //let exp_gross_expense = (exp_frt + exp_demdes + mis_cost - exp_extra_rev) - (demmurage_amt_purch + desmurage_amt_purch);
      let exp_gross_expense = expenses_frate + otherexp;
      exp_gross_expense = exp_gross_expense.toFixed(2);

      //let exp_net_expense = (exp_gross_expense - exp_ttl_com) + (demcommissionamt_purch - descommissionamt_purch);
      let exp_net_expense = exp_gross_expense - freightCommission_exp;
      exp_net_expense = exp_net_expense.toFixed(2);

      totalVoyageDays = totalVoyageDays.toFixed(2);

      //-----------------------show values on the ui----------------
      // revenue
      estimateData[0][colName] = this.dollarFormatedValue(rev_net_rev);
      let reves = estimateData[0]["estimate"]
        ? estimateData[0]["estimate"].replaceAll(",", "") * 1
        : 0;
      let revac = estimateData[0]["actual"]
        ? estimateData[0]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["Diff"] = FirstVSSecond(revac, reves);
      estimateData[0]["perDiff"] = perFirstVSSecond(revac, reves);
      estimateData[0]["posted"] = this.dollarFormatedValue(net_revenue_posted);
      estimateData[0]["cash_in"] = this.dollarFormatedValue(net_revenue_cash);
      estimateData[0]["sec_variance"] = FirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );
      estimateData[0]["sec_per"] = perFirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );

      estimateData[0]["children"][0][colName] =
        this.dollarFormatedValue(revenues_frate);

      let frtes = estimateData[0]["children"][0]["estimate"]
        ? estimateData[0]["children"][0]["estimate"].replaceAll(",", "") * 1
        : 0;
      let frtac = estimateData[0]["children"][0]["actual"]
        ? estimateData[0]["children"][0]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][0]["Diff"] = FirstVSSecond(frtac, frtes);
      estimateData[0]["children"][0]["perDiff"] = perFirstVSSecond(
        frtac,
        frtes
      );
      estimateData[0]["children"][0]["posted"] =
        this.dollarFormatedValue(frightInvoice_posted);
      estimateData[0]["children"][0]["cash_in"] =
        this.dollarFormatedValue(frightInvoice_cash);
      estimateData[0]["children"][0]["sec_variance"] = FirstVSSecond(
        frightInvoice_posted,
        frightInvoice_cash
      );
      estimateData[0]["children"][0]["sec_per"] = perFirstVSSecond(
        frightInvoice_posted,
        frightInvoice_cash
      );

      estimateData[0]["children"][1][colName] = this.dollarFormatedValue(
        freightCommission_rev
      );
      let frtcmsnes = checkNanAndFormat(
        estimateData[0]["children"][1]["estimate"]
      );
      let frtcmsnac = checkNanAndFormat(
        estimateData[0]["children"][1]["actual"]
      );
      estimateData[0]["children"][1]["Diff"] = FirstVSSecond(
        frtcmsnac,
        frtcmsnes
      );
      estimateData[0]["children"][1]["perDiff"] = perFirstVSSecond(
        frtcmsnac,
        frtcmsnes
      );
      estimateData[0]["children"][1]["posted"] =
        this.dollarFormatedValue(frightComm_posted);
      estimateData[0]["children"][1]["cash_in"] =
        this.dollarFormatedValue(frightComm_cash);
      estimateData[0]["children"][1]["sec_variance"] = FirstVSSecond(
        frightComm_posted,
        frightComm_cash
      );
      estimateData[0]["children"][1]["sec_per"] = perFirstVSSecond(
        frightComm_posted,
        frightComm_cash
      );

      estimateData[0]["children"][2][colName] = this.dollarFormatedValue(0);
      let miscreves = estimateData[0]["children"][2]["estimate"]
        ? estimateData[0]["children"][2]["estimate"].replaceAll(",", "") * 1
        : 0;
      let miscrevac = estimateData[0]["children"][2]["actual"]
        ? estimateData[0]["children"][2]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][2]["Diff"] = FirstVSSecond(
        miscrevac,
        miscreves
      );
      estimateData[0]["children"][2]["perDiff"] = perFirstVSSecond(
        miscrevac,
        miscreves
      );
      estimateData[0]["children"][2]["posted"] =
        this.dollarFormatedValue(miscRev_posted);
      estimateData[0]["children"][2]["cash_in"] =
        this.dollarFormatedValue(miscRev_cash);
      estimateData[0]["children"][2]["sec_variance"] = FirstVSSecond(
        miscRev_posted,
        miscRev_cash
      );
      estimateData[0]["children"][2]["sec_per"] = perFirstVSSecond(
        miscRev_posted,
        miscRev_cash
      );

      estimateData[0]["children"][3][colName] = this.dollarFormatedValue(0);

      let demmuragees = estimateData[0]["children"][3]["estimate"]
        ? estimateData[0]["children"][3]["estimate"].replaceAll(",", "") * 1
        : 0;
      let demmurageac = estimateData[0]["children"][3]["actual"]
        ? estimateData[0]["children"][3]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][3]["Diff"] = FirstVSSecond(
        demmurageac,
        demmuragees
      );
      estimateData[0]["children"][3]["perDiff"] = perFirstVSSecond(
        demmurageac,
        demmuragees
      );
      estimateData[0]["children"][3]["posted"] =
        this.dollarFormatedValue(demAmt_posted);
      estimateData[0]["children"][3]["cash_in"] =
        this.dollarFormatedValue(demAmt_cash);
      estimateData[0]["children"][3]["sec_variance"] = FirstVSSecond(
        demAmt_posted,
        demAmt_cash
      );
      estimateData[0]["children"][3]["sec_per"] = perFirstVSSecond(
        demAmt_posted,
        demAmt_cash
      );

      estimateData[0]["children"][4][colName] = this.dollarFormatedValue(
        demmurage_amt_rev.toFixed(2)
      );
      let demcomamtes = estimateData[0]["children"][4]["estimate"]
        ? estimateData[0]["children"][4]["estimate"].replaceAll(",", "") * 1
        : 0;
      let demcomamtac = estimateData[0]["children"][4]["actual"]
        ? estimateData[0]["children"][4]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][4]["Diff"] = FirstVSSecond(
        demcomamtac,
        demcomamtes
      );
      estimateData[0]["children"][4]["perDiff"] = perFirstVSSecond(
        demcomamtac,
        demcomamtes
      );
      estimateData[0]["children"][4]["posted"] =
        this.dollarFormatedValue(demComAmt_posted);
      estimateData[0]["children"][4]["cash_in"] =
        this.dollarFormatedValue(demComAmt_cash);
      estimateData[0]["children"][4]["sec_variance"] = FirstVSSecond(
        demComAmt_posted,
        demComAmt_cash
      );
      estimateData[0]["children"][4]["sec_per"] = perFirstVSSecond(
        demComAmt_posted,
        demComAmt_cash
      );

      estimateData[0]["children"][5][colName] = this.dollarFormatedValue(
        desmurage_amt_rev.toFixed(2)
      );
      let desamtes = checkNanAndFormat(
        estimateData[0]["children"][5]["estimate"]
      );
      let desamtac = checkNanAndFormat(
        estimateData[0]["children"][5]["actual"]
      );
      estimateData[0]["children"][5]["Diff"] = FirstVSSecond(
        desamtac,
        desamtes
      );
      estimateData[0]["children"][5]["perDiff"] = perFirstVSSecond(
        desamtac,
        desamtes
      );
      estimateData[0]["children"][5]["posted"] =
        this.dollarFormatedValue(desAmt_posted);
      estimateData[0]["children"][5]["cash_in"] =
        this.dollarFormatedValue(desAmt_cash);
      estimateData[0]["children"][5]["sec_variance"] = FirstVSSecond(
        desAmt_posted,
        desAmt_cash
      );
      estimateData[0]["children"][5]["sec_per"] = perFirstVSSecond(
        desAmt_posted,
        desAmt_cash
      );

      estimateData[0]["children"][6][colName] =
        this.dollarFormatedValue(extra_rev);
      let extrareves = estimateData[0]["children"][6]["estimate"]
        ? estimateData[0]["children"][6]["estimate"].replaceAll(",", "") * 1
        : 0;
      let extrarevac = estimateData[0]["children"][6]["actual"]
        ? estimateData[0]["children"][6]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][6]["Diff"] = FirstVSSecond(
        extrarevac,
        extrareves
      );
      estimateData[0]["children"][6]["perDiff"] = perFirstVSSecond(
        extrarevac,
        extrareves
      );
      estimateData[0]["children"][6]["posted"] = this.dollarFormatedValue(0);
      estimateData[0]["children"][6]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[0]["children"][6]["sec_variance"] = FirstVSSecond(0);
      estimateData[0]["children"][6]["sec_per"] = perFirstVSSecond(0);

      estimateData[0]["children"][7][colName] =
        this.dollarFormatedValue(rev_gross_rev);
      let grossreves = estimateData[0]["children"][7]["estimate"]
        ? estimateData[0]["children"][7]["estimate"].replaceAll(",", "") * 1
        : 0;
      let grossrevac = estimateData[0]["children"][7]["actual"]
        ? estimateData[0]["children"][7]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][7]["Diff"] = FirstVSSecond(
        grossrevac,
        grossreves
      );
      estimateData[0]["children"][7]["perDiff"] = perFirstVSSecond(
        grossrevac,
        grossreves
      );
      estimateData[0]["children"][7]["posted"] =
        this.dollarFormatedValue(gross_revenue_posted);
      estimateData[0]["children"][7]["cash_in"] =
        this.dollarFormatedValue(gross_revenue_cash);
      estimateData[0]["children"][7]["sec_variance"] = FirstVSSecond(
        gross_revenue_posted,
        gross_revenue_cash
      );
      estimateData[0]["children"][7]["sec_per"] = perFirstVSSecond(
        gross_revenue_posted,
        gross_revenue_cash
      );

      estimateData[0]["children"][8][colName] =
        this.dollarFormatedValue(rev_net_rev);

      let netreves = estimateData[0]["children"][8]["estimate"]
        ? estimateData[0]["children"][8]["estimate"].replaceAll(",", "") * 1
        : 0;
      let netrevac = estimateData[0]["children"][7]["actual"]
        ? estimateData[0]["children"][8]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[0]["children"][8]["Diff"] = FirstVSSecond(
        netrevac,
        netreves
      );
      estimateData[0]["children"][8]["perDiff"] = perFirstVSSecond(
        netrevac,
        netreves
      );
      estimateData[0]["children"][8]["posted"] =
        this.dollarFormatedValue(net_revenue_posted);
      estimateData[0]["children"][8]["cash_in"] =
        this.dollarFormatedValue(net_revenue_cash);
      estimateData[0]["children"][8]["sec_variance"] = FirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );
      estimateData[0]["children"][8]["sec_per"] = perFirstVSSecond(
        net_revenue_posted,
        net_revenue_cash
      );

      //----------------------     // expense calculation---------------------------------------//

      estimateData[1][colName] = this.dollarFormatedValue(exp_net_expense);
      let expenses = estimateData[1]["estimate"]
        ? estimateData[1]["estimate"].replaceAll(",", "") * 1
        : 0;
      let expensesac = estimateData[1]["actual"]
        ? estimateData[1]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["Diff"] = FirstVSSecond(expensesac, expenses);
      estimateData[1]["perDiff"] = perFirstVSSecond(expensesac, expenses);
      estimateData[1]["posted"] = this.dollarFormatedValue(0);
      estimateData[1]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[1]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["sec_per"] = perFirstVSSecond(0, 0);

      estimateData[1]["children"][0][colName] =
        this.dollarFormatedValue(expenses_frate);
      let frtexpnses = checkNanAndFormat(
        estimateData[1]["children"][0]["estimate"]
      );
      let frtexpnsac = checkNanAndFormat(
        estimateData[1]["children"][0]["actual"]
      );
      estimateData[1]["children"][0]["Diff"] = FirstVSSecond(
        frtexpnsac,
        frtexpnses
      );
      estimateData[1]["children"][0]["perDiff"] = perFirstVSSecond(
        frtexpnsac,
        frtexpnses
      );
      estimateData[1]["children"][0]["posted"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][0]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][0]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["children"][0]["sec_per"] = perFirstVSSecond(0, 0);

      //-----------VC Frieght Commission	-----------------------------------
      estimateData[1]["children"][1][colName] = this.dollarFormatedValue(
        freightCommission_exp.toFixed(2)
      );
      let frtcomes = checkNanAndFormat(
        estimateData[1]["children"][1]["estimate"]
      );
      let frtcomac = checkNanAndFormat(
        estimateData[1]["children"][1]["actual"]
      );
      estimateData[1]["children"][1]["Diff"] = FirstVSSecond(
        frtcomac,
        frtcomes
      );
      estimateData[1]["children"][1]["perDiff"] = perFirstVSSecond(
        frtcomac,
        frtcomes
      );
      estimateData[1]["children"][1]["posted"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][1]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][1]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["children"][1]["sec_per"] = perFirstVSSecond(0, 0);

      //---------------VC Pur Add. Commission-------------------------
      estimateData[1]["children"][2][colName] = this.dollarFormatedValue(
        amt_add_percentage.toFixed(2)
      );
      let vcaddcomes = checkNanAndFormat(
        estimateData[1]["children"][2]["estimate"]
      );
      let vcaddcomac = checkNanAndFormat(
        estimateData[1]["children"][2]["actual"]
      );
      estimateData[1]["children"][2]["Diff"] = FirstVSSecond(
        vcaddcomac,
        vcaddcomes
      );
      estimateData[1]["children"][2]["perDiff"] = perFirstVSSecond(
        vcaddcomac,
        vcaddcomes
      );
      estimateData[1]["children"][2]["posted"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][2]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][2]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["children"][2]["sec_per"] = perFirstVSSecond(0, 0);

      ///-------------VC Pur Bro. Commission----------------------

      estimateData[1]["children"][3][colName] = this.dollarFormatedValue(
        amt_bro_percentage.toFixed(2)
      );
      let vcbrocomes = checkNanAndFormat(
        estimateData[1]["children"][3]["estimate"]
      );
      let vcbrocomac = checkNanAndFormat(
        estimateData[1]["children"][3]["actual"]
      );
      estimateData[1]["children"][3]["Diff"] = FirstVSSecond(
        vcbrocomac,
        vcbrocomes
      );
      estimateData[1]["children"][3]["perDiff"] = perFirstVSSecond(
        vcbrocomac,
        vcbrocomes
      );
      estimateData[1]["children"][3]["posted"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][3]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][3]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["children"][3]["sec_per"] = perFirstVSSecond(0, 0);

      //----------------Demmurrage-----------------------------------------//

      let demexp = 0;
      estimateData[1]["children"][4][colName] =
        this.dollarFormatedValue(demexp);
      let demexpes = checkNanAndFormat(
        estimateData[1]["children"][4]["estimate"]
      );
      let demexpac = checkNanAndFormat(
        estimateData[1]["children"][4]["actual"]
      );
      estimateData[1]["children"][4]["Diff"] = FirstVSSecond(
        demexpac,
        demexpes
      );
      estimateData[1]["children"][4]["perDiff"] = perFirstVSSecond(
        demexpac,
        demexpes
      );
      estimateData[1]["children"][4]["posted"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][4]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][4]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["children"][4]["sec_per"] = perFirstVSSecond(0, 0);

      //----------------despatch---------------------------------------------//
      let desexp = 0;
      estimateData[1]["children"][5][colName] =
        this.dollarFormatedValue(desexp);

      let desexpes = checkNanAndFormat(
        estimateData[1]["children"][5]["estimate"]
      );
      let desexpac = checkNanAndFormat(
        estimateData[1]["children"][5]["actual"]
      );
      estimateData[1]["children"][5]["Diff"] = FirstVSSecond(
        desexpac,
        desexpes
      );
      estimateData[1]["children"][5]["perDiff"] = perFirstVSSecond(
        desexpac,
        desexpes
      );
      estimateData[1]["children"][5]["posted"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][5]["cash_in"] = this.dollarFormatedValue(0);
      estimateData[1]["children"][5]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["children"][5]["sec_per"] = perFirstVSSecond(0, 0);

      //-------------------------Other Expenses	------------------------
      estimateData[1]["children"][6][colName] = dollarUSLocale.format(
        otherexp.toFixed(2)
      );
      let otherexpnses = estimateData[1]["children"][6]["estimate"]
        ? estimateData[1]["children"][6]["estimate"].replaceAll(",", "") * 1
        : 0;
      let otherexpnsac = estimateData[1]["children"][6]["actual"]
        ? estimateData[1]["children"][6]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][6]["Diff"] = (
        otherexpnsac - otherexpnses
      ).toFixed(2);
      estimateData[1]["children"][6]["perDiff"] =
        otherexpnses === 0
          ? "0.00"
          : (((otherexpnsac - otherexpnses) / otherexpnses) * 100).toFixed(2);
      estimateData[1]["children"][6]["posted"] = "0.00";
      estimateData[1]["children"][6]["cash_in"] = "0.00";
      estimateData[1]["children"][6]["sec_variance"] = FirstVSSecond(0, 0);
      estimateData[1]["children"][6]["sec_per"] = perFirstVSSecond(0, 0);

      // gross expense

      estimateData[1]["children"][7][colName] =
        this.dollarFormatedValue(exp_gross_expense);
      let grossExpest = estimateData[1]["children"][7]["estimate"]
        ? estimateData[1]["children"][7]["estimate"].replaceAll(",", "") * 1
        : 0;
      let grossExpact = estimateData[1]["children"][7]["actual"]
        ? estimateData[1]["children"][7]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][7]["Diff"] = FirstVSSecond(
        grossExpact,
        grossExpest
      );
      estimateData[1]["children"][7]["perDiff"] = perFirstVSSecond(
        grossExpact,
        grossExpest
      );
      estimateData[1]["children"][7]["posted"] =
        this.dollarFormatedValue(grossExpenses_posted);
      estimateData[1]["children"][7]["cash_in"] =
        this.dollarFormatedValue(grossExpenses_cash);
      estimateData[1]["children"][7]["sec_variance"] = FirstVSSecond(
        grossExpenses_posted,
        grossExpenses_cash
      );
      estimateData[1]["children"][7]["sec_per"] = perFirstVSSecond(
        grossExpenses_posted,
        grossExpenses_cash
      );

      // 18 net expense

      estimateData[1]["children"][8][colName] =
        this.dollarFormatedValue(exp_net_expense);

      let netExpest = estimateData[1]["children"][8]["estimate"]
        ? estimateData[1]["children"][8]["estimate"].replaceAll(",", "") * 1
        : 0;
      let netExpact = estimateData[1]["children"][8]["actual"]
        ? estimateData[1]["children"][8]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[1]["children"][8]["Diff"] = FirstVSSecond(
        netExpact,
        netExpest
      );
      estimateData[1]["children"][8]["perDiff"] = perFirstVSSecond(
        netExpact,
        netExpest
      );
      estimateData[1]["children"][8]["posted"] =
        this.dollarFormatedValue(netExpenses_posted);
      estimateData[1]["children"][8]["cash_in"] =
        this.dollarFormatedValue(netExpenses_cash);
      estimateData[1]["children"][8]["sec_variance"] = FirstVSSecond(
        netExpenses_posted,
        netExpenses_cash
      );
      estimateData[1]["children"][8]["sec_per"] = perFirstVSSecond(
        netExpenses_posted,
        netExpenses_cash
      );

      // voyage result-----------
      let itemValue = rev_net_rev - exp_net_expense;

      estimateData[2][colName] = this.dollarFormatedValue(itemValue);

      estimateData[2]["children"][0][colName] =
        this.dollarFormatedValue(itemValue);

      let vygreses = estimateData[2]["estimate"]
        ? estimateData[2]["estimate"].replaceAll(",", "") * 1
        : 0;
      let vygresac = estimateData[2]["actual"]
        ? estimateData[2]["actual"].replaceAll(",", "") * 1
        : 0;
      estimateData[2]["Diff"] = (vygresac - vygreses).toFixed(2);
      estimateData[2]["perDiff"] = (
        ((vygresac - vygreses) / vygreses) *
        100
      ).toFixed(2);
      estimateData[2]["posted"] = "0.00";
      estimateData[2]["cash_in"] = "0.00";
      estimateData[2]["sec_variance"] = "0.00";
      estimateData[2]["sec_per"] = "0.00";

      let profitlses = checkNanAndFormat(
        estimateData[2]["children"][0]["estimate"]
      );
      let profitlsac = checkNanAndFormat(
        estimateData[2]["children"][0]["actual"]
      );
      estimateData[2]["children"][0]["Diff"] = FirstVSSecond(
        profitlsac,
        profitlses
      );
      estimateData[2]["children"][0]["perDiff"] = perFirstVSSecond(
        profitlsac,
        profitlses
      );
      estimateData[2]["children"][0]["posted"] = "0.00";
      estimateData[2]["children"][0]["cash_in"] = "0.00";
      estimateData[2]["children"][0]["sec_variance"] = "0.00";
      estimateData[2]["children"][0]["sec_per"] = "0.00";

      estimateData[2]["children"][1][colName] = this.dollarFormatedValue(
        (itemValue / (totalVoyageDays - 0)).toFixed(2)
      );
      let dlprofitlses = checkNanAndFormat(
        estimateData[2]["children"][1]["estimate"]
      );
      let dlprofitlsac = checkNanAndFormat(
        estimateData[2]["children"][1]["actual"]
      );
      estimateData[2]["children"][1]["Diff"] = FirstVSSecond(
        dlprofitlsac,
        dlprofitlses
      );
      estimateData[2]["children"][1]["perDiff"] = perFirstVSSecond(
        dlprofitlsac,
        dlprofitlses
      );
      estimateData[2]["children"][1]["posted"] = "0.00";
      estimateData[2]["children"][1]["cash_in"] = "0.00";
      estimateData[2]["children"][1]["sec_variance"] = "0.00";
      estimateData[2]["children"][1]["sec_per"] = "0.00";

      estimateData[2]["children"][2][colName] = this.dollarFormatedValue(
        tsd * 1
      );
      let tsdes = checkNanAndFormat(estimateData[2]["children"][2]["estimate"]);
      let tsdac = checkNanAndFormat(estimateData[2]["children"][2]["actual"]);
      estimateData[2]["children"][2]["Diff"] = FirstVSSecond(tsdac, tsdes);
      estimateData[2]["children"][2]["perDiff"] = perFirstVSSecond(
        tsdac,
        tsdes
      );
      estimateData[2]["children"][2]["posted"] = "0.00";
      estimateData[2]["children"][2]["cash_in"] = "0.00";
      estimateData[2]["children"][2]["sec_variance"] = "0.00";
      estimateData[2]["children"][2]["sec_per"] = "0.00";

      estimateData[2]["children"][3][colName] = this.dollarFormatedValue(
        tpd * 1
      );
      let tpdes = checkNanAndFormat(estimateData[2]["children"][3]["estimate"]);
      let tpdac = checkNanAndFormat(estimateData[2]["children"][3]["actual"]);
      estimateData[2]["children"][3]["Diff"] = FirstVSSecond(tpdac, tpdes);
      estimateData[2]["children"][3]["perDiff"] = perFirstVSSecond(
        tpdac,
        tpdes
      );
      estimateData[2]["children"][3]["posted"] = "0.00";
      estimateData[2]["children"][3]["cash_in"] = "0.00";
      estimateData[2]["children"][3]["sec_variance"] = "0.00";
      estimateData[2]["children"][3]["sec_per"] = "0.00";

      estimateData[2]["children"][4][colName] = this.dollarFormatedValue(
        totalVoyageDays * 1
      );
      let netvygdses = checkNanAndFormat(
        estimateData[2]["children"][4]["estimate"]
      );
      let netvygdsac = checkNanAndFormat(
        estimateData[2]["children"][4]["actual"]
      );
      estimateData[2]["children"][4]["Diff"] = FirstVSSecond(
        netvygdsac,
        netvygdses
      );
      estimateData[2]["children"][4]["perDiff"] = perFirstVSSecond(
        netvygdsac,
        netvygdses
      );
      estimateData[2]["children"][4]["posted"] = "0.00";
      estimateData[2]["children"][4]["cash_in"] = "0.00";
      estimateData[2]["children"][4]["sec_variance"] = "0.00";
      estimateData[2]["children"][4]["sec_per"] = "0.00";

      // revenue invoices

      estimateData[0]["children"][0]["children"] = [
        ...estimateDatavalue["freight_sell_invoices"],
      ];

      estimateData[0]["children"][1]["children"] = [
        ...estimateDatavalue["freight_sell_commission_invoice"],
      ];

      estimateData[0]["children"][6]["children"] = [
        ...estimateDatavalue["other_revenue_invoices"],
      ];

      // expense invoices
      estimateData[1]["children"][0]["children"] = [
        ...estimateDatavalue["freight_purchase_invoices"],
      ];

      estimateData[1]["children"][1]["children"] = [
        ...estimateDatavalue["freight_purchase_commission_invoice"],
      ];

      estimateData[1]["children"][6]["children"] = [
        ...estimateDatavalue["other_expense_invoices"],
      ];

      revenue = {
        rev_net_rev,
        revenues_frate,
        extra_rev,
        desmurage_amt_rev,
        demmurage_amt_rev,
        freightCommission_rev,
        extra_rev,
        rev_gross_rev,
        exp_net_expense,
        expenses_frate,
        freightCommission_exp,

        bb,
        exp_gross_expense,
        exp_net_expense,
        itemValue,
        totalVoyageDays,
      };

      return { estimateData, revenue };
    } catch (err) {
      console.log("err", err);
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  handleClick = async () => {
    // Fetch the element by class name
    const element = document.querySelector(
      ".ant-table-row-expand-icon-collapsed"
    );

    if (element) {
      await element.click();
    }
  };

  savePNL = async () => {
    const _id = this.state?.formData?.id;
    this.setState({ ...this.state, saveLoading: true, showPL: false });
    const currentUser = localStorage.getItem("currentUser");
    const token = localStorage.getItem("oceanToken");

    const currentTime = moment(new Date()).format("YYYY-MM-DD,HH:mm:ss");
    //const dropdownUser=currentUser.split(" ").join("")+currentTime;
    const dropdownUser =
      "|" + currentTime + "|" + _id.toString() + "|" + currentUser;

    const savePayload = {
      //pnl_data:[{pnl_est_data: this.state.estimateData, pnl_frm_data: this.state.formData}],
      pnl_data: this.state.formData,
      remark: this.state.remark,
      user: dropdownUser,
      created_at: currentTime,
      voyage_no: _id,
    };

    const myurl = `${URL_WITH_VERSION}/voyage-manager/voyage-pnl/save`;
    try {
      const response = await fetch(myurl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(savePayload),
      });

      const data = await response.json();
      if (data.data) {
        await this.getSavedPNLList(true);
        openNotificationWithIcon("info", data.message);
      } else {
        openNotificationWithIcon("error", data.message);
      }
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  componentDidMount() {
    const pl = this.__pl();
    this.setState(
      (prevState) => ({
        ...prevState,
        showPL: false,
        estimateData: pl.estimateData,
        tooltipData: pl.revenue,
      }),
      async () => {
        await this.getSavedPNLList();
        this.setState((prevState) => ({
          ...prevState,
          showPL: true,
        }));
      }
    );
  }

  getSavedPNLList = async (calledAfterSave = false) => {
    const _id = this.props.formData.id;
    try {
      let qParamString = objectToQueryStringFunc({ ae: _id });
      let url = `${URL_WITH_VERSION}/voyage-manager/voyage-pnl/drop-list?l=0&p=1&${qParamString}`;
      const response = await getAPICall(url);
      // console.log('response data dropdownlist:', response.data)
      if (response.data.length > 0) {
        const selectOptionsList = [
          {
            value: "actual",
            label: "Actual",
          },
          ...response?.data?.map((item) => ({
            label: item.pnl_label,
            value: item.id,
          })),
        ];

        // this.setState({...this.state,selectOptions:selectOptionsList})

        if (calledAfterSave) {
          this.setState(
            {
              ...this.state,
              showPL: false,
              saveLoading: false,
              selectOptions: selectOptionsList,
            },
            () => this.setState({ ...this.state, showPL: true })
          );
        } else {
          this.setState({ ...this.state, selectOptions: selectOptionsList });
        }
      }
    } catch (err) {
      openNotificationWithIcon("error", "Something went wrong", 3);
    }
  };

  handleSelectChange = async (value) => {
    this.setState({ ...this.state, showPL: false });
    let changedFormData = {};
    let disableBtn = true;
    let updatedRemark = "";
    if (value === "actual") {
      changedFormData = this.props.formData;
      disableBtn = false;
    } else {
      const _id = this.props.formData.id;
      try {
        let qParamString = objectToQueryStringFunc({ ae: value });
        let url = `${URL_WITH_VERSION}/voyage-manager/pnl/edit?${qParamString}`;
        const response = await getAPICall(url);
        if (response.data.hasOwnProperty("pnl_data")) {
          changedFormData = response.data.pnl_data;
        }
        if (response.data.hasOwnProperty("remark")) {
          updatedRemark = response.data.remark;
        }
      } catch (err) {
        openNotificationWithIcon("error", "Something went wrong", 3);
      }
    }

    this.setState(
      {
        ...this.state,
        formData: changedFormData,
        actualSelection: disableBtn,
        selectedOption: value,
        remark: updatedRemark,
        //showPL: false,
      },
      () => {
        this.setState(
          {
            ...this.state,
            estimateData: this.__pl()?.estimateData,
          },
          () => {
            this.setState({ ...this.state, showPL: true });
          }
        );
      }
    );
  };

  remarkHandler = (e) => {
    const remarkValue = e.target.value;

    this.setState({ ...this.state, remark: remarkValue });
  };

  showcalculation = (title) => {
    if (title) {
      return (
        <>
          {VoyageReletCalcArr?.map((el) => {
            if (
              el?.title?.toLowerCase().replace(/[^a-z0-9]+/g, "") ===
              title?.toLowerCase().replace(/[^a-z0-9]+/g, "")
            ) {
              return (
                <VoyageReletPnl
                  tooltipData={this.state.tooltipData}
                  desc={el.formula}
                  title={el.title}
                  formData={this.state.formData}
                />
              );
            }
          })}
        </>
      );
    }
  };

  TableBodyCell = (props) => {
    const { title } = props;

    let totaldescarr = [
      "Revenue",
      "Freight (Cargo )",
      "VC Cargo Commision",
      "Other Revenue",
      "Gross Revenue",
      "Net Revenue",
      "Expenses",
      "VC Frieght",
      "VC Frieght Commission",
      "Other Expenses",
      "Gross Expenses",
      "Net Expenses",
      "Voyage Result",
      "Profit (Loss)",
      "Daily Profit (Loss)",
    ];

    return (
      <>
        {totaldescarr.includes(title) ? (
          <Popover
            content={() => this.showcalculation(title)}
            placement="right"
            destroyTooltipOnHide={true}
            fresh={true}
          >
            <td
              {...props}
              style={{
                ...props.style,
              }}
            />
          </Popover>
        ) : (
          <td
            {...props}
            style={{
              ...props.style,
            }}
          />
        )}
      </>
    );
  };

  render() {
    const {
      estimateData,
      showPL,
      viewTabs,
      saveLoading,
      actualSelection,
      selectedOption,
      selectOptions,
      remark,
      openGraphModal,
      openAICompModal,
    } = this.state;

    const handleOpenAnalytics = () => {
      this.setState((prev) => ({ ...prev, openGraphModal: true }));
    };

    const handleOpenAIComprater = () => {
      this.setState((prev) => ({ ...prev, openAICompModal: true }));
    };
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          icon={<SaveOutlined />}
          loading={saveLoading}
          size="middle"
          onClick={this.savePNL}
          disabled={actualSelection}
        >
          Save PNL
        </Button>

        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            float: "right",
            marginRight: "9%",
          }}
        >
          <Select
            defaultValue="Actual"
            value={selectedOption}
            style={{
              width: 250,
            }}
            placement="Select saved PNL"
            loading={!showPL}
            disabled={!showPL}
            onChange={this.handleSelectChange}
            options={showPL ? selectOptions : []}
          />
          {/* <Tag
            id="aiAnalysis-noon"
            onClick={handleOpenAnalytics}
            style={{
              fontSize:"14px",
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
              width: "6.9rem",
              height: "30px",
              fontWeight: "bold",
              marginLeft: "40px",
              borderRadius: "30px",
            }}
          > */}
          {/* <BarChartOutlined style={{ marginLeft: "0.4rem" }} />
            AI Analysis */}
          <div
            onClick={handleOpenAnalytics}
            style={{ cursor: "pointer", marginleft: "0.5rem" }}
          >
            <AiButton text="AI Analysis" />
          </div>
          {/* </Tag> */}

          {/* <Tag
            id="aiAnalysis-noon"
            style={{
              fontSize:"15px",
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
              width: "16rem",
              height: "30px",
              fontWeight: "bold",
              borderRadius: "30px",
            }}
          > */}
          {/* <BarChartOutlined style={{marginLeft:"0.4rem"}}/> */}
          {/* <span>AI-Powered P&L Comparator</span> */}
          <div
            style={{
              marginLeft: "0.9rem",
            }}
            onClick={handleOpenAIComprater}
          >
            <AiButton text="AI-Powered P&L Comparator" />
          </div>
          {/* </Tag> */}
        </div>

        {openGraphModal ? (
          <Modal
            open={openGraphModal}
            onCancel={() =>
              this.setState((prev) => ({ ...prev, openGraphModal: false }))
            }
            footer={null}
            width={"85vw"}
            title="P&L Analysis"
          >
            <PnlGraph estimateData={this.props.estimateData} />
          </Modal>
        ) : null}

        {openAICompModal ? (
          <Modal
            open={openAICompModal}
            onCancel={() =>
              this.setState((prev) => ({ ...prev, openAICompModal: false }))
            }
            footer={null}
            width={"85vw"}
            title="AI-Powered P&L Comparator"
          >
            {/* <PnlGraph estimateData={this.props.estimateData} /> */}
            <AiPLComparator
              actualPL={this.props.estimateData}
              selectOptions={this?.state?.selectOptions}
            />
          </Modal>
        ) : null}

        <div>
          <ToolbarUI routeUrl={"pl-main"} callback={(e) => this.callback(e)} />
        </div>
        {showPL === true ? (
          <div ref={this.elementRef}>
            <Tabs defaultActiveKey="1">
              {viewTabs?.map((e) => {
                if (e === "Actual &  Operation View") {
                  return (
                    <TabPane tab={e} key="ao2">
                      <Table
                        className="pl-summary-list-view"
                        bordered
                        columns={columns}
                        dataSource={estimateData}
                        components={{
                          body: {
                            cell: this.TableBodyCell,
                          },
                        }}
                        pagination={false}
                        rowClassName={(r, i) =>
                          i % 2 === 0
                            ? r.is_invoiced
                              ? "table-striped-listing colorthepl"
                              : "table-striped-listing"
                            : r.is_invoiced
                            ? "table-striped-listing colorthepl"
                            : "dull-color table-striped-listing"
                        }
                      />
                      <Row gutter={16} className="m-t-18">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <FormItem
                            label="Remark"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: "10px 0 0 9px" }}
                          >
                            <div
                              style={{ border: "1px solid blue" }}
                              className="vk"
                            >
                              <TextArea
                                placeholder="Remark"
                                value={remark}
                                onChange={this.remarkHandler}
                                autoSize={{ minRows: 6, maxRows: 6 }}
                              />
                            </div>
                          </FormItem>
                        </Col>
                      </Row>
                    </TabPane>
                  );
                } else if (e === "Estimate View") {
                  return (
                    <TabPane tab={e} key="ev1">
                      <Table
                        className="pl-summary-list-view"
                        bordered
                        columns={columns2}
                        dataSource={estimateData}
                        pagination={false}
                        rowClassName={(r, i) =>
                          i % 2 === 0
                            ? "table-striped-listing"
                            : "dull-color table-striped-listing"
                        }
                      />
                      <Row gutter={16} className="m-t-18">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <FormItem
                            label="Remark"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ margin: "10px 0 0 9px" }}
                          >
                            <div
                              style={{ border: "1px solid blue" }}
                              className="vk"
                            >
                              <TextArea
                                placeholder="Remark"
                                autoSize={{ minRows: 6, maxRows: 6 }}
                              />
                            </div>
                          </FormItem>
                        </Col>
                      </Row>
                    </TabPane>
                  );
                } else if (e === "Account View") {
                  return (
                    <TabPane tab={e} key="av3">
                      Accounts
                    </TabPane>
                  );
                }
              })}
            </Tabs>
          </div>
        ) : (
          <div className="col col-lg-12">
            <Spin tip="Loading...">
              <Alert message=" " description="Please wait..." type="info" />
            </Spin>
          </div>
        )}
      </div>
    );
  }
}

export default PL;
