import React, { Component } from "react";
import { Modal } from "antd";
import NormalFormIndex from "../../shared/NormalForm/normal_from_index";
import AttachmentFile from "../../shared/components/Attachment";
import ShareLink from "../../shared/ShareLink";
import { v4 as uuidv4 } from "uuid";

import URL_WITH_VERSION, {
  postAPICall,
  getAPICall,
  openNotificationWithIcon,
  apiDeleteCall,
} from "../../shared";
import {
  CloudUploadOutlined,
  SaveOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frmName: "vessel_attachment_form",
      formData: {},
      loadForm: false,
      oldFormData: this.props.frmData || {},
      isUpdate: false,
      visibleVesselAttachment: false,
      imagesAttach: [],
      indexNumber: 0,
      shareViewVisible: false,
    };
  }

  componentDidMount = () => {
    const { oldFormData } = this.state;
    let vessel_form_id = oldFormData.id;
    this.prepaireFormData(vessel_form_id);
  };

  prepaireFormData = async (vessel_form_id) => {
    if (vessel_form_id) {
      const response = await getAPICall(
        `${URL_WITH_VERSION}/vessel/vessel-list-attachment/edit?e=${vessel_form_id}`
      );
      const respData = await response["data"];

      if (respData && respData[".."] && respData[".."].length > 0) {
        const imagesAttach = respData[".."].map((item) => {
          return item.share_link2;
        });
        this.setState({
          formData: respData,
          loadForm: true,
          isUpdate: true,
          imagesAttach: imagesAttach,
        });
      } else {
        this.setState({ loadForm: true, isUpdate: false });
      }
    }
  };

  saveFormData = (data) => {
    const { frmName, oldFormData, imagesAttach } = this.state;
    let vessel_form_id = oldFormData.id;
    let dataArray = [];
    let insert_array = {};
    if (imagesAttach.length === 0) {
      openNotificationWithIcon("info", "Please Upload Attachment First");
      return;
    }
    if (imagesAttach && imagesAttach.length > 0) {
      data[".."].map((e, index) => {
        let obj1 = {};
        obj1.certificate_name = data[".."][index]["certificate_name"];
        obj1.certificate_no = data[".."][index]["certificate_no"];
        obj1.s_no = data[".."][index]["s_no"];
        obj1.issue_date_validility_from =
          data[".."][index]["issue_date_validility_from"];
        obj1.inspection_date = data[".."][index]["inspection_date"];
        obj1.valid_upto = data[".."][index]["valid_upto"];
        obj1.vessel_id = vessel_form_id;
        //obj1.share_link = "";
        obj1.share_link2 = imagesAttach[index];
        obj1.id = data[".."][index]["id"] || -9e6;
        dataArray.push(obj1);
        return true;
      });
      insert_array[".."] = dataArray;
    }
    let suURL = `${URL_WITH_VERSION}/vessel/vessel-list-attachment/save?frm=${frmName}`;

    let suMethod = "POST";
    postAPICall(suURL, insert_array, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        if (vessel_form_id) {
          this.setState({ loadForm: false });
          this.props.onClose();
        }
      } else {
        openNotificationWithIcon("error", data.message);
      }
    });
  };

  deleteTableData = async (id) => {
    const { oldFormData } = this.state;
    let vessel_form_id = oldFormData.id;
    let delete_data = {
      id: id,
    };

    postAPICall(
      `${URL_WITH_VERSION}/vessel/vesselattachment/delete`,
      delete_data,
      "delete",
      (response) => {
        if (response && response.data) {
          openNotificationWithIcon("success", response.message);
          this.prepaireFormData(vessel_form_id);
        } else {
          openNotificationWithIcon("error", response.message);
        }
      }
    );
  };
  onClickExtraIcon = async (action, data) => {
    const { imagesAttach } = this.state;
    imagesAttach?.splice(action.index, 1);
    this.setState((prev) => ({
      ...prev,
      imagesAttach: imagesAttach,
    }));
    if (Math.sign(data.id) > 0) {
      this.deleteTableData(data.id);
    }
  };

  onUploadVesselDoc = (action, data) => {
    this.setState(
      { ...this.state, indexNumber: action.index, rowId: action.ID },
      async () => {
        this.setState({ visibleVesselAttachment: true }, () => {});
      }
    );
  };

  openShareView = async (data) => {
    // if(this.state.imagesAttach && this.state.imagesAttach.length > 0){
    this.setState({ ...this.state, shareViewVisible: true });
    // }else{
    //   openNotificationWithIcon('info', 'Please upload attachment first')
    // }
  };

  isVesselDocCancel = () => {
    this.setState({ ...this.state, visibleVesselAttachment: false });
  };

  isVesselDoc = () => this.setState({ visibleVesselAttachment: false });

  closeShareView = () => this.setState({ shareViewVisible: false });

  uploadedFiles = (data) => {
    const { indexNumber, imagesAttach } = this.state;
    const _data = {
      attachment: data.fileName,
      share_link: data.url,
      id: uuidv4(),
    };

    let _imagesAttach = imagesAttach;
    if (_imagesAttach[indexNumber] && _imagesAttach[indexNumber].length > 0) {
      _imagesAttach[indexNumber] = [..._imagesAttach[indexNumber], _data];
    } else {
      _imagesAttach[indexNumber] = [_data];
    }

    this.setState({ ...this.state, imagesAttach: _imagesAttach });
  };

  deleteAttachment = (item) => {
    const { imagesAttach, rowId, indexNumber } = this.state;
    const filterItem =
      imagesAttach[indexNumber] &&
      imagesAttach[indexNumber].length > 0 &&
      imagesAttach[indexNumber].filter((e) => e.share_link !== item.url);

    let _imagesAttach = Object.assign([], imagesAttach);
    _imagesAttach[indexNumber] = filterItem;

    // const filterItem = imagesAttach.map((e) => {
    //   return e.filter(a => a.share_link !== item.url)

    // });

    this.setState(
      {
        ...this.state,
        imagesAttach: Object.assign([], _imagesAttach),
      },
      () => {
        apiDeleteCall(
          `${URL_WITH_VERSION}/vessel/vessel-list-attachment/delete`,
          { id: rowId, "-": filterItem },
          (data) => {
            if (data) {
              openNotificationWithIcon("success", data.message, 2);
            } else {
              openNotificationWithIcon("error", data.message, 2);
            }
          }
        );
      }
    );
  };

  render() {
    const {
      frmName,
      formData,
      isUpdate,
      loadForm,
      visibleVesselAttachment,
      indexNumber,
      imageFile,
      imagesAttach,
    } = this.state;

    return (
      <>
        {loadForm && (
          <NormalFormIndex
            key={"key_" + frmName + "_0"}
            formClass="label-min-height"
            formData={formData}
            frmCode={"vessel_attachment_form"}
            showForm={true}
            addForm={true}
            showToolbar={[
              {
                isLeftBtn: [
                  {
                    key: "s1",
                    isSets: [
                      {
                        id: "1",
                        key: "save",
                        type: <SaveOutlined />,
                        withText: "Save",
                        showToolTip: true,
                        event: (event, data) => {
                          this.saveFormData(data);
                        },
                      },
                      {
                        id: "2",
                        key: "share",
                        type: <ShareAltOutlined />,
                        withText: "Share",
                        showToolTip: true,
                        event: (event, data) => {
                          this.openShareView(data);
                        },
                      },
                    ],
                  },
                ],
                isRightBtn: [{ key: "s1", isSets: [] }],
                isResetOption: false,
              },
            ]}
            extraTableButton={{
              "..": [
                {
                  icon: <CloudUploadOutlined />,
                  onClickAction: (action, data) => {
                    this.onUploadVesselDoc(action, data);
                  },
                },
              ],
            }}
            tableRowDeleteAction={(action, data) =>
              this.onClickExtraIcon(action, data)
            }
            inlineLayout={true}
          />
        )}

        {visibleVesselAttachment ? (
          <Modal
            open={visibleVesselAttachment}
            title="Upload Attachment"
            onOk={this.isVesselDoc}
            onCancel={this.isVesselDocCancel}
            footer={null}
            width={1000}
            maskClosable={false}
          >
            <AttachmentFile
              uploadType="vessel"
              attachments={imagesAttach[indexNumber]}
              singleSelection={false}
              deleteAttachment={(item) => this.deleteAttachment(item)}
              onCloseUploadFileArray={(fileArr) => this.uploadedFiles(fileArr)}
              tableId={indexNumber}
            />
          </Modal>
        ) : undefined}

        <Modal
          open={this.state.shareViewVisible}
          title="Share"
          onOk={this.closeShareView}
          onCancel={this.closeShareView}
          footer={null}
          width={1000}
          maskClosable={false}
        >
          <ShareLink vesselId={this.state.oldFormData.id} />
        </Modal>
      </>
    );
  }
}

export default Attachment;
