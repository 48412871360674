import React, { useEffect, useState } from "react";
import { Button, Avatar, Typography } from "antd";
import { Icon } from "@iconify/react";
import moment from "moment";

const PopUpCalender = (data) => {
  const [calenderDetails, setCalenderDetails] = useState(null);
  useEffect(() => {
    setCalenderDetails(data.data);
  }, [data]);
  // console.log(data.data, "data")

  const DateTime = (eventData) => {
    if (eventData) {
      const eventStart = moment(eventData?.start?.dateTime);
      const eventEnd = moment(eventData?.end?.dateTime);
      if (eventStart.isSame(eventEnd, "day")) {
        const formattedDate = eventStart.format("ddd DD/MM/YYYY");
        const formattedStartTime = eventStart.format("HH:mm");
        const formattedEndTime = eventEnd.format("HH:mm");

        return `${formattedDate}, ${formattedStartTime} to ${formattedEndTime}`;
      } else {
        const formattedStart = eventStart.format("ddd DD/MM/YYYY, HH:mm");
        const formattedEnd = eventEnd.format("ddd DD/MM/YYYY, HH:mm");

        return `${formattedStart} to ${formattedEnd}`;
      }
    }
    return "";
  };
  //  join meeting url
  const handleJoinMeeting = (url) => {
    if (url) window.location.href = url;
  };
  return (
    <div
      style={{
        // width: "25rem",
        // height: "25rem",
        divSizing: "border-div",
        backgroundColor: "white",
        padding: "0",
      }}
    >
      <div style={{ width: "100%" }}>
        {/* Event Information */}
        <div
          style={{
            // width: "calc(100% - 2rem)",
            // height: "5rem",
            padding: "1rem",
            paddingBottom: "1.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            // borderTop: "0.1rem solid #8a9aab",
            borderBottom: "0.1rem solid #4a5a5e",
          }}
        >
          <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            {calenderDetails?.subject}
          </div>

          {calenderDetails?.onlineMeeting?.joinUrl && (
            <div>
              <Button
                onClick={() =>
                  handleJoinMeeting(calenderDetails?.onlineMeeting?.joinUrl)
                }
                variant="contained"
                size="small"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center", // Ensures vertical alignment
                  gap: "5px", // Adds space between icon and text
                }}
              >
                <Icon
                  icon="tabler:video"
                  fontSize={18}
                  style={{ fontSize: 18, verticalAlign: "middle" }}
                />{" "}
                Join
              </Button>
            </div>
          )}
        </div>

        {/* Time and Location */}
        <div
          style={{
            width: "100%",
            padding: "0 1rem",
            // height: "calc(100% - 7rem)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              height: calenderDetails?.location?.displayName?.length
                ? "4rem"
                : "1.5rem",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "50%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // gap: "0.8rem",
              }}
            >
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon icon="subway:time-1" fontSize={18} />
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  width: "100%",
                  borderBottom: "0.1rem solid #dadada",
                }}
              >
                {DateTime(calenderDetails)}
                {/* Fri 04/10/2024 to Wed 01/01/2025, 10:30 */}
              </div>
            </div>
            {calenderDetails?.location?.displayName?.length > 0 && (
              <div
                style={{
                  width: "100%",
                  height: "50%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "0.8rem",
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon icon="mdi:location-on-outline" fontSize={30} />
                </div>
                <div
                  style={{
                    fontSize: "0.8rem",
                    width: "100%",
                    borderBottom: "0.1rem solid #dadada",
                  }}
                >
                  {calenderDetails?.location?.displayName}
                  {/* {} Microsoft Teams meeting} */}
                </div>
              </div>
            )}
          </div>

          {/* Inviter Information */}
          <div
            style={{
              width: "100%",
              // height: "3rem",
              marginTop: "0.8rem",
              display: "flex",
              flexDirection: "row",
              gap: "0.8rem",
            }}
          >
            <div>
              <Avatar
                alt={calenderDetails?.organizer?.emailAddress?.name}
                style={{ width: 24, height: 24 }}
              />
            </div>
            <div
              style={{
                fontSize: "0.8rem",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "3rem",
              }}
            >
              <div style={{ height: "1.5rem" }}>
                {calenderDetails?.organizer?.emailAddress?.name}
              </div>
              <div
                style={{
                  height: "1.5rem",
                  borderBottom: "0.1rem solid #dadada",
                }}
              >
                {calenderDetails?.attendees?.length > 1
                  ? `you and ${calenderDetails?.attendees?.length - 1} more`
                  : ""}
                {/* Accepted 3, didn't respond 2 */}
              </div>
            </div>
          </div>

          {/* Organizer Note */}
          <div
            style={{
              width: "100%",
              // height: "4rem",
              marginTop: "0.8rem",
              display: "flex",
              flexDirection: "row",
              gap: "0.8rem",
            }}
          >
            <div>
              <Icon icon="pepicons-pencil:menu" fontSize={20} />
            </div>
            <div
              style={{
                width: "100%",
                padding: "0 1rem 0 0",
                borderBottom: "0.1rem solid #dadada",
                marginBottom: "0.8rem",
              }}
            >
              <Typography
                style={{
                  fontSize: "0.8rem",
                  width: "100%",
                  // height: "6rem", // Adjust height based on your needs
                  overflow: "hidden",
                  display: "-webkit-div",
                  WebkitdivOrient: "vertical",
                  WebkitLineClamp: 8, // Change this number based on how many lines you want to show
                  textOverflow: "ellipsis",
                }}
              >
                <div>{calenderDetails?.bodyPreview}</div>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpCalender;
