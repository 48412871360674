// import React, { useState, useEffect } from "react";
// import { Table, Button, Spin } from "antd";
// import AiLoader from "../../../../shared/components/PL/tcov/AiLoader";
// // import 'antd/dist/antd.css'; // Import Ant Design styles
// import "./AIVoyage.scss";
// import { getAPICall, postAPICall } from "../../../../shared";
// import MapboxGLRoute from "./RouteMap";

// const AiVoyagePlanner = ({
//   cargoData,
//   getCargoFromAI,
//   liveVesselPosition,
//   marineData,
// }) => {
//   const [data, setData] = useState(cargoData);
//   const [loading, setLoading] = useState(true);
//   const [show, setShow] = useState(false);
//   const [vesselData, setVesselData] = useState({});
//   const [idx, setIdx] = useState(0);
//   const menuItems = [
//     "AI Optimized Route",
//     "AI Dynamic Rate Forecasting",
//     "AI Speed Optimization",
//     "AI Bunker Market Insights",
//     "AI Voyage Report Insights",
//     // "AI Cargo Matching",
//     // "AI CII Index Forecast",
//     // "AI Profit & Loss Analysis",
//     // "AI Actionable Inputs",
//     // "AI Vessel Performance Analysis (Noon Report)",
//   ];

//   // useEffect(() => {
//   //   setLoading(true);
//   //   let timeout;

//   //   if (cargoData && cargoData.length > 0) {
//   //     setData(cargoData);
//   //     setLoading(false);
//   //   } else {
//   //     timeout = setTimeout(() => {
//   //       setLoading(false);
//   //     }, 2000);
//   //   }

//   //   return () => clearTimeout(timeout);
//   // }, [cargoData]);

//   const fetchData = async () => {
//     try {
//       //   const { formData } = state;
//       const aiPayLoad = {
//         vessel_info: liveVesselPosition?.vessel_position,
//       };

//       const url = `${process.env.REACT_APP_URL}v1/recommendation_bp/route_prediction`;
//       // const url =
//       //   "http://192.168.1.5:5000/api/v1/recommendation_bp/route_prediction";

//       const response = await postAPICall(
//         url,
//         { ...aiPayLoad, key: menuItems },
//         "POST"
//       );

//       const data = await response?.route_prediction;
//       setVesselData(data);

//       //   responseDataRef.current = data;
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//     setLoading(true);
//     const timeout = setTimeout(() => {
//       setData(cargoData);
//       setLoading(false);
//     }, 5000); // 8 seconds timeout

//     return () => clearTimeout(timeout);
//   }, [cargoData]);

//   console.log("DEMO----->>>>>>>>>", vesselData);

//   const handleSelection = (key) => {
//     console.log("UUUUU---", idx, vesselData);
//     switch (key) {
//       case 0:
//         setVesselData(vesselData?.forecasting);
//         break;
//       case 1:
//         setVesselData(vesselData?.market_insights);
//         break;
//       case 2:
//         setVesselData(vesselData?.optimization);
//         break;
//       case 3:
//         setVesselData(vesselData?.report_insights);
//         break;
//       case 4:
//         setVesselData(vesselData?.route);
//         break;
//       default:
//         setVesselData("No data available for the selected key.");
//         break;
//     }
//   };

//   useEffect(() => {
//     setLoading(true);
//     handleSelection(idx);
//     setTimeout(() => {
//       setLoading(false);
//     }, 1000);
//   }, [idx]);

//   const handleImport = (record) => {
//     getCargoFromAI(record);
//     // console.log('Importing record:', record);
//     // let data = {
//     //     cargo_name:record?.cargo_name||"",
//     //     cp_qty:record?.cargo_size||"",
//     //     laycan_from:"",
//     //     laycan_to:""
//     // }
//     // return record;
//   };

//   //   console.log("first", show);

//   return (
//     <>
//       {loading ? (
//         <div
//           style={{
//             display: "flex",
//             marginTop: "20px",
//             height: "500px",
//             justifyContent: "center",
//           }}
//         >
//           <AiLoader
//             text={
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   textAlign: "center",
//                   height: "100%",
//                 }}
//               >
//                 <p style={{ fontSize: "27px" }}>
//                   {show ? "Comming Soon" : "Loading..."}
//                 </p>
//                 {!show && (
//                   <p style={{ fontSize: "20px" }}>AI Voyage Planner </p>
//                 )}
//               </div>
//             }
//           />
//         </div>
//       ) : (
//         <div>
//           <div className="menu-bar">
//             {menuItems.map((item, index) => (
//               <div
//                 key={index}
//                 className="menu-item"
//                 onClick={() => {
//                   setShow(true);
//                   setIdx(index);
//                 }}
//               >
//                 {item}
//               </div>
//             ))}
//           </div>

//           {/* {show && <MapboxGLRoute routeData={vesselData} />} */}
//           {show && (
//             <div>
//               <h2>{vesselData}</h2>
//               <p></p>
//             </div>
//           )}
//         </div>
//       )}
//     </>
//   );
// };

// export default AiVoyagePlanner;

import React, { useState, useEffect } from "react";
import AiLoader from "../../../../shared/components/PL/tcov/AiLoader";
import "./AIVoyage.scss";
import { postAPICall } from "../../../../shared";

const AiVoyagePlanner = ({
  cargoData,
  getCargoFromAI,
  liveVesselPosition,
  marineData,
}) => {
  const [loading, setLoading] = useState(true);
  const [vesselData, setVesselData] = useState({});
  const [displayData, setDisplayData] = useState("");
  const [idx, setIdx] = useState(0);
  const [show, setShow] = useState(false);

  const menuItems = [
    "AI Optimized Route",
    "AI Dynamic Rate Forecasting",
    "AI Speed Optimization",
    "AI Bunker Market Insights",
    "AI Voyage Report Insights",
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const aiPayLoad = {
        vessel_info:
          liveVesselPosition?.vessel_position !== undefined
            ? liveVesselPosition?.vessel_position
            : marineData,
      };

      const url = `${process.env.REACT_APP_URL}v1/recommendation_bp/route_prediction`;
      const response = await postAPICall(
        url,
        { ...aiPayLoad, key: menuItems },
        "POST"
      );
      const data = response?.route_prediction || {};
      setVesselData(data);
      setDisplayData(data?.forecasting);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelection = (key) => {
    if (!vesselData) return;
    switch (key) {
      case 0:
        setDisplayData(vesselData.route || "No forecasting data available.");
        break;
      case 1:
        setDisplayData(
          vesselData.forecasting || "No market insights data available."
        );
        break;
      case 2:
        setDisplayData(
          vesselData.optimization || "No optimization data available."
        );
        break;
      case 3:
        setDisplayData(
          vesselData.market_insights || "No report insights data available."
        );
        break;
      case 4:
        setDisplayData(
          vesselData.report_insights || "No route data available."
        );
        break;
      default:
        setDisplayData("Invalid selection.");
        break;
    }
  };

  useEffect(() => {
    fetchData();
  }, [cargoData]);

  useEffect(() => {
    handleSelection(idx);
    if (vesselData) {
      // setDisplayData(vesselData.forecasting);
      setShow(true);
    }
  }, [idx, vesselData]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            height: "500px",
            justifyContent: "center",
          }}
        >
          <AiLoader
            text={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  height: "100%",
                }}
              >
                <p style={{ fontSize: "27px" }}>
                  {show ? "AI Analizing" : "Loading..."}
                </p>
                {!show && <p style={{ fontSize: "20px" }}>AI Voyage Planner</p>}
              </div>
            }
          />
        </div>
      ) : (
        <div>
          <div className="menu-bar">
            {menuItems.map((item, index) => (
              <div
                key={index}
                className={`menu-item ${idx === index ? "ai-active" : ""}`}
                onClick={() => {
                  setShow(true);
                  setIdx(index);
                }}
              >
                {item}
              </div>
            ))}
          </div>

          {show && (
            <div className="mapbox-gl-container">
              <div className="info-box">
                <h2>{menuItems[idx]}</h2>
                <p>{displayData}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AiVoyagePlanner;
