import React, { useState, useEffect } from 'react';
import { Table, Button, Spin } from 'antd';
import AiLoader from '../../../../shared/components/PL/tcov/AiLoader';
// import 'antd/dist/antd.css'; // Import Ant Design styles

const AiCargoSuggestion = ({ cargoData,getCargoFromAI }) => {
  const [data, setData] = useState(cargoData);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setLoading(true);
  //   let timeout;

  //   if (cargoData && cargoData.length > 0) {
  //     setData(cargoData);
  //     setLoading(false);
  //   } else {
  //     timeout = setTimeout(() => {
  //       setLoading(false);
  //     }, 2000);
  //   }

  //   return () => clearTimeout(timeout);
  // }, [cargoData]);

    useEffect(() => {
      setLoading(true);
      const timeout = setTimeout(() => {
        setData(cargoData);
        setLoading(false);
      }, 5000); // 8 seconds timeout
  
      return () => clearTimeout(timeout); 
    }, [cargoData]);

  const columns = [
    {
      title: 'Cargo Name',
      dataIndex: 'cargo_name',
      key: 'cargo_name',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'Cargo Size',
      dataIndex: 'cargo_size',
      key: 'cargo_size',
      align: 'center',
    },
    {
      title: 'Cargo Type',
      dataIndex: 'cargo_type',
      key: 'cargo_type',
      align: 'center',
      render: (data) => <p style={{textTransform:"capitalize"}}>{data}</p>,
    },
    {
      title: 'Discharge Port',
      dataIndex: 'discharge_port',
      key: 'discharge_port',
      render: (port) => <p style={{textTransform:"capitalize"}}>{port.port} {port.country?`, ${port.country}`:"-"}</p>,
      align: 'center',
    },
    {
      title: 'Load Port',
      dataIndex: 'load_port',
      key: 'load_port',
      render: (port) => <p style={{textTransform:"capitalize"}}>{port.port}, {port.country}</p>,
      align: 'center',
    },
    {
      title: 'Laycan',
      dataIndex: 'laycan',
      key: 'laycan',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleImport(record)}>Import</Button>
      ),
      align: 'center',
    },
  ];

  const handleImport = (record) => {
      getCargoFromAI(record);
    // console.log('Importing record:', record);
    // let data = {
    //     cargo_name:record?.cargo_name||"",
    //     cp_qty:record?.cargo_size||"",
    //     laycan_from:"",
    //     laycan_to:""
    // }
    // return record; 
  };

  return (
    <>
    {loading ? (
      <div style={{ display:"flex" , marginTop: '20px', height:"500px" ,justifyContent:"center"}}>
         <AiLoader
          text={(
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              height: '100%',
            }}>
              <p style={{ fontSize: "27px" }}>Loading...</p>
              <p style={{ fontSize: "20px" }}>AI Suggested Cargos </p>
            </div>
          )}
        />
      </div>
    ) : (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="_id"
      style={{ textAlign: 'center' }}
      className="custom-table"
      loading={loading}
    />
  )}
  </>
  );
};

export default AiCargoSuggestion;
