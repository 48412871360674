import React, { useRef, useState, forwardRef, useEffect } from "react";
import { Menu, Dropdown, Modal, Spin } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import Email from "../../components/Email";
import moment from "moment";
import html2canvas from "html2canvas";
import { forEach } from "lodash";

const ComponentToPrint = forwardRef((props, ref) => {
  const [formReportdata, setFormReportdata] = useState(
    Object.assign({}, props.data || {})
  );

  return (
    <article className="article toolbaruiWrapper" ref={ref}>
      <div className="box box-default" id="divToPrint">
        <div className="box-body">
          <div className="invoice-inner-download mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <span className="title">
                  <img
                    className="reportlogo"
                    src={formReportdata.logo}
                    crossOrigin="anonymous"
                    alt="N/A"
                  />
                </span>
                <p className="sub-title m-0">{formReportdata.full_name}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="text-center invoice-top-address">
                <p>{formReportdata.address || "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="row p10">
            <div className="col-md-12">
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">Vessel Name :</td>
                    <td className="">{formReportdata.vessel_name || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Type :</td>
                    <td className="">{formReportdata.vessel_type || "N/A"}</td>

                    <td className="font-weight-bold">Vessel Sub Type:</td>
                    <td className="">
                      {formReportdata.vessel_sub_type || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Head Fix-TCI</td>
                    <td className="">{formReportdata.tc_code || "N/A"}</td>

                    <td className="font-weight-bold">My Company / LOB :</td>
                    <td className="">
                      {formReportdata.company_fixed_with_name || "N/A"}/
                      {formReportdata.company_lob_name || "N/A"}
                    </td>

                    <td className="font-weight-bold">Status :</td>
                    <td className="">
                      {formReportdata.tci_status_name || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Owner :</td>
                    <td className="">{formReportdata.owner_name || "N/A"}</td>
                    <td className="font-weight-bold">CP Date :</td>
                    <td className="">
                      {formReportdata.cp_date_fixed_by || "N/A"}
                    </td>
                    <td className="font-weight-bold">Payment Terms :</td>
                    <td className="">
                      {formReportdata.payment_term_name || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Beneficiary :</td>
                    <td className="">
                      {formReportdata.beneficiary_name1
                        ? formReportdata.beneficiary_name1
                        : "N/A"}
                    </td>

                    <td className="font-weight-bold">Fixed By User :</td>
                    <td className="">
                      {formReportdata.fixed_by_user
                        ? formReportdata.fixed_by_user
                        : "N/A"}
                    </td>
                    {/* <td className="font-weight-bold">Ops User :</td>
                    <td className="">
                      {formReportdata.ops_user_name
                        ? formReportdata.ops_user_name
                        : "N/A"}
                    </td> */}
                    <td className="font-weight-bold">Billing Period :</td>
                    <td className="">
                      {formReportdata.billing_period
                        ? formReportdata.billing_period
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Ownership type :</td>
                    <td className="">
                      {formReportdata.ownership_type || "N/A"}
                    </td>
                    <td className="font-weight-bold">Contract Type :</td>
                    <td className="">
                      {formReportdata.contract_type
                        ? formReportdata.contract_type
                        : "N/A"}
                    </td>
                    {/* <td className="font-weight-bold">Currency :</td>
                    <td className="">
                      {formReportdata.currency_name || "N/A"}
                    </td> */}

                    <td className="font-weight-bold">Ops User :</td>
                    <td className="">
                      {formReportdata.ops_user_name
                        ? formReportdata.ops_user_name
                        : "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laycan from :</td>
                    <td className="">{formReportdata.laycan_from || "N/A"}</td>
                    <td className="font-weight-bold">Voy. ops type :</td>
                    <td className="">
                      {formReportdata.Voy_ops_type_name
                        ? formReportdata.Voy_ops_type_name
                        : "N/A"}
                    </td>
                    <td className="font-weight-bold">Currency :</td>
                    <td className="">
                      {formReportdata.currency_name || "N/A"}
                    </td>
                  </tr>

                  <tr>
                    <td className="font-weight-bold">Laycan To :</td>
                    <td className="">{formReportdata.laycan_to || "N/A"}</td>
                    <td className="font-weight-bold">Trade Area :</td>
                    <td className="">
                      {formReportdata.trade_area_name
                        ? formReportdata.trade_area_name
                        : "N/A"}
                    </td>
                    <td className="font-weight-bold">Config Date :</td>
                    <td className="">{formReportdata.config_date || "N/A"}</td>
                  </tr>

                  <tr>
                    {/* <td className="font-weight-bold">Config Date :</td>
                    <td className="">{formReportdata.config_date || "N/A"}</td> */}

                    <td className="font-weight-bold"> </td>
                    <td className=""> </td>
                    <td className="font-weight-bold"> </td>
                    <td className=""> </td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">Delivery Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Delivery / Redelivery</th>
                    <th>Port</th>
                    <th>Estimated Date</th>
                    <th>Actual Date</th>
                    <th>Difference ( Days )</th>
                    <th>Min Days</th>
                    <th>Max Days</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.deliveryterm &&
                  formReportdata.deliveryterm &&
                  formReportdata.deliveryterm.length > 0
                    ? formReportdata.deliveryterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.d_t_name || "N/A"}</td>
                              <td>{e.port_name || "N/A"}</td>
                              <td>{e.est_gmt || "N/A"}</td>
                              <td>{e.actual_gmt || "N/A"}</td>
                              <td>{e.difference_days || "N/A"}</td>
                              <td>{e.min_days || "N/A"}</td>
                              <td>{e.max_days || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">Pricing</h4>
              <hr className="invoice-line" />
              <h4 className="font-weight-bold">Hire Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Hire Rate</th>
                    <th>Period</th>
                    <th>Rate Type</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Duration</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.hireterm &&
                  formReportdata.hireterm &&
                  formReportdata.hireterm.length > 0
                    ? formReportdata.hireterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.hire_rate || "N/A"}</td>
                              <td>{e.period_name || "N/A"}</td>
                              <td>{e.rate_type_name || "N/A"}</td>
                              <td>{e.from_date || "N/A"}</td>
                              <td>{e.to_date || "N/A"}</td>
                              <td>{e.duration || "N/A"}</td>
                              <td>{e.comment || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Commission Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Commission</th>
                    <th>Rate Type</th>
                    <th>Rate Figure</th>
                    <th>Payment Method</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Estimate Amount</th>
                    <th>Comment</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.commissionterm &&
                  formReportdata.commissionterm &&
                  formReportdata.commissionterm.length > 0
                    ? formReportdata.commissionterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e[".commission_name"] || "N/A"}</td>
                              <td>
                                {e.commission_rate_type
                                  ? e.commission_rate_type
                                  : "N/A"}
                              </td>
                              <td>{e.rate_figure || "N/A"}</td>
                              <td>{e.payment_method_name || "N/A"}</td>
                              <td>{e.from_gmt || "N/A"}</td>
                              <td>{e.to_gmt || "N/A"}</td>
                              <td>{e.estimate_amount || "N/A"}</td>
                              <td>{e.comment || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Other Terms</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Short Code</th>
                    <th>Description</th>
                    <th>Rate Type</th>
                    <th>Amount</th>
                    <th>Commission</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.otherterm &&
                  formReportdata.otherterm &&
                  formReportdata.otherterm.length > 0
                    ? formReportdata.otherterm.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.short_code || "N/A"}</td>
                              <td>{e.description_name || "N/A"}</td>
                              <td>{e.other_rate_type_name || "N/A"}</td>
                              <td>{e.amount || "N/A"}</td>
                              <td>{e["commission"] === 1 ? "Yes" : "No"}</td>
                              <td>{e.from_gmt || "N/A"}</td>
                              <td>{e.to_gmt || "N/A"}</td>
                              <td>{e.remarks || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">Bunkers</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Short Code</th>
                    <th>Description</th>
                    <th>IFO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>ULSFO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>VLSFO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>LSMGO Qty</th>
                    <th>CP Price ($/MT)</th>
                    <th>MGO Qty</th>
                    <th>CP Price ($/MT)</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.bunkers &&
                  formReportdata.bunkers &&
                  formReportdata.bunkers.length > 0
                    ? formReportdata.bunkers.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.short_code ? e.short_code : "0.00"}</td>
                              <td>
                                {e.description_name
                                  ? e.description_name
                                  : "0.00"}
                              </td>
                              <td>{e.f_IFO ? e.f_IFO : "0.00"}</td>
                              <td>{e.f_IFO_p ? e.f_IFO_p : "0.00"}</td>
                              <td>{e.f_ULSFO ? e.f_ULSFO : "0.00"}</td>
                              <td>{e.f_ULSFO_p ? e.f_ULSFO_p : "0.00"}</td>
                              <td>{e.f_VLSFO ? e.f_VLSFO : "0.00"}</td>
                              <td>{e.f_VLSFO_p ? e.f_VLSFO_p : "0.00"}</td>
                              <td>{e.f_LSMGO ? e.f_LSMGO : "0.00"}</td>
                              <td>{e.f_LSMGO_p ? e.f_LSMGO_p : "0.00"}</td>
                              <td>{e.f_MGO ? e.f_MGO : "0.00"}</td>
                              <td>{e.f_MGO_p ? e.f_MGO_p : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">
                TC Bunker Adjustment
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>BOD</th>
                    <th>BOD Value</th>
                    <th>BOR</th>
                    <th>BOR Value</th>
                    <th>NET Bunker</th>
                    <th>NET Bunker Value</th>
                    <th>% Allowed</th>
                    <th>Allocated Bunker Amount</th>
                    <th>Voyage No.</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.tcbunkeradjustment &&
                  formReportdata.tcbunkeradjustment &&
                  formReportdata.tcbunkeradjustment.length > 0
                    ? formReportdata.tcbunkeradjustment.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.bod ? e.bod : "0.00"}</td>
                              <td>{e.bod_value ? e.bod_value : "0.00"}</td>
                              <td>{e.bor ? e.bor : "0.00"}</td>
                              <td>{e.bor_value ? e.bor_value : "0.00"}</td>
                              <td>{e.net_bunker ? e.net_bunker : "0.00"}</td>
                              <td>
                                {e.net_bunker_value
                                  ? e.net_bunker_value
                                  : "0.00"}
                              </td>
                              <td>{e.per_allowed ? e.per_allowed : "0.00"}</td>
                              <td>
                                {e.all_bunker_amount
                                  ? e.all_bunker_amount
                                  : "0.00"}
                              </td>
                              <td>
                                {e.voyage_number ? e.voyage_number : "0.00"}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">
                OffHire Invoiced
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Voyage No</th>
                    <th>P&L Status </th>
                    <th>Activity</th>

                    <th>Reason </th>
                    <th>From Date Time</th>

                    <th>To Date Time</th>

                    <th>Hours </th>

                    <th>Days</th>

                    <th>Miles </th>

                    <th>Hire Rate</th>

                    <th>Contract Type</th>

                    <th>IFO QTY</th>
                    <th>IFO Value</th>

                    <th>VLSFO QTY</th>
                    <th>VLSFO Amount</th>

                    <th>ULSFO QTY</th>
                    <th>ULSFO Value</th>

                    <th>LSMGO QTY</th>
                    <th>LSMGO Value</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata?.offhiredelay &&
                  formReportdata?.offhiredelay?.length > 0
                    ? formReportdata?.offhiredelay.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.voyage_no || "N/A"}</td>
                              <td>{e.pnl_status == 1 ? "Yes" : "No"}</td>

                              <td>
                                {e.activity_name ? e.activity_name : "N/A"}
                              </td>
                              <td>{e.reason_name ? e.reason_name : "N/A"}</td>
                              <td>{e.delay_from ? e.delay_from : "0.00"}</td>
                              <td>{e.delay_to ? e.delay_to : "0.00"}</td>
                              <td>{e.hours ? e.hours : "0.00"}</td>
                              <td>{e.days ? e.days : "days"}</td>
                              <td>{e.miles ? e.miles : "0.00"}</td>
                              <td>{e.hire_rate ? e.hire_rate : "Hire rate"}</td>
                              <td>
                                {e.contract_type_name
                                  ? e.contract_type_name
                                  : "0.00"}
                              </td>
                              <td>{e.ifo_qty ? e.ifo_qty : "0.00"}</td>
                              <td>{e.ifo_value ? e.ifo_value : "0.00"}</td>
                              <td>{e.vlsfo_qty ? e.vlsfo_qty : "0.00"}</td>
                              <td>{e.vlsfo_amt ? e.vlsfo_amt : "0.00"}</td>
                              <td>{e.ulsfo_qty ? e.ulsfo_qty : "0.00"}</td>
                              <td>{e.ulsfo_value ? e.ulsfo_qty : "0.00"}</td>
                              <td>{e.lsmgo_qty ? e.lsmgo_qty : "0.00"}</td>
                              <td>{e.lsmgo_value ? e.lsmgo_value : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>MGO QTY</th>
                    <th>MGO Amount</th>
                    <th>Net Bunker Amount</th>
                    <th>Time Loss Value</th>
                    <th>Final Amount</th>
                    <th>Payment</th>
                  </tr>
                </thead>

                <tbody>
                  {formReportdata?.offhiredelay &&
                  formReportdata?.offhiredelay?.length > 0
                    ? formReportdata?.offhiredelay.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.mgo_qty ? e.mgo_qty : "0.00"}</td>
                              <td>{e.mgo_amt ? e.mgo_amt : "0.00"}</td>
                              <td>
                                {e.net_bunker_amt ? e.net_bunker_amt : "0.00"}
                              </td>
                              <td>
                                {e.time_loss_value ? e.time_loss_value : "0.00"}
                              </td>
                              <td>{e.final_amt ? e.final_amt : "0.00"}</td>
                              <td>{e.in_payment == 1 ? "Yes" : "No"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              {/* <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr>
                    <th>TCI %</th>
                    <th>VLSFO Qty</th>
                    <th>TCI Daily Cost</th>

                    <th>VLSFO$/MT</th>
                    <th>TCI L</th>
                    <th>IFO Qty</th>
                    <th>TCI Lumpsum</th>
                    <th>IFO$/MT </th>
                    <th>TCI Stmt </th>
                    <th>LSMGO Qty </th>
                    <th>Remarks </th>
                    <th>LSMGO$/MT </th>
                    <th>Last Updated </th>
                    <th>TCI Final Hire Amount</th>
                    <th>Last Updated By</th>
                    <th>TCI Bunker Value</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.offhiredelay &&
                  formReportdata.offhiredelay.length > 0
                    ? formReportdata.offhiredelay.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.activity || "N/A"}</td>
                              <td>{e.reason ? e.reason : "N/A"}</td>
                              <td>{e.delay_from ? e.delay_from : "N/A"}</td>
                              <td>{e.delay_to ? e.delay_to : "N/A"}</td>
                              <td>{e.hours ? e.hours : "N/A"}</td>
                              <td>{e.ded_adj_tci ? e.ded_adj_tci : "N/A"}</td>
                              <td>{e.miles ? e.miles : "N/A"}</td>
                              <td>{e.hfo_qty ? e.hfo_qty : "N/A"}</td>
                              <td>{e.hfo_rob ? e.hfo_rob : "N/A"}</td>
                              <td>{e.mdo_qty ? e.mdo_qty : "N/A"}</td>
                              <td>{e.mdo_rob ? e.mdo_rob : "N/A"}</td>
                              <td>{e.mgo_qty ? e.mgo_qty : "N/A"}</td>
                              <td>{e.mgo_rob ? e.mgo_rob : "N/A"}</td>
                              <td>{e.tci_per ? e.tci_per : "N/A"}</td>
                              <td>
                                {e.tci_daily_cost ? e.tci_daily_cost : "N/A"}
                              </td>
                              <td>{e.tci_l ? e.tci_l : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table> */}

              <h4 className="font-weight-bold tc-sub-header">
                Vessel OffHire Delay
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Voyage No</th>
                    <th>P&L Status</th>
                    <th>Offhire Activity Type</th>
                    <th>Reason</th>

                    <th>From Date Time</th>
                    <th>To Date Time</th>
                    <th>Hours</th>
                    <th>Days</th>
                    <th>Miles</th>
                    <th>Hire Rate</th>
                    <th>Contract Type</th>
                    <th>IFO QTY</th>
                    <th>IFO Value</th>
                    <th>VLSFO QTY</th>
                    <th>VLSFO Value</th>
                    <th>ULSFO QTY</th>
                    <th>ULSFO Value</th>
                    <th>LSMGO QTY</th>
                    <th>LSMGO Value</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.vesseloffhiredelay &&
                  formReportdata.vesseloffhiredelay.length > 0
                    ? formReportdata.vesseloffhiredelay.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.voyage_numer || "N/A"}</td>
                              <td>{e.pnl_status == 1 ? "Yes" : "No"}</td>
                              <td>{e.activity_name || "N/A"}</td>
                              <td>{e.reason_name ? e.reason_name : "N/A"}</td>

                              <td>{e.delay_from ? e.delay_from : "0.00"}</td>
                              <td>{e.delay_to ? e.delay_to : "0.00"}</td>
                              <td>{e.hours ? e.hours : "0.00"}</td>
                              <td>{e.days ? e.days : "0.00"}</td>
                              <td>{e.miles ? e.miles : "0.00"}</td>
                              <td>{e.hire_rate ? e.hire_rate : "0.00"}</td>
                              <td>
                                {e.contract_type_name
                                  ? e.contract_type_name
                                  : "N/A"}
                              </td>
                              <td>{e.ifo_qty ? e.ifo_qty : "0.00"}</td>
                              <td>{e.ifo_value ? e.ifo_value : "0.00"}</td>
                              <td>{e.vlsfo_qty ? e.vlsfo_qty : "0.00"}</td>
                              <td>{e.vlsfo_amt ? e.vlsfo_amt : "0.00"}</td>
                              <td>{e.ulsfo_qty ? e.ulsfo_qty : "0.00"}</td>
                              <td>{e.ulsfo_amt ? e.ulsfo_amt : "0.00"}</td>
                              <td>{e.lsmgo_qty ? e.lsmgo_qty : "0.00"}</td>
                              <td>{e.lsmgo_value ? e.lsmgo_value : "0.00"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>MGO QTY</th>
                    <th>MGO Value</th>
                    <th>Net Bunker Amount</th>
                    <th>Hire Loss Value</th>
                    <th>Final Amount</th>
                    <th>Remark</th>
                  </tr>
                </thead>

                <tbody>
                  {formReportdata?.vesseloffhiredelay &&
                  formReportdata?.vesseloffhiredelay?.length > 0
                    ? formReportdata?.vesseloffhiredelay.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.mgo_qty ? e.mgo_qty : "0.00"}</td>
                              <td>{e.mgo_amt ? e.mgo_amt : "0.00"}</td>
                              <td>
                                {e.net_bunker_amt ? e.net_bunker_amt : "0.00"}
                              </td>
                              <td>
                                {e.time_loss_value ? e.time_loss_value : "0.00"}
                              </td>
                              <td>{e.final_amt ? e.final_amt : "0.00"}</td>
                              <td>{e.description ? e.description : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">Voyage History</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>VoyageNo</th>
                    <th>Voyage Type</th>
                    <th>Code</th>
                    <th>Voyage Result</th>
                    <th>Gross Income</th>
                    <th>Gross Expenses</th>
                    <th>Voyage Days</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.voyagehistory &&
                  formReportdata.voyagehistory.length > 0
                    ? formReportdata.voyagehistory.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.voyage_no || "N/A"}</td>
                              <td>{e.voyage_type || "N/A"}</td>
                              <td>{e.code || "N/A"}</td>
                              <td>{e.voyage_result || "N/A"}</td>
                              <td>{e.gross_income ? e.gross_income : "N/A"}</td>
                              <td>
                                {e.gross_expenses ? e.gross_expenses : "N/A"}
                              </td>
                              <td>{e.voyage_days || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold tc-sub-header">
                Performing Speed
              </h4>
              <hr className="invoice-line" />
              <h4 className="font-weight-bold">Port Consp. Table (Per Day)</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Fuel Type</th>
                    <th>Grade</th>
                    <th>Unit</th>
                    <th>Loading</th>
                    <th>Disch</th>
                    <th>Ideal On</th>
                    <th>Capacity</th>
                    <th>Margin</th>
                    <th>Heat</th>
                    <th>Heat+</th>
                    <th>Heat+2</th>
                    <th>IGS</th>
                    <th>Clean</th>
                    <th>Menu UV</th>
                    <th>AV</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["portconsp.tableperday"] &&
                  formReportdata["portconsp.tableperday"].length > 0
                    ? formReportdata["portconsp.tableperday"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>
                                {e.con_type_name ? e.con_type_name : "N/A"}
                              </td>
                              <td>{e.con_g_name ? e.con_g_name : "N/A"}</td>
                              <td>
                                {e.con_unit_name ? e.con_unit_name : "N/A"}
                              </td>
                              <td>{e.con_loading ? e.con_loading : "N/A"}</td>
                              <td>{e.con_disch ? e.con_disch : "N/A"}</td>
                              <td>{e.con_ideal_on ? e.con_ideal_on : "N/A"}</td>
                              <td>{e.con_capacity ? e.con_capacity : "N/A"}</td>
                              <td>{e.con_margin ? e.con_margin : "N/A"}</td>
                              <td>{e.con_heat ? e.con_heat : "N/A"}</td>
                              <td>{e.con_heat_p ? e.con_heat_p : "N/A"}</td>
                              <td>{e.con_heat_pp ? e.con_heat_pp : "N/A"}</td>
                              <td>{e.con_igs ? e.con_igs : "N/A"}</td>
                              <td>{e.con_clean ? e.con_clean : "N/A"}</td>
                              <td>{e.con_maneuv ? e.con_maneuv : "N/A"}</td>
                              <td>{e.con_av ? e.con_av : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">
                Sea Spd Consp. Table (Per Day)
              </h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Speed Type</th>
                    <th>Speed (Kt)</th>
                    <th>Passage Type</th>
                    <th>Engine Load (%)</th>
                    <th>IFO Consp.</th>
                    <th>VLSFO Consp.</th>
                    <th>MGO Consp.</th>
                    <th>LSMGO Consp.</th>
                    <th>ULSFO Consp.</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["seaspdconsp.tableperday"] &&
                  formReportdata["seaspdconsp.tableperday"].length > 0
                    ? formReportdata["seaspdconsp.tableperday"].map(
                        (e, idx) => {
                          return (
                            <>
                              <tr key={idx}>
                                <td>
                                  {e.spd_type_name ? e.spd_type_name : "N/A"}
                                </td>
                                <td>{e.speed}</td>
                                <td>{e.passagename ? e.passagename : "N/A"}</td>
                                <td>{e.engine_load ? e.engine_load : "N/A"}</td>
                                <td>{e.ifo ? e.ifo : "0.00"}</td>
                                <td>{e.vlsfo ? e.vlsfo : "0.00"}</td>
                                <td>{e.mgo ? e.mgo : "0.00"}</td>
                                <td>{e.lsmgo ? e.lsmgo : "0.00"}</td>
                                <td>{e.ulsfo ? e.ulsfo : "0.00"}</td>
                              </tr>
                            </>
                          );
                        }
                      )
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Notices Alerts</h4>
              <table className="table custom-table-bordered tc-table">
                <tbody>
                  <tr>
                    <td className="border-0">
                      <table className="table custom-table-bordered tc-table">
                        <tbody>
                          <tr className="HeaderBoxText">
                            <td className="font-weight-bold">
                              {" "}
                              Delivery Date :
                            </td>
                            <td>
                              {formReportdata["."] &&
                              formReportdata["."][0] &&
                              formReportdata["."][0]["delivery_date"]
                                ? formReportdata["."][0]["delivery_date"]
                                : "N/A"}
                            </td>

                            <td className="font-weight-bold">Notification :</td>

                            <td>
                              {formReportdata["."] &&
                              formReportdata["."][0] &&
                              formReportdata["."][0]["d_notification"] === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table custom-table-bordered tc-table">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Email Body</th>
                            <th>Notice Type</th>
                            <th>Delivery Date</th>
                            <th>Expected Days</th>
                            <th>Notice Sent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata[".."] &&
                          formReportdata[".."].length > 0
                            ? formReportdata[".."].map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>
                                        {e.email_body ? e.email_body : "N/A"}
                                      </td>
                                      <td>
                                        {e.notice_type ? e.notice_type : "N/A"}
                                      </td>
                                      <td>
                                        {e.project_delivary !== "N/A"
                                          ? moment(e.project_delivary).format(
                                              "YYYY-MM-DD"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {isNaN(e.email_content)
                                          ? "N/A"
                                          : e.email_content}
                                      </td>
                                      <td>
                                        {e["notice_sent"] === 1 ? "Yes" : "No"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>
                    </td>

                    <td className="border-0">
                      <table className="table custom-table-bordered tc-table">
                        <tbody>
                          <tr className="HeaderBoxText">
                            <td className="font-weight-bold">
                              Redelivery Date :
                            </td>
                            <td>
                              {formReportdata["-"][0]["redelivery_date"]
                                ? formReportdata["-"][0]["redelivery_date"]
                                : "N/A"}
                            </td>

                            <td className="font-weight-bold">Notification :</td>
                            <td>
                              {formReportdata["-"][0]["r_notification"] === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table custom-table-bordered tc-table">
                        <thead>
                          <tr className="HeaderBoxText">
                            <th>Email Body</th>
                            <th>Notice Type</th>
                            <th>Redelivery Date</th>
                            <th>Expected Days</th>
                            <th>Notice Sent</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formReportdata["..."] &&
                          formReportdata["..."].length > 0
                            ? formReportdata["..."].map((e, idx) => {
                                return (
                                  <>
                                    <tr key={idx}>
                                      <td>
                                        {e.email_body ? e.email_body : "N/A"}
                                      </td>
                                      <td>
                                        {e.notice_type ? e.notice_type : "N/A"}
                                      </td>
                                      <td>
                                        {e.project_delivary !== "N/A"
                                          ? moment(e.project_delivary).format(
                                              "YYYY-MM-DD"
                                            )
                                          : "N/A"}
                                      </td>
                                      <td>
                                        {isNaN(e.email_content)
                                          ? "N/A"
                                          : e.email_content}
                                      </td>
                                      <td>
                                        {e["notice_sent"] === 1 ? "Yes" : "No"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : undefined}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h4 className="font-weight-bold">Bareboat</h4>
              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Beneficiary</th>
                    <th>Code</th>
                    <th>Date</th>
                    <th>Contract Start</th>
                    <th>Contract End</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata.bareboat && formReportdata.bareboat.length > 0
                    ? formReportdata.bareboat.map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>
                                {e.beneficiary_name
                                  ? e.beneficiary_name
                                  : "N/A"}
                              </td>

                              <td>{e.code}</td>
                              <td>{e["date"] || "N/A"}</td>
                              <td>{e["contract_start"] || "N/A"}</td>
                              <td>{e["contract_end"] || "N/A"}</td>
                              <td>{e.remarks || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Purchase</h4>

              <table className="table custom-table-bordered tc-table">
                <tbody>
                  {formReportdata["...."] && formReportdata["...."].length > 0
                    ? formReportdata["...."].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td className="font-weight-bold">
                                Counterparty :
                              </td>
                              <td className="text-right">
                                {e.counterparty_name
                                  ? e.counterparty_name
                                  : "N/A"}
                              </td>

                              <td className="font-weight-bold">
                                Purchase Price/Curr :
                              </td>
                              <td className="text-right">
                                {e.purchase_price || "N/A"} /
                                {e.p_currency_name ? e.p_currency_name : "N/A"}
                              </td>

                              <td className="font-weight-bold">Notes :</td>
                              <td className="text-right">{e.notes || "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Payment Terms</th>
                    <th>Status</th>
                    <th>Payment Date</th>
                    <th>Amount</th>
                    <th>Amount Base</th>
                    <th>Exchange Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["....."] && formReportdata["....."].length > 0
                    ? formReportdata["....."].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>
                                {e.payment_terms_name
                                  ? e.payment_terms_name
                                  : "N/A"}
                              </td>
                              <td>{e.status_name ? e.status_name : "N/A"}</td>
                              <td>{e.payment_date ? e.payment_date : "N/A"}</td>
                              <td>{e.amount || "N/A"}</td>
                              <td>{e.amount_base || "N/A"}</td>
                              <td>{e.exch_rate ? e.exch_rate : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Broker</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Payment Date</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["......"] &&
                  formReportdata["......"].length > 0
                    ? formReportdata["......"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.brocker_name ? e.brocker_name : "N/A"}</td>
                              <td>{e.amount ? e.amount : "N/A"}</td>
                              <td>{e.type_name ? e.type_name : "N/A"}</td>
                              <td>{e.payment_date ? e.payment_date : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <h4 className="font-weight-bold">Sales</h4>

              <table className="table custom-table-bordered tc-table">
                <tbody>
                  {formReportdata["......."] &&
                  formReportdata["......."].length > 0
                    ? formReportdata["......."].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td className="font-weight-bold">
                                Counterparty :
                              </td>
                              <td className="text-right">
                                {e.counterparty_name1
                                  ? e.counterparty_name1
                                  : "N/A"}
                              </td>

                              <td className="font-weight-bold">
                                Purchase Price/Curr :
                              </td>
                              <td className="text-right">
                                {e.purchase_price || "N/A"} /
                                {e.currency_name ? e.currency_name : "N/A"}
                              </td>
                              <td className="font-weight-bold">Notes :</td>
                              <td className="text-right">{e.notes}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Payment Terms</th>
                    <th>Status</th>
                    <th>Payment Date</th>
                    <th>Amount</th>
                    <th>Amount Base</th>
                    <th>Exchange Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["........"] &&
                  formReportdata["........"].length > 0
                    ? formReportdata["........"].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>
                                {e.payment_terms_name
                                  ? e.payment_terms_name
                                  : "N/A"}
                              </td>
                              <td>{e.status_name ? e.status_name : "N/A"}</td>
                              <td>{e.payment_date ? e.payment_date : "N/A"}</td>
                              <td>{e.amount ? e.amount : "N/A"}</td>
                              <td>{e.amount_base ? e.amount_base : "N/A"}</td>
                              <td>{e.exch_rate ? e.exch_rate : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>

              <table className="table custom-table-bordered tc-table">
                <thead>
                  <tr className="HeaderBoxText">
                    <th>Broker</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Payment Date</th>
                  </tr>
                </thead>
                <tbody>
                  {formReportdata["........."] &&
                  formReportdata["........."].length > 0
                    ? formReportdata["........."].map((e, idx) => {
                        return (
                          <>
                            <tr key={idx}>
                              <td>{e.brocker_name ? e.brocker_name : "N/A"}</td>
                              <td>{e.amount ? e.amount : "N/A"}</td>
                              <td>{e.type_name ? e.type_name : "N/A"}</td>
                              <td>{e.payment_date ? e.payment_date : "N/A"}</td>
                            </tr>
                          </>
                        );
                      })
                    : undefined}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
});

const TciReports = (props) => {
  const [pdfData, setPdfData] = useState();
  const [userInput, setUserInput] = useState();
  const [emailModal, setEmailModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mailTitlePayload, setMailTitlePayload] = useState({});

  const [state, setState] = useState({
    name: "Printer",
  });

  const componentRef = useRef();

  const printReceipt = () => {
    window.print();
  };

  useEffect(() => {
    setUserInput(props.data);
  }, []);

  const sendEmailReportModal = async () => {
    try {
      setLoading(true);

      const quotes = document.getElementById("divToPrint");

      const canvas = await html2canvas(quotes, {
        logging: true,
        letterRendering: 1,
        useCORS: true,
        allowTaint: true,
        scale: 2,
      });

      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF("p", "mm");
      let position = 25;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      doc.addImage(pageData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          pageData,
          "PNG",
          5,
          position + 25,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      // Create Blob
      const blob = doc.output("blob");

      // Use the blob as needed (e.g., send it to the server, create a download link, etc.)
      setLoading(false);
      setPdfData(blob);
      setEmailModal(true);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // this.setState({ loading: false });
      // Handle errors here
    }
  };

  const printDocument = () => {
    var quotes = document.getElementById("divToPrint");

    html2canvas(quotes, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "html-to-img.png";
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 30;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 5, position, imgWidth - 8, imgHeight - 7);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          5,
          position + 30,
          imgWidth - 8,
          imgHeight - 7
        );
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      doc.save("TciReport.pdf");
    });
  };

  return (
    <div className="body-wrapper modalWrapper">
      <article className="article toolbaruiWrapper">
        <div className="box box-default">
          <div className="box-body">
            <div className="toolbar-ui-wrapper">
              <div className="leftsection"></div>
              <div className="rightsection">
                <span className="wrap-bar-menu">
                  <ul className="wrap-bar-ul">
                    <li
                      onClick={sendEmailReportModal}
                      style={{ cursor: loading ? "not-allowed" : "pointer" }}
                    >
                      Send Email
                    </li>
                    <li onClick={printDocument}>Download</li>
                    <li>
                      <ReactToPrint
                        trigger={() => (
                          <span className="text-bt">
                            <PrinterOutlined /> Print
                          </span>
                        )}
                        content={() => componentRef.current}
                      />
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>

      <article className="article">
        <div className="box box-default">
          <div className="box-body">
            <ComponentToPrint ref={componentRef} data={props.data} />
          </div>
        </div>
      </article>

      {emailModal && (
        <Modal
          title="New Message"
          visible={emailModal}
          onOk={() => {
            setEmailModal(false);
          }}
          onCancel={() => {
            setEmailModal(false);
          }}
          footer={null}
        >
          {pdfData && (
            <Email
              handleClose={() => {
                setEmailModal(false);
              }}
              attachmentFile={pdfData}
              title={window.corrector(
                `TC-In_List Report||${userInput.vessel_name}||${userInput.full_name}||${userInput.company_lob_name}||${userInput.tci_status_name}`
              )}

              // title={`TC-In_List Report|| ${userInput.vessel_name}(${userInput.full_name})||${userInput.company_lob_name}||${userInput.tci_status_name}`}
            />
          )}
        </Modal>
      )}
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default TciReports;
