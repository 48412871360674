import React from 'react'
import PopToolTip from '../../services/Tooltip'
import { Popover } from 'antd'

const TableHeaderToolTip = ({ currentRow }) => {
    if (currentRow.f_tool_tip && (currentRow.group_name !== "Port Itinerary"  && currentRow.group_name !== "Bunker Details" && currentRow.group_name !== "Port Date Details" && currentRow.group_name !== "CII Dynamics" && currentRow.group_name !== "EU ETS" &&  currentRow.group_name !== "Port Itinerary"  && currentRow.group_name !== "Bunker Details" && currentRow.group_name !== "Port Date Details" && currentRow.group_name !== "CII Dynamics" && currentRow.group_name !== "EU ETS" && currentRow.group_name!=="Delivery Term" && currentRow.group_name!=="Hire Term" && currentRow.group_name!== "Commission Term" && currentRow.group_name!== "Other Term" && currentRow.group_name!=="Bunkers" && currentRow.group_name!=="TC Bunker Adjustment" && currentRow.group_name!=="OffHire Delay" && currentRow.group_name!=="Vessel OffHire Delay" && currentRow.group_name!=="Voyage History" && currentRow.group_name!== "Port Consp. Table (Per Day)" && currentRow.group_name!=="Sea Spd Consp. Table (Per Day)" && currentRow.group_name!=="OffHire Delays" && currentRow.group_name!== ".." && currentRow.group_name!=="...")) {
        return currentRow.name
    }
    if (currentRow.group_name == "." || currentRow.group_name == "Cargos") {
            return currentRow.name
         }
    return (
        <Popover
            placement="right"
            destroyTooltipOnHide={true}
            content={<PopToolTip text={currentRow.f_tool_tip} title={currentRow.name} />}
            overlayClassName="custom-tooltip"
        >
            {currentRow.name}
        </Popover>
    )
}

export default TableHeaderToolTip