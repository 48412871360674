import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

// CSS file for mapbox-gl styles
import "mapbox-gl/dist/mapbox-gl.css";
import "../../../routes/vessel-activate-list/noonReport.css";
import { Modal } from "antd";
import MarineModal from "../../dynamic-vspm/MarineModal";
const MAPBOXTOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const VesselMap = ({ center, vesselData }) => {
  const { live_position, vessel_particulars } = vesselData;
  const [isModal, setIsModal] = useState(false);

  // console.log({vesselData})

  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);

  const [coordinates, setCoordinates] = useState({
    longitude: Number(live_position.longitude),
    latitude: Number(live_position.latitude),
  });

  // useEffect(() => {
  //   if (map.current) return; // initialize map only once

  //     // Set Mapbox access token
  //      mapboxgl.accessToken = MAPBOXTOKEN;

  //   map.current = new mapboxgl.Map({
  //     container: mapContainer.current,
  //     style: "mapbox://styles/mapbox/streets-v11",
  //     // center: [coordinates.longitude, coordinates.latitude],
  //     zoom: 1.65,
  //     projection: "mercator",
  //     preserveDrawingBuffer: true,
  //     // minZoom: 1.7,
  //   });
   
  //   const markerElement = document.createElement("div");
  //   markerElement.onclick = () => moreDetail();
  //   markerElement.innerHTML = `
  //           <div class="live-vessel-box">
  //               <div class="live-info-box">
  //                   <span>Vessel : ${vessel_particulars.shipname}</span>
  //                   <span>IMO : ${vessel_particulars.imo}</span>
  //                   <span>Lat : ${coordinates.latitude}</span>
  //                   <span>Long. : ${coordinates.longitude}</span>
  //               </div>
  //               <img src="/ship.svg" width="30" alt="●" />
  //           </div>`;
  //   markerElement.style.width = "30px";
  //   markerElement.style.height = "30px";
  //   markerElement.style.backgroundSize = "contain";
  //   markerElement.style.backgroundRepeat = "no-repeat";
   
  //   marker.current = new mapboxgl.Marker({ element: markerElement })
  //     .setLngLat([coordinates?.longitude, coordinates?.latitude])
  //     .addTo(map.current);

  //   map.current.flyTo({
  //     center: [coordinates.longitude, coordinates.latitude],
  //     zoom: 1,
  //     essential: true,
  //   });

  //   return () => map.current.remove();
  // }, []);
  
  

  useEffect(() => {
    if (!map.current) {
      // Initialize map only once
      mapboxgl.accessToken = MAPBOXTOKEN;
  
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        zoom: 1.65,
        projection: "mercator",
        preserveDrawingBuffer: true,
      });
    }
  
    // Update coordinates and marker
    if (marker.current) {
      marker.current.remove(); // Remove the old marker
    }
  
    const markerElement = document.createElement("div");
    markerElement.onclick = () => moreDetail();
    markerElement.innerHTML = `
            <div class="live-vessel-box">
                <div class="live-info-box">
                    <span>Vessel : ${vessel_particulars.shipname}</span>
                    <span>IMO : ${vessel_particulars.imo}</span>
                    <span>Lat : ${live_position.latitude}</span>
                    <span>Long. : ${live_position.longitude}</span>
                </div>
                <img src="/ship.svg" width="30" alt="●" />
            </div>`;
    markerElement.style.width = "30px";
    markerElement.style.height = "30px";
    markerElement.style.backgroundSize = "contain";
    markerElement.style.backgroundRepeat = "no-repeat";
  
    marker.current = new mapboxgl.Marker({ element: markerElement })
      .setLngLat([Number(live_position.longitude), Number(live_position.latitude)])
      .addTo(map.current);
  
    // Fly to the new coordinates
    map.current.flyTo({
      center: [Number(live_position.longitude), Number(live_position.latitude)],
      zoom: 1.65,
      essential: true,
    });
  }, [vesselData]);
  

  const moreDetail = () => {
    setIsModal(true);
  };

  return (
    <div>
      <div ref={mapContainer} style={{ width: "100%", height: "400px" }} />
      {isModal && (
        <Modal
          open={isModal}
          onCancel={() => setIsModal(false)}
          footer={false}
          title="Live Vessel Information"
        >
          <div className="data-row more-Info-Modal">
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>Vessel Name</span>
                  </td>
                  <td>
                    <span>{vessel_particulars.shipname}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>IMO : </span>
                  </td>
                  <td>
                    <span>{vessel_particulars.imo}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Longitude : </span>
                  </td>
                  <td>
                    <span>{live_position.longitude}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Latitude : </span>
                  </td>
                  <td>
                    <span>{live_position.latitude}</span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span>Last Port</span>
                  </td>
                  <td>
                    <span>{live_position.lastPort}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>NextPort : </span>
                  </td>
                  <td>
                    <span>{live_position.nextPort}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Distance Travelled : </span>
                  </td>
                  <td>
                    <span>{live_position.distance_travelled}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Distance To Go : </span>
                  </td>
                  <td>
                    <span>{live_position.distance_to_go}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default VesselMap;
