import React, { useEffect, useState } from "react";
import { Form, Button, Select, Spin, Layout, Drawer, Modal, Alert } from "antd";
import NormalFormIndex from "../../../../../shared/NormalForm/normal_from_index";
import {
  DeleteOutlined,
  MenuFoldOutlined,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import URL_WITH_VERSION, {
  apiDeleteCall,
  getAPICall,
  objectToQueryStringFunc,
  openNotificationWithIcon,
  postAPICall,
} from "../../../../../shared";
import redux_state from "../../../../../services/redux_state";

const FormItem = Form.Item;
const Option = Select.Option;
const { Content } = Layout;
const GenerateCargoEnquiry = (props) => {
  const [state, setState] = useState({
    refreshForm: false,
    frmName: "tcov_full_cargo_enq_form",
    formData: props.formData || {},
    frmVisible: true,
    id: props.id,
  });

  // useEffect(() => {
  //   if (props.id) {
  //     onEditClick(props.id);
  //   }
  // }, []);

  const saveFormData = async (vData, innercb) => {
    let formData1 = redux_state.reduxStore?.["tcov_full_estimate_form"];
    const { estimate_id } = formData1;
    const vDatawithId = { ...vData, tcov_id: estimate_id };

    let type = "save";
    let suMethod = "POST";
    if (vData.hasOwnProperty("id")) {
      type = "update";
      suMethod = "PUT";
    }
    const { frmName } = state;
    setState((prevState) => ({ ...prevState, frmVisible: false }));
    let suURL = `${URL_WITH_VERSION}/voyagecargo/cargo-enquiry-${type}?frm=${frmName}`;
    await postAPICall(suURL, vDatawithId, suMethod, (data) => {
      if (data && data.data) {
        openNotificationWithIcon("success", data.message);
        // if (type === "save") {
        //   window.emitNotification({
        //     n_type: "cargo enquiry Added",
        //     msg: window.notificationMessageCorrector(`cargo enquiry is added`),
        //   });
        // } else {
        //   window.emitNotification({
        //     n_type: "Off hire Updated",
        //     msg: window.notificationMessageCorrector(`Off Hire is updated, for Voyage`),
        //   });
        // }
        setState(
          (prevState) => ({
            ...prevState,
            formData: {
              id: data.row.id,
            },
            id: data.row.id,
          }),
          () => {
            onEditClick(data.data.id);
          }
        );
      } else {
        openNotificationWithIcon("error", data.message);

        setState((prevState) => ({
          ...prevState,
          frmVisible: true,
        }));
      }
    });
  };

  const onEditClick = async (id) => {
    const { formData } = state;
    let _id = formData["id"] || id;
    // if (
    //   _id
    // ) {
    //   let qParams = { e: _id };
    //   let qParamString = objectToQueryStringFunc(qParams);

    //   const response = await getAPICall(
    //     `${URL_WITH_VERSION}/voyagecargo/tonnage-enquiry-list?ae=${qParamString}`
    //   );
    //   const respData = await response["data"];
    //   setState((prevState) => ({
    //     ...prevState,
    //     formData: respData,
    //     frmVisible: true,
    //   }));
    // }

    const response = await getAPICall(
      `${URL_WITH_VERSION}/voyagecargo/cargo-enquiry-edit?ae=${id}`
    );
    let respData = response.data;

    setState((prevState) => ({
      ...prevState,
      formData: respData,
      // frmVisible: true,
    }));

    // else {
    //   openNotificationWithIcon("error", "Something went wrong", 4);
    //   setState((prevState) => ({ ...prevState, frmVisible: true }));
    // }
  };

  const deleteData = (id, tcov_id, innercb) => {
    let URL = `${URL_WITH_VERSION}/voyagecargo/cargo-enquiry-delete`;
    if (id) {
      apiDeleteCall(URL, { id: id }, (resp) => {
        // if (resp && resp.data) {
        //   openNotificationWithIcon("success", resp.message);
        //   window.emitNotification({
        //     n_type: "Off hire Deleted",
        //     msg: window.notificationMessageCorrector(
        //       `Off Hire is deleted, for Voyage(${data.voyage}), vessel(${data.vessel_code}), by ${window.userName}`
        //     ),
        //   });
        //   if (props.hasOwnProperty("modalCloseEvent")) {
        //     props.modalCloseEvent();
        //   }
        // } else {
        //   openNotificationWithIcon("error", resp.message);
        // }
      });
    } else {
      openNotificationWithIcon("info", "Please fill the form first.", 3);
    }
  };

  const updateCargoName = async () => {
    // Initialize an object to store cargo names

    // Array containing selectors for the elements to observe
    const var_drop = [
      'div[name="cargo_name"]',
      'div[name="freight_type"]',
      'div[name="load_term"]',
      'div[name="discharge_load_term"]',
    ];
    let arr = [];
    // Loop through the selectors
    for (let i = 0; i < var_drop.length; i++) {
      const selector = var_drop[i];

      // Select the element using the current selector
      const cargoDiv = document.querySelector(selector);

      // Check if the cargoDiv exists and has child elements
      if (cargoDiv && cargoDiv.children.length > 0) {
        let dropdownName = "";
        // Iterate through the child elements
        Array.from(cargoDiv.children).forEach(function (child) {
          // Check if the child element has text content
          if (child.textContent.trim() !== "") {
            dropdownName = child.textContent.trim();

            arr.push(dropdownName);
          }
        });
        // Store the cargo name in var_drop_name based on the index
      }
    }

    let obj = {
      cargoName: arr[0],
      freight_type: arr[1],
      load_term: arr[2],
      discharge_load_term: arr[3],
    };

    return obj;
  };

  const sendMail = async (vData) => {
    const selectedCargo = await updateCargoName();

    let id = null;
    let sendData = {
      recepients: vData.send_to, //"noreply@theoceann.com",
      message: `<h3>Account For: ${vData.account_for} </h3>
      <h3>Cargo Details: ${vData.cargo_details} </h3>
      <h3>Cargo Name: ${selectedCargo.cargoName} </h3>
      <h3>CP Qty: ${vData.cp_qty} </h3>
      <h3>Option %: ${vData.option} </h3>
      <h3>Min/Max Qty: ${vData.min_qty}| ${vData.max_qty} </h3>
      <h3>Freight Type:${selectedCargo.freight_type}</h3>
      <h3>Freight Rate:${vData.freight_rate}</h3>
      <h3>World Scale:${vData.world_scale}</h3>
      <h3>Load Port Options:${vData.load_port_options}</h3>
      <h3>Discharge Port Options:${vData.discharge_port_options}</h3>
      <h3>Load Term:${selectedCargo.load_term}</h3>
      <h3>Discharge Term:${selectedCargo.discharge_load_term}
      <h3>Broker Commission %:${vData.broker_commission}</h3>
      <h3>DEM : ${vData.dem}</h3>
      <h3>Des:${vData.des}</h3>
      
      <h3>Other Details:${vData.other_details}</h3>
      `,
      subject: vData.subject,
      link: "",
    };
    if (!state.id) {
      let type = "save";
      let suMethod = "POST";
      let suURL = `${URL_WITH_VERSION}/voyagecargo/cargo-enquiry-${type}?frm=${state.frmName}`;
      await postAPICall(suURL, vData, suMethod, (data) => {
        if (data && data.data) {
          openNotificationWithIcon("success", data.message);
          setState(
            (prevState) => ({
              ...prevState,
              formData: {
                id: data.row.id,
              },
            }),
            () => {
              onEditClick(data.data.id);
            }
          );
          id = data.row.id;
          postAPICall(
            `${URL_WITH_VERSION}/email/send`,
            sendData,
            "post",
            (dataR) => {
              if (dataR && dataR.data) {
                // openNotificationWithIcon("success", dataR.message);

                postAPICall(
                  `${URL_WITH_VERSION}/voyagecargo/send-cargo-enquiry`,
                  { id: data.row.id },
                  "post",
                  (dataRes) => {}
                );
              } else {
                openNotificationWithIcon("error", dataR.message);
              }
            }
          );
        } else {
          openNotificationWithIcon("error", data.message);

          setState((prevState) => ({
            ...prevState,
            frmVisible: true,
          }));
        }
      });
    } else if (props.id || state.id) {
      postAPICall(
        `${URL_WITH_VERSION}/email/send`,
        sendData,
        "post",
        (dataR) => {
          if (dataR && dataR.data) {
            openNotificationWithIcon("success", dataR.message);

            postAPICall(
              `${URL_WITH_VERSION}/voyagecargo/send-cargo-enquiry`,
              { id: props.id || state.id },
              "post",
              (dataRes) => {}
            );
          } else {
            openNotificationWithIcon("error", dataR.message);
          }
        }
      );
    } else {
    }
  };
  let formisLeftBtn = !props.isMailSent
    ? [
        {
          key: "s1",
          isSets: [
            // {
            //   id: "1",
            //   key: "menu-fold",
            //   type: <MenuFoldOutlined />,
            //   withText: "List",
            //   showToolTip: true,
            //   event: ()=>{
            //     // console.log('world')
            //   },
            // },
            // { id: '8', key: 'plus', type: 'plus', withText: '', event: (key, saveData, innerCB) => (innerCB && typeof innerCB === 'function' ? innerCB() : undefined) },
            {
              id: "2",
              key: "save",
              type: <SaveOutlined />,
              withText: "Save",
              showToolTip: true,
              event: (key, saveData, innerCB) => {
                saveFormData(saveData, innerCB);
              },
            },
            {
              id: "3",
              key: "delete",
              type: <DeleteOutlined />,
              withText: "Delete",
              showToolTip: true,
              event: (key, saveData, innerCB) => {
                if (saveData["id"] && saveData["id"] > 0) {
                  Modal.confirm({
                    title: "Confirm",
                    content: "Are you sure, you want to delete it?",
                    onOk: () =>
                      deleteData(saveData["id"], saveData["tcov_id"], innerCB),
                  });
                }
              },
            },
            {
              id: "4",
              key: "send",
              type: <SendOutlined />,
              withText: "send",
              showToolTip: true,
              event: (key, saveData, innerCB) => {
                sendMail(saveData);
              },
            },
          ],
        },
      ]
    : [
        {
          key: "s1",
          isSets: [
            // {
            //   id: "1",
            //   key: "menu-fold",
            //   type: <MenuFoldOutlined />,
            //   withText: "List",
            //   showToolTip: true,
            //   event: ()=>{
            //     console.log('world')
            //   },
            // },
            // { id: '8', key: 'plus', type: 'plus', withText: '', event: (key, saveData, innerCB) => (innerCB && typeof innerCB === 'function' ? innerCB() : undefined) },
            // {
            //   id: "2",
            //   key: "save",
            //   type: <SaveOutlined />,
            //   withText: "Save",
            //   showToolTip: true,
            //   event: (key, saveData, innerCB) => {
            //     saveFormData(saveData, innerCB);
            //   },
            // },
            // {
            //   id: "4",
            //   key: "send",
            //   type: <SendOutlined />,
            //   withText: "send",
            //   showToolTip: true,
            //   event: (key, saveData, innerCB) => {
            //     sendMail(saveData);
            //   },
            // },
          ],
        },
      ];

  return (
    <div className="tcov-wrapper full-wraps">
      <Layout className="layout-wrapper">
        <Layout>
          <Content className="content-wrapper">
            <div className="fieldscroll-wrap">
              <div className="body-wrapper">
                <article className="article">
                  <div className="box box-default">
                    <div className="box-body common-fields-wrapper">
                      {state.refreshForm === false && state.formData ? (
                        <NormalFormIndex
                          key={"key_" + state.frmName + "_0"}
                          formClass="label-min-height"
                          formData={state.formData}
                          showForm={true}
                          frmCode={state.frmName}
                          formDevideInCols={1}
                          showToolbar={[
                            {
                              isLeftBtn: formisLeftBtn,
                              isRightBtn: [
                                {
                                  key: "rbs1",
                                  isSets: [
                                    // {
                                    //   key: "reports",
                                    //   isDropdown: 0,
                                    //   withText: "Reports",
                                    //   type: "",
                                    //   menus: [],
                                    // },
                                  ],
                                },
                              ],
                              isResetOption: false,
                            },
                          ]}
                          inlineLayout={true}
                        />
                      ) : undefined}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default GenerateCargoEnquiry;
