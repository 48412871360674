export const BunkerDetailsCalculation = (formData, index) => {
  let ecafuelGrade = formData.eca_fuel_grade;
  let bunkerobj = {};
  let consarr = formData?.["."] ?? [];
  let fuel_cons = 0;

  if (formData?.["bunkerdetails"]?.[index]?.["is_updated"] == 1) {
    bunkerobj = { ...formData["bunkerdetails"][index] };
    return bunkerobj;
  }

  formData?.portitinerary?.map((el, ind) => {
    if (ind == index) {
      let { tsd = 0, eca_days = 0, passage, funct, days = 0, xpd = 0 } = el;
      let nonEcadays = tsd - eca_days;
      let ifo_cons = findfuelcons("IFO", consarr, passage);
      let vlsfo_cons = findfuelcons("VLSFO", consarr, passage);
      let mgo_cons = findfuelcons("MGO", consarr, passage);
      let lsmgo_cons = findfuelcons("LSMGO", consarr, passage);
      let ulsfo_cons = findfuelcons("ULSFO", consarr, passage);
      if (ecafuelGrade === "7") {
        // LSMGO
        if (vlsfo_cons && ifo_cons) {
          fuel_cons = vlsfo_cons;
          bunkerobj["vlsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["ifo"] = "0.00";
        } else if (!vlsfo_cons && ifo_cons) {
          fuel_cons = ifo_cons;
          bunkerobj["ifo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["vlsfo"] = "0.00";
        } else if (vlsfo_cons && !ifo_cons) {
          fuel_cons = vlsfo_cons;
          bunkerobj["vlsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["ifo"] = "0.00";
        } else {
          bunkerobj["ifo"] = "0.00";
          bunkerobj["vlsfo"] = "0.00";
        }
        bunkerobj["lsmgo"] = "0.00";
        bunkerobj["mgo"] = "0.00";
        bunkerobj["ulsfo"] = "0.00";
      } else if (ecafuelGrade === "10") {
        //ULSFO
        let ifo_cons = findfuelcons("IFO", consarr, passage);
        let vlsfo_cons = findfuelcons("VLSFO", consarr, passage);
        let ulsfo_cons = findfuelcons("ULSFO", consarr, passage);
        if (ulsfo_cons) {
          fuel_cons = ulsfo_cons;
          bunkerobj["ulsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["vlsfo"] = "0.00";
          bunkerobj["ifo"] = "0.00";
        } else if (vlsfo_cons && ifo_cons) {
          fuel_cons = vlsfo_cons;
          bunkerobj["vlsfo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["ifo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
        } else if (ifo_cons) {
          fuel_cons = ifo_cons;
          bunkerobj["ifo"] = parseFloat(fuel_cons * nonEcadays).toFixed(2);
          bunkerobj["vlsfo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
        } else {
          bunkerobj["ifo"] = "0.00";
          bunkerobj["vlsfo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
        }
        bunkerobj["lsmgo"] = "0.00";
        bunkerobj["mgo"] = "0.00";
      } else {
        if (
          ifo_cons > 0 &&
          vlsfo_cons > 0 &&
          mgo_cons > 0 &&
          lsmgo_cons > 0 &&
          ulsfo_cons > 0
        ) {
          bunkerobj["lsmgo"] = "0.00";
          bunkerobj["mgo"] = "0.00";
          bunkerobj["ulsfo"] = "0.00";
          bunkerobj["ifo"] = "0.00";
          bunkerobj["vlsfo"] = parseFloat(vlsfo_cons * tsd).toFixed(2);
        } else {
          bunkerobj["ifo"] = ifo_cons
            ? parseFloat(ifo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["lsmgo"] = lsmgo_cons
            ? parseFloat(lsmgo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["mgo"] = mgo_cons
            ? parseFloat(mgo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["vlsfo"] = vlsfo_cons
            ? parseFloat(vlsfo_cons * tsd).toFixed(2)
            : "0.00";
          bunkerobj["ulsfo"] = ulsfo_cons
            ? parseFloat(ulsfo_cons * tsd).toFixed(2)
            : "0.00";
        }
      }
      bunkerobj["eca_consp"] = SeafuelConsECA(
        ecafuelGrade,
        passage,
        consarr,
        eca_days
      );
      bunkerobj["pc_ifo"] = portfuelCons("3", consarr, funct, days, xpd);
      bunkerobj["pc_vlsfo"] = portfuelCons("5", consarr, funct, days, xpd);
      bunkerobj["pc_lsmgo"] = portfuelCons("7", consarr, funct, days, xpd);
      bunkerobj["pc_mgo"] = portfuelCons("4", consarr, funct, days, xpd);
      bunkerobj["pc_ulsfo"] = portfuelCons("10", consarr, funct, days, xpd);
    }
  });

  return bunkerobj;
};

export const findfuelcons = (fuelType, consarr, passage) => {
  let fuelcons = 0;
  consarr.map((el) => {
    const { fuel_type, fuel_code } = el;
    if (el.fuel_code === fuelType) {
      fuelcons = passage === "1" ? el?.ballast_value : el.laden_value;
    }
  });
  return isNaN(fuelcons) || !fuelcons ? 0 : parseFloat(fuelcons);
};

export const findCpPassage = (fuelType, passageType, consArr) => {
  let fuel_cons = 0;
  consArr?.map((el) => {
    const { fuel_type, fuel_code } = el;
    if (fuel_type == fuelType) {
      if (passageType === "1") {
        fuel_cons = isNaN(el?.ballast_value) ? 0 : parseFloat(el.ballast_value);
      } else if (passageType === "2") {
        fuel_cons = isNaN(el?.laden_value) ? 0 : parseFloat(el.laden_value);
      }
    }
  });

  fuel_cons = isNaN(fuel_cons) ? 0 : parseFloat(fuel_cons);
  return fuel_cons;
};

export const findPortFunc = (fuelType, consArr, portFunc) => {
  let port_cons = 0;
  let idlePort_cons = 0;
  consArr?.map((el) => {
    const { fuel_type, fuel_code } = el;

    if (fuel_type == fuelType) {
      idlePort_cons = el.con_ideal_on;
      switch (portFunc) {
        case "2": // loading
          port_cons = el.con_loading;
          break;
        case "9": // Dischagring
          port_cons = el.con_disch;
          break;
        default: // ideal
          port_cons = el.con_ideal_on;
      }
    }
  });

  port_cons = isNaN(port_cons) ? 0 : parseFloat(port_cons);
  idlePort_cons = isNaN(idlePort_cons) ? 0 : parseFloat(idlePort_cons);
  return { port_cons, idlePort_cons };
};

export const SeafuelConsECA = (fuelType, passageType, consArr, days) => {
  let fuel_cons = findCpPassage(fuelType, passageType, consArr);
  let _days = isNaN(days) ? 0 : parseFloat(days);
  return parseFloat(fuel_cons * _days).toFixed(2);
};

export const portfuelCons = (fuelType, consArr, portFunc, pdays, xpd) => {
  let { port_cons, idlePort_cons } = findPortFunc(fuelType, consArr, portFunc);
  let _pdays = isNaN(pdays) ? 0 : parseFloat(pdays);
  let _xpd = isNaN(xpd) ? 0 : parseFloat(xpd);
  let totalportcons = port_cons * _pdays + idlePort_cons * _xpd;
  return parseFloat(totalportcons).toFixed(2);
};

export function updateCopyObj(copyData, index, values, copyobj) {
  // Utility function to calculate `arob` and `dr` values
  const calculateValues = (prevDr, current, pcValue, rValue) => {
    const arob = parseFloat(prevDr || 0) - parseFloat(current || 0);
    const dr = (parseFloat(arob) || 0) - (parseFloat(pcValue) || 0) + parseFloat(rValue || 0);
    return {
      arob: parseFloat(arob).toFixed(2),
      dr: parseFloat(dr).toFixed(2),
    };
  };

  // Keys to calculate
  const keys = ["vlsfo", "lsmgo", "ifo", "ulsfo", "mgo"];

  if (index > 0) {
    keys.forEach((key) => {
      const prevDr = copyData[index - 1]?.[`dr_${key}`];
      const currentValue = values?.[key];
      const pcValue = values?.[`pc_${key}`];
      const rValue = copyobj?.[`r_${key}`];
      const { arob, dr } = calculateValues(prevDr, currentValue, pcValue, rValue);

      copyobj[`arob_${key}`] = arob;
      copyobj[`dr_${key}`] = dr;
    });
  } else {
    keys.forEach((key) => {
      const arobKey = `arob_${key}`;
      const pcValue = values?.[`pc_${key}`];
      const rValue = copyobj?.[`r_${key}`];
      let dr = (parseFloat(copyobj?.[arobKey]) || 0) - (parseFloat(pcValue) || 0) + parseFloat(rValue || 0);
      copyobj[`dr_${key}`] = parseFloat(dr).toFixed(2);
    });
  }

  return copyobj; // Return updated copyobj
}

export function updateBunkerDetails(dataArray, updatedIndex, updatedKey, updatedValue,fuelType,isBunkerDROB,arobKey,pcKey,drKey,rKey, isFinalUpdate = false) {
  // Clone the array to avoid mutating the original
  const updatedData = [...dataArray];

  // Update the value of the changed key in the specified row
  updatedData[updatedIndex][updatedKey] = isFinalUpdate
    ? parseFloat(updatedValue||0).toFixed(2) // Apply rounding only on final update
    : updatedValue; // Allow raw input for intermediate updates

 

  // If the updated key starts with "dr_", skip recalculation for the current index
  const startIndex = isBunkerDROB ? updatedIndex + 1 : updatedIndex;

  // Helper function to round to 2 decimal places
  const roundToTwo = (value) => (Math.round(value * 100) / 100).toFixed(2);

  // Recalculate for the specified index and onward
  for (let i = startIndex; i < updatedData.length; i++) {
    if (i === updatedIndex && isBunkerDROB) {
      // Skip recalculation for the current index if the updated key is 
      continue;
    }

    // Calculate AROB for the current row (if not the first row)
    if (i > 0) {
      updatedData[i][arobKey] = roundToTwo(
        parseFloat(updatedData[i - 1][drKey] || 0) - 
        parseFloat(updatedData[i][fuelType] || 0)
      );
    }

    // Calculate DR for the current row
    updatedData[i][drKey] = roundToTwo(
      parseFloat(updatedData[i][arobKey] || 0) + 
      parseFloat(updatedData[i][rKey] || 0) - 
      parseFloat(updatedData[i][pcKey] || 0)
    );
  }

  return updatedData;
}

export function calculateFuelData(data) {
  data.forEach((item, index) => {
    console.log({item,index})
    if (index === 0) {
      // For index 0
      item.dr_vlsfo = (parseFloat(item.arob_vlsfo || 0) + parseFloat(item.r_vlsfo || 0)).toFixed(2);
      item.dr_ifo = (parseFloat(item.arob_ifo || 0) + parseFloat(item.r_ifo || 0)).toFixed(2);
      item.dr_lsmgo = (parseFloat(item.arob_lsmgo || 0) + parseFloat(item.r_lsmgo || 0)).toFixed(2);
      item.dr_ulsfo = (parseFloat(item.arob_ulsfo || 0) + parseFloat(item.r_ulsfo || 0)).toFixed(2);
      item.dr_mgo = (parseFloat(item.arob_mgo || 0) + parseFloat(item.r_mgo || 0)).toFixed(2);
    } else {
      // For index > 0
      const prevItem = data[index - 1];
      item.dr_vlsfo = (
        parseFloat(item.arob_vlsfo || 0) +
        parseFloat(item.r_vlsfo || 0) -
        parseFloat(item.pc_vlsfo || 0)
      ).toFixed(2);
      item.dr_ifo = (
        parseFloat(item.arob_ifo || 0) +
        parseFloat(item.r_ifo || 0) -
        parseFloat(item.pc_ifo || 0)
      ).toFixed(2);
      item.dr_lsmgo = (
        parseFloat(item.arob_lsmgo || 0) +
        parseFloat(item.r_lsmgo || 0) -
        parseFloat(item.pc_lsmgo || 0)
      ).toFixed(2);
      item.dr_ulsfo = (
        parseFloat(item.arob_ulsfo || 0) +
        parseFloat(item.r_ulsfo || 0) -
        parseFloat(item.pc_ulsfo || 0)
      ).toFixed(2);
      item.dr_mgo = (
        parseFloat(item.arob_mgo || 0) +
        parseFloat(item.r_mgo || 0) -
        parseFloat(item.pc_mgo || 0)
      ).toFixed(2);

      // Update arob values
      item.arob_vlsfo = (
        parseFloat(prevItem.dr_vlsfo || 0) - parseFloat(item.vlsfo || 0)
      ).toFixed(2);
      item.arob_ifo = (
        parseFloat(prevItem.dr_ifo || 0) - parseFloat(item.ifo || 0)
      ).toFixed(2);
      item.arob_lsmgo = (
        parseFloat(prevItem.dr_lsmgo || 0) - parseFloat(item.lsmgo || 0)
      ).toFixed(2);
      item.arob_ulsfo = (
        parseFloat(prevItem.dr_ulsfo || 0) - parseFloat(item.ulsfo || 0)
      ).toFixed(2);
      item.arob_mgo = (
        parseFloat(prevItem.dr_mgo || 0) - parseFloat(item.mgo || 0)
      ).toFixed(2);
    }
  });
  return data;
}

export function autoCalculateBunkerConsumption(dataArray) {
  // Utility function to calculate `arob` and `dr` values
  const calculateValues = (prevDr, current, pcValue, rValue) => {
    const arob = parseFloat(prevDr || 0) - parseFloat(current || 0);
    const dr = (parseFloat(arob) || 0) - (parseFloat(pcValue) || 0) + parseFloat(rValue || 0);
    return {
      arob: parseFloat(arob).toFixed(2),
      dr: parseFloat(dr).toFixed(2),
    };
  };

  // Keys to calculate
  const keys = ["vlsfo", "lsmgo", "ifo", "ulsfo", "mgo"];

  for (let i = 0; i < dataArray.length; i++) {
    const currentElement = dataArray[i];

    if (i > 0) {
      keys.forEach((key) => {
        const prevDr = dataArray[i - 1]?.[`dr_${key}`];
        const currentValue = currentElement?.[key];
        const pcValue = currentElement?.[`pc_${key}`];
        const rValue = currentElement?.[`r_${key}`];
        const { arob, dr } = calculateValues(prevDr, currentValue, pcValue, rValue);

        currentElement[`arob_${key}`] = arob;
        currentElement[`dr_${key}`] = dr;
      });
    } else {
      keys.forEach((key) => {
        const arobKey = `arob_${key}`;
        const pcValue = currentElement?.[`pc_${key}`];
        const rValue = currentElement?.[`r_${key}`];
        let dr = (parseFloat(currentElement?.[arobKey]) || 0) - (parseFloat(pcValue) || 0) + parseFloat(rValue || 0);
        currentElement[`dr_${key}`] = parseFloat(dr).toFixed(2);
      });
    }
  }

  return dataArray;
}


