import React, { useEffect, useState } from "react";
import { getAPICall } from "../../shared";
import { Table } from "antd";
import "../port-congestion/PortTable.css"


const LatestFivePortCongestionTable = () => {
  const [tableData, SetTableData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "PORT NAME",
      key: "PORT_NAME",
      dataIndex: "PORT_NAME",
    },
    {
      title: "YEAR",
      key: "YEAR",
      dataIndex: "YEAR",
    },
    {
      title: "WEEK",
      key: "WEEK",
      dataIndex: "WEEK",
    },
    {
      title: "MARKET",
      key: "MARKET",
      dataIndex: "MARKET",
    },
    {
      title: "SHIPCLASS",
      key: "SHIPCLASS",
      dataIndex: "SHIPCLASS",
    },
    {
      title: "VESSELS",
      key: "VESSELS",
      dataIndex: "VESSELS",
    },
    {
      title: "CALLS",
      key: "CALLS",
      dataIndex: "CALLS",
    },
    {
      title: "TIME ANCH",
      key: "TIME_ANCH",
      dataIndex: "TIME_ANCH",
    },
    {
      title: "TIME PORT",
      key: "TIME_PORT",
      dataIndex: "TIME_PORT",
    },
    {
      title: "TIME ANCH STDEV",
      key: "TIME_ANCH_STDEV",
      dataIndex: "TIME_ANCH_STDEV",
    },
    {
      title: "TIME ANCH DIFF",
      key: "TIME_ANCH_DIFF",
      dataIndex: "TIME_ANCH_DIFF",
    },
    {
      title: "TIME ANCH DIFF PERC",
      key: "TIME_ANCH_DIFF_PERC",
      dataIndex: "TIME_ANCH_DIFF_PERC",
    },
  ];
  const getData = async () => {
    try {
      const url = `${process.env.REACT_APP_MT_URL}marine/port-congestion-db`;
      const response = await getAPICall(url);
      console.log("responsee", response);
      if (response) {
        SetTableData(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="port-table">
      <Table dataSource={tableData} columns={columns} />
    </div>
  );
};

export default LatestFivePortCongestionTable;
