import React, { useCallback, useEffect, useRef, useState } from "react";
import "./email.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import {
  AutoComplete,
  Button,
  Input,
  Popover,
  Select,
  Spin,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import {
  CloseOutlined,
  EllipsisOutlined,
  ExpandOutlined,
  LineOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  getUserSignature,
  setEmailReplySuggestions,
  updateComposeMailField,
  updateComposeMailWithIdNull,
} from "../../actions/emailActions";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { v4 as uuidv4 } from "uuid";

// Importing quill-emoji
import "quill-emoji/dist/quill-emoji.css";
import * as Emoji from "quill-emoji";
import {
  getAPICall,
  openNotificationWithIcon,
  postAPICall,
} from "../../shared";
import DebounceSelect from "./DebounceSelect";
import { Icon } from "@iconify-icon/react";
import MenuComponent from "./MenuComponent";
import ImageResize from "quill-image-resize-module-react";
import HtmlToReact from "html-to-react";
import ReactDOMServer from "react-dom/server";
// import "quill-table-ui/dist/quill.table.ui.css";
// import Quill from 'quill';
// import { TableModule } from "quill-table-ui";
import Table from "quill-table";
import {
  extractEmail,
  parseSender,
  sendermaillReturn,
} from "../../services/email_utils";
import Swal from "sweetalert2";
import SignatureEditor from "./Signature";
// import './DivBlot';

// Email validation function
const isValidEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

// Register the module with Quill
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/table", Table);

// Register custom blots
// const Block = Quill.import("blots/block");
// class DivBlot extends Block {}
// DivBlot.blotName = "div";
// DivBlot.tagName = "div";
// Quill.register(DivBlot);

// // Define a custom blot for span tags to preserve inline styles
// const Inline = Quill.import('blots/inline');
// class SpanBlot extends Inline {
//   static blotName = 'span';
//   static tagName = 'span';
// }
// Quill.register(SpanBlot);

const Block = Quill.import('blots/block');
class DivBlot extends Block {
  static blotName = 'div';
  static tagName = 'div';

  static create(value) {
    let node = super.create();
    node.setAttribute('style', value.style);
    return node;
  }

  static formats(node) {
    return {
      style: node.getAttribute('style'),
    };
  }

  format(name, value) {
    if (name === 'style' && value) {
      this.domNode.setAttribute('style', value);
    } else {
      super.format(name, value);
    }
  }
}
Quill.register(DivBlot);

// Define a custom blot for span tags to preserve inline styles
const Inline = Quill.import('blots/inline');
class SpanBlot extends Inline {
  static blotName = 'span';
  static tagName = 'span';

  static create(value) {
    let node = super.create();
    node.setAttribute('style', value.style);
    return node;
  }

  static formats(node) {
    return {
      style: node.getAttribute('style'),
    };
  }

  format(name, value) {
    if (name === 'style' && value) {
      this.domNode.setAttribute('style', value);
    } else {
      super.format(name, value);
    }
  }
}
Quill.register(SpanBlot);

const Container = Quill.import("blots/container");
class TableBlot extends Container { }
TableBlot.blotName = "table";
TableBlot.tagName = "table";
Quill.register(TableBlot);

class TableRowBlot extends Block { }
TableRowBlot.blotName = "tr";
TableRowBlot.tagName = "tr";
Quill.register(TableRowBlot);

class TableCellBlot extends Block { }
TableCellBlot.blotName = "td";
TableCellBlot.tagName = "td";
Quill.register(TableCellBlot);

const SUGGESTION_COLOR = [
  "#347928",
  "#624E88",
  "#EF9C66",
  "#6C0345",
  "#117554",
  "#914F1E",
  "#15B392",
];

const ComposeReply = ({
  composeData = {
    Date: "2024-09-05T05:24:24.403Z",
    to: [],
    cc: [],
    bcc: [],
    body: "",
    subject: "",
    attachments: [],
    owner_email: "theoceann002@gmail.com",
    ThreadID: "",
    GmailMessageId: "",
    listOfFile: [],
    totalSize: 0,
    uid: "4d3a72a4-34f2-493f-a48d-3ce6d261e677",
    _id: null,
  },
  composePopups = [],
  composeIndex = 0,
  handleReplyClose = () => { },
  onMaximize = () => { },
  onMinimize = () => { },
  onSend = () => { },
  mail,
  showComposeModal,
  setShowComposeModal,
  replyComposeData,
  setReplyComposeData,
  mailType = "",
  setReplyMailIndex,
}) => {

  // const [replyComposeData,setReplyComposeData] = useState(null)
  const [localBody, setLocalBody] = useState(composeData?.body || "");
  const [showCc, setShowCc] = useState(composeData?.cc?.length > 0);
  const [showBcc, setShowBcc] = useState(composeData?.bcc?.length > 0);
  const restrictionData = useSelector((state) => state.email.restrictionData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [attachments, setAttachments] = useState(
    replyComposeData?.attachments || []
  );

  // console.log({ attachments })
  const [previousData, setPreviousData] = useState(replyComposeData);
  const [inputValue, setInputValue] = useState("");
  const [inputCcValue, setInputCcValue] = useState("");
  const [inputBccValue, setInputBccValue] = useState("");
  const [senderEmail, setSenderEmail] = useState("");

  const [emailOptions, setEmailOptions] = useState([]);
  const [fromEmailOptions, setFromEmailOptions] = useState([]);
  const [replyContent, setReplyContent] = useState("");
  const [showReplyMailDetails, setShowReplyMailDetails] = useState(false);
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [replySuggestion, setReplySuggestion] = useState([]);
  const [showRevertButton, setShowRevertButton] = useState(false);
  const [emailBodyBackup, setEmailBodyBackup] = useState("");
  const [openRewritePopover, setOpenRewritePopover] = useState(false);
  const [isLoadingAISearch, setIsLoadingAISearch] = useState(false);
  const [rewriteText, setRewriteText] = useState("");

  const signature = useSelector((state) => state.email.signature);
  const replySuggestionsList = useSelector(
    (state) => state.email.replySuggestions
  );
  const replyOriginalBodyText = useSelector(
    (state) => state.email.replyOriginalBodyText
  );
  const quillRef = useRef(null);
  const suggestionsRef = useRef(null);

  const updateSuggestionsHeight = () => {
    if (suggestionsRef.current) {
      const height = suggestionsRef.current.offsetHeight;
      document.documentElement.style.setProperty(
        "--quill-margin-top",
        `${height + 10}px`
      );
    }
  };


  useEffect(() => {
    // Measure the height of the suggestions div initially
    updateSuggestionsHeight();

    window.addEventListener("resize", updateSuggestionsHeight);
    return () => {
      window.removeEventListener("resize", updateSuggestionsHeight);
    };
  }, [replySuggestion]);

  //   useEffect(() => {
  //     if (quillRef.current) {
  //       const editor = quillRef.current.getEditor();
  //       editor.focus();
  //     }
  //   }, []);

  const handleReplyChange = (value) => {
    setReplyContent(value);
  };

  const htmlToReactParser = new HtmlToReact.Parser();
  useEffect(() => {
    // Dynamic module registration
    if (typeof Quill !== "undefined" && Emoji) {
      Quill.register("modules/emoji", Emoji);
    }

    // const input = document.querySelector("input[data-link]");
    // input.dataset.link = "https://www.site.com";
    // input.placeholder = "https://www.site.com";

    const userData = localStorage.getItem("oceanToken")
      ? JSON.parse(
        atob(localStorage.getItem("oceanToken")?.split(".")[1] ?? "")
      )
      : null;

    if (userData && userData?.inboxes) {
      const newOptions = userData?.inboxes?.map((item) => ({ value: item }));
      setFromEmailOptions(newOptions);
      setSenderEmail(newOptions[0]?.value);

      const updatedComposePopups = [...composePopups];
      updatedComposePopups[composeIndex] = {
        ...updatedComposePopups[composeIndex],
        composedData: {
          ...updatedComposePopups[composeIndex]?.composedData,
          owner_email: newOptions[0]?.value,
        },
      };
      //   setComposePopups(updatedComposePopups);
    }
    fetchEmailOptions("");
  }, []);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.dangerouslyPasteHTML(replyComposeData?.body);
    }
  }, []);

  //   const updateQuillContent = debounce((body) => {
  //     if (quillRef.current) {
  //       const quill = quillRef.current.getEditor();
  //       const currentCursorPosition = quill.getSelection()?.index || 0;

  //       quill.updateContents(quill.clipboard.convert(body || ""));

  //       quill.setSelection(currentCursorPosition, 0);
  //     }
  //   }, 300);
  //   // Call the debounced function inside useEffect
  //   useEffect(() => {
  //     updateQuillContent(replyComposeData?.body || "");
  //   }, [replyComposeData?.body]);

  useEffect(() => {
    if (replyComposeData?.body) {
      setLocalBody(
        `<br/><br/><br/><p>------------------------------------------------</p>${replyComposeData?.body}` ||
        ""
      );
    }
    setAttachments(replyComposeData?.attachments || []);

    return () => {
      setLocalBody("");
    };
  }, [replyComposeData]);

  useEffect(() => {
    if (replySuggestionsList) {
      setReplySuggestion(replySuggestionsList);
    }
  }, [replySuggestionsList]);

  const saveDraft = useCallback(
    async (data) => {
      const url = `${process.env.REACT_APP_MT_URL}mail/draft`;
      const response = await postAPICall(url, data, "post");
      if (response?._id && data.uid && data._id === null) {
        dispatch(updateComposeMailWithIdNull(data.uid, "_id", response._id));
      }
    },
    [dispatch]
  );

  const handleInputChange = (value, name) => {
    // if (replyComposeData?._id) {
    //   dispatch(updateComposeMailField(replyComposeData?._id, name, value));
    // } else {
    //   dispatch(updateComposeMailWithIdNull(replyComposeData?.uid, name, value));
    // }
    // saveDraft(replyComposeData);
    if (name == "subject") {
      setReplyComposeData({ subject: value });
    } else if (name === "to") {
      setReplyComposeData({ ...replyComposeData, to: value });
    } else if (name === "cc") {
      setReplyComposeData({ ...replyComposeData, cc: value });
    } else if (name === "bcc") {
      setReplyComposeData({ ...replyComposeData, bcc: value });
    }
  };

  const debouncedUpdateBody = useCallback(
    debounce((value) => {
      // dispatch(updateComposeMailField(replyComposeData?._id, "body", value));
      if (replyComposeData?._id) {
        dispatch(updateComposeMailField(replyComposeData?._id, "body", value));
      } else {
        dispatch(updateComposeMailWithIdNull(replyComposeData?.uid, "body", value));
      }
    }, 1000),
    [dispatch, replyComposeData?.body] // will be created only once initially
  );

  const handleBodyChange = (value) => {
    setLocalBody(value);
    setReplyComposeData({ ...replyComposeData, body: value });

    // dispatch(updateComposeMailField(replyComposeData?.id, 'body', value));
    debouncedUpdateBody(value);
  };


  const checkRestrictedTask = (email, type) => {
    // console.log("restrictiondataa",restrictionData)
    const user = restrictionData?.filter((ele) => ele.emailAccount?.import_email === email);
    // console.log("isallowed", user)
    // console.log("type", type)
    if (type == "reply" && user?.[0]?.reply == false) {
      Swal.fire({ title: "User doesn't have access to reply to mails", text: "Please contact the administrator" });
      return false
    } else if (type == "replyAll" && user?.[0]?.replyAll == false) {
      Swal.fire({ title: "User doesn't have access to replyAll to mails", text: "Please contact the administrator" });
      return false
    }
    else if (type == "forward" && user?.[0]?.foreword == false) {
      Swal.fire({ title: "User doesn't have access to forward mails", text: "Please contact the administrator" });
      return false
    }
    else {
      return true
    }
  }
  const handleSendClick = async () => {
    if (restrictionData?.length > 0) {
      if (!checkRestrictedTask(replyComposeData?.owner_email, mailType)) {
        return;
      }
    }
    if (replyComposeData?.to?.length === 0) {
      openNotificationWithIcon(
        "info",
        "Please specify at least one recipient.",
        2
      );
    } else {
      //   onSend({
      //     ...replyComposeData,
      //     body: localBody,
      //   });

      setIsSendingMail(true);
      let url = `${process.env.REACT_APP_MT_URL}mail/ReplayToEmail`;
      if (replyComposeData?.mailType === "forward") {
        url = `${process.env.REACT_APP_MT_URL}mail/SendEmail`;
      }
      const response = await postAPICall(url, replyComposeData, "post");
      if (response && response?.status) {
        // handleReplyClose(replyComposeData, mail);
        setShowComposeModal(false);
        setReplyMailIndex(null);
        openNotificationWithIcon("success", "Mail sent successfully");
        setIsSendingMail(false);
      } else {
        openNotificationWithIcon("error", response?.msg);
        setIsSendingMail(false);
      }
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, "link", "image", "emoji"],
      // ["link", "image"],
      // ["emoji"],
      // ["clean"],
      ["table"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    // table: true,
    // imageResize: {
    //   parchment: Quill.import('parchment'),
    //   modules: ['Resize', 'DisplaySize']
    // },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "emoji",
    "table",
    // "div",
    // "span",
  ];

  // Fetch email options from API
  const fetchEmailOptions = async (query) => {
    // if (!query) {
    //   setEmailOptions([]);
    //   return;
    // }
    setLoading(true);
    let url = `${process.env.REACT_APP_MT_URL}mail/all-mails?query=${query}`;
    try {
      // Simulating API call delay
      setTimeout(async () => {
        const response = await getAPICall(url);
        const data = response?.emails?.map((item) => ({
          value: item,
          label: item,
        }));
        setEmailOptions(data);
      }, 500); // Delay for demonstration
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = useCallback(
    debounce((value) => {
      fetchEmailOptions(value);
    }, 300),
    []
  );

  const handleEmailSelect = (value) => {
    if (value && isValidEmail(value) && !replyComposeData?.to.includes(value)) {
      handleInputChange([...replyComposeData?.to, value], "to");
    }
    setInputValue("");
  };
  const handleCcEmailSelect = (value) => {
    if (value && isValidEmail(value) && !replyComposeData?.cc.includes(value)) {
      handleInputChange([...replyComposeData?.cc, value], "cc");
    }
    setInputCcValue("");
  };
  const handleBccEmailSelect = (value) => {
    if (
      value &&
      isValidEmail(value) &&
      !replyComposeData?.bcc?.includes(value)
    ) {
      handleInputChange([...(replyComposeData?.bcc || []), value], "bcc");
    }
    setInputBccValue("");
  };

  const handleEmailRemove = (email, name) => {
    if (name === "to") {
      const newEmails = replyComposeData?.to?.filter((item) => item !== email);
      handleInputChange(newEmails, "to");
    } else if (name === "cc") {
      const newEmails = replyComposeData?.cc?.filter((item) => item !== email);
      handleInputChange(newEmails, "cc");
    }
  };
  const handleEmailAttachmentRemove = (attachment) => {
    const newAttachment = replyComposeData?.attachments?.filter(
      (item) => item.uid !== attachment.uid
    );
    handleInputChange(newAttachment, "attachments");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.name === "to" && isValidEmail(inputValue)) {
        handleEmailSelect(inputValue);
      } else if (event.target.name === "cc" && isValidEmail(inputCcValue)) {
        handleCcEmailSelect(inputCcValue);
      } else if (event.target.name === "bcc" && isValidEmail(inputBccValue)) {
        handleBccEmailSelect(inputBccValue);
      }
    }
  };

  const renderEmailSummary = () => {
    const allEmail = [
      ...(replyComposeData?.to || []),
      ...(replyComposeData?.cc || []),
      ...(replyComposeData?.bcc || []),
    ];
    if (allEmail?.length === 0) {
      return "Recipients";
    } else if (allEmail.length <= 2) {
      return allEmail.join(", ");
    } else {
      const displayedEmails = allEmail.slice(0, 2).join(", ");
      return (
        <>
          {displayedEmails} and{" "}
          <span
            style={{
              padding: "0 5px",
              backgroundColor: "#f5f5f5",
              boxShadow: "inset 0 0 0 1px #dadce0",
            }}
          >
            {allEmail?.length - 2} more
          </span>
        </>
      );
    }
  };

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);

    const filePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            uid: uuidv4(),
            filename: file.name,
            size: file.size,
            data: reader.result.split(",")[1], // Base64 string without the data:image/...;base64, prefix
          });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const fileData = await Promise.all(filePromises);

      // Assuming replyComposeData?.attachments is an array
      const updatedAttachments = [
        ...(replyComposeData?.attachments || []),
        ...fileData,
      ];

      // console.log({ updatedAttachments })

      dispatch(
        updateComposeMailField(
          replyComposeData?._id,
          "attachments",
          updatedAttachments
        )
      );
      setAttachments(updatedAttachments)
      setReplyComposeData({ ...replyComposeData, attachments: updatedAttachments })
    } catch (error) {
      console.error("Error reading files:", error);
    }
  };

  // const handleInsertSignature = () => {
  //   dispatch(getUserSignature()); // Ensure signature is fetched
  //   setLocalBody((prevBody) => `${prevBody}\n\n${signature}`);
  // };

  const handleInsertSignature = () => {
    const signature =
      "<div><br><br><h2>Best regards,</h2><p><b>Your Name</b></p><p><b>Your Company</b></p><div>";
    // setEmailData((prevData) => ({
    //   ...prevData,
    //   body: prevData.body ? prevData.body + signature : signature,
    // }));

    setLocalBody((prevBody) => `${prevBody}${signature}`);
    setReplyComposeData({
      ...replyComposeData,
      body: `${replyComposeData?.body}${signature}`,
    });
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.clipboard.dangerouslyPasteHTML(
        `${replyComposeData?.body}${signature}`
      );
    }

    // handleInsertHtml(signature);
    // setLocalBody((prevBody) => `${prevBody}${quill?.root?.innerHTML ||signature}`);
  };

  const handleReplySuggestionClick = (suggestionText) => {
    // setLocalBody((prevBody) => `${suggestionText}<br/>${prevBody}`);
    setReplyComposeData({
      ...replyComposeData,
      body: `${suggestionText}${replyComposeData.body}`,
    });
    dispatch(setEmailReplySuggestions([]));
    setShowRevertButton(true);
  };

  const handleReplyDraftDelete = () => { };

  const handleUndo = () => {
    const editor = quillRef.current.getEditor();
    editor.history.undo();
  };

  const handleRedo = () => {
    const editor = quillRef.current.getEditor();
    editor.history.redo();
  };

  const handleAutoReply = async () => {
    let emailBodyBackupData = replyComposeData?.body;
    try {
      setIsLoadingAISearch(true);
      dispatch(setEmailReplySuggestions([]));
      const url = `${process.env.REACT_APP_MT_URL}marine/openai-reply`;
      const response = await postAPICall(
        url,
        { body: replyComposeData.body, subject: replyComposeData.subject },
        "post"
      );
      if (response?.status) {
        let updateBody = response?.reply_message?.replace(/\n/g, "<br/>") || "";
        setReplyComposeData({
          ...replyComposeData,
          body: `${updateBody} 
          <div>--------------------------------------------------------------------------------</div>
          ${emailBodyBackupData}`,
        });
        setIsLoadingAISearch(false);
        setShowRevertButton(true);
      }
    } catch (err) {
      console.log(err);
      setIsLoadingAISearch(false);
    }
  };

  const rewriteWithOceannAI = async () => {
    try {
      setIsLoadingAISearch(true);
      const url = `${process.env.REACT_APP_MT_URL}marine/check-text`;
      const editor = quillRef.current.getEditor();
      const plainText = editor?.getText();
      const response = await postAPICall(url, { text: plainText }, "post");
      if (response?.status) {
        setRewriteText(response?.corrected_sentence?.replace(/\n/g, "<br/>") || "");
        setIsLoadingAISearch(false);
        setOpenRewritePopover(true);
      } else {
        setIsLoadingAISearch(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoadingAISearch(false);
    }
  };


  return (
    <div className="compose-popup" style={{ position: "relative" }}>
      <div className="compose-header">
        <span className="title">
          {mailType == "reply" && "Reply"}
          {mailType == "replyAll" && "Reply All"}
          {mailType == "forward" && "Forward"}
        </span>
        <div style={{ display: "flex", gap: "15px" }}>
          <div
            onClick={() => {
              setShowComposeModal(!showComposeModal);
              if (quillRef.current) {
                const quill = quillRef.current.getEditor();
                quill.clipboard.dangerouslyPasteHTML(replyComposeData?.body);
              }
              //   onMaximize();
              //   console.log("localBody==>>>>", localBody);
              //   const updatedComposePopups = [...composePopups];
              //   updatedComposePopups[composeIndex] = {
              //     ...updatedComposePopups[composeIndex],
              //     composedData: {
              //       ...updatedComposePopups[composeIndex].composedData,
              //       body: localBody,
              //     },
              //   };
              //   setComposePopups(updatedComposePopups);
            }}
            style={{ color: "#fff", cursor: "pointer" }}
          >
            <ExpandOutlined />
          </div>
          <div
            onClick={() => handleReplyClose(replyComposeData, mail)}
            style={{ color: "#fff", cursor: "pointer" }}
          >
            <CloseOutlined />
          </div>
        </div>
      </div>
      <Spin spinning={isLoadingAISearch} size="default">
        <div className="compose-body">
          <div
            style={{
              borderBottom: "1px solid #ccc",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ paddingLeft: "10px" }}>
              <strong>From:</strong>{" "}
            </span>
            <Input
              placeholder="From"
              name="from"
              value={replyComposeData?.owner_email}
              className="compose_input"
              disabled
            />
            {/* <Select
            // mode="tags"
            style={{ width: "100%" }}
            disabled
            // placeholder="From"
            // notFoundContent={loading ? <Spin size="small" /> : null}
            // onSearch={handleSearch}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            value={
              replyComposeData?.owner_email
                ? replyComposeData?.owner_email
                : fromEmailOptions?.[0]
            }
            options={fromEmailOptions}
            onChange={(value) => {
              console.log(`selected ${value}`);
              handleInputChange(value, "owner_email");
              setSenderEmail(value);
            }}
            // dropdownRender={(menu) => (options.length > 0 ? menu : null)}
            // className="custom-select"
          /> */}
          </div>
          {showSummary ? (
            <div
              className="email-summary"
              style={{ borderBottom: "1px solid #ccc" }}
              onClick={() => setShowSummary(false)}
            >
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "8px",
                  paddingTop: "8px",
                  marginBottom: "8px",
                }}
              >
                {renderEmailSummary()}
              </p>
            </div>
          ) : (
            <>
              <div
                style={{ borderBottom: "1px solid #ccc", paddingTop: "5px" }}
              >
                <div style={{ display: "flex" }}>
                  <span style={{ paddingLeft: "10px" }}>
                    <strong>To:</strong> &nbsp;&nbsp;
                  </span>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                  >
                    <div
                      className="email-tags"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      {replyComposeData?.to?.map((email) => (
                        <Tag
                          key={email}
                          closable
                          onClose={() => handleEmailRemove(email, "to")}
                          style={{ marginBottom: "8px", marginRight: "8px" }}
                        >
                          {email}
                        </Tag>
                      ))}
                    </div>
                    <AutoComplete
                      options={emailOptions}
                      placeholder="Enter Email"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      value={inputValue}
                      onSearch={handleSearch}
                      onChange={setInputValue}
                      onSelect={handleEmailSelect}
                      notFoundContent={loading ? <Spin size="small" /> : null}
                      dropdownClassName="custom-autocomplete-dropdown"
                      style={{
                        flex: "1",
                        minWidth: "200px",
                        width: "100%",
                      }}
                    >
                      <Input
                        onPressEnter={handleKeyDown}
                        name="to"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="compose_input input_to"
                      />
                    </AutoComplete>
                  </div>
                </div>
                <div style={{ cursor: "pointer", textAlign: "end" }}>
                  <span onClick={() => setShowCc(!showCc)}>Cc</span>
                  <span
                    onClick={() => setShowBcc(!showBcc)}
                    style={{ marginLeft: "10px" }}
                  >
                    Bcc
                  </span>
                </div>
              </div>

              {(showCc || replyComposeData?.cc?.length > 0) && (
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingTop: "5px",
                    paddingBottom: "6px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <span style={{ paddingLeft: "10px" }}>
                      <strong>Cc:</strong> &nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <div
                        className="email-tags"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {replyComposeData?.cc?.map((email) => (
                          <Tag
                            key={email}
                            closable
                            onClose={() => handleEmailRemove(email, "cc")}
                            style={{ marginBottom: "8px", marginRight: "8px" }}
                          >
                            {email}
                          </Tag>
                        ))}
                      </div>
                      <AutoComplete
                        options={emailOptions}
                        placeholder="Enter Email"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        value={inputCcValue}
                        onSearch={handleSearch}
                        onChange={setInputCcValue}
                        onSelect={handleCcEmailSelect}
                        notFoundContent={loading ? <Spin size="small" /> : null}
                        dropdownClassName="custom-autocomplete-dropdown"
                        style={{
                          flex: "1",
                          minWidth: "200px",
                          width: "100%",
                        }}
                      >
                        <Input
                          onPressEnter={handleKeyDown}
                          name="cc"
                          value={inputCcValue}
                          onChange={(e) => setInputCcValue(e.target.value)}
                          className="compose_input input_to"
                        />
                      </AutoComplete>
                    </div>
                  </div>
                </div>
              )}
              {(showBcc || replyComposeData?.bcc?.length > 0) && (
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingTop: "5px",
                    paddingBottom: "6px",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <span style={{ paddingLeft: "10px" }}>
                      <strong>Bcc:</strong> &nbsp;&nbsp;
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                    >
                      <div
                        className="email-tags"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                        }}
                      >
                        {replyComposeData?.bcc?.map((email) => (
                          <Tag
                            key={email}
                            closable
                            onClose={() => handleEmailRemove(email, "bcc")}
                            style={{ marginBottom: "8px", marginRight: "8px" }}
                          >
                            {email}
                          </Tag>
                        ))}
                      </div>
                      <AutoComplete
                        options={emailOptions}
                        placeholder="Enter Email"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        value={inputBccValue}
                        onSearch={handleSearch}
                        onChange={setInputBccValue}
                        onSelect={handleBccEmailSelect}
                        notFoundContent={loading ? <Spin size="small" /> : null}
                        dropdownClassName="custom-autocomplete-dropdown"
                        style={{
                          flex: "1",
                          minWidth: "200px",
                          width: "100%",
                        }}
                      >
                        <Input
                          onPressEnter={handleKeyDown}
                          name="bcc"
                          value={inputBccValue}
                          onChange={(e) => setInputBccValue(e.target.value)}
                          className="compose_input input_to"
                        />
                      </AutoComplete>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div style={{ borderBottom: "1px solid #ccc", marginBottom: "10px" }}>
            <Input
              placeholder="Subject"
              name="subject"
              value={replyComposeData?.subject}
              // value={mailType == "forward" ? `Fwd: ${replyComposeData?.subject}` : `Re: ${replyComposeData?.subject}`}
              onFocus={() => setShowSummary(true)}
              onChange={(e) => handleInputChange(e.target.value, "subject")}
              className="compose_input"
            />
          </div>
          <div style={{ position: "relative" }}>
            <div
              ref={suggestionsRef}
              style={{
                position: "absolute",
                top: "50px",
                display: "flex",
                // justifyContent: "space-between",
                gap: "5px",
                // pointerEvents: "none",
              }}
            >
              {replySuggestion?.length > 0 &&
                replySuggestion?.map((item, index) => (
                  <div
                    onClick={() => handleReplySuggestionClick(item)}
                    style={{
                      // pointerEvents: "auto",
                      cursor: "pointer",
                      border: "1px solid",
                      borderRadius: "10px",
                      padding: "5px",
                      zIndex: "1",
                      background: "#fff",
                      color: SUGGESTION_COLOR[index],
                      textAlign: "center",
                    }}
                  >
                    {item}
                  </div>
                ))}
            </div>
            <ReactQuill
              ref={quillRef}
              className="custom-quill custom-quill-reply"
              // value={localBody}
              value={replyComposeData?.body}
              onChange={handleBodyChange}
              onFocus={() => setShowSummary(true)}
              // onPaste={handlePaste}

              // onBlur={() => {
              //   // dispatch(updateComposeMailField(replyComposeData?._id, "body", localBody))
              // if (replyComposeData?._id) {
              //   dispatch(updateComposeMailField(replyComposeData?._id, 'body', localBody));
              // } else {
              //   dispatch(updateComposeMailWithIdNull(replyComposeData?.uid,'body', localBody));
              // }
              // }}
              modules={modules}
              formats={formats}
              style={{
                minHeight: "200px",
                maxHeight: "300px",
                marginBottom: "10px",
              }}
            />
            <div
              className="attachment-list"
              style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
            >
              {attachments?.length > 0 &&
                attachments?.map((file) => (
                  <div
                    key={file?.uid}
                    style={{ marginBottom: "8px", marginRight: "8px" }}
                  >
                    <span>{file?.filename}</span>
                    <Tag
                      closable
                      onClose={() => {
                        let updatedAttachments = attachments?.filter(
                          (attachment) => attachment?.uid !== file?.uid
                        )
                        setAttachments(
                          updatedAttachments
                        );
                        setReplyComposeData({ ...replyComposeData, attachments: updatedAttachments })
                        handleEmailAttachmentRemove(file);

                        // saveDraft();
                      }}
                      style={{ marginLeft: "8px" }}
                    >
                      Remove
                    </Tag>
                  </div>
                ))}
            </div>
          </div>
          {/* <ReactQuill
          value={replyContent}
          onChange={handleReplyChange}
          placeholder="Type your reply here..."
        />
        <div
          onClick={() => setShowReplyMailDetails(!showReplyMailDetails)}
          style={{
            cursor: "pointer",
            width: "fit-content",
            backgroundColor: "aliceblue",
            padding: "0 5px",
            marginTop: "5px",
          }}
        >
          <EllipsisOutlined style={{ fontSize: "25px" }} />
        </div>
        {showReplyMailDetails && (
          <>
            <hr />
            <div className="previous-mails" >
              <div className="previous-mail">
                <div className="mail-header">
                  <div>{mail?.Date}</div>
                  <div>{mail?.sender?.emailAddress?.address}</div>
                  <div>{mail?.Subject}</div>
                </div>
                <div
                  className="mail-body"
                  dangerouslySetInnerHTML={{ __html: mail?.Body }}
                ></div>
              </div>
            </div>
          </>
        )} */}
          {/* <div
            className="attachment-list"
            style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
          >
            {attachments?.length > 0 &&
              attachments?.map((file) => (
                <div
                  key={file?.uid}
                  style={{ marginBottom: "8px", marginRight: "8px" }}
                >
                  <span>{file?.filename}</span>
                  <Tag
                    closable
                    onClose={() => {
                      let updatedAttachments =attachments?.filter(
                        (attachment) => attachment?.uid !== file?.uid
                      )
                      setAttachments(
                        updatedAttachments
                      );
                      setReplyComposeData({...replyComposeData,attachments:updatedAttachments})
                      handleEmailAttachmentRemove(file);

                      // saveDraft();
                    }}
                    style={{ marginLeft: "8px" }}
                  >
                    Remove
                  </Tag>
                </div>
              ))}
          </div> */}
        </div>
      </Spin>
      <div className="compose-footer">
        {!(localBody === "<p><br></p>" || localBody == "") && (
          <Popover
            content={
              <div style={{ maxWidth: "450px", maxHeight: "80vh", overflowY: "auto" }}>
                <div dangerouslySetInnerHTML={{ __html: rewriteText }} />
                <div style={{ display: "flex", gap: "10px" }}>
                  <span className="div_button" onClick={() => {
                    // setLocalBody(rewriteText);
                    setReplyComposeData({ ...replyComposeData, body: rewriteText })
                    setOpenRewritePopover(false);
                  }}>
                    Replace
                  </span>
                  <span
                    className="div_button"
                    onClick={() => setOpenRewritePopover(false)}
                  >
                    Ignore
                  </span>
                </div>
              </div>
            }
            title={false}
            trigger="click"
            open={openRewritePopover}
          // onOpenChange={handleOpenChange}
          >
            {/* {!(localBody === "<p><br></p>" || localBody == "") && ( */}
            <div className="rewrite_oceannai" onClick={rewriteWithOceannAI}>
              <Icon
                icon={"mingcute:pencil-2-ai-line"}
                style={{ fontSize: "20px" }}
              />
              <span>Rewrite with Oceann AI</span>
            </div>
            {/* )} */}
          </Popover>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            className="btn"
            onClick={handleSendClick}
            disabled={isSendingMail}
          >
            Send{" "}
            {isSendingMail && (
              <Spin indicator={<LoadingOutlined spin />} size="small" />
            )}
          </button>
          <Tooltip title="Attach files">
            <label style={{ cursor: "pointer", marginLeft: "15px" }}>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              {/* <PaperClipOutlined /> */}
              <Icon
                icon={"majesticons:attachment"}
                style={{ fontSize: "20px" }}
              />
            </label>
          </Tooltip>
          <MenuComponent
            localBody={localBody}
            setLocalBody={setLocalBody}
            quillRef={quillRef}
            composeData={replyComposeData}
            setReplyComposeData={setReplyComposeData}
            senderEmail={senderEmail}
          />
          <Tooltip title="Insert signature">
            <div
              style={{ cursor: "pointer"}}
              // onClick={handleInsertSignature}
            > <SignatureEditor/>
              {/* <Icon icon={"mi:pen"} style={{ fontSize: "20px" }} /> */}
            </div>
          </Tooltip>
          <Tooltip title="Auto reply">
            <div className="auto-reply" onClick={() => handleAutoReply()}>
              <Icon icon={"codicon:reply"} style={{ fontSize: "20px" }} />
              <span>Auto reply</span>
            </div>
          </Tooltip>
          {/* {showRevertButton &&  */}
          <>
            <Tooltip title="Undo">
              <div className="auto-reply" onClick={() => {
                handleUndo();
                // setShowRevertButton(false);
              }}>
                <Icon icon={"carbon:undo"} style={{ fontSize: "20px" }} />
                <span>Undo</span>
              </div>
            </Tooltip>
            <Tooltip title="Redo">
              <div className="auto-reply" onClick={() => {
                handleRedo();
                // setShowRevertButton(false);
              }}>
                <span>Redo</span>
                <Icon icon={"carbon:redo"} style={{ fontSize: "20px" }} />
              </div>
            </Tooltip>
          </>
          {/* } */}
        </div>
        <div className="d-flex align-items-center">
          {/* <Tooltip title="Revert">
          <div className="auto-reply">
            <Icon
              icon={"carbon:undo"}
              style={{ fontSize: "20px" }}
            />
            <span>Revert</span>
          </div>
          </Tooltip> */}
          {/* <Tooltip title="Delete">
            <div
              style={{ cursor: "pointer", paddingTop:"5px", marginLeft: "10px" }}
              onClick={() => handleReplyDraftDelete()}
            >
              <Icon
                icon={"material-symbols:delete-outline"}
                style={{ fontSize: "20px" }}
              />
            </div>
          </Tooltip> */}
        </div>
      </div>
    </div>
  );
};

export default ComposeReply;
