import React, { useEffect, useState } from "react";
import AiLoader from "../../../../shared/components/PL/tcov/AiLoader";

const AiBunkerAnalysis = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around", // Distribute items between top and bottom
        alignItems: "center",
        height: "350px",
        margin:"80px"
      }}
    >
      <div class="textWrapper">
        <p class="text-bunker" style={{fontSize:"2.8rem"}} >AI Bunker Optimizing....</p>
        <div class="invertbox"></div>
      </div>
      {loading ? <AiLoader text={"Coming Soon..."} /> : null}
    </div>
  );
};

export default AiBunkerAnalysis;
