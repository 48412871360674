import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import dayjs from 'dayjs'
import { Skeleton } from 'antd'
import { getAPICall } from '../../shared'

const AllMailChart = () => {
  const [chartData, setChartData] = useState(null)

  const getAllData = async () => {
    const url = `${process.env.REACT_APP_MT_URL}bunker/get-last-seven_day`

    try {
      let response = await getAPICall(url)
      const data = response.data

      // Process the data
      const ports = data.map(item => item.port_name)
      const hsfoPrices = data.map(item => item.fuel_type.find(fuel => fuel.Grade === 'HSFO')?.Price || '-')
      const mgoPrices = data.map(item => item.fuel_type.find(fuel => fuel.Grade === 'MGO')?.Price || '-')
      const vlsfoPrices = data.map(item => item.fuel_type.find(fuel => fuel.Grade === 'VLSFO')?.Price || '-')

      setChartData({ ports, hsfoPrices, mgoPrices, vlsfoPrices })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllData()
  }, [])

  const getOption = () => {
    const todayDate = dayjs().format('ddd, Do MMMM YYYY')
    return {
      title: {
        text: `Fuel Prices($) by Port (${todayDate})`,
        textStyle: {
          fontSize: 16, // Main title font size
          rich: {
            date: {
              fontSize: 12, // Smaller font size for the date
              color: '#005792', // Optional: Different color for the date
            }
          }
        }
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['HSFO', 'MGO', 'VLSFO'],
        // top: '8%', 
        left: 'right',
        icon: 'circle', // Optionally, change the icon shape for better aesthetics
      },
      color: ['#005792', '#00bbf0', '#fdb44b'],
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      // toolbox: {
      //   feature: {
      //     saveAsImage: {}
      //   }
      // },
      xAxis: {
        type: 'category',
        boundaryGap: true, // Bar charts generally have boundary gaps
        data: chartData ? chartData.ports : Array(5).fill(''),
        axisLabel: {
          rotate: 45, // Rotate labels to fit all
          interval: 0 // Ensure all labels are shown
        }
      },
      yAxis: {
        type: 'value',
        min: 0, // Adjust based on your data
        max: 1000, // Adjust based on your data
        interval: 100 // Adjust based on your data
      },
      series: [
        {
          name: 'HSFO',
          type: 'bar',
          data: chartData ? chartData.hsfoPrices : Array(5).fill('-'),
          barWidth: '20%', // Adjust the width of the bars
        },
        {
          name: 'MGO',
          type: 'bar',
          data: chartData ? chartData.mgoPrices : Array(5).fill('-'),
          barWidth: '20%', // Adjust the width of the bars
        },
        {
          name: 'VLSFO',
          type: 'bar',
          data: chartData ? chartData.vlsfoPrices : Array(5).fill('-'),
          barWidth: '20%', // Adjust the width of the bars
        }
      ]
    }
  }



  // const getOption = () => {
  //   const todayDate = dayjs().format('ddd, Do MMMM YYYY');
  //   return {
  //     title: {
  //       text: `Fuel Prices($) by Port (${todayDate})`,
  //       textStyle: {
  //         fontSize: 16,
  //       },
  //     },
  //     tooltip: {
  //       trigger: 'axis',
  //     },
  //     legend: {
  //       data: ['HSFO', 'MGO', 'VLSFO'],
  //       left: 'right',
  //       icon: 'circle',
  //     },
  //     color: ['#005792', '#00bbf0', '#fdb44b'], // Custom colors for bars
  //     grid: {
  //       left: '3%',
  //       right: '4%',
  //       bottom: '3%',
  //       containLabel: true,
  //     },
  //     xAxis: {
  //       type: 'category',
  //       boundaryGap: true,
  //       data: chartData ? chartData.ports : Array(5).fill(''),
  //       axisLabel: {
  //         rotate: 45,
  //         interval: 0,
  //       },
  //     },
  //     yAxis: {
  //       type: 'value',
  //       min: 0,
  //       max: 1000,
  //       interval: 100,
  //     },
  //     series: [
  //       {
  //         name: 'HSFO',
  //         type: 'bar',
  //         data: chartData ? chartData.hsfoPrices : Array(5).fill('-'),
  //         barWidth: '20%',
  //       },
  //       {
  //         name: 'MGO',
  //         type: 'bar',
  //         data: chartData ? chartData.mgoPrices : Array(5).fill('-'),
  //         barWidth: '20%',
  //       },
  //       {
  //         name: 'VLSFO',
  //         type: 'bar',
  //         data: chartData ? chartData.vlsfoPrices : Array(5).fill('-'),
  //         barWidth: '20%',
  //       },
  //     ],
  //   };
  // };
  

  return (
    <div>
      {chartData == null ? (
        <Skeleton variant='rectangular' width='100%' height={300} animation='pulse' className='globalLoading'>
          Loading...
        </Skeleton>
      ) : (
        <ReactECharts style={{ height: '350px' }} option={getOption()} />
      )}
    </div>
  )
}

export default AllMailChart
