import React, { useState } from "react";
import { Modal, Tabs } from "antd";
// import "../tcov/AiTracker.css";
import "../tcov/AIVoyage.scss";
import AiLoader from "../../../../shared/components/PL/tcov/AiLoader";
import LiveOpenPosition from "./LiveOpenPosition";
import HistoricalData from "./HistoricalData";

const { TabPane } = Tabs;

const AiTracker = () => {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabClick = (key) => {
    setActiveTab(key);
  };
  return (
    <>
      <div className="menu-bar2">
        <div key="1"  className={`menu-item ${activeTab=="1"? "ai-active" : ""}`} onClick={() => handleTabClick("1")}>
          Live/Open Position
        </div>
        <div key="2" className={`menu-item ${activeTab=="2"? "ai-active" : ""}`}  onClick={() => handleTabClick("2")}>
          Historical Data
        </div>
      </div>
      <div className="tab-content">
        {activeTab === "1" && (
          <div style={{maxHeight:"400px",overflowY:"auto"}}>
            <h4>Live/Open Position</h4>
            <LiveOpenPosition />
          </div>
        )}
        {activeTab === "2" && (
          <div style={{maxHeight:"400px",overflowY:"auto"}}>
            <h4>Historical Data</h4>
            <HistoricalData />
          </div>
        )}
      </div>
    </>
  );
};

export default AiTracker;
